import { ChangeDetectorRef, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { LoadingService } from "@app/core";
import { alertApiError } from "@app/core/helpers";
import { CouponInfo } from "@app/models";
import { CouponsService } from "@app/services";
import { ToastService } from "@app/shared/ui";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CouponSelectModalComponent } from "../coupon-select-modal/coupon-select-modal.component";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-coupon-input",
  templateUrl: "./coupon-input.component.html",
  styleUrls: ["./coupon-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CouponInputComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => CouponInputComponent)
    }
  ]
})
export class CouponInputComponent implements ControlValueAccessor, Validator {
  private _couponsList: CouponInfo[];

  /**
   * The `autoOpen` input property is used to automatically open the coupon selection modal window if no coupon is currently selected.
   * This behavior is triggered when the component is initialized.
   * If `autoOpen` is true, `couponsList` should exist and be populated with available coupons.
   */
  @Input() autoOpen = false;

  @Input() set couponsList(val: CouponInfo[]) {
    this._couponsList = val;
    this._onValidatorChange?.call([]);
  }

  @Input() disableEmpty = false;
  @Input() displayInactiveCoupon = false;

  couponId?: string;
  selectedCoupon: CouponInfo;
  selectedCouponTitle = "";
  control?: FormControl<string>;
  controlDisabled = false;
  isSelectedCouponActive = false;

  constructor(
    private _modalSvc: NgbModal,
    private _couponsSvc: CouponsService,
    private _loader: LoadingService,
    private _toastSvc: ToastService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  get isSelectCouponDisabled() {
    return this.disableEmpty && (!this._couponsList || this._couponsList.length == 0);
  }

  selectCoupon() {
    if (this.isSelectCouponDisabled || this.controlDisabled) return;

    const modal = this._modalSvc.open(CouponSelectModalComponent, { backdrop: "static", size: "xxl", centered: true });
    const comp: CouponSelectModalComponent = modal.componentInstance;
    comp.selectedCouponId = this.couponId;
    if (this._couponsList) comp.couponList = this._couponsList;

    modal.result
      .then(
        (coupon: CouponInfo) => {
          this.couponId = coupon.id;
          this.selectedCoupon = coupon;
          this._onChange(coupon.id);
        },
        (rejectReason) => {}
      )
      .finally(() => {
        this._onTouched();
        this.control?.updateValueAndValidity();
      });
  }

  writeValue(couponId: string): void {
    this.couponId = couponId;
    this.selectedCoupon = this._couponsList?.find((x) => x.id == couponId);

    if (this.autoOpen && !this.couponId && !this.selectedCoupon && this._couponsList?.length) {
      this.selectCoupon();
    }

    if (couponId && !this.selectedCoupon) {
      // Load Coupon if not exist in list:
      this._couponsSvc
        .getCouponById(couponId)
        .pipe(
          this._loader.pipe(),
          alertApiError(this._toastSvc),
          filter((x) => !!x)
        )
        .subscribe((coupon) => {
          if (this.displayInactiveCoupon) {
            this.selectedCoupon = coupon;
          } else {
            this.selectedCouponTitle = coupon.title;
            this._changeDetectorRef.markForCheck();
          }
        });
    }
  }
  private _onChange: (couponId?: string) => void;
  registerOnChange(onChange: (couponId?: string) => void): void {
    this._onChange = onChange;
  }
  private _onTouched: () => void;
  registerOnTouched(onTouched: () => void): void {
    this._onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.controlDisabled = isDisabled;
    this.isSelectedCouponActive = !!this._couponsList?.find((x: CouponInfo) => x.id === this.couponId);

    this._changeDetectorRef.markForCheck();
  }
  validate(control: FormControl<string>): ValidationErrors {
    if (this.control != control) this.control = control;
    if (!this._couponsList || !control.value) return null;

    const coupon = this._couponsList.find((x) => x.id == control.value);
    if (!coupon) {
      control.markAsTouched();
      return { couponNotActive: true };
    }
    return null;
  }
  private _onValidatorChange: () => void;
  registerOnValidatorChange(onValidatorChange: () => void): void {
    this._onValidatorChange = onValidatorChange;
  }
}
