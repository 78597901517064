import { Injectable } from "@angular/core";
import { Subscription } from "@app/models";

@Injectable({ providedIn: "root" })
export class CurrentSubscriptionPlanService {
  private subscription: Subscription;

  getSubscriptionPlanId(): number {
    return this.subscription?.subscriptionPlanId;
  }

  setSubscription(subscriptionPlan: Subscription): void {
    this.subscription = subscriptionPlan;
  }
}
