import { Injectable } from "@angular/core";
import { SortEvent } from "@app/shared/directives/advanced-sortable.directive";

@Injectable({
  providedIn: "root"
})
export class TableService {
  compare = (v1: string, v2: string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

  sort(tables: any[], column: string, direction: string): any[] {
    if (direction === "" || column === "") {
      return tables;
    } else {
      return [...tables].sort((a, b) => {
        const res = this.compare(`${a[column]}`, `${b[column]}`);
        return direction === "asc" ? res : -res;
      });
    }
  }

  sortTable(
    tables: any[],
    { column, direction }: SortEvent,
    sortByNumber: boolean = false,
    compareFn: (a: any, b: any) => number | null = null
  ): any[] {
    if (direction === "" || column === "") {
      return tables;
    } else {
      if (compareFn) {
        return [...tables].sort((a, b) => {
          const res = compareFn(a, b);

          return direction === "asc" ? res : -res;
        });
      }
      return [...tables].sort((a, b) => {
        const firstValue = sortByNumber ? a[column] : `${a[column]}`;
        const secondValue = sortByNumber ? b[column] : `${b[column]}`;
        const res = this.compare(firstValue, secondValue);

        return direction === "asc" ? res : -res;
      });
    }
  }

  /** Filters items by text.
   * @param items - filtering items.
   * @param text - text filtering by.
   * @param valuesFn - function to retrieve value of `item`, that should be checked for `text`.
   */
  filterByText<T>(items: T[] | undefined | null, text: string, valuesFn?: (x: T) => any[]): T[] {
    if (!items || !text) return items;

    valuesFn ??= (item) => {
      return Object.getOwnPropertyNames(item).map((x) => item[x]);
    };

    const result = items.filter((item) => {
      const values = valuesFn(item);
      return values.some((x) => {
        if (x == null) return false;

        let value: string;
        switch (typeof x) {
          case "string":
          case "object":
            value = x.toString().toLowerCase();
            break;
          case "number":
          case "bigint":
          case "boolean":
            value = x.toString();
            break;
          default:
            throw new Error(`Unsupported type: ${typeof x}`);
        }

        return value.includes(text);
      });
    });
    return result;
  }

  header(headers, column): any {
    headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    return headers;
  }

  pagination(tables: any[], page, pageSize): any[] {
    return tables.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
  }

  setFilterLocalStorage(filter, key) {
    localStorage.setItem(key, JSON.stringify(filter));
  }

  getFilterLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key)) as any;
  }

  setSortLocalStorage(sorts, key) {
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(sorts));
  }

  getSortLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key)) as SortEvent;
  }

  clearLocalStorage(keySort, keyFilter) {
    localStorage.removeItem(keySort);
    localStorage.removeItem(keyFilter);
  }
}
