import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { ImportAccounts, ImportLocations, ImportResult, ImportUsers, TemplateType } from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class DataImportService {
  private serviceUrl = "import";
  constructor(private http: DataService, public https: HttpClient) {}

  public importAccounts(model: ImportAccounts): Observable<ImportResult> {
    return this.http.post<ImportResult>(`${this.serviceUrl}/accounts`, model);
  }

  public importLocations(model: ImportLocations): Observable<ImportResult> {
    return this.http.post<ImportResult>(`${this.serviceUrl}/locations`, model);
  }

  public importUsers(model: ImportUsers): Observable<ImportResult> {
    return this.http.post<ImportResult>(`${this.serviceUrl}/users`, model);
  }

  public getTemplate(type: TemplateType): Observable<Blob> {
    return this.http.getFull(`${this.serviceUrl}/template?type=${type}`).pipe(map((res) => res.body as Blob));
  }
}
