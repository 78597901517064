import { Component, Input } from "@angular/core";
import { BrandColorPalette, Template, TemplatesCarouselData } from "@app/models";
import { BehaviorSubject, combineLatest, interval } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "app-templates-carousel",
  templateUrl: "./templates-carousel.component.html",
  styleUrls: ["./templates-carousel.component.scss"]
})
export class TemplatesCarouselComponent {
  private readonly defaultInterval = 7000;
  private readonly delayBeforeUpdate = 30;

  private dataToUpdate: TemplatesCarouselData | null = null;

  brandColorsPalette: BrandColorPalette | undefined;
  nextSlideIndex = 0;
  carouselEmpty = false;

  templates$ = new BehaviorSubject<Template[]>([]);
  private nextSlideInterval$ = new BehaviorSubject<number>(0);

  private timer$ = this.nextSlideInterval$.pipe(switchMap((timeout) => interval(timeout)));

  ready$ = combineLatest([this.templates$, this.timer$]).pipe(
    tap(([list]) => {
      this.prepareNextSlide(list);
    }),
    map(([list]) => !!list.length || this.carouselEmpty)
  );

  @Input() set carouselData(data: TemplatesCarouselData | null | undefined) {
    if (data) {
      const { withPause, updateOnNewCircle } = data;

      if (this.templates$.value.length && updateOnNewCircle) {
        this.dataToUpdate = data;
      } else {
        this.generateSlidesListInfo(data);
      }
    }
  }

  private prepareNextSlide(list: Template[]): void {
    this.nextSlideIndex = this.nextSlideIndex + 1 < list.length ? this.nextSlideIndex + 1 : 0;

    const isLastSlide = this.nextSlideIndex === list?.length - 1;
    const nextSlide = list[this.nextSlideIndex];
    const displayTime = nextSlide?.configuration?.options?.displayTime || this.defaultInterval;

    this.nextSlideInterval$.next(displayTime);

    if (isLastSlide && this.dataToUpdate) {
      setTimeout(() => {
        if (this.dataToUpdate) {
          this.generateSlidesListInfo(this.dataToUpdate as TemplatesCarouselData);

          this.dataToUpdate = null;
        }
      }, Math.max(0, displayTime - this.delayBeforeUpdate));
    }
  }

  private generateSlidesListInfo(data: TemplatesCarouselData): void {
    let { templateList = [], brandColorsPalette } = data;
    this.brandColorsPalette = brandColorsPalette;

    this.carouselEmpty = !templateList?.length;

    this.templates$.next(templateList);

    this.nextSlideIndex = 0;
  }
}
