import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ITemplatePageComponentData, PrintTemplateComponent, TemplatePreviewPageComponent } from "./template-page";
import { TemplatesCarouselPreviewComponent } from "./templates-carousel-preview";

const routes: Routes = [
  { path: "print/:templateId", component: PrintTemplateComponent },
  { path: "preview/:templateId", component: TemplatePreviewPageComponent },
  { path: "print-global/:templateId", component: PrintTemplateComponent, data: <ITemplatePageComponentData>{ isGlobalTemplate: true } },
  {
    path: "preview-global/:templateId",
    component: TemplatePreviewPageComponent,
    data: <ITemplatePageComponentData>{ isGlobalTemplate: true }
  },

  { path: "carousel-preview/:locationId", component: TemplatesCarouselPreviewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRoutingModule {}
