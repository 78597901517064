export class ColorPickerData {
  static readonly whiteColor = "#FFFFFF";

  static readonly solidColors = [
    "#000",
    "#545454",
    "#737373",
    "#A6A6A6",
    "#D9D9D9",
    ColorPickerData.whiteColor,
    "#FF3131",
    "#FF5757",
    "#FF66C4",
    "#CB6CE6",
    "#8C52FF",
    "#5E17EB",
    "#0097B2",
    "#0CC0DF",
    "#5CE1E6",
    "#38B6FF",
    "#5271FF",
    "#004AAD",
    "#00BF63",
    "#7ED957",
    "#C1FF72",
    "#FFDE59",
    "#FFBD59",
    "#FF914D"
  ];

  static readonly gradients = [
    "linear-gradient(90deg, #000 0%, #666 100.61%)",
    "linear-gradient(90deg, #000 0%, #B68415 100.61%)",
    "linear-gradient(90deg, #000 0%, #2F2DB5 100.61%)",
    "linear-gradient(90deg, #A6A6A6 0%, #F9F9F9 100.61%)",
    "linear-gradient(90deg, #FFF1B3 0%, #FFB0F2 100.61%)",
    "linear-gradient(90deg, #C6F7D5 0%, #9ABFFC 100.61%)",
    "linear-gradient(90deg, #FF3632 0%, #FF8A4B 100.61%)",
    "linear-gradient(90deg, #F7575B 0%, #9753F0 100.61%)",
    "linear-gradient(90deg, #666FF8 0%, #E768CC 100.61%)",
    "linear-gradient(90deg, #0A4BB0 0%, #BD69E2 100.61%)",
    "linear-gradient(90deg, #8959FD 0%, #61D4E9 100.61%)",
    "linear-gradient(90deg, #58D8E3 0%, #0858B3 100.61%)",
    "linear-gradient(90deg, #8458F6 0%, #10B275 100.61%)",
    "linear-gradient(90deg, #0A9CAB 0%, #6FD262 100.61%)",
    "linear-gradient(90deg, #1EC3D5 0%, #E9DC65 100.61%)",
    "linear-gradient(90deg, #FFD858 0%, #FF9B4F 100.61%)",
    "linear-gradient(90deg, #FF6CBF 0%, #FFD264 100.61%)",
    "linear-gradient(90deg, #8D54F5 0%, #F78C5A 100.61%)"
  ];

  static isItGradient(color: string = ""): boolean {
    return color.toLowerCase().includes("linear-gradient(");
  }
}
