// https://stackblitz.com/edit/ng-select-ejmvk8?file=app%2Fapp.component.ts
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { DropdownPosition, NgSelectModule } from "@ng-select/ng-select";
import { TranslocoModule } from "@ngneat/transloco";
import { InputComponentBase } from "../../base/input-component-base";

@Component({
  selector: "app-select-multiple",
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, TranslocoModule],
  templateUrl: "./select-multiple.component.html",
  styleUrls: ["./select-multiple.component.scss"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectMultipleComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectMultipleComponent), multi: true }
  ]
})
export class SelectMultipleComponent<TValue, TItem> extends InputComponentBase<TValue[] | null> {
  @Input() items?: TItem[];
  @Input() bindValue = "id";
  @Input() bindLabel = "name";
  @Input() bindLabelFn: (x: TItem) => string = (x) => x[this.bindLabel];
  @Input() isAllSelectionEnabled = true;
  @Input() clearable = false;
  @Input() dropdownPosition: DropdownPosition = "auto";

  @Input() set appendOptionsToBody(value: boolean) {
    this.appendOptionsTo = value ? "body" : null;
  }

  @Input() set isAllSelected(value: boolean) {
    this._isAllSelected = value;
    const formControlValue = this.formControl?.value;

    if (value && formControlValue?.length) {
      setTimeout(() => {
        this.onChange?.([]);
        this._updateSelection([]);
      });
    } else {
      this._updateSelection(formControlValue);
    }
  }

  @Output() isAllSelectedChange = new EventEmitter<boolean>();

  allValue = {} as TValue; // Special object for 'All' value.
  selectedOptions: TValue[] = [];
  disabled = false;

  private _isAllSelected = false;
  private appendOptionsTo: "body" | null = null;

  get appendTo(): string {
    return <string>this.appendOptionsTo;
  }

  selectionChanged(selection: TValue[]) {
    let isAllSelected = selection.some((x) => x === this.allValue);

    // Remove ALL selection on another selected:
    if (isAllSelected && selection.length > 1 && this._isAllSelected) {
      isAllSelected = false;
    }

    if (isAllSelected != this._isAllSelected) {
      // Update 'isAllSelected':
      this._isAllSelected = isAllSelected;
      this.isAllSelectedChange.emit(isAllSelected);
    }

    const newValue = isAllSelected ? [] : selection.filter((x) => x != this.allValue);
    this.onChange?.(newValue);
    this._updateSelection(newValue);
  }

  override writeValue(value: TValue[] | null): void {
    if (value?.length) {
      setTimeout(() => {
        this._isAllSelected = false;
        this.isAllSelectedChange.emit(false);
      });
    }
    this._updateSelection(value);
  }
  override setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private _updateSelection(newValue: TValue[] | null) {
    this.selectedOptions = newValue instanceof Array ? [...newValue] : [];
    if (this._isAllSelected) this.selectedOptions.unshift(this.allValue);
  }
}
