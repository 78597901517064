import { Component, OnInit } from "@angular/core";

import { User } from "@app/models";
import { UserService } from "@app/services";
import { AccountService } from "@app/core/services/account.service";
import { ProfileModel } from "@app/core/models/profile-model";
import { AuthService } from "@app/core";

@Component({ templateUrl: "home.component.html" })
export class HomeComponent implements OnInit {
  loading = false;
  user: ProfileModel;
  userFromApi: User;

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  login() {
    this.loading = true;
    this.authService.login();
  }
}
