import { Injectable } from "@angular/core";
import {
  ImportResult,
  ProductItem,
  ProductItemCategoryGetDto,
  ProductItemFileImportPostDto,
  ProductItemGetDto,
  ProductItemListDto,
  ProductItemLocations,
  ProductItemPostDto,
  ProductItemPutDto
} from "@app/models";
import { DataService } from "@app/core/services/data.service";
import { Observable, of } from "rxjs";
import { AuthService } from "@app/core";

@Injectable({ providedIn: "root" })
export class ProductItemService {
  serviceUrl = "productitems";

  constructor(private http: DataService, private authSvc: AuthService) {}

  getAll(): Observable<ProductItemListDto[]> {
    return this.http.get(`${this.serviceUrl}`);
  }

  getProductsLocationNone(): Observable<ProductItemListDto[]> {
    return this.http.get(`${this.serviceUrl}/nonelocation`);
  }

  getProductsByLocationId(locationId: number): Observable<ProductItemListDto[]> {
    return this.http.get(`${this.serviceUrl}/bylocation/${locationId}`);
  }

  getById(id: number): Observable<ProductItemGetDto> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  create(product: ProductItemPostDto): Observable<ProductItemGetDto> {
    return this.http.post(`${this.serviceUrl}`, product);
  }

  update(product: ProductItemPutDto): Observable<ProductItemGetDto> {
    return this.http.put(`${this.serviceUrl}`, product);
  }

  createProducts(products: ProductItemFileImportPostDto[]): Observable<ImportResult> {
    return this.http.post(`${this.serviceUrl}/add/products`, products);
  }

  delete(id: number) {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  deleteProducts(items: number[]) {
    return this.http.post(`${this.serviceUrl}/delete/products`, items);
  }

  getCategories(): Observable<ProductItemCategoryGetDto[]> {
    return this.http.get(`${this.serviceUrl}/categories`);
  }

  getProductLocations(productId: number): Observable<ProductItemLocations> {
    return this.http.get(`${this.serviceUrl}/${productId}/locations`);
  }

  updateProductLocations(productId: number, productLocations: ProductItemLocations): Observable<ProductItemLocations> {
    return this.http.post(`${this.serviceUrl}/${productId}/locations`, productLocations);
  }

  setFilterLocalStorage(filter) {
    localStorage.setItem("filterProducts", JSON.stringify(filter));
  }

  getFilterLocalStorage() {
    return JSON.parse(localStorage.getItem("filterProducts")) as ProductItem;
  }
}
