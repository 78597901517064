import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { UserService } from "@app/services";
import { ToastService } from "@app/shared/ui";
import { TranslocoService } from "@ngneat/transloco";
import { BehaviorSubject } from "rxjs";
import { Unsubscriber } from "../base/unsubscriber.component";

@Component({
  selector: "app-pincode-management",
  templateUrl: "./pincode-management.component.html",
  styleUrls: ["./pincode-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PincodeManagementComponent extends Unsubscriber implements OnInit {
  @Input() userId: number;

  pincodeHidden = true;
  canGeneratePincode$ = new BehaviorSubject<boolean>(true);
  canSendPincode$ = new BehaviorSubject<boolean>(true);

  form = new FormGroup({
    pincode: new FormControl({ value: "", disabled: true })
  });

  constructor(private toast: ToastService, private transloco: TranslocoService, private userService: UserService) {
    super();
  }

  ngOnInit(): void {
    if (!!this.userId) {
      this.sub = this.userService.getPin(this.userId).subscribe(({ pin }) => {
        this.form.controls.pincode.setValue(pin);
        this.canSendPincode$.next(!!pin);
      });
    }
  }

  toggleIcon(): void {
    this.pincodeHidden = !this.pincodeHidden;
  }

  sendPincode(): void {
    this.canSendPincode$.next(false);

    this.sub = this.userService.sendUserPin(this.userId).subscribe(
      () => {
        const message = this.transloco.translate("users.pincodeSentSuccess");
        this.toast.success(message);
        this.canSendPincode$.next(true);
      },
      ({ error }) => {
        this.toast.danger(error?.Message);
        this.canSendPincode$.next(true);
      }
    );
  }

  generatePincode(): void {
    this.canGeneratePincode$.next(false);

    this.sub = this.userService.resetUserPin(this.userId).subscribe(
      ({ pin, warningMessage }) => {
        if (pin) {
          const message = this.transloco.translate("users.pincodeResetSuccess");
          this.form.controls.pincode.setValue(pin);
          this.canSendPincode$.next(true);
          this.toast.success(message);
        }

        if (warningMessage) {
          this.toast.danger(warningMessage);
        }

        this.canGeneratePincode$.next(true);
      },
      ({ error }) => {
        this.toast.danger(error?.Message);
        this.canGeneratePincode$.next(true);
      }
    );
  }
}
