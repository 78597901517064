export class ProductItemDto {
  id: number;
  productId: string;
  productName: string;
  manufacturer: string;
  price: number;
  categoryIds: number[];
}

export class ProductItem extends ProductItemDto {
  productCategoryId: string;
  productCategory: string;
  productPrice: string;
  locationId: string;
  selected: boolean;
}

export interface ProductItemListRow extends ProductItem {
  categoryNames: string;
  categoryNamesTooltip: string[];
}

export class ProductItemLocations {
  productItem: number;
  locations: number[];
}

export class ProductItemGetDto {
  product: ProductItemDto;
  categoryIds: number[];
}

export class ProductItemListDto {
  product: ProductItemDto;
  categoryNames: string[];
}

export class ProductItemFileImportPostDto {
  product: ProductItemDto;
  externalCategoryIds: number[];
  categoryNames: string[];
}

export class ProductItemPostDto {
  productId: string;
  productName: string;
  manufacturer: string;
  price: number;
  categoryIds: number[];
}

export class ProductItemPutDto {
  id: number;
  productId: string;
  productName: string;
  manufacturer: string;
  price: number;
  categoryIds: number[];
}

export class ProductItemCategoryGetDto {
  id: number;
  name: string;
  isActive: boolean;
}
