import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Account, Brand, BrandCheckName, BrandFilterParameters } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BrandService {
  private serviceUrl = "brand";
  constructor(private http: DataService) {}

  createBrand(brand: Brand): Observable<Brand> {
    return this.http.post(`${this.serviceUrl}`, brand);
  }

  getAllBrands(partnerIds: number[] = [], includeUnassigned = false): Observable<Brand[]> {
    let params = new HttpParams();

    partnerIds.forEach((id) => {
      params = params.append("partnerIds", id.toString());
    });

    if (includeUnassigned) {
      params = params.append("includeUnassigned", "true");
    }

    return this.http.get(`${this.serviceUrl}?${params.toString()}`);
  }

  updateBrand(id: number, brand: Brand): Observable<Brand> {
    return this.http.put(`${this.serviceUrl}/${id}`, brand);
  }

  getBrandById(id: number): Observable<Brand> {
    return this.http.get(`${this.serviceUrl}/` + id);
  }

  getPublicBrands(): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/public`);
  }

  getOwnBrands(): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/own`);
  }

  getBrandAccounts(brandId: number): Observable<Account[]> {
    return this.http.get(`${this.serviceUrl}/${brandId}/accounts`);
  }

  count(parameters: BrandFilterParameters): Observable<boolean> {
    return this.http.get(`${this.serviceUrl}/count`, parameters);
  }

  checkName(parameters: BrandFilterParameters): Observable<BrandCheckName> {
    return this.http.get(`${this.serviceUrl}/checkname`, parameters);
  }

  deleteBrand(id: number): Observable<void> {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }
}
