import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Observable } from "rxjs";
import { DomainType, GenerateDomainNameDto, PartnerDomainConfigurationDto, SupportedDomainDto } from "@app/models";

@Injectable({
  providedIn: "root"
})
export class DomainService {
  // Super admin only
  private serviceUrl = "domain";
  constructor(private http: DataService) {}

  getSupportedDomains(): Observable<SupportedDomainDto[]> {
    return this.http.get(`${this.serviceUrl}/supportedDomains`);
  }

  generateDomainNames(domainType: DomainType, baseDomainName: string, currentCustomDomainId?: number, prefix = ""): Observable<GenerateDomainNameDto[]> {
    const params: PartnerDomainConfigurationDto = { domainType, baseDomainName, currentCustomDomainId };

    if (prefix) {
      params.prefix = prefix;
    }

    return this.http.post(`${this.serviceUrl}/generateDomainNames`, params);
  }
}
