import { CommonModule } from "@angular/common";
import { Component, forwardRef } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { SimplebarAngularModule } from "simplebar-angular";
import { FilterSelectComponentBase } from "../base/filter-select-component-base";
import { IFilterSelectItem } from "../i-filter-select-item";

/** Filter for lists.
 * @example: <app-filter-select [ngModel]="" [items]="items" label="Filter..." [canClear]="true"> </app-filter-select>
 * @example: <app-filter-select [ngModel]="" [items]="items" label="Filter..." [canClear]="true" itemsMaxHeightPx="200" itemsWidthPx="400" xPosition="before" yPosition="above"> </app-filter-select>
 */
@Component({
  selector: "app-filter-select",
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatMenuModule, NgbTooltipModule, SimplebarAngularModule, TranslocoModule],
  templateUrl: "./filter-select.component.html",
  styleUrls: ["./filter-select.component.scss", "../filter-select-common.scss"],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FilterSelectComponent), multi: true }]
})
export class FilterSelectComponent<TValue> extends FilterSelectComponentBase<TValue> {
  selectedItem: IFilterSelectItem<TValue>;
  protected updateSelectedFromControl(): void {
    const value = this.formControl?.value;
    this.selectedItem = this.items.find((item) => item.value === value);
  }

  selectSingle(item: IFilterSelectItem<TValue>) {
    this.formControl.setValue(item.value);
    //if (super.onChange) super.onChange(item.id);
    this.closeMenu();
  }

  resetControl() {
    this.formControl.setValue(null);
    this.closeMenu();
  }
}
