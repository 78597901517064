import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DatePickerRangeInfo, NavFilterItem, TimeFrameTypes } from "@app/models";
import { DateFilterService } from "@app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { CustomFilterModalComponent } from "../custom-filter-modal";

@Component({
  selector: "app-date-nav-filters",
  templateUrl: "./date-nav-filters.component.html",
  styleUrls: ["./date-nav-filters.component.scss"]
})
export class DateNavFiltersComponent implements OnInit {
  @Input() maxDaysBetween?: number = null;

  @Output() change: EventEmitter<number> = new EventEmitter<number>();

  constructor(private dateFilterService: DateFilterService, private modalSvc: NgbModal) {}

  navFilterList$: Observable<NavFilterItem[]>;

  private currentRangeInfo: DatePickerRangeInfo = {
    fromDate: null,
    toDate: null
  };

  ngOnInit(): void {
    this.setNavFilterList();
    this.initFilter();
  }

  filter(item: NavFilterItem): void {
    this.filterByNavItem(item);
  }

  private initFilter(): void {
    this.filterByNavItem();
  }

  private filterByNavItem(item: NavFilterItem = null): void {
    const timeFrame: TimeFrameTypes = item?.type;
    this.change.emit(timeFrame);
    if (timeFrame === TimeFrameTypes.Custom) {
      this.handleCustomFilter();
    } else {
      this.dateFilterService.filter({ timeFrame });
    }
  }

  private setNavFilterList(): void {
    this.navFilterList$ = this.dateFilterService.navFilterList$.pipe(map((list: NavFilterItem[]) => list));
  }

  private handleCustomFilter(): void {
    const modal = this.modalSvc.open(CustomFilterModalComponent, {
      backdrop: "static",
      size: "l",
      centered: true,
      windowClass: "custom"
    });
    const modalComponent: CustomFilterModalComponent = modal.componentInstance;
    modalComponent.fromDate = this.currentRangeInfo?.fromDate;
    modalComponent.toDate = this.currentRangeInfo?.toDate;
    modalComponent.maxDaysBetween = this.maxDaysBetween;

    modal.dismissed.pipe(take(1)).subscribe((rangeInfo: DatePickerRangeInfo) => {
      if (rangeInfo) {
        this.currentRangeInfo = rangeInfo;
        this.change.emit(TimeFrameTypes.Custom);
        this.dateFilterService.filter({ timeFrame: TimeFrameTypes.Custom, rangeInfo });
      }
    });
  }
}
