import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountService } from "./account.service";

import { AuthService } from "./auth.service";
import { UtilityService } from "./utility.service";
import { RoleTypes } from "@app/models";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private accountSvc: AccountService,
    private utilitySvc: UtilityService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let result = this.checkLogin();
    if (!result) {
      this.authService.login();
      return result;
    }

    result = this.checkRole(route, state);
    if (!result) {
      this.router.navigate(["/access-denied"]);
      return result;
    }

    result = this.isEmailVerified();
    if (!result) {
      this.router.navigate(["/verify"]);
      return result;
    }

    // result = this.isCreateBrandPage(state);
    // if (result) {
    //   return result;
    // }

    result = this.isManagerAccountConfigured(state);
    if (!result) {
      this.router.navigate(["/quick-setup"]);
      return result;
    }

    return result;
  }

  private checkLogin(): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    return false;
  }

  private checkRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let result = true;

    const roles = Object.keys(route.data).length ? (route.data.roles as string[]) : null;

    if (result && route.data && roles) {
      result = this.authService.isUserInRoles(roles);
      return result;
    }

    return true;
  }
  isManagerAccountConfigured(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = user.doneQuickSetup || user.role !== RoleTypes.Manager;
    if (!result && !state.url.startsWith("/quick-setup")) {
      return false;
    }
    return true;
  }
  isEmailVerified() {
    const user = this.accountSvc.user;
    const result = user && (user.email_verified || this.authService.requredMinimumRole(RoleTypes.PartnerAdmin));
    return result;
  }

  isAccountPendingConfiguration(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = user.doneQuickSetup && !user.configured;
    if (result && state.url !== "/pending-registration") {
      return false;
    }
    return true;
  }

  isCreateBrandPage(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = user.configured;
    if (!result && state.url.startsWith("/create-brand")) {
      return true;
    }
    return false;
  }
}
