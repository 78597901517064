import { AllowedDeliveryChannel } from "../campaign";
import { ShortLinkQrConfiguration, ShortLinkQrConfigurationDto } from "../qr-code-configuration";
import { WidgetType } from "../widget-type";
import { Location } from "@app/models";

export class ShortLink {
  public targetUrl: string;
  public code: string;
  public brandId: number;
  public locationId: number;
  public deliveryMethod: AllowedDeliveryChannel;

  public validTo: Date;
  public usageLimit: number;
}
export class ShortLinkCustomPost {
  public targetUrl: string;
  public name: string;
  public locationId: number;
  public linkDeliveryChannel?: AllowedDeliveryChannel;
  public customData?: ShortLinkCustomDataDto;
}

export class ShortLinkCustomDataDto {
  public description: string;
  public couponValidationCode?: string;
  public couponSessionId?: string;
  public targetUrl?: string;
  public widgetType?: WidgetType;
  public printId?: number;
}

export class ShortLinkPreviewDto {
  public qrCodeLinkUrl: string;
  public shortUrl: string;
}

export class ShortLinkGetDto {
  public id: number;
  public targetUrl: string;
  public code: string;
  public brandId?: number;
  public validTo?: Date;
  public usageLimit: number;
  public locationId?: number;
  public widgetId?: number;
  public widget: string;
  public configuration: ShortLinkQrConfiguration;
  public name: string;
  public shortUrl: string;
  public viewCount: number;
  public deliveryMethod: AllowedDeliveryChannel;
  public linkDeliveryChannel?: AllowedDeliveryChannel;
  public linkDeliverySource?: AllowedDeliveryChannel;
  public creationSource?: ShortLinkCreationSources;
  public createdBy?: number;
  public createdOn: Date;
  public createdDate: string;
  public customData?: ShortLinkCustomDataDto;
  public selected?: boolean;
  public bulkShortLinkId?: number;
}

export interface ShortLinkPutDto {
  locationId: number;
  name?: string;
  targetUrl?: string;
  configuration: ShortLinkQrConfigurationDto;
  customData?: ShortLinkCustomDataDto;
}
export class ShortLinkConfigurationDto {
  public primaryColor?: string;
  public secondaryColor?: string;
  public backgroundColor?: string;
  public width?: number;
  public height?: number;
}
export class ShortLinkUsage {
  public total: number;
  public used: number;
  public left: number;
}

export class ShortLinkFilterLocationsWidgetsPostDto {
  locationIds: number[];
}

export interface IShortLinkData {
  type?: "Apps" | "Custom";
}

export enum ShortLinkCreationSources {
  Api = 1,
  Admin = 2
}

export interface LocationCustomShortLinkDto {
  locationId: number;
  shortLinkId: number;
}

export interface LocationCustomShortLinkResultDto {
  location: Location;
  shortLink: ShortLinkGetDto;
}
