import { AbstractControl, ValidatorFn } from "@angular/forms";

export function minMaxLengthValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const length = control.value.length;

    if (length < min || length > max) {
      return {
        minMaxLength: {
          valid: false,
          requiredLength: { min, max },
          actualLength: length
        }
      };
    }

    return null;
  };
}
