<div *transloco="let t; read: 'common.grid'" class="btn-group page-size float-end" ngbDropdown container="body">
  <button type="button" class="btn page-link dropdown-toggle" ngbDropdownToggle>
    {{ pageSize }} / {{ t("page") }}<i class="dropdown-toggle-icon"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
    <div *ngFor="let size of sizeList" class="dropdown-item" (click)="getPagination(size)">
      {{ size }} / {{ t("page") }}
    </div>
  </div>
</div>
