<div class="modal-header" *transloco="let t; read: 'bulkLinks'">
  <h4 class="page-title-box m-0 p-0">{{ t("selectCouponsTitle") }}</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="cancel()"></button>
</div>

<div class="modal-body" *transloco="let t; read: 'bulkLinks'">
  <div class="row align-items-center default-widget-modal min-h-400" *transloco="let tc; read: 'common'">
    <div class="coupon-carousel-wrapper centered-content" *ngIf="couponList">
      <app-coupon-carousel
        class="coupon-carousel"
        [disabledNotActive]="true"
        [responsive]="true"
        [displayLoading]="false"
        [coupons]="couponList"
        [withPreview]="false"
        [selectionType]="'single'"
        [viewType]="'small'"
        [couponSizeType]="'small'"
        [withNothingFoundText]="true"
        [removeSelected]="true"
        (selectCoupon)="onSelectCoupon($event)"
      >
      </app-coupon-carousel>
    </div>
    <div
      class="text-center font-size-16"
      *ngIf="!couponList || !couponList?.length"
      innerHTML="{{ t('message.noCoupons', { url: externalCouponSetupUrl }) }}"
    ></div>
    <div class="modal-footer">
      <div class="card-body row px-2 g-3">
        <div class="col-6">
          <button type="button" class="btn btn-outline btn-modal-footer w-100" (click)="cancel()">
            {{ tc("controls.cancel") }}
          </button>
        </div>
        <div class="col-6 text-end" *ngIf="couponList">
          <button type="button" class="btn btn-primary btn-modal-footer w-100" (click)="apply()">
            <i class="fa-lg mdi"></i>
            {{ tc("controls.select") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
