import { Component, EventEmitter, Input, ViewChild } from "@angular/core";
import { MatMenuTrigger, MenuPositionX, MenuPositionY } from "@angular/material/menu";
import { InputComponentBase } from "../../base/input-component-base";
import { IFilterSelectItem } from "../i-filter-select-item";

@Component({ template: "" })
export abstract class FilterSelectComponentBase<TValue, TControlValue = TValue> extends InputComponentBase<TControlValue> {
  protected menuTrigger: MatMenuTrigger;
  @ViewChild("menuTrigger") public set __menuTrigger(val: MatMenuTrigger) {
    this.menuTrigger = val;
    if (val) this.onMenuTriggerInit.emit(val);
  }
  onMenuTriggerInit = new EventEmitter<MatMenuTrigger>();

  filterText = "";

  @Input() itemsMaxHeightPx?: number = 400;
  @Input() itemsWidthPx?: number = 350;
  @Input() xPosition: MenuPositionX = "after";
  @Input() yPosition: MenuPositionY = "below";

  items: (IFilterSelectItem<TValue> & { isSelected?: boolean })[] = [];
  filteredItems: (IFilterSelectItem<TValue> & { isSelected?: boolean })[] = [];
  @Input("items") set __items(val: IFilterSelectItem<TValue>[]) {
    this.items = val ?? [];
    this.filter();
    this.updateSelectedFromControl();
  }

  @Input() canClear = false;

  protected abstract updateSelectedFromControl(): void;

  filter() {
    this.filteredItems = this.items.filter(
      (x) => x.title?.toLowerCase().includes(this.filterText) || x.description?.toLowerCase().includes(this.filterText)
    );
  }
  filterInputKeydown($event: KeyboardEvent) {
    if ($event.key === "Escape") {
      this.filterText = "";
      this.filter();
    }
    $event.stopPropagation(); // Fix: MatMenu loses focus with numeric input.
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  override writeValue(value: TControlValue): void {
    this.updateSelectedFromControl();
  }
}
