import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function isValidNumberAndLetter(value: string, length?: number) {
  const numberAndLetterRegex: RegExp = /[^A-Za-z0-9]+/g;
  return !numberAndLetterRegex.test(value);
}

export function numberAndLetterValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    const isValid = isValidNumberAndLetter(value);

    return isValid ? null : { numberAndLetter: true };
  };
}
