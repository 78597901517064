<button class="btn p-0 shadow-none">
  <i
    class="mdi mdi-content-copy"
    container="body"
    [class.text-secondary]="disabled"
    [ngbTooltip]="contentTemplate"
    [autoClose]="'inside'"
    (click)="copy()"
  ></i>
</button>

<ng-template #contentTemplate>{{ tooltipMessage }}</ng-template>
