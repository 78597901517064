<div *transloco="let tc; read: 'common'" class="h-100">
  <ng-container *transloco="let t; read: 'locations'">
    <div class="title mb-3">{{ "location.selectLocationsTitle" | transloco }}:</div>

    <div class="list d-flex flex-column h-100">
      <div class="d-flex list-header">
        <div class="list-column list-column-positions"></div>
        <div class="list-column">{{ t("location") }}</div>
      </div>

      <ngx-simplebar class="d-flex w-100 list-content">
        <div *ngFor="let l of locations" class="d-flex list-row">
          <div class="list-column list-column-positions d-flex justify-content-center align-items-center">
            <input
              class="form-check-input widget m-0"
              type="checkbox"
              [checked]="l.selected"
              (change)="changeCheckbox(l, $event.target)"
            />
          </div>

          <div class="list-column">
            <div class="title">
              {{ l.companyName }}
            </div>
            <div class="text-sm-left text-black-50">{{ l.address }}, {{ l.city }}, {{ l.state }} {{ l.zip }}</div>
          </div>
        </div>
      </ngx-simplebar>
      <div class="text-center fw-bold" *ngIf="!locations.length && !loading">{{ t("notFound") }}</div>
    </div>
  </ng-container>
</div>
