import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import {
  AccountValidationResultDto,
  AssignBulkToLocationPostDto,
  BulkActivationStatus,
  BulkLinkFilterPostDto,
  BulkLinkGetDto,
  BulkLinkPutDto,
  BulkLinksDetails,
  BulkShortLinkDeliverySourcesGetDto,
  BulkShortLinkPostDto,
  BulkTotalViewsGetDto,
  DeliverySources,
  ItemWithLabel,
  LinkedBulkShortLinkPostDto,
  SearchBulkAssignAccountsPostDto,
  ShortLinkGetDto
} from "@app/models";
import { TranslocoService } from "@ngneat/transloco";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class BulkLinkService {
  private serviceUrl = "BulkShortlink";

  readonly deliverySourceOptions$: Observable<ItemWithLabel<string>[]> = this.getDeliverySources().pipe(
    map(({ allowedBulkDeliveredSources }) => allowedBulkDeliveredSources || []),
    map((list: string[]) =>
      list.map((key: keyof DeliverySources) => ({
        label: this.transloco.translate(`enums.DeliverySources.${key}`),
        value: key
      }))
    )
  );

  constructor(private http: DataService, private transloco: TranslocoService) {}

  getById(id: number): Observable<BulkLinksDetails> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  getActivatedBulks(): Observable<BulkLinkGetDto[]> {
    return this.http.get(`${this.serviceUrl}`);
  }

  getLinkedBulks(bulkId: number): Observable<BulkLinkGetDto[]> {
    return this.http.post(`${this.serviceUrl}/${bulkId}/bulks`);
  }

  getAll(locationIds?: number[], accountId?: number): Observable<BulkLinkGetDto[]> {
    var filter = new BulkLinkFilterPostDto();
    filter.locationIds = locationIds;
    filter.accountId = accountId;
    return this.http.post(`${this.serviceUrl}/list`, filter);
  }
  getAllBulksTotalView(locationIds?: number[], bulkIds?: number[]): Observable<BulkTotalViewsGetDto[]> {
    var filter = new BulkLinkFilterPostDto();
    filter.locationIds = locationIds;
    filter.bulkIds = bulkIds;
    return this.http.post(`${this.serviceUrl}/clicks`, filter);
  }

  getDeliverySources(): Observable<BulkShortLinkDeliverySourcesGetDto> {
    return this.http.get(`${this.serviceUrl}/deliverySources`);
  }

  create(bulkLink: BulkShortLinkPostDto): Observable<BulkLinkGetDto> {
    return this.http.post(`${this.serviceUrl}`, bulkLink);
  }

  addLinkedBulk(linkedBulkLink: LinkedBulkShortLinkPostDto): Observable<BulkLinkGetDto> {
    return this.http.post(`${this.serviceUrl}/linkBulk`, linkedBulkLink);
  }

  assignBulkToUser(assignBulkLink: AssignBulkToLocationPostDto): Observable<BulkLinkGetDto> {
    return this.http.post(`${this.serviceUrl}/assign`, assignBulkLink);
  }

  getAvailableBulkAccounts(search: SearchBulkAssignAccountsPostDto): Observable<AccountValidationResultDto[]> {
    return this.http.post(`${this.serviceUrl}/assign/availableAccounts`, search);
  }

  update(id: number, bulkLink: BulkLinkPutDto): Observable<BulkLinkGetDto> {
    return this.http.put(`${this.serviceUrl}/${id}`, bulkLink);
  }
  delete(id: number): Observable<BulkLinkGetDto> {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  getShortLinksByBulkId(bulkId: number): Observable<ShortLinkGetDto[]> {
    return this.http.get(`${this.serviceUrl}/${bulkId}/links`);
  }

  findBulkShortLinkByName(locationId: number, name: string): Observable<BulkLinkGetDto> {
    return this.http.get(`${this.serviceUrl}/getByName/${locationId}/${name}`);
  }
  findShortLinkPrefix(prefix: string): Observable<boolean> {
    return this.http.get(`${this.serviceUrl}/isAvailablePrefix/${prefix}`);
  }

  getExportCsv(bulkId: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/${bulkId}/export/csv`);
  }

  updateBulkActivationStatus(id: number, activationStatus: BulkActivationStatus): Observable<BulkLinkGetDto> {
    return this.http.post(`${this.serviceUrl}/${id}/activationStatus`, activationStatus);
  }
}
