import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { VendastaUser, VendastaProduct, VendastaAccount, RetentionCenterAccount } from "@app/models";

import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class VendastaService {
  private serviceUrl = "retentionCenterPlatform";
  constructor(private http: DataService) {}
  getAccount(accountId: string): Observable<VendastaAccount> {
    return this.http.get(`${this.serviceUrl}/getAccount/${accountId}`);
  }
  getAllUsers(): Observable<VendastaUser[]> {
    return this.http.get(`${this.serviceUrl}/getUsers`);
  }
  getUser(email: string): Observable<VendastaUser> {
    return this.http.get(`${this.serviceUrl}/getUser/${email}`);
  }
  getUserAccount(email: string): Observable<string> {
    return this.http.get(`${this.serviceUrl}/getUserAccounts/${email}`).pipe(map((a) => a && a[0]));
  }
  getProducts(accountId: string): Observable<VendastaProduct[]> {
    return this.http.get(`${this.serviceUrl}/getProducts/${accountId}`);
  }
  createAccount(userId: number, account: RetentionCenterAccount): Observable<RetentionCenterAccount> {
    return this.http.post(`${this.serviceUrl}/user/${userId}`, account);
  }
}
