<div [formGroup]="form" *transloco="let t; read: 'bulkLinks'">
  <div>
    <label class="card-title font-size-20">
      {{ title || t("design") }}
    </label>
  </div>

  <div class="mt-2">
    <label class="card-title">{{ t("chooseQrCodeColor") }}</label>
  </div>

  <div class="row mb-3" *transloco="let tcolor; read: 'applicationStyles.color'">
    <div class="col-md-6">
      <app-colors-form-control
        formControlName="primary"
        [placeholder]="tcolor('primary')"
      ></app-colors-form-control>
    </div>
    <div class="col-md-6">
      <app-colors-form-control
        formControlName="secondary"
        [placeholder]="tcolor('secondary')"
      ></app-colors-form-control>
    </div>

    <div class="col-md-6">
      <app-colors-form-control
        formControlName="backgroundColor"
        [placeholder]="tcolor('backgroundColor')"
        [colorPickerType]="sizeType === SizeType.Full ? 'predefined' : 'color'"
      ></app-colors-form-control>
    </div>

    <div *ngIf="sizeType === SizeType.Full" class="col-md-12">
      <app-input
        [control]="form.controls.primaryText"
        [label]="t('fields.primaryText')"
      ></app-input>
    </div>
    <div *ngIf="sizeType === SizeType.Full" class="col-md-6">
      <app-colors-form-control
        formControlName="primaryTextColor"
        [placeholder]="t('fields.primaryTextColor')"
      ></app-colors-form-control>
    </div>

    <div *ngIf="sizeType === SizeType.Full" class="col-md-12">
      <app-input
        [control]="form.controls.secondaryText"
        [label]="t('fields.secondaryText')"
      ></app-input>
    </div>
    <div *ngIf="sizeType === SizeType.Full" class="col-md-6">
      <app-colors-form-control
        formControlName="secondaryTextColor"
        [placeholder]="t('fields.secondaryTextColor')"
      ></app-colors-form-control>
    </div>
  </div>

  <div class="d-flex mb-3">
    <i class="ri-information-line fs-4 me-2"></i>
    <span class="mt-1">{{ t("qrCodeColorsHint") }}</span>
  </div>

  <div *ngIf="sizeType === SizeType.Full" class="col">
    <app-checkbox
      *ngIf="isAdmin"
      size="lg"
      [control]="form.controls.hasPrintId"
      [inlineLabel]="t('switchPrintId')"
      [isSlider]="true"
    ></app-checkbox>
  </div>

  <div *ngIf="sizeType === SizeType.Full" class="col">
    <app-checkbox
      *ngIf="serialNumberType !== SerialNumberTypes.None"
      size="lg"
      [control]="form.controls.hasSerialNumber"
      [inlineLabel]="t('switchNumber')"
      [isSlider]="true"
    ></app-checkbox>
  </div>

  <div *ngIf="sizeType === SizeType.Full" class="col">
    <app-checkbox
      *ngIf="targetType === ShortLinkTargetType.Coupon"
      size="lg"
      [control]="form.controls.hasCouponCode"
      [inlineLabel]="t('switchCoupon')"
      [isSlider]="true"
    ></app-checkbox>
  </div>

  <div class="col">
    <app-checkbox
      size="lg"
      [control]="form.controls.hasFrame"
      [inlineLabel]="t('switchFrame')"
      [isSlider]="true"
    ></app-checkbox>
  </div>

  <div class="col">
    <div class="mt-2">
      <label class="card-title">{{ t("switchLogo") }}</label>
    </div>

    <app-radio-group
      formControlName="qrLogoType"
      groupName="qrLogoType"
      [items]="logoTypeRadioButtons"
    >
      <app-image-upload-form-control
        *ngIf="form.controls.qrLogoType.value === DisplayQrCodeLogoTypes.CustomLogo"
        formControlName="logo"
        storageFolder="links"
        [hasCropper]="true"
        [aspectRatio]="logoImageAspectRatio"
      ></app-image-upload-form-control>

      <app-dropdown
        *ngIf="isCustomBrandVisible && form.controls.qrLogoType.value === DisplayQrCodeLogoTypes.DisplayQrCodeLogo"
        [control]="form.controls.logoBrandId"
        [items]="customBrandList"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [searchable]="true"
      ></app-dropdown>
    </app-radio-group>
  </div>
</div>
