<div *transloco="let tc; read: 'common'" class="form-group">
  <div class="input-group d-flex justify-content-center" [class.is-invalid]="control.touched && control.errors">
    <input
      numbersOnly
      min="1"
      [max]="maxQuantity"
      class="form-control width-80"
      [formControl]="control"
      [class.is-invalid]="control.touched && control.errors"
    />
    <button [disabled]="control.value >= maxQuantity" (click)="changeQuantityVal(QuantityInputAction.Increase)" class="btn btn-primary">
      <i class="mdi mdi-plus-thick"></i>
    </button>
    <button [disabled]="control.value <= 1" (click)="changeQuantityVal(QuantityInputAction.Decrease)" class="btn btn-light" type="button">
      <i class="mdi mdi-minus-thick"></i>
    </button>
  </div>

  <app-form-field-error
    *ngIf="control.touched && !control.valid"
    [label]="label"
    [errors]="control.errors"
    class="{{ errorClassList }}"
  ></app-form-field-error>
</div>
