import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/** @deprecated Use import from `ui-kit` */
export const phoneMask = "(000) 000-0000";

/** @deprecated Use import from `ui-kit` */
export function isValidPhone(value: string) {
  const phoneRegex: RegExp = /^\+?[0-9]{10,11}$/; // todo check phone format
  const isValid = phoneRegex.test(value);
  return isValid;
}

/** @deprecated Use import from `ui-kit` */
export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    const value = control.value.toString() as string;

    const isValid = isValidPhone(value);

    return isValid ? null : { isValid: { value: control.value }, type: "phone" };
  };
}
