export interface LoyaltyProgramSettingsGetDto {
  state: LoyaltyProgramState;
  isAutomaticCalculationEnabled: boolean;
}

export interface LoyaltyProgramSettingsPostDto {
  isAutomaticCalculationEnabled: boolean;
}

export enum LoyaltyProgramState {
  Active = "Active",
  Inactive = "Inactive"
}
