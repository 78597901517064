import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Location } from "@app/models";

@Component({
  selector: "app-location-autocomplete",
  templateUrl: "./location-autocomplete.component.html",
  styleUrls: ["./location-autocomplete.component.scss"]
})
export class LocationAutocompleteComponent implements OnInit {
  constructor() {}

  @Input() showTitle: boolean;

  @Input() set locations(locations: Location[]) {
    if (locations) {
      this._locations = locations;
      this.filter();
    }
  }
  get locations(): Location[] {
    return this._locations;
  }

  @Output() filtered = new EventEmitter<Location[]>();

  private _locations: Location[] = [];

  searchControl = new FormControl<string>("");
  filteredLocations: Location[] = [];

  ngOnInit(): void {}

  filter(): void {
    this.filteredLocations = this.getFilteredLocations();
    if (this.locations.length !== this.filteredLocations.length) this.filtered.emit(this.filteredLocations);
  }

  private getFilteredLocations(): Location[] {
    let search = this.searchControl.value;
    if (!search || !search.trim()) return this._locations.slice();

    const searchWord = search.toLowerCase();
    return this._locations.filter((location: Location) => {
      return location.companyName && location.companyName.toLowerCase().indexOf(searchWord) !== -1;
    });
  }
}
