import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import {
  ConditionGetDto,
  ConditionLocationsDto,
  ConditionPostDto,
  ConditionsGetDto,
  ConditionValidationErrorsDto,
  ConditionWidgetMessageDeliveriesPutDto,
  ConditionWidgetMessageDeliveryGetDto,
  ConditionWidgetsInfo,
  ConditionWithLocationIdsDto,
  CouponPostDto,
  GeneratedTemplateGetDto,
  TemplateContentTypeDto,
  WidgetType
} from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ConditionService {
  constructor(private http: DataService) {}

  private serviceUrl = "Condition";

  getConditions(data: ConditionsGetDto): Observable<ConditionGetDto[]> {
    return this.http.post(`${this.serviceUrl}/conditions`, data);
  }

  getCondition(accountId: number, conditionId: number): Observable<ConditionGetDto> {
    const data: ConditionsGetDto = { accountId };

    return this.getConditions(data).pipe(
      map((conditions: ConditionGetDto[] = []) => {
        return conditions?.find((item: ConditionGetDto) => item.id === conditionId);
      })
    );
  }

  getConditionWidgets(): Observable<WidgetType[]> {
    return this.http.get(`${this.serviceUrl}/widgets`).pipe(map((data: ConditionWidgetsInfo) => data?.widgets));
  }

  getConditionLocationIds(conditionId: number): Observable<number[]> {
    return this.http.get(`${this.serviceUrl}/locations/${conditionId}`).pipe(map((data: ConditionLocationsDto) => data?.locationIds));
  }

  getConditionSearchNames(conditionType: string, searchValue: string = ""): Observable<string[]> {
    const top = 10;
    const params = searchValue ? { top, searchValue } : { top };

    return this.http.get(`${this.serviceUrl}/${conditionType}/searchNames`, params);
  }

  addCondition(condition: ConditionPostDto): Observable<ConditionGetDto> {
    return this.http.post(`${this.serviceUrl}`, condition);
  }

  updateConditionLocations(conditionId: number, locationIds: number[]): Observable<ConditionLocationsDto> {
    const data: ConditionLocationsDto = { conditionId, locationIds };
    return this.http.post(`${this.serviceUrl}/locations`, data);
  }

  deleteCondition(conditionId: number): Observable<null> {
    return this.http.delete(`${this.serviceUrl}/${conditionId}`);
  }

  updateCondition(conditionId: number, condition: ConditionPostDto): Observable<ConditionGetDto> {
    return this.http.put(`${this.serviceUrl}/${conditionId}`, condition);
  }

  getWidgetConditions(widgetId: number): Observable<ConditionWithLocationIdsDto[]> {
    return this.http.get(`${this.serviceUrl}/widgetConditions/${widgetId}`);
  }

  getAccountTemplateConditions(accountTemplateId: number): Observable<ConditionGetDto[]> {
    return this.http.get(`${this.serviceUrl}/templateConditions/${accountTemplateId}`);
  }

  getTemplateContentTypes(): Observable<TemplateContentTypeDto[]> {
    return this.http.get(`${this.serviceUrl}/template-content-types`);
  }

  getTemplates(): Observable<GeneratedTemplateGetDto[]> {
    return this.http.get(`${this.serviceUrl}/templates`);
  }

  getConditionDelivery(conditionId: number): Observable<ConditionWidgetMessageDeliveryGetDto[]> {
    return this.http.get(`${this.serviceUrl}/delivery/${conditionId}`).pipe(
      map((messageDeliveries: ConditionWidgetMessageDeliveryGetDto[]) => {
        // Deserialize 'widgetDataJson':
        return messageDeliveries.map((messageDelivery) => {
          messageDelivery.widgetDataJson = this._deserializeMessageDeliveryWidgetData(messageDelivery.widgetDataJson);
          return messageDelivery;
        });
      })
    );
  }

  updateConditionDelivery(
    conditionId: number,
    postData: ConditionWidgetMessageDeliveriesPutDto
  ): Observable<ConditionWidgetMessageDeliveryGetDto[]> {
    return this.http.put(`${this.serviceUrl}/delivery/${conditionId}`, postData).pipe(
      map((messageDeliveries: ConditionWidgetMessageDeliveryGetDto[]) => {
        // Deserialize 'widgetDataJson':
        return messageDeliveries.map((messageDelivery) => {
          messageDelivery.widgetDataJson = this._deserializeMessageDeliveryWidgetData(messageDelivery.widgetDataJson);
          return messageDelivery;
        });
      })
    );
  }

  updateConditionStatus(conditionId: number, isActive: boolean): Observable<void> {
    return this.http.post(`${this.serviceUrl}/${conditionId}/status/${isActive}`);
  }

  getConditionValidationErrors(): Observable<ConditionValidationErrorsDto[]> {
    return this.http.get(`${this.serviceUrl}/validations`);
  }

  private _deserializeMessageDeliveryWidgetData(widgetDataJson: string | CouponPostDto): CouponPostDto {
    if (typeof widgetDataJson !== "string") throw new Error("'string' for deserialize expected.");
    return widgetDataJson ? JSON.parse(widgetDataJson as string) : null;
  }
}
