import { Injectable } from "@angular/core";
import {
  Condition,
  ConditionGetDto,
  ConditionInfo,
  ConditionMarketingAction,
  ConditionOptionsBaseForm,
  ConditionTable,
  ConditionTriggerInfo,
  ConditionType,
  CouponInfo,
  CouponPostDto,
  WidgetCodes,
  WidgetType
} from "@app/models";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: "root"
})
export class ConditionDataHandlerService {
  constructor(private translocoService: TranslocoService) {}

  private readonly triggerSign = "$";

  private static getLocationAmountValue(locationAmount?: number): number | null {
    return locationAmount ?? null;
  }

  // get handled condition info

  getConditionOptionsForm(condition: ConditionGetDto): ConditionOptionsBaseForm {
    return {
      id: condition.id,
      [ConditionTable.name]: condition.name,
      [ConditionTable.trigger]: this.getParsedConditionTriggerInfo(condition.conditionParametersJson),
      locationsAmount: condition.locationsAmount,
      deliveryChannel: condition.deliveryChannel
    };
  }

  // get handled conditions info

  getConditionsInfo(conditions: ConditionGetDto[]): ConditionInfo[] {
    const infoList = conditions.map((x) => this.getConditionInfo(x));
    return infoList;
  }

  // get handles values string:

  getTriggerRangeValue(info: ConditionTriggerInfo, setSignToAll: boolean = false): string {
    const from: number = info.min || 0;
    const to: number = info.max || 0;
    const everyoneSign: string = setSignToAll ? this.triggerSign : "";

    return `${everyoneSign}${from} – ${everyoneSign}${to}`;
  }

  getTriggerSelectValue(items: string[] = []): string {
    const separator = ", ";
    return items.join(separator);
  }

  getDetailsValue(coupon: CouponInfo): string {
    return coupon?.name || "";
  }

  getWidgetValue(widgetType: WidgetType): string {
    const code: string = WidgetCodes[WidgetType[widgetType]];
    return code ? `widgets.${code}.title` : "";
  }

  getConditionTypeLocalizationKey(conditionType: ConditionType): string {
    return `POSConditions.${conditionType.toString()}`;
  }

  private getTriggerValueByJSON(conditionParametersJson: string, typeValue: ConditionType): string {
    const info: ConditionTriggerInfo = this.getParsedConditionTriggerInfo(conditionParametersJson);
    const useSignInTrigger: boolean = this.isConditionTypeValueEqualType(typeValue, ConditionType.TransactionAmount);
    const rangeCondition: boolean = this.isConditionTypeRange(typeValue);

    if (rangeCondition) {
      return this.getTriggerRangeValue(info, useSignInTrigger);
    }

    return this.getTriggerSelectValue(info?.names);
  }

  // get form options data:

  getParsedConditionTriggerInfo(conditionParametersJson: string): ConditionTriggerInfo {
    const conditionParameters = JSON.parse(conditionParametersJson);

    return {
      min: conditionParameters?.Min || 0,
      max: conditionParameters?.Max || 0,
      names: conditionParameters?.Names || []
    };
  }

  isConditionTypeInfoEqualType(typeValue: ConditionType, conditionType: ConditionType): boolean {
    return this.isConditionTypeValueEqualType(typeValue, conditionType);
  }

  isConditionTypeValueEqualType(typeValue: ConditionType, conditionType: ConditionType): boolean {
    return typeValue?.toString() === ConditionType[conditionType];
  }

  isConditionTypeRange(typeValue: ConditionType): boolean {
    return (
      this.isConditionTypeInfoEqualType(typeValue, ConditionType.TransactionAmount) ||
      this.isConditionTypeInfoEqualType(typeValue, ConditionType.ProductQuantity)
    );
  }

  isConditionTypeProduct(typeValue: ConditionType): boolean {
    return (
      this.isConditionTypeInfoEqualType(typeValue, ConditionType.ProductCategory) ||
      this.isConditionTypeInfoEqualType(typeValue, ConditionType.ProductName) ||
      this.isConditionTypeInfoEqualType(typeValue, ConditionType.Manufacturer)
    );
  }

  // handlers:

  getCouponPostDto(coupon: CouponInfo): CouponPostDto {
    if (coupon) {
      return {
        CouponId: coupon.id,
        CouponImageUrl: coupon.couponImage,
        CouponUrl: coupon.url,
        CouponName: coupon.name,
        CouponOffer: coupon.title
      };
    }

    return null;
  }

  private getConditionInfo(condition: Condition): ConditionInfo {
    const conditionTypeLabel: string = this.getConditionTypeLocalizationKey(condition.conditionType);
    const triggerFrom: number = this.getTriggerFromByJSON(condition.conditionParametersJson);

    let marketingAction: string = "";
    switch (condition.marketingAction) {
      case ConditionMarketingAction.Widget:
        marketingAction = this.getWidgetValue(condition.widgetId);
        break;
      case ConditionMarketingAction.CfsDisplayPromotionalSlider:
        marketingAction = "POSConditions.wizard.ConditionMarketingAction.CfsDisplayPromotionalSlider";
        break;
    }

    return {
      id: condition.id,
      [ConditionTable.active]: condition.active,
      [ConditionTable.name]: condition.name,
      [ConditionTable.type]: this.translocoService.translateObject(conditionTypeLabel),
      [ConditionTable.trigger]: this.getTriggerValueByJSON(condition.conditionParametersJson, condition.conditionType),
      [ConditionTable.marketingAction]: this.translocoService.translateObject(marketingAction),
      [ConditionTable.locationsAmount]: ConditionDataHandlerService.getLocationAmountValue(condition?.locationsAmount),
      errors: condition.errors || [],
      triggerFrom
    };
  }

  private getTriggerFromByJSON(json: string): number {
    const triggerInfo: ConditionTriggerInfo = this.getParsedConditionTriggerInfo(json);

    return Number(triggerInfo?.min);
  }
}
