import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { SendSurveyWidgetSettings } from "@app/pages/components/widget-settings/send-survey-widget-settings/send-survey-widget-settings";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from "@angular/forms";
import { Patterns } from "@app/shared/validators";

@Component({
  selector: "app-send-survey-widget-settings",
  templateUrl: "./send-survey-widget-settings.component.html",
  styleUrls: ["./send-survey-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SendSurveyWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SendSurveyWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class SendSurveyWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private _widget: SendSurveyWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.widgetSetting = JSON.parse(value) as SendSurveyWidgetSettings;
  }

  public set widgetSetting(value: SendSurveyWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls.surveyUrl.setValue(value.surveyUrl);
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      surveyUrl: new UntypedFormControl("", [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)])
    });
    this.widgetSettingsReady.emit();
  }
}
