import { Component } from "@angular/core";
import { AppConfigService } from "@app/core/services/app-config.service";
import { FooterService } from "@app/services";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})

/**
 * Footer component
 */
export class FooterComponent {
  // set the currenr year
  year: number = new Date().getFullYear();
  title = this.configSvc.appData.title;

  viewType$ = this.service.viewType$;

  constructor(private configSvc: AppConfigService, private service: FooterService) {}
}
