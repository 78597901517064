<form [formGroup]="form" class="{{ classList }}" *transloco="let t; read: 'fromToDatepicker'">
  <div *ngIf="label" class="col-xl-2 col-lg-2 col-md-2 col-sm-3">
    <label class="font-bold col-form-label">{{ label | transloco }}</label>
  </div>
  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 d-inline">
    <div class="input-group input-range-date">
      <span class="input-group-text">{{ t('from') }}</span>
      <input
        type="text"
        class="input-group-text form-control text-center cursor-pointer"
        formControlName="startDate"
        (click)="showDateRangeModal()"
      />
      <span class="input-group-text">- {{ t('to') }}</span>
      <input
        type="text"
        class="input-group-text form-control text-center cursor-pointer"
        formControlName="endDate"
        (click)="showDateRangeModal()"
      />
      <button class="input-group-text" (click)="showDateRangeModal()"><i class="ri-calendar-line font-size-18"></i></button>
    </div>
  </div>
</form>
