import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Location } from "@app/models/location";

@Component({
  selector: "app-location-form",
  templateUrl: "./location-form.component.html",
  styleUrls: ["./location-form.component.scss"]
})
export class LocationFormComponent implements OnInit {
  private _storage: Storage = sessionStorage;
  private _location: Location;
  public get location(): Location {
    if (!this._location) this._location = (JSON.parse(this._storage.getItem("location")) as Location) || null;
    return this._location;
  }
  @Input() public set location(value: Location) {
    value = value ? value : this.location;
    if (value) {
      this.form.controls.companyName.setValue(value.companyName);
      this.form.controls.phoneNumber.setValue(value.phoneNumber);
      this.form.controls.state.setValue(value.state);
      this.form.controls.city.setValue(value.city);
      this.form.controls.zip.setValue(value.zip);
      this.form.controls.address.setValue(value.address);
      this.form.controls.email.setValue(value.email);
      this.form.controls.dealerCode.setValue(value.dealerCode);
    }
  }

  form = new UntypedFormGroup({
    companyName: new UntypedFormControl({ value: "", disabled: true }),
    phoneNumber: new UntypedFormControl({ value: "", disabled: true }),
    state: new UntypedFormControl({ value: "", disabled: true }),
    city: new UntypedFormControl({ value: "", disabled: true }),
    zip: new UntypedFormControl({ value: "", disabled: true }),
    address: new UntypedFormControl({ value: "", disabled: true }),
    email: new UntypedFormControl({ value: "", disabled: true }),
    dealerCode: new UntypedFormControl({ value: "", disabled: true })
  });
  constructor() {}

  ngOnInit(): void {}
}
