import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { DigitalAsset, DigitalAssetType } from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class DigitalAssetService {
  private serviceUrl = "asset";
  constructor(private http: DataService, public https: HttpClient) {}

  public getAll(): Observable<DigitalAsset[]> {
    return this.http.get(`${this.serviceUrl}`);
  }
  public getAsset(id: number): Observable<DigitalAsset> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }
  public getByType(assetType: DigitalAssetType): Observable<DigitalAsset[]> {
    return this.http.get(`${this.serviceUrl}/type/${assetType}`);
  }
}
