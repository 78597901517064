import { Observable, PartialObserver, ReplaySubject, Subscription } from "rxjs";
import { retry } from "rxjs/operators";

export class SingletonSubject<T> extends ReplaySubject<T> {
  private _isRequested = false;
  constructor(private _observable$: Observable<T>) {
    super(1);
  }

  override subscribe(observer?: PartialObserver<T>): Subscription;
  override subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription;
  override subscribe(next?: unknown, error?: unknown, complete?: unknown): Subscription {
    if (!this._isRequested) {
      this._isRequested = true;
      this._observable$.pipe(retry(1)).subscribe(
        (x) => {
          this.next(x);
          this.complete();
        },
        (error) => {
          this.error(error);
          this.complete();
        }
      );
    }
    return super.subscribe(next as any /* , error as any, complete as any */);
  }
}
