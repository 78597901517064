export enum BulkActivationStatus {
  Pending = 0,
  Downloaded,
  Printed,
  NewAccount,
  LinkedToAccount
}

export enum ActivationBulkOption {
  PricingPlan = "PricingPlan",
  Quantity = "Quantity"
}

export enum BulkType {
  Generic = "Generic",
  Activation = "Activation"
}

export enum RelationshipBulkType {
  Parent = "Parent",
  Child = "Child"
}

export enum DeliverySources {
  LandingPage,
  Cmc,
  Printed,
  Cfs,
  Kiosk,
  Pos,
  Custom,
  Stand,
  SquareSticker,
  CircleSticker
}
