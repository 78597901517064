import { Injectable } from "@angular/core";
import { FeatureModel, WidgetType, WidgetModel } from "@app/models";

@Injectable({ providedIn: "root" })
export class FeaturesService {
  constructor() {}

  private widgets: WidgetModel[] = [];
  private serviceUrl = "widgetManagement";
  private faqFeature: FeatureModel;

  private features = [
    new FeatureModel(WidgetType.IssueCoupon, "ri-coupon-line"),
    new FeatureModel(WidgetType.ValidateCoupon, "ri-qr-scan-line"),
    new FeatureModel(WidgetType.EnrollLoyalty, "ri-service-line"),
    new FeatureModel(WidgetType.IssuePoints, "ri-hand-coin-line"),
    new FeatureModel(WidgetType.RequestReview, "ri-chat-forward-line"),
    new FeatureModel(WidgetType.SetReminder, "ri-alarm-line"),
    new FeatureModel(WidgetType.AddPromo, "ri-file-list-3-line"),
    new FeatureModel(WidgetType.DownloadMobileApp, "ri-smartphone-line"),
    new FeatureModel(WidgetType.ConnectSocialMedia, "ri-link"),
    new FeatureModel(WidgetType.SendPaymentLink, "ri-refund-2-line"),
    new FeatureModel(WidgetType.ScheduleAppointment, "ri-calendar-todo-line"),
    new FeatureModel(WidgetType.Waitlist, "ri-user-add-line"),
    new FeatureModel(WidgetType.SendSurvey, "ri-question-answer-line"),
    new FeatureModel(WidgetType.SendGiftCard, "ri-gift-2-line"),
    new FeatureModel(WidgetType.AnniversaryClub, "ri-cake-2-line"),
    new FeatureModel(WidgetType.ReferralProgram, "ri-medal-line"),
    new FeatureModel(WidgetType.SmartPage, "ri-settings-line"),
    new FeatureModel(WidgetType.SeeMenu, "ri-list-check-3")
  ];

  getFeatures() {
    return this.features;
  }
}
