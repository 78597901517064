<div class="modal-header">
  <h4 class="page-title-box m-0 p-0">{{ title }}</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="cancel()"></button>
</div>
<div class="card-body">
  <div class="font-size-18" [innerHTML]="content"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="confirm()">{{ confirmBtnText }}</button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="fa-lg mdi mdi-cancel"></i>
    {{ cancelBtnText }}
  </button>
</div>
