import {
  ActivitiesCardInfoItem,
  ActivityByPeriodGetDto,
  AppsActivityByPeriod,
  DashboardChartOptions,
  TotalActivitiesCardInfo
} from "@app/models";
import * as echarts from "echarts";
import { ECharts, EChartsOption } from "echarts";
import { CallbackDataParams } from "echarts/types/dist/shared";
import { DashboardExportUtilities, EchartsToolboxFeatureData } from "@app/services/dashboard/dashboard-export-activities.utilities";
import { DashboardService } from "@app/services";

interface EChartSeriesData {
  value: number;
  name: string;
  itemStyle?: { normal: { color: string } };
}

interface ColorInfo {
  class: string;
  color: string;
}

export class DashboardAppActivitiesUtilities {
  constructor(public dashboardService: DashboardService) {}

  private static topAppsColorInfo: ColorInfo[] = [
    {
      class: "yellow",
      color: "#F79824"
    },
    {
      class: "green",
      color: "#4DAA57"
    },
    {
      class: "blue",
      color: "#2520B6"
    },
    {
      class: "pink",
      color: "#c44cec"
    },
    {
      class: "purple",
      color: "#8664d3"
    }
  ];

  private static noDataColor = "#b6b7c0";
  private static readonly topAppsNumber = 5;
  private static toolboxFeatureClickParams: any;
  private static dashboardExportUtilities: DashboardExportUtilities = new DashboardExportUtilities();

  private static get noDataSeriesItem(): EChartSeriesData {
    return {
      value: 0,
      name: "No data",
      itemStyle: { normal: { color: DashboardAppActivitiesUtilities.noDataColor } }
    };
  }

  private get chartOptions(): EChartsOption {
    return {
      tooltip: {
        trigger: "item",
        className: "activities-by-apps__tooltip",
        formatter: DashboardAppActivitiesUtilities.tooltipFormatter
      },
      toolbox: {
        feature: {
          dataView: { show: false, readOnly: false },
          magicType: { show: false, type: ["line", "bar"] },
          myTool: {
            show: true,
            title: "",
            icon: "image://./assets/images/menu-icon.svg",
            onclick: this.toolboxFeatureClickHandler.bind(this)
          },
          restore: { show: false },
          saveAsImage: {
            show: false,
            type: "png",
            title: "Save",
            emphasis: { iconStyle: { color: "#495057", textPadding: 0 } }
          }
        }
      },
      legend: {
        orient: "horizontal",
        top: "bottom",
        left: "center"
      },
      series: [
        {
          name: "Total Activities",
          type: "pie",
          radius: ["100px", "150px"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            overflow: "none",
            fontSize: 12,
            formatter: DashboardAppActivitiesUtilities.labelFormatter
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 12,
              fontWeight: "bolder"
            }
          },
          labelLine: {
            show: true
          },
          data: [],
          top: 0,
          bottom: 50,
          left: 0
        }
      ]
    };
  }

  // handlers:

  private static tooltipFormatter(params: CallbackDataParams): string {
    const { seriesName, marker, data, percent } = params;
    const { name, value }: any = data;

    return `${seriesName}<br>${marker} <span class="bold">${name}: ${value}</span> (${percent}%)`;
  }

  private static labelFormatter(params: CallbackDataParams): string {
    const { value, percent } = params;

    return `${value} (${percent}%)`;
  }

  private static setDataToOptions(info: ActivityByPeriodGetDto<AppsActivityByPeriod>, chartOptions: EChartsOption): void {
    chartOptions.series[0].data = DashboardAppActivitiesUtilities.getChartData(info);
  }

  private static getChartData(info: ActivityByPeriodGetDto<AppsActivityByPeriod>): EChartSeriesData[] {
    let data: EChartSeriesData[] = [];
    const activities: AppsActivityByPeriod[] = info?.data;

    if (activities?.length) {
      data = DashboardAppActivitiesUtilities.getChartDataOnActivities(activities);
    }

    if (data?.length > DashboardChartOptions.maxDataLength) {
      data = data?.slice(0, DashboardChartOptions.maxDataLength);
    }

    if (!data?.length) {
      data.push(DashboardAppActivitiesUtilities.noDataSeriesItem);
    }

    return data;
  }

  private static getChartDataOnActivities(activities: AppsActivityByPeriod[]): EChartSeriesData[] {
    const topApps: AppsActivityByPeriod[] = DashboardAppActivitiesUtilities.getTopApps(activities);

    return activities
      .map((activity: AppsActivityByPeriod) => {
        return DashboardAppActivitiesUtilities.getEChartSeriesData(activity, topApps);
      })
      .filter((item: EChartSeriesData) => !!item.value);
  }

  private static getEChartSeriesData(activity: AppsActivityByPeriod, topApps: AppsActivityByPeriod[]): EChartSeriesData {
    const data: EChartSeriesData = {
      value: activity.totalCount,
      name: activity.widgetName
    };
    const color: string = DashboardAppActivitiesUtilities.getChartItemColor(activity, topApps);

    if (color) {
      data.itemStyle = { normal: { color } };
    }

    return data;
  }

  private static getChartItemColor(activity: AppsActivityByPeriod, topApps: AppsActivityByPeriod[]): string {
    const topAppsIndex: number = topApps.findIndex((item: AppsActivityByPeriod) => activity.widgetId === item.widgetId);

    if (topAppsIndex >= 0) {
      return DashboardAppActivitiesUtilities.topAppsColorInfo[topAppsIndex]?.color;
    }

    return "";
  }

  private static getTopApps(activities: AppsActivityByPeriod[]): AppsActivityByPeriod[] {
    return activities
      .sort((a: AppsActivityByPeriod, b: AppsActivityByPeriod) => b.totalCount - a.totalCount)
      .slice(0, DashboardAppActivitiesUtilities.topAppsNumber)
      .filter((item: AppsActivityByPeriod) => !!item.totalCount);
  }

  private static getActivitiesCardInfoList(topApps: AppsActivityByPeriod[], maxCount: number): ActivitiesCardInfoItem[] {
    return topApps?.map((activity: AppsActivityByPeriod, index: number) => {
      return {
        label: activity.widgetName,
        value: activity.totalCount,
        progressPerCent: DashboardAppActivitiesUtilities.getProgressForActivity(activity.totalCount, maxCount),
        colorClass: DashboardAppActivitiesUtilities.topAppsColorInfo[index]?.class
      };
    });
  }

  private static getProgressForActivity(activityValue: number, maxValue: number): number {
    return (activityValue / maxValue) * 100;
  }

  private static handleOptionsOnWindowWidth(chartOptions: EChartsOption): void {
    if (window.innerWidth <= DashboardChartOptions.smallDesktopScreen) {
      DashboardAppActivitiesUtilities.updateOptionsOnSmallScreen(chartOptions);

      if (window.innerWidth <= DashboardChartOptions.smallMobileScreen) {
        DashboardAppActivitiesUtilities.updateOptionsOnSmallestScreen(chartOptions);
      }
    } else {
      DashboardAppActivitiesUtilities.updateOptionsOnLargeScreen(chartOptions);
    }
  }

  // update chart options handlers:

  static updateOptionsOnLargeScreen(chartOptions: EChartsOption): void {
    if (chartOptions) {
      chartOptions.series[0].radius = ["100px", "150px"];
      chartOptions.series[0].top = 0;
      chartOptions.series[0].bottom = 50;

      chartOptions.series[0].label.show = true;
      chartOptions.series[0].emphasis.label.show = true;
      chartOptions.series[0].labelLine.show = true;
      chartOptions.series[0].silent = false;
    }
  }

  static updateOptionsOnSmallScreen(chartOptions: EChartsOption): void {
    if (chartOptions) {
      chartOptions.series[0].radius = ["100px", "150px"];
      chartOptions.series[0].top = "-20%";
      chartOptions.series[0].bottom = 0;

      chartOptions.series[0].label.show = false;
      chartOptions.series[0].emphasis.label.show = false;
      chartOptions.series[0].labelLine.show = false;
      chartOptions.series[0].silent = true;
    }
  }

  static updateOptionsOnSmallestScreen(chartOptions: EChartsOption): void {
    if (chartOptions) {
      chartOptions.series[0].radius = ["70%", "100%"];
      chartOptions.series[0].top = "-30%";
      chartOptions.series[0].bottom = 0;
    }
  }

  getChartOptions(info: ActivityByPeriodGetDto<AppsActivityByPeriod>): EChartsOption {
    const chartOptions: EChartsOption = this.chartOptions;

    DashboardAppActivitiesUtilities.handleOptionsOnWindowWidth(chartOptions);
    DashboardAppActivitiesUtilities.setDataToOptions(info, chartOptions);

    return chartOptions;
  }

  getActivitiesForCard(activities: AppsActivityByPeriod[]): TotalActivitiesCardInfo {
    if (activities?.length) {
      const topApps: AppsActivityByPeriod[] = DashboardAppActivitiesUtilities.getTopApps(activities);

      if (topApps?.length) {
        const totalCount: number = topApps[0].totalCount;
        const list: ActivitiesCardInfoItem[] = DashboardAppActivitiesUtilities.getActivitiesCardInfoList(topApps, totalCount);

        return { totalCount, list };
      }
    }

    return { totalCount: 0, list: [] };
  }

  toolboxFeatureClickHandler(params = null): void {
    if (params) {
      DashboardAppActivitiesUtilities.toolboxFeatureClickParams = params;
    } else {
      params = DashboardAppActivitiesUtilities.toolboxFeatureClickParams;
    }

    const lineChartElement: HTMLElement = document.getElementById("lineChart-app");
    const lineChart: ECharts = echarts.init(lineChartElement, null, { renderer: "canvas" });

    const menu: HTMLElement = document.getElementById("id-chart-dom-app");
    const exportSVG: HTMLElement = document.getElementById("id-exportSVG-app");
    const exportPNG: HTMLElement = document.getElementById("id-exportPNG-app");
    const exportCSV: HTMLElement = document.getElementById("id-exportCSV-appsvg");

    const data: EchartsToolboxFeatureData = {
      params,
      lineChart,
      menu,
      exportSVG,
      exportCSV,
      exportPNG,
      fileNameFirstPart: "activities_by_apps",
      withArraySeriesData: true
    };

    const closeOtherChartsMenu: boolean = DashboardAppActivitiesUtilities.dashboardExportUtilities.toolboxFeatureClickHandler(data, "apps");

    if (closeOtherChartsMenu) {
      this.dashboardService.closeOtherChartsMenu("apps");
    }
  }
}
