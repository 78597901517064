import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"]
})
export class NotFoundComponent {
  @Input() item = "";
  @Input() title = "";
  @Input() isImport = false;
  @Input() withAddAction = true;
  @Input() disabled = false;
  @Input() otherActionText = "";
  @Input() notFoundText = "";

  @Output() clickedAdd = new EventEmitter();
  @Output() clickedOtherAction = new EventEmitter();
  @Output() clickedImport = new EventEmitter();
  @Output() clickedDownload = new EventEmitter();

  add(): void {
    this.clickedAdd.emit();
  }

  otherAction(): void {
    this.clickedOtherAction.emit();
  }

  import(): void {
    this.clickedImport.emit();
  }

  download(): void {
    this.clickedDownload.emit();
  }
}
