export enum CouponPerUsers {
  Undefined = 0,
  Forever = 1,
  PerMonth = 2,
  PerYear = 3
}

export class IssueCouponWidgetSettings {
  public allowMultipleCoupons: boolean;
  public activeCoupons: string[];
  public couponPerUser: CouponPerUsers;
}
