import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BrandColorPalette, ScreenSizeType, Template, TemplatePreviewOptions } from "@app/models";
import { Unsubscriber } from "@app/shared/components";
import { Observable } from "rxjs";
import { TemplatePreviewService } from "../../template-preview";

type TemplateType = "lottie" | "image" | "";

@Component({
  selector: "app-template",
  templateUrl: "./template.component.html",
  styleUrls: ["./template.component.scss"]
})
export class TemplateComponent extends Unsubscriber implements OnInit {
  constructor(private sanitizer: DomSanitizer, private templatePreviewService: TemplatePreviewService) {
    super();
  }

  private readonly smallScreen = 768;

  generatedBody: SafeHtml | undefined;
  isScreenSizeResponsive = false;
  screenSize: ScreenSizeType;
  templateType: TemplateType;
  options$: Observable<TemplatePreviewOptions>;

  @Input() type: "page" | "carouselItem" = "page";
  @Input() index = 0;
  @Input() forPrint = false;
  @Input() page = 0;

  @Input() brandColorsPalette: BrandColorPalette | undefined;

  @Input() set template(template: Template) {
    if (template) {
      const { generatedBody } = template;
      this.generatedBody = generatedBody ? this.sanitizer.bypassSecurityTrustHtml(generatedBody as string) : "";
      this.templateType = TemplateComponent.getTemplateType(generatedBody);

      if (this.templateType === "lottie") {
        this.options$ = this.templatePreviewService.getLottieOptions(template);
      }
    }
  }

  @Output() print = new EventEmitter();

  ngOnInit(): void {
    this.sub = this.templatePreviewService.isScreenSizeResponsive$.subscribe((isScreenSizeResponsive: boolean) => {
      this.isScreenSizeResponsive = isScreenSizeResponsive;
      this.setScreenSize();
    });
  }

  private static getTemplateType(generatedBody: string): TemplateType {
    const temporaryDiv: HTMLElement = document.createElement("div");
    temporaryDiv.innerHTML = generatedBody;
    const templateElement: HTMLElement = temporaryDiv.querySelector(".template");
    const classList: DOMTokenList = (templateElement as HTMLElement)?.classList;

    if (classList?.contains("lottie")) {
      return "lottie";
    } else if (classList?.contains("image")) {
      return "image";
    }

    return "";
  }

  @HostListener("window:resize") handleResizeEvent(): void {
    this.setScreenSize();
  }

  launchPrint(): void {
    if (this.forPrint) {
      this.print.emit();
    }
  }

  private setScreenSize(): void {
    const updatedScreenSize: ScreenSizeType = this.getScreenSizeType();

    if (this.screenSize !== updatedScreenSize) {
      this.screenSize = updatedScreenSize;
    }
  }

  private getScreenSizeType(): ScreenSizeType {
    let updatedScreenSize: ScreenSizeType = ScreenSizeType.Full;

    if (this.isScreenSizeResponsive) {
      if (window.innerWidth <= this.smallScreen) {
        updatedScreenSize = ScreenSizeType.Compact;
      } else if (window.innerHeight >= window.innerWidth) {
        updatedScreenSize = ScreenSizeType.Vertical;
      }
    }

    return updatedScreenSize;
  }
}
