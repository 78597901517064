import { Injectable } from "@angular/core";

import { AuthService } from "@app/core";
import { DataService } from "@app/core/services/data.service";
import {
  CreatePartnerAdminModel,
  CreateUserModel,
  GetPinResponseDto,
  PartnerAdmin,
  RegisterUserModel,
  ResetUserPinResponseDto,
  User,
  UserList
} from "@app/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserService {
  serviceUrl = "user";
  constructor(private http: DataService, private authSvc: AuthService) {}

  getAll(): Observable<User[]> {
    return this.http.get(`${this.serviceUrl}`);
  }
  getById(id: number): Observable<User> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }
  getByManagerId(managerId: number): Observable<User[]> {
    return this.http.get(`${this.serviceUrl}/manager/${managerId}`);
  }
  getByPartnerId(partnerId: number): Observable<User[]> {
    return this.http.get(`${this.serviceUrl}/partner/${partnerId}/admins`);
  }
  getPartnerAdmins(): Observable<User[]> {
    return this.http.get(`${this.serviceUrl}/partner/admins`);
  }
  register(user: RegisterUserModel): Observable<number> {
    return this.http.post(`${this.serviceUrl}/manager`, user);
  }
  createUserByManager(user: CreateUserModel): Observable<User> {
    return this.http.post(`${this.serviceUrl}/manager`, user);
  }

  createPartnerAdmin(user: CreatePartnerAdminModel): Observable<User> {
    return this.http.post(`${this.serviceUrl}/partner`, user);
  }
  createNewUser(user: User): Observable<number> {
    return this.http.post(`${this.serviceUrl}/addUser`, user);
  }
  updateUser(user: User | PartnerAdmin): Observable<number> {
    return this.http.put(`${this.serviceUrl}/${user.id}`, user);
  }
  deleteUser(userId: number) {
    return this.http.delete(`${this.serviceUrl}/${userId}`);
  }

  getUsersByIds(userIds: number[]): Observable<User[]> {
    let filter = new UserList();
    filter.userIds = userIds;
    return this.http.post(`${this.serviceUrl}/list`, filter);
  }

  getPin(userId: number): Observable<GetPinResponseDto> {
    return this.http.post(`${this.serviceUrl}/${userId}/pin`);
  }
  resetUserPin(userId: number): Observable<ResetUserPinResponseDto> {
    return this.http.post(`${this.serviceUrl}/${userId}/pin/reset`);
  }
  sendUserPin(userId: number): Observable<void> {
    return this.http.post(`${this.serviceUrl}/${userId}/pin/send`);
  }
}
