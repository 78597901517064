export class TotalTransactionsByPeriodReport {
  totalCount: number;
  marketingActionCount: number;
  scanCount: number;
  clickCount: number;
}

export interface TotalTransactionsByPeriodPostDto {
  startDate?: string;
  endDate?: string;
}

export class POSActivityReport {
  count: number;
  settings: POSActivityReportSetting;
}

export class POSActivityReportSetting {
  name: string;
  title: string;
  iconName: string;
}

export interface TotalTransactionsReportByPeriodGetDto<Activity> {
  data: Activity;
  endDate: string;
  startDate: string;
  total: number;
}

export const posActivityReportSettings: POSActivityReportSetting[] = [
  { name: "totalCount", title: "POS Transactions", iconName: "ri-arrow-left-right-line" },
  { name: "marketingActionCount", title: "Marketing Engagements", iconName: "ri-funds-fill" },
  { name: "scanCount", title: "QR Code & Marketing Call to Action presented on CFS", iconName: "ri-qr-code-line" },
  { name: "clickCount", title: "SMS & Email Link Clicks", iconName: "ri-arrow-left-up-fill" },
  { name: "emailSentCount", title: "Automated Emails sent", iconName: "ri-mail-send-fill" },
  { name: "smsSentCount", title: "Automated SMS messages sent", iconName: "ri-send-plane-fill" }
];
