export class MultiSelectItem {
  id?: number;
  name?: string;
  value?: string;
  selected: boolean;
  description?: string;
  data?: any;
}

export class FilterLocations {
  locationIds: number[];
}
