import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import {
  ShortLink,
  ShortLinkCreationSources,
  ShortLinkCustomPost,
  ShortLinkFilterLocationsWidgetsPostDto,
  ShortLinkGetDto,
  ShortLinkPutDto,
  ShortLinkUsage,
  ShortLinkPreviewDto
} from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ShortLinkService {
  private serviceUrl = "ShortLink";

  constructor(private http: DataService) {}

  getById(id: number): Observable<ShortLinkGetDto> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  getCustomByName(locationId: number, name: string): Observable<ShortLinkGetDto> {
    return this.http.get(`${this.serviceUrl}/custom/${locationId}/name/${name}`);
  }

  getCustomWidgetQrCodePreview(locationId: number, widgetId: number): Observable<ShortLinkPreviewDto> {
    return this.http.get(`${this.serviceUrl}/location/${locationId}/widget/${widgetId}/preview`);
  }

  getCustomLinkLocationUsage(locationId: number): Observable<ShortLinkUsage> {
    return this.http.get(`${this.serviceUrl}/usage/${locationId}`);
  }

  getAllCustom(locationIds: number[]): Observable<ShortLinkGetDto[]> {
    var filter = new ShortLinkFilterLocationsWidgetsPostDto();
    filter.locationIds = locationIds;
    return this.http.post(`${this.serviceUrl}/custom`, filter);
  }

  getAllApps(locationIds: number[]): Observable<ShortLinkGetDto[]> {
    var filter = new ShortLinkFilterLocationsWidgetsPostDto();
    filter.locationIds = locationIds;

    return this.http.post(`${this.serviceUrl}/apps`, filter);
  }
  createCustomShortLink(shortLink: ShortLinkCustomPost): Observable<ShortLinkGetDto> {
    return this.http.post(`${this.serviceUrl}/${ShortLinkCreationSources.Admin}`, shortLink);
  }
  update(id: number, shortLink: ShortLinkPutDto): Observable<ShortLinkGetDto> {
    return this.http.put(`${this.serviceUrl}/${ShortLinkCreationSources.Admin}/${id}`, shortLink);
  }
}
