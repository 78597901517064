import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TableCellAction, TableCellActionDataIn, TableCellActionType } from "@app/models";

type TableRow = { id: string | number } & { [key: string]: any };

@Component({
  selector: "app-table-cell-actions-menu",
  templateUrl: "./table-cell-actions-menu.component.html",
  styleUrls: ["./table-cell-actions-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellActionsMenuComponent {
  @Input() row: TableRow;
  @Input() actionList: TableCellActionDataIn[];

  @Output() action = new EventEmitter<TableCellAction>();

  onAction(actionType: TableCellActionType, customTypeValue = ""): void {
    this.action.emit({ actionType, customTypeValue });
  }
}
