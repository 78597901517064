<form [formGroup]="form" *transloco="let t; read: 'widgets.enrollLoyalty'">
  <!-- BEGIN -->

  <input formControlName="loyaltyProgram" hidden />
  <input formControlName="maxStampsPoints" hidden />

  <div class="col-12">
    <button type="button" class="btn btn-primary font-size-15 w-100 mb-3" (click)="createNewProgram()" *ngIf="!isCreatedProgram">
      {{ t("btnCreateNewProgram") }}
    </button>
    <button type="button" class="btn btn-primary font-size-15 w-100 mb-4" (click)="previewProgram()" *ngIf="isCreatedProgram">
      {{ t("btnProgramDetails") }}
    </button>

    <div class="separator text-secondary gap-4 my-3" *ngIf="!isCreatedProgram">or</div>
    <div class="form-group" *ngIf="!isCreatedProgram || isAdmin">
      <label *ngIf="!isAdmin" class="col-form-label">{{ t("labelLinkProgram") }} </label>
      <label *ngIf="isAdmin" class="col-form-label">{{ t("labelAdminLinkProgram") }} </label>
      <app-url-form-control [label]="''" formControlName="enrollLoyaltyLink" [validators]="validators"></app-url-form-control>
    </div>
  </div>

  <ngb-alert
    type="warning"
    class="mt-3 mb-0"
    [dismissible]="false">
    {{ ('widgets.enrollLoyalty' + (isConfiguredAdmin ? '.disclaimerConfigured' : '.disclaimerUnConfigured') ) | transloco }}
  </ngb-alert>
  <!-- END -->
</form>
