import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { LandingService } from "@app/services";

@Component({
  selector: "app-thumbnail-preview",
  templateUrl: "./thumbnail-preview.component.html",
  styleUrls: ["./thumbnail-preview.component.scss"]
})
export class ThumbnailPreviewComponent implements OnInit {
  @Input() pageId: number;
  @Input() brandId: number;
  @Input() locationId: number;

  @Input() height = 100;
  @Input() width = 100;
  loading = true;
  zoom = this.width / 540;
  pageUrl: SafeResourceUrl;
  constructor(
    public route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public landingSvc: LandingService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const pageUrl = this.landingSvc.getLandingPageUrl(this.pageId, !(this.brandId && this.locationId), this.brandId, this.locationId);
    this.pageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pageUrl);
    setTimeout(() => {
      this.loading = false;
      this.cdr.detectChanges();
    }, 200);
  }
}
