import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService, ProfileModel } from "@app/core";
import { AccountService } from "@app/core/services/account.service";
import { AppConfigService } from "@app/core/services/app-config.service";
import { Account, AccountConfigurationDto, PlatformTypes, RoleTypes, SubscriptionPlan } from "@app/models";
import {
  AccountsService,
  LoyaltyProgramService,
  PlatformAccountService,
  PosSettingsService,
  PosSystemService,
  SubscriptionPlanService,
  ViewAsPartnerService
} from "@app/services";
import { MetisMenu } from "metismenujs";
import { forkJoin, of, Subject } from "rxjs";
import { catchError, filter, switchMap, take, takeUntil, tap } from "rxjs/operators";
import {
  adminNavMenu,
  getNavMenuItemsDeep,
  locationManagerNavMenu,
  managerNavMenu,
  NavMenuIdentifier,
  NavMenuItem,
  partnerAdminNavMenu
} from "./menus";
import { AccountConfigurationContentProviderDto } from "@app/models/content-providers";
import { ContentProviderType } from "cfs-communication-pack";

/** Sidebar component */
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() isCondensed = false;
  @ViewChild("ngxSimplebar") ngxSimplebarRef;
  @ViewChild("sideMenu") sideMenuRef: ElementRef;
  @ViewChildren("sideNavLink") sideNavLinkRef: QueryList<ElementRef<HTMLAnchorElement>>;
  readonly labelTranslationKey = "common.menu.";
  readonly digitalScreensDashboardLink = "http://app.clearlinetv.com/";
  user: ProfileModel;
  private _menu: MetisMenu;
  menuItems: NavMenuItem[] = [];

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private _router: Router,
    private _configSvc: AppConfigService,
    private _platformAccountSvc: PlatformAccountService,
    private _accountSvc: AccountService,
    private _accountsSvc: AccountsService,
    private _authService: AuthService,
    private _planService: SubscriptionPlanService,
    private _viewAsPartnerService: ViewAsPartnerService,
    private _posSystemService: PosSystemService,
    private _posSettingsService: PosSettingsService,
    private _loyaltyProgramService: LoyaltyProgramService
  ) {}

  ngOnInit() {
    this.menuItems = SidebarComponent._getMenuItemsForRole(this._accountSvc.user?.role);

    this._updateNestingVisibility();

    this._accountSvc.userChanged$
      .pipe(
        tap((profile) => {
          this.user = profile;
        }),
        switchMap((profile) => {
          const isLocationManager: boolean = this._accountSvc.isUserInRole(RoleTypes.LocationManager);
          const locationId: number = +this._accountSvc.user.locationId;
          const hasLocationPlan: boolean = Number.isInteger(locationId) && (!!profile?.doneQuickSetup || isLocationManager);

          return hasLocationPlan ? this._planService.getLocationPlan(locationId) : of(null);
        }),
        filter((plan) => !!plan),
        takeUntil(this._unsubscribe$)
      )
      .subscribe((plan) => {
        this._showAllMenu();
        this._handleItemsDisplayOnAccount(plan);

        if (this.user?.subscriptionId) {
          this._setMenuItemVisibility(NavMenuIdentifier.OutboundMarketing, false); //for clover
        }
        this._updateNestingVisibility();

        document.body.setAttribute("data-sidebar", "dark");
        this._scrollToActiveMenu();
      });
  }

  ngAfterViewInit() {
    this._menu = new MetisMenu(this.sideMenuRef.nativeElement);
    this._setMenuActiveStates();

    this._router.events.forEach((x) => {
      if (x instanceof NavigationEnd) {
        this._setMenuActiveStates();
        this._scrollToActiveMenu();
      }
    });
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenuRef) || this.isCondensed) {
      setTimeout(() => {
        this._menu = new MetisMenu(this.sideMenuRef.nativeElement);
      });
    } else if (this._menu) {
      this._menu.dispose();
    }
  }

  onRouterLink(): void {
    this._viewAsPartnerService.deactivateFeature();
  }

  onClick(fnName: string, params: unknown): void {
    this._viewAsPartnerService.deactivateFeature();

    if (typeof this[fnName] === "function") {
      this[fnName](params);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /** Shows all menus except `customDisplayHandling`. */
  private _showAllMenu() {
    this.menuItems.forEach((x) => {
      if (!x.customDisplayHandling) {
        x.hidden = false;
      }
    });
  }
  private _handleItemsDisplayOnAccount(plan: SubscriptionPlan): void {
    const isManager: boolean = this._accountSvc.isUserInRole(RoleTypes.Manager);
    const isLocationManager: boolean = this._accountSvc.isUserInRole(RoleTypes.LocationManager);

    if (isManager || isLocationManager) {
      this._setMenuItemVisibility(NavMenuIdentifier.Dashboard, plan.options.hasDashboard);
      this._setMenuItemVisibility(NavMenuIdentifier.MarketingCenter, plan.options.hasCmc);
      this._setMenuItemVisibility(NavMenuIdentifier.CFSTerminals, plan.options.hasCfs);
      this._setMenuItemVisibility(NavMenuIdentifier.Templates, plan.options.hasCfs);
      this._setMenuItemVisibility(NavMenuIdentifier.PixelTags, plan.options.hasTrackingPixel);
      this._setMenuItemVisibility(NavMenuIdentifier.Contacts, plan.options.hasExternalContacts);
      this._setMenuItemVisibility(NavMenuIdentifier.ReportingAnalytics, plan.options.hasReportingAndAnalytics);
      this._setMenuItemVisibility(NavMenuIdentifier.Products, plan.options.hasProducts);

      this._accountsSvc
        .getCurrentAccount()
        .pipe(
          tap((account: Account) => this._accountSvc.setCurrentAccount(account)),
          take(1)
        )
        .subscribe((account: Account) => {
          const configuration: AccountConfigurationDto = account.configuration;
          const { reportName, isReportEnabled } = configuration.reporting?.activationTransactions || {};
          const isDigitalScreensEnabled: boolean = this.getIsDigitalScreensEnabled(configuration);
          this._setMenuItemVisibility(NavMenuIdentifier.CustomTransactionReport, isReportEnabled);

          if (isReportEnabled) {
            this._setMenuItemLabel(NavMenuIdentifier.CustomTransactionReport, reportName, true);
          }

          this._setMenuItemVisibility(NavMenuIdentifier.DigitalScreens, isDigitalScreensEnabled);
          this._setMenuItemVisibility(NavMenuIdentifier.DigitalScreensDashboard, isDigitalScreensEnabled);

          const isPosIntegrationAvailable: boolean = account?.isPosIntegrationAvailable === true && plan.options.hasPosIntegration;
          this._setMenuItemVisibility(NavMenuIdentifier.POSRules, isPosIntegrationAvailable);
          this._setMenuItemVisibility(NavMenuIdentifier.POSActivityReport, isPosIntegrationAvailable);
          this._setMenuItemVisibility(NavMenuIdentifier.POSSettings, isPosIntegrationAvailable);
          this._updateNestingVisibility();

          // todo: replace with account configuration isReceiptPromotionEnabled flag if it will be implemented:
          forkJoin([
            this._posSystemService.getAccountPosSystemSettings().pipe(catchError(() => of(null))),
            this._loyaltyProgramService.getSettings().pipe(catchError(() => of(null)))
          ]).subscribe(([posSettings, loyaltySettings]) => {
            const { isReceiptPromotionEnabled } = posSettings?.configuration || {};
            const isLoyaltyEnabled = loyaltySettings?.state === "Active";

            this._setMenuItemVisibility(NavMenuIdentifier.POSReceiptSettings, isReceiptPromotionEnabled);
            this._setMenuItemVisibility(NavMenuIdentifier.LoyaltyProgram, isLoyaltyEnabled);
            this._setMenuItemVisibility(NavMenuIdentifier.LoyaltyReporting, isLoyaltyEnabled);
          });
        });

      this._posSettingsService.settings$
        .pipe(
          filter((v) => !!v),
          takeUntil(this._unsubscribe$)
        )
        .subscribe((posSettings) => {
          const { isReceiptPromotionEnabled } = posSettings;

          this._setMenuItemVisibility(NavMenuIdentifier.POSReceiptSettings, isReceiptPromotionEnabled);
        });
    }

    if (isManager) {
      this._platformAccountSvc
        .getPlatformAccounts(this._accountSvc.userId)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe((platformAccounts) => {
          const hasRetentionCenterPlatform = platformAccounts.some((x) => x.platformId == PlatformTypes.Vendasta);
          const has2RewardPlatform = platformAccounts.some((x) => x.platformId == PlatformTypes.TwoReward);
          const hasSmsPlatform = platformAccounts.some((x) => x.platformId == PlatformTypes.SmsUSA);

          this._setMenuItemVisibility(
            NavMenuIdentifier.CompanyConnectors,
            (hasSmsPlatform || hasRetentionCenterPlatform) && plan.options.hasConnectors
          );
          this._setMenuItemVisibility(NavMenuIdentifier.OutboundMarketing, has2RewardPlatform && plan.options.hasOutboundMarketing);
          this._setMenuItemVisibility(NavMenuIdentifier.AdvancedTools, has2RewardPlatform && plan.options.hasOutboundMarketing);
          this._setMenuItemVisibility(
            NavMenuIdentifier.Contacts,
            (hasSmsPlatform || has2RewardPlatform) && plan.options.hasExternalContacts
          );
        });
    }
  }

  private _setMenuItemVisibility(identifier: NavMenuIdentifier, isVisible: boolean) {
    const menuItem = this._getMenuItem(identifier);
    if (menuItem) menuItem.hidden = !isVisible;
  }

  private _setMenuItemLabel(identifier: NavMenuIdentifier, label: string, untranslatedLabel = false) {
    const menuItem = this._getMenuItem(identifier);

    if (menuItem && label) {
      menuItem.label = label;
      menuItem.untranslatedLabel = untranslatedLabel;
    }
  }

  private _getMenuItem(identifier: NavMenuIdentifier, searchSubItems = true): NavMenuItem | undefined {
    const menuItems = searchSubItems ? getNavMenuItemsDeep(this.menuItems) : this.menuItems;
    const res = menuItems.find((x) => x.identifier === identifier);
    return res;
  }

  private _updateNestingVisibility() {
    getNavMenuItemsDeep(this.menuItems).forEach((menuItem) => {
      if (menuItem.subItems) menuItem.hidden = menuItem.subItems.every((x) => x.hidden);
    });
  }

  private _setMenuActiveStates() {
    SidebarComponent._removeAllClasses("mm-active");
    SidebarComponent._removeAllClasses("mm-show");

    const links = this.sideNavLinkRef.map((x) => x.nativeElement);

    // Find active link by page path:
    let path = window.location.pathname;
    let activeLink = links.find((x) => x.pathname === path);
    if (!activeLink) {
      const slashInd = path.lastIndexOf("/");
      path = path.substring(0, slashInd);
      activeLink = links.find((x) => x.pathname === path);
    }
    if (!activeLink) return;

    // Add classes:
    activeLink.classList.add("active");
    const parent1Li = activeLink.parentElement as HTMLLIElement;
    if (!parent1Li) return;

    parent1Li.classList.add("mm-active");
    const parent2Ul = parent1Li.parentElement.closest("ul");
    if (!parent2Ul || parent2Ul.id === "side-menu") return;

    parent2Ul.classList.add("mm-show");
    const parent3 = parent2Ul.parentElement;
    if (!parent3 || parent3.id === "side-menu") return;

    parent3.classList.add("mm-active");
    const childAnchor = parent3.querySelector(".has-arrow");
    const childDropdown = parent3.querySelector(".has-dropdown");
    if (childAnchor) {
      childAnchor.classList.add("mm-active");
    }
    if (childDropdown) {
      childDropdown.classList.add("mm-active");
    }
    const parent4 = parent3.parentElement;
    if (!parent4 || parent4.id === "side-menu") return;

    parent4.classList.add("mm-show");
    const parent5 = parent4.parentElement;
    if (!parent5 || parent5.id === "side-menu") return;

    parent5.classList.add("mm-active");
    const child2Anchor = parent5.querySelector(".is-parent");
    if (!child2Anchor || child2Anchor.id === "side-menu") return;

    child2Anchor.classList.add("mm-active");
  }

  private _scrollToActiveMenu() {
    setTimeout(() => {
      const activeElement = document.getElementsByClassName("mm-active").item(0) as HTMLElement;
      const scrollElement = this.ngxSimplebarRef?.SimpleBar?.getScrollElement();
      if (activeElement == null || scrollElement == null) return;

      const offsetTop = activeElement.offsetTop;
      if (offsetTop > 500) {
        scrollElement.scrollTop = offsetTop + 300;
      }
    }, 300);
  }

  private static _getMenuItemsForRole(userRole: string): NavMenuItem[] {
    switch (userRole) {
      case RoleTypes.Admin:
        return adminNavMenu;
      case RoleTypes.PartnerAdmin:
        return partnerAdminNavMenu;
      case RoleTypes.Manager:
        return managerNavMenu;
      case RoleTypes.LocationManager:
        return locationManagerNavMenu;
      default:
        return [];
    }
  }

  public static removeAllClasses() {
    SidebarComponent._removeAllClasses("mm-active");
    SidebarComponent._removeAllClasses("mm-show");
  }

  /** Remove `className` from all document elements. */
  private static _removeAllClasses(className: "mm-active" | "mm-show") {
    const elements = document.getElementsByClassName(className);
    while (elements[0]) {
      elements[0].classList.remove(className);
    }
  }

  private getIsDigitalScreensEnabled(accountConfiguration: AccountConfigurationDto): boolean {
    return !!accountConfiguration.externalConnection?.contentProviders?.find(
      (item: AccountConfigurationContentProviderDto) => item.providerType === ContentProviderType.Yodeck && item.isEnabled
    );
  }

  /// Menu commands:
  openPlatform(platformId: number) {
    this._platformAccountSvc.openPlatform(platformId, this._accountSvc.userId);
  }
  openProduct(productCode: string) {
    const url = this._platformAccountSvc.getExternalProductUrlByCode(productCode);
    window.open(url, "_blank");
  }
  openCmc() {
    window.open(this._configSvc.appData.marketingCenterUrl, "_blank");
  }

  openDigitalScreensDashboard() {
    window.open(this.digitalScreensDashboardLink, "_blank");
  }

  logout() {
    this._authService.logout();
  }
  /**
   * Change the layout onclick
   * @param layout Change the layout
   */ //~~~Not used? layout.component.ts:32 - "changeLayout"
  /* changeLayout(layout: string) {
    this.eventService.broadcast("changeLayout", layout);
  } */
}
