export enum ShortLinkTargetTypes {
  DestinationUrl = "DestinationUrl",
  Coupon = "Coupon",
  Widget = "Widget"
}

export enum ShortLinkTargetType {
  DestinationUrl = 0,
  Coupon = 1,
  Widget = 2
}
