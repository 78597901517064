import { ApexLegend } from "ng-apexcharts";

import { ChartData, ChartOptions, TimeFrameTypes } from "@app/models";
import { OrdinalRawValue } from "echarts/types/src/util/types";
import { DateTimeZoneService } from "../date-timezone.service";
import {
  CustomLinks,
  CustomLinksByPeriod,
  CustomLinksByPeriodGetDto,
  CustomLinksCardInfoItem,
  TotalCustomLinksCardInfo
} from "../../models";

export class DashboardCustomLinksUtilities {
  private static readonly topNumber = 5;
  constructor() {}
  private static readonly minNumberForShortLabel = 20;

  private static apexTooltipItem: any = {
    title: {
      formatter: function (value: string) {
        return value;
      }
    }
  };

  private static apexLegend: ApexLegend = {
    tooltipHoverFormatter: function (type: string, opts: any) {
      const value = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
      return `${type} - <strong>${value}</strong>`;
    }
  };

  private static chartOptions: Partial<ChartOptions> = {
    chart: {
      height: 350,
      type: "line",

      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "clearline_custom_links"
          },
          svg: {
            filename: "clearline_custom_links"
          },
          png: {
            filename: "clearline_custom_links"
          }
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "20%"
      }
    },

    dataLabels: {
      enabled: false
    },

    stroke: {
      width: 3,
      curve: "smooth"
    },
    legend: DashboardCustomLinksUtilities.apexLegend,
    markers: {
      size: 5,
      hover: {
        sizeOffset: 3
      }
    },
    yaxis: {
      min: 0,
      labels: {
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: function (value: OrdinalRawValue | number) {
          return value ? (value as number).toFixed(0) : "0";
        }
      }
    },
    xaxis: {
      offsetX: 0,
      offsetY: 0,
      tickPlacement: "between",
      labels: {
        trim: false,
        hideOverlappingLabels: true
      }
    },
    tooltip: {
      y: [
        DashboardCustomLinksUtilities.apexTooltipItem,
        DashboardCustomLinksUtilities.apexTooltipItem,
        DashboardCustomLinksUtilities.apexTooltipItem,
        DashboardCustomLinksUtilities.apexTooltipItem
      ]
    }
  };

  static getTopCustomLinks(arr: CustomLinks[]): CustomLinks[] {
    if (arr && arr?.length) {
      const uniqueNames = this.getUniqueByKey(arr) as CustomLinks[];
      let group = [];
      uniqueNames.forEach((item, i) => {
        let findList = arr.filter((s) => s.name === item.name);
        const shortLink: CustomLinks = this.getTotalCustomLinksFromList(findList);
        group.push(shortLink);
      });
      return group
        .sort((a: CustomLinks, b: CustomLinks) => b.totalCount - a.totalCount)
        .slice(0, this.topNumber)
        .filter((item: CustomLinks) => !!item.totalCount);
    }
  }

  static getUniqueByKey(array) {
    const arrayUniqueByKey = [...new Map(array.map((item) => [item["name"], item])).values()];
    return arrayUniqueByKey;
  }

  static getCustomLinksForCard(list: CustomLinks[]): TotalCustomLinksCardInfo {
    if (list?.length) {
      const allList = list;
      const topList = this.getTopCustomLinks(list);
      const totalCustomLinks: CustomLinks = this.getTotalCustomLinksFromList(allList);
      const data: CustomLinksCardInfoItem[] = topList.map((item: CustomLinks) => {
        return {
          label: item.name,
          value: item.totalCount,
          progressPerCent: this.getProgress(item.totalCount, totalCustomLinks?.totalCount),
          color: item.color
        };
      });

      return {
        totalCount: totalCustomLinks?.totalCount ?? 0,
        list: data
      };
    }
  }

  static getChartOptions(info: CustomLinksByPeriodGetDto<CustomLinksByPeriod>, currentTimeFrame: TimeFrameTypes): Partial<ChartOptions> {
    if (currentTimeFrame) {
      const data: ChartData = this.getApexAxisChartData(info, currentTimeFrame);
      const chartOptions: Partial<ChartOptions> = {
        ...DashboardCustomLinksUtilities.chartOptions
      };

      chartOptions.series = data.series;
      chartOptions.xaxis.categories = data.categories;
      chartOptions.xaxis = { ...chartOptions.xaxis };
      return chartOptions;
    }
  }

  private static getPeriodCustomLinksItemCounts(
    info: CustomLinksByPeriodGetDto<CustomLinksByPeriod>,
    key: string,
    periods: string[]
  ): number[] {
    const result: number[] = [];

    if (!info?.data) return result;
    if (periods && info.data) {
      periods.forEach((item: string) => {
        const obj = info.data.find((x) => x.name === key && x.name !== null && x.startDate === item);
        result.push(obj?.totalCount ? obj?.totalCount : 0);
      });
    }
    return result;
  }

  private static getApexAxisChartData(info: CustomLinksByPeriodGetDto<CustomLinksByPeriod>, currentTimeFrame: TimeFrameTypes): ChartData {
    if (!info) {
      return {
        series: [],
        categories: []
      };
    }

    if (info.data) {
      const periods: string[] = DashboardCustomLinksUtilities.getPeriodsForCustomLinks(info.data);
      const uniqueNames = this.getUniqueByKey(info.data) as CustomLinksByPeriod[];

      const series = uniqueNames
        .filter((x) => x.name !== null)
        .map((item) => {
          return {
            name: item.name,
            color: item.color,
            data: this.getPeriodCustomLinksItemCounts(info, item.name, periods)
          };
        });

      const timeCategories = [];
      if (periods?.length) {
        const shortDateLabel: boolean = periods?.length > this.minNumberForShortLabel;

        periods.forEach((item: string) => {
          const date: string = DateTimeZoneService.getChartDateItem(item, currentTimeFrame, shortDateLabel);
          timeCategories.push(date);
        });
      }

      return {
        series,
        categories: timeCategories
      };
    }
  }

  private static getPeriodsForCustomLinks(list: CustomLinksByPeriod[]): string[] {
    const result = [...new Set(list.map((obj) => obj.startDate))];
    return result;
  }

  private static getProgress(value: number, totalValue: number): number {
    return (value / totalValue) * 100;
  }

  private static getTotalCustomLinksFromList(list: CustomLinks[]): CustomLinks {
    return list.reduce((previousValue: CustomLinks, currentValue: CustomLinks) => {
      if (!previousValue) {
        return { ...currentValue };
      } else {
        previousValue.totalCount += currentValue.totalCount;
        return { ...previousValue };
      }
    }, null);
  }
}
