import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { ClientProfileGetDto, ClientProfilePutDto, ClientProfileStatisticsGetDto } from "@app/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CustomerService {
  private serviceUrl = "ClientProfile";

  constructor(private http: DataService) {}

  getCustomerList(locationIds: number[]): Observable<ClientProfileGetDto[]> {
    return this.http.post<ClientProfileGetDto[]>(`${this.serviceUrl}/account`, locationIds);
  }

  getStatistics(id: string): Observable<ClientProfileStatisticsGetDto> {
    return this.http.get<ClientProfileStatisticsGetDto>(`${this.serviceUrl}/${id}/statistics`);
  }

  getCustomer(id: string): Observable<ClientProfileGetDto> {
    return this.http.get<ClientProfileGetDto>(`${this.serviceUrl}/${id}`);
  }

  updateCustomer(id: string, payload: ClientProfilePutDto): Observable<ClientProfileGetDto> {
    return this.http.put<ClientProfileGetDto>(`${this.serviceUrl}/${id}`, payload);
  }

  deleteCustomer(id: string): Observable<void> {
    return this.http.delete<void>(`${this.serviceUrl}/account/${id}`);
  }
}
