import { Injectable } from "@angular/core";
import { AuthService } from "@app/core/services/auth.service";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { AccountService } from "./account.service";
import { RoleTypes } from "@app/models";

@Injectable({
  providedIn: "root"
})
export class EmailVerifyGuard implements CanActivate {
  constructor(private router: Router, private accountSvc: AccountService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let result = true;

    result = this.isEmailVerified();
    if (!result) {
      this.router.navigate(["/"]);
      return result;
    }

    return result;
  }

  isAccountPendingConfiguration(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = user?.doneQuickSetup && !user?.configured;
    if (!result) {
      return false;
    }
    return true;
  }

  isEmailVerified() {
    const user = this.accountSvc.user;
    const result = user && (user.email_verified || this.authService.requredMinimumRole(RoleTypes.PartnerAdmin));
    if (result) {
      return false;
    }
    return true;
  }
}
