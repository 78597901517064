import { Brand, AllowedDeliveryChannel, LandingPage, Location } from "@app/models";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export class WidgetSettingsModalBaseComponent {
  widgetId: number;
  title: string;
  widgetSettingsJson: string;
  deliveryMethod: AllowedDeliveryChannel;
  location: Location;
  readonly = false;
  configured = false;
  page: LandingPage;

  brand: Brand;

  constructor(public activeModal: NgbActiveModal) {}

  dismiss() {
    this.activeModal.dismiss();
  }
}
