<div *transloco="let tc; read: 'common'">
  <button mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" class="select-button w-100">
    <div *ngIf="!formControl.value">{{ label }}</div>
    <div *ngIf="formControl.value" [ngbTooltip]="selectedItem?.title" class="selected-value">
      {{ selectedItem?.title }}
    </div>
  </button>

  <mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition" class="filter-select-menu-container">
    <div (click)="$event.stopPropagation()" class="px-2">
      <div class="input-group d-flex p-0">
        <input
          [(ngModel)]="filterText"
          (ngModelChange)="filter()"
          (keydown)="filterInputKeydown($event)"
          [placeholder]="tc('controls.search')"
          class="form-control"
        />
      </div>

      <ngx-simplebar [style.max-height]="itemsMaxHeightPx + 'px'" [style.width]="itemsWidthPx + 'px'" class="mt-1">
        <button
          *ngFor="let item of filteredItems"
          mat-menu-item
          (click)="selectSingle(item)"
          class="mat-menu-item"
          [class.menu-item-selected]="item.value === formControl?.value"
        >
          <div class="menu-item-text">
            <div class="menu-item-title">{{ item.title }}</div>
            <div class="menu-item-description ps-2">{{ item.description }}</div>
          </div>
        </button>
      </ngx-simplebar>

      <div *ngIf="canClear" class="action-area splitter mt-1">
        <button (click)="resetControl()" type="button" class="btn btn-outline-light btn-card-action mt-2">
          {{ tc("controls.reset") }}
        </button>
      </div>
    </div>
  </mat-menu>
</div>
