import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GridCellStatus } from "./grid-cell-status.model";

@Component({
  selector: "app-grid-cell-status",
  templateUrl: "./grid-cell-status.component.html",
  styleUrls: ["./grid-cell-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCellStatusComponent {
  @Input() status: GridCellStatus | string;
  @Input() searchQuery: string;
}
