import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { FileMimeType } from "@app/shared/file-uploader/file-uploader-modal/file-type";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class FileUploaderService {
  private serviceUrl = "FileUploader";
  constructor(private http: DataService) {}

  saveFile(file: File, storageFolder: string): Observable<string> {
    return this.http.uploadFile(`${this.serviceUrl}/${storageFolder}`, file);
  }

  uploadFormData(formData: FormData, storageFolder: string): Observable<string> {
    return this.http.uploadFormData(`${this.serviceUrl}/${storageFolder}`, formData).pipe(map((response) => response.link));
  }

  getAcceptedFiles(extensions: Array<FileMimeType>) {
    return `${extensions?.length ? extensions?.join(", ") : "application/*"}`;
  }
}
