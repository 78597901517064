import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
@Pipe({
  name: "maskedDigitalHighlight"
})
export class MaskedDigitalHighlightPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    if (!searchTerm || !value || searchTerm.match(/[a-zA-Z]/)) {
      return value;
    }

    const sanitizedSearchTerm = searchTerm.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    const searchTermDigits = sanitizedSearchTerm.replace(/\D/g, ""); // Extract only digits from the search term

    if (!searchTermDigits) {
      return value;
    }

    const searchTermArray = searchTermDigits.split("");

    let highlightedValue = "";
    let valueIndex = 0;

    // Check if the search term is a single digit
    if (searchTermArray.length === 1) {
      for (let i = 0; i < value.length; i++) {
        const currentChar = value[i];

        // Check if the current character is the single-digit search term
        if (currentChar === searchTermArray[0]) {
          // Highlight the digit
          highlightedValue += `<mark>${currentChar}</mark>`;
        } else {
          // Append the non-digit character
          highlightedValue += currentChar;
        }
      }
    } else {
      // Search term has more than one digit, maintain the original logic for ordering
      for (const digit of searchTermArray) {
        const nextIndex = value.indexOf(digit, valueIndex);

        if (nextIndex !== -1) {
          highlightedValue += value.substring(valueIndex, nextIndex);
          highlightedValue += `<mark>${value[nextIndex]}</mark>`;
          valueIndex = nextIndex + 1;
        } else {
          return value;
        }
      }

      highlightedValue += value.substring(valueIndex);
    }

    return highlightedValue;
  }
}
