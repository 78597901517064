import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { SeeMenuWidgetSettings } from "@app/pages/components/widget-settings/see-menu-widget-settings/see-menu-widget-settings";
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from "@angular/forms";
import { Patterns } from "@app/shared/validators";

@Component({
  selector: "app-see-menu-widget-settings",
  templateUrl: "./see-menu-widget-settings.component.html",
  styleUrls: ["./see-menu-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SeeMenuWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SeeMenuWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class SeeMenuWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private _widget: SeeMenuWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.widgetSetting = JSON.parse(value) as SeeMenuWidgetSettings;
  }

  public set widgetSetting(value: SeeMenuWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls.menuUrl.setValue(value.menuUrl);
        this.form.controls.imageUrl.setValue(value.imageUrl);
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      menuUrl: new FormControl("", [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)]),
      imageUrl: new FormControl("")
    });
    this.widgetSettingsReady.emit();
  }
}
