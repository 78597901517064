import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Location, MultiSelectItem } from "@app/models";

@Component({
  selector: "app-multi-autocomplete",
  templateUrl: "./multi-autocomplete.component.html",
  styleUrls: ["./multi-autocomplete.component.scss"]
})
export class MultiAutocompleteComponent implements OnInit {
  constructor() {}

  @Input() set items(items: MultiSelectItem[]) {
    if (items) {
      this._items = items;
      this.filter();
    }
  }
  get items(): MultiSelectItem[] {
    return this._items;
  }

  @Output() filtered = new EventEmitter<MultiSelectItem[]>();

  private _items: MultiSelectItem[] = [];

  searchControl = new FormControl<string>("");
  filteredItems: MultiSelectItem[] = [];

  ngOnInit(): void {}

  filter(): void {
    this.filteredItems = this.getFilteredItems();
    if (this.items.length !== this.filteredItems.length) this.filtered.emit(this.filteredItems);
  }

  private getFilteredItems(): MultiSelectItem[] {
    let search = this.searchControl.value;
    if (!search || !search.trim()) return this._items.slice();

    const searchWord = search.toLowerCase();
    return this._items.filter((item: MultiSelectItem) => {
      return item.name && item.name.toLowerCase().indexOf(searchWord) !== -1;
    });
  }
}
