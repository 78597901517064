import { Injectable } from "@angular/core";
import {
  CashRegister,
  CFSConnectionSettings,
  CFSPairingState,
  CFSRequestResult,
  ClientCashRegister,
  ManagerMessagingService
} from "cfs-communication-pack";
import { ToastService } from "@app/shared/ui";
import { filter, take, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class CFSConnectionService {
  constructor(
    private messagingService: ManagerMessagingService,
    public toastSvc: ToastService,
    private translateService: TranslocoService
  ) {}

  init(userId: string): void {
    this.messagingService.init(userId);
  }

  getState(): Observable<CFSPairingState> {
    // should be run only one on init
    return this.messagingService.state$;
  }

  runWatchingStatuses(cashRegister: CashRegister): void {
    // should be run only one on init
    const { id, locationId, cfsCode } = cashRegister;

    this.messagingService.runWatchingStatuses(
      {
        id,
        locationId,
        cfsCode
      },
      false
    );
  }

  updateStateOnConnect(state: CFSPairingState): void {
    this.messagingService.updateStateOnConnect(state);
  }

  pairing(currentCashRegisterId: number, locationId: number, currentCode: string, oldCode: string = ""): Observable<CFSRequestResult> {
    // todo: add to common project
    const settings: CFSConnectionSettings = this.getCFSConnectionSettings(currentCashRegisterId, locationId);
    this.messagingService.startPairing(currentCode, currentCashRegisterId, settings, oldCode);

    return this.messagingService.pairingResult$.pipe(
      filter((result: CFSRequestResult) => result?.code === currentCode),
      tap(() => this.messagingService.finishPairing()),
      take(1)
    );
  }

  resetCashRegister(cashRegister: ClientCashRegister): Observable<CFSRequestResult> {
    const { cfsCode, id, locationId } = cashRegister;

    if (cfsCode) {
      const settings: CFSConnectionSettings = this.getCFSConnectionSettings(id, locationId);
      this.messagingService.startCashRegisterReset(cfsCode, settings);

      return this.messagingService.cfsResetResult$.pipe(
        filter((result: CFSRequestResult) => result?.code === cfsCode),
        tap(() => this.messagingService.finishCashRegisterReset()),
        take(1)
      );
    } else {
      return of({ confirmed: false, errorType: "noCfsCode" });
    }
  }

  notifyAboutConnectionStatus(status: boolean): void {
    if (status) {
      this.toastSvc.success(this.translateService.translateObject("cfs.notifications.paired"));
    } else {
      this.toastSvc.danger(this.translateService.translateObject("cfs.notifications.notPaired"));
    }
  }

  destroySubscriptions(): void {
    this.messagingService.destroyMessages();
  }

  private getCFSConnectionSettings(cashRegisterId: number, locationId: number): CFSConnectionSettings {
    return {
      locationId,
      cashRegisterId
    };
  }
}
