import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { take } from "rxjs/operators";
import { LoadingService } from "../../../core";
import { Location } from "../../../models";
import { LocationService } from "../../../services";

@Component({
  selector: "app-location-filter",
  templateUrl: "./location-filter.component.html",
  styleUrls: ["./location-filter.component.scss"]
})
export class LocationFilterComponent implements OnInit {
  @Input() hideReset: boolean;
  @Input() selectedLocation: Location = null;
  @Input() visibleMoreOne: boolean = false;
  visibleFilter: boolean = false;

  constructor(private locationService: LocationService, private loadingService: LoadingService) {}

  @Output() filterByLocation = new EventEmitter<Location>();

  private isLocationsSet = false;

  dropdownOpened = false;
  loading = false;
  public locations: Location[] = [];

  ngOnInit(): void {
    if (!this.isLocationsSet) {
      this.setLocations();
    }
  }

  setVisibleMoreOne() {
    this.visibleFilter = this.visibleMoreOne ? this.locations.length > 1 : true;
  }

  onSelectedLocation(location: Location): void {
    this.selectedLocation = location;
    this.dropdownOpened = false;

    this.filterByLocation.emit(location);
  }

  private setLocations(): void {
    this.startLoading();
    this.isLocationsSet = true;

    this.locationService
      .getAll()
      .pipe(take(1))
      .subscribe((locations: Location[] = []) => {
        this.finishLoading();
        this.locations = locations;
        this.setVisibleMoreOne();
      });
  }

  private startLoading(): void {
    this.loading = true;
    this.loadingService.showSpinner();
  }

  private finishLoading(): void {
    this.loading = false;
    this.loadingService.hideSpinner();
  }
}
