export class Command<T> {
  private _canExecute: (model: T) => boolean;
  private _disabled: (model: T) => boolean;
  private _execute: (model: T) => void;
  public name: string;
  public text: string;
  public icon: string;
  public iconColor: string;
  public tooltip: string;

  public canExecute(model: T): boolean {
    return this._canExecute(model);
  }
  public disabled(model: T): boolean {
    return this._disabled(model);
  }
  public execute(model: T) {
    this._execute(model);
  }

  constructor(
    name: string,
    text: string,
    icon: string,
    iconColor: string,
    canExecute: ((model: T) => boolean) | boolean,
    execute: (model: T) => void,
    tooltip = "",
    disabled: ((model: T) => boolean) | boolean = false
  ) {
    this.name = name;
    this.text = text;
    this.icon = icon;
    this.iconColor = iconColor;
    if (typeof canExecute === "boolean") this.canExecute = () => canExecute;
    else this.canExecute = canExecute;
    this.execute = execute;
    this.tooltip = tooltip;
    if (typeof disabled === "boolean") this.disabled = () => disabled;
    else this.disabled = disabled;
  }
}
