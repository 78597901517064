import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Observable } from "rxjs";
import { MediaContent, MediaContentType } from "@app/models/media-content";

@Injectable({
  providedIn: "root"
})
export class MediaContentService {
  private serviceUrl = "mediaContent";

  constructor(private http: DataService, public https: HttpClient) {}

  public getMediaContent(id: number): Observable<MediaContent> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  public getByType(type: MediaContentType): Observable<MediaContent[]> {
    return this.http.get(`${this.serviceUrl}/type/${type}`);
  }
}
