import { Injectable } from "@angular/core";
import { AccountPosSystemSettingsConfiguration } from "@app/models";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PosSettingsService {
  private settings = new BehaviorSubject<AccountPosSystemSettingsConfiguration>(null);
  settings$: Observable<AccountPosSystemSettingsConfiguration> = this.settings.asObservable();

  getSettings(): AccountPosSystemSettingsConfiguration {
    return this.settings.value;
  }

  updateSettings(settings: AccountPosSystemSettingsConfiguration): void {
    this.settings.next(settings);
  }
}
