import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable({
  providedIn: "root"
})
export class ImperosnationService {
  constructor(private oauthService: OAuthService) {}

  impersonate(userId: number): void {
    const queryParameters = this.oauthService.customQueryParams as any;
    queryParameters.actAsUserId = userId;

    window.open("?actAsUserId=" + queryParameters.actAsUserId);
  }
}
