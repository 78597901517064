import { Component, Input } from "@angular/core";

@Component({
  selector: "app-base-url-form-control",
  template: ""
})
export class BaseUrlFormControlComponent {
  @Input() label: string;
  @Input() isRequired = false;
  @Input() isHideTooltip = false;
  @Input() iconInside: string;
  @Input() iconInsideColor: string;
  @Input() iconSvg: string;
  @Input() placeholder: string;
  @Input() labelClassList = "";
  @Input() errorClassList = "";
  @Input() disable = false;
  @Input() isLinked = false;
  @Input() icon = "";
  @Input() iconColor = "";
  @Input() inputGroup = false;
  @Input() tooltipItems: string[] = [];

  /** @deprecated use `isRequired` instead */
  @Input() set mark(value: boolean) {
    this.isRequired = value;
  }
}
