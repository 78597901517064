import { FormControl } from "@angular/forms";

export function positiveIntegerValidator(control: FormControl): { [key: string]: any } | null {
  const value: number = +control.value;

  if (control.value !== null && (!Number.isInteger(value) || value <= 0)) {
    return { positiveInteger: true };
  }

  return null;
}
