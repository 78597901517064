import { QRCodeSettings } from "..";

export interface CouponSearchModel {
  active?: boolean;
  directory?: string;
  keyword?: string;
  tags?: string;
  category: string;
}

export class Category {
  id = "";
  name: string;
}

export class CouponInfo {
  id: string;
  code: string;
  url: any;
  couponImage: string;
  status: string; // (example | active | archived | template | deleted)
  name: string;
  title: string;
  subtitle: string;
  description: string;
  couponCategoryId: string;
  couponCategoryName: string;
  banner1: string;
  banner2: string;
  banner3: string;
  banner4: string;
  banner5: string;
  subaccount: string;
  logoUrl: string;
  couponTags: string;
  expiryDate: string;
  isSelected: boolean;
}

export class CouponInfoQr extends CouponInfo {
  qrSettings?: QRCodeSettings;

  public static toCouponSettings(coupon: CouponInfo) {
    const res = Object.assign(new CouponInfoQr(), coupon);
    res.qrSettings = { url: res.url, logo: "" } as QRCodeSettings;

    return res;
  }
}

export interface AccountCouponsGetDto {
  locationId: number;
  couponIds: string[];
}

export interface CouponLocationsGetDto {
  locationIds: number[];
}
