import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Account } from "@app/models";

import { BehaviorSubject, Observable } from "rxjs";
import { take, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AccountsService {
  private serviceUrl = "account";
  private currentAccount$ = new BehaviorSubject<Account>(null);

  constructor(private http: DataService) {}

  getAccounts(partnerIds: number[] = []): Observable<Account[]> {
    const queryParams = partnerIds.map((number) => `partnerIds=${number}`).join("&");
    const queryString = partnerIds.length ? `?${queryParams}` : "";

    return this.http.get(`${this.serviceUrl}${queryString}`);
  }

  getCurrentAccount(): Observable<Account> {
    return this.currentAccount$.value
      ? this.currentAccount$.pipe(take(1))
      : this.http.get(`${this.serviceUrl}/current`).pipe(tap((account: Account) => this.currentAccount$.next(account)));
  }

  getAccount(accountId: number): Observable<Account> {
    return this.http.get(`${this.serviceUrl}/${accountId}`);
  }

  updateAccount(account: Account): Observable<null> {
    return this.http.put(`${this.serviceUrl}/${account.id}`, account);
  }
}
