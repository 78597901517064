<div [formGroup]="form" class="">
  <div class="" *transloco="let t">
    <label class="col-form-label font-weight-semibold">
      {{ t(providerTitle) }}
    </label>
    <app-input
      *ngIf="getLabel('userName')"
      [control]="form.controls.username"
      [label]="getLabel('userName')"
      [isRequired]="true"
      [placeholder]="getLabel('userName')"
    ></app-input>

    <app-input
      *ngIf="getLabel('password')"
      [control]="form.controls.password"
      [label]="getLabel('password')"
      [isRequired]="true"
      [placeholder]="getLabel('password')"
    ></app-input>
    <app-input
      *ngIf="getLabel('clientApiId')"
      [control]="form.controls.clientApiId"
      [label]="getLabel('clientApiId')"
      [isRequired]="true"
      [placeholder]="getLabel('clientApiId')"
    ></app-input>
    <app-input
      *ngIf="getLabel('clientApiSecret')"
      [control]="form.controls.clientApiSecret"
      [label]="getLabel('clientApiSecret')"
      [isRequired]="true"
      [placeholder]="getLabel('clientApiSecret')"
    ></app-input>
  </div>
</div>
