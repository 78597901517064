import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CouponInfo } from "@app/models";
import { CouponService } from "@app/services/coupon.service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CouponsService {
  private coupons: CouponInfo[] = [];

  constructor(private couponService: CouponService) {}

  getCouponById(couponId: string): Observable<CouponInfo> {
    const coupon: CouponInfo = this.coupons.find((item: CouponInfo) => item.id === couponId);

    return coupon
      ? of(coupon)
      : this.couponService.getCouponById(couponId).pipe(
          tap((newCoupon: CouponInfo) => {
            this.coupons.push(newCoupon);
          })
        );
  }
}
