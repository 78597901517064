import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Account } from "@app/models";
import { AccountsService } from "@app/services";
import { Observable } from "rxjs";
import { map, take, tap } from "rxjs/operators";

export function isCustomTransactionReportAvailableGuard(): Observable<boolean> {
  const accountsService: AccountsService = inject(AccountsService);
  const router: Router = inject(Router);

  return accountsService.getCurrentAccount().pipe(
    take(1),
    map((account: Account) => {
      return account.configuration.reporting?.activationTransactions?.isReportEnabled;
    }),
    tap((isAllowed: boolean) => {
      if (!isAllowed) {
        router.navigate(["access-denied"]);
      }
    })
  );
}
