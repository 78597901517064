import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CouponInfoQr, QRCodeSettings } from "@app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CouponPreviewModalComponent } from "@app/shared/components/coupon-preview-modal";

@Component({
  selector: "app-coupon",
  templateUrl: "./coupon.component.html",
  styleUrls: ["./coupon.component.scss"]
})
export class CouponComponent {
  constructor(private modalSvc: NgbModal) {}

  @Input() viewType: "simple" | "full" = "full";
  @Input() sizeType: "standard" | "small" = "standard";
  @Input() selectionType: "single" | "multiple" = "multiple";
  @Input() withPreview = true;
  @Input() index: number;
  @Input() previewMode = false;
  @Input() item: CouponInfoQr;
  @Input() selectedCoupon: CouponInfoQr;
  @Input() visible = true;
  @Input() disabledNotActive: boolean;
  @Input() removeSelected = false;

  @Output() selectCoupon = new EventEmitter<CouponInfoQr>();
  @Output() closeQrCodeModal = new EventEmitter<null>();

  loading = true;
  qrCodeSettings: QRCodeSettings = null;

  get isActive() {
    return this.item && this.item.status === "active";
  }

  singleSelectCoupon(coupon: CouponInfoQr): void {
    if (this.selectionType === "single" && !this.disabledNotActive) {
      this.selectCoupon.emit(coupon);
    } else if (this.disabledNotActive && this.isActive) {
      this.selectCoupon.emit(coupon);
    }

    this.removeSelectedCoupon(coupon);
  }

  multipleSelectCoupon(coupon: CouponInfoQr): void {
    if (this.viewType === "full" && this.selectionType === "multiple") {
      this.selectCoupon.emit(coupon);
    }
  }

  preview(coupon: CouponInfoQr) {
    const featureModal = this.modalSvc.open(CouponPreviewModalComponent, {
      backdrop: "static",
      size: "lg",
      centered: true
    });

    const comp: CouponPreviewModalComponent = featureModal.componentInstance;
    comp.item = coupon;
  }

  removeSelectedCoupon(coupon: CouponInfoQr) {
    if (this.removeSelected && this.selectedCoupon) {
      if (this.selectedCoupon.id == coupon.id) this.selectCoupon.emit(null);
      this.item.isSelected = null;
    }
  }
}
