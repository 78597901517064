<div
  class="w-100 box-container" *transloco="let t; read: 'common'"
  [class.is-invalid]="control?.touched && control?.invalid && selectedCoupon">
  <div
    *ngIf="selectedCoupon"
    (click)="selectCoupon()"
    class="box-coupon col-md-12 pt-1 pb-1 ps-2"
    [class.disabled]="controlDisabled">
    <div class="row">
      <div class="col-3">
        <img [src]="selectedCoupon.couponImage" class="box-coupon-image" />
      </div>
      <div class="col-9 align-self-center">
        <span class="text-muted font-size-12">{{ selectedCoupon.name }}</span>
        <br />
        <span class="font-bold">{{ selectedCoupon.title }}</span>
      </div>
    </div>
  </div>

  <button
    *ngIf="!couponId || (!displayInactiveCoupon && !selectedCoupon)"
    [disabled]="isSelectCouponDisabled || controlDisabled"
    (click)="selectCoupon()"
    type="button"
    class="btn btn-outline-primary">
    {{ t("controls.select") }}
  </button>
</div>

<ng-container *ngIf="control?.touched && control.errors">
  <ng-container *ngIf="control.errors.required">
    <div class="invalid-feedback position-absolute d-block">
      {{ "common.error.required" | transloco }}
    </div>
  </ng-container>
  <ng-container *ngIf="control.errors.couponNotActive && displayInactiveCoupon">
    <div class="invalid-feedback position-absolute d-block">
      {{ "common.components.errors.couponNotActive" | transloco }}
    </div>
  </ng-container>
</ng-container>

<app-warning
  *ngIf="!displayInactiveCoupon && (control?.errors?.couponNotActive || controlDisabled && !isSelectedCouponActive)"
  [text]="'common.components.errors.couponNotActive' | transloco: { title: selectedCouponTitle }"
  class="d-flex mt-3">
</app-warning>
