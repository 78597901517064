import { AfterViewChecked, ChangeDetectorRef, Component, Injector, Input, OnInit, forwardRef } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, UntypedFormControl, ValidatorFn } from "@angular/forms";
import { BaseUrlFormControlComponent } from "../base-url-form-control.component";

@Component({
  selector: "app-url-form-control",
  templateUrl: "./url-form-control.component.html",
  styleUrls: ["./url-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UrlFormControlComponent),
      multi: true
    }
  ]
})
export class UrlFormControlComponent extends BaseUrlFormControlComponent implements ControlValueAccessor, OnInit, AfterViewChecked {
  formControl = new UntypedFormControl(null);
  ngControl?: NgControl;

  constructor(private changeDetectorRef: ChangeDetectorRef, private injector: Injector) {
    super();
  }

  @Input() set value(value: string) {
    this.formControl.setValue(value);
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  get value() {
    return this.formControl.value;
  }

  @Input() set validators(value: ValidatorFn[]) {
    this.formControl.setValidators(value);
  }

  ngOnInit(): void {
    this.placeholder = !this.placeholder ? this.label : this.placeholder;
    this.ngControl = this.injector.get(NgControl, null);
  }

  ngAfterViewChecked() {
    const control = this.ngControl?.control;
    if (control && control.touched != this.formControl.touched) {
      if (control.touched) this.formControl.markAsTouched();
      else this.formControl.markAsUntouched();
    }

    this.setDisabledState(this.disable);
    this.changeDetectorRef.detectChanges();
  }

  onTouched: any = () => null;
  onChange: any = () => null;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  writeValue(obj: any): void {
    this.value = obj;
  }

  valueChanged() {
    this.updateValue();
  }

  onPaste() {
    setTimeout(() => {
      this.updateValue();
    });
  }

  get validator() {
    const validator = this.formControl.validator({} as AbstractControl);

    if (validator && validator.required) {
      return true;
    }
  }

  private updateValue() {
    this.value = this.formControl.value;
    this.onTouched();
  }
}
