import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from "@angular/forms";
import {
  AddPromoWidgetSettings,
  PromoPreferences
} from "@app/pages/components/widget-settings/add-promo-widget-settings/add-promo-widget-settings";
import { Patterns } from "@app/shared/validators";
import { CouponService, WidgetService } from "../../../../services";
import { Brand, Location, CouponInfo, WidgetType } from "../../../../models";
import { WidgetSettingsPreviewMessageComponent } from "../WidgetSettingsPreviewMessageComponent";

export class Preferences {
  value: PromoPreferences;
  name: string;
  checked: boolean;
}

enum SettingsControl {
  PromoListUrl = "promoListUrl",
  PromoPreferences = "promoPreferences",
  SelectedCouponId = "selectedCouponId",
  CampaignId = "campaignId"
}

@Component({
  selector: "app-add-promo-widget-settings",
  templateUrl: "./add-promo-widget-settings.component.html",
  styleUrls: ["./add-promo-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AddPromoWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddPromoWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class AddPromoWidgetSettingsComponent extends WidgetSettingsPreviewMessageComponent implements OnInit, OnDestroy {
  constructor(private changeDetectorRef: ChangeDetectorRef, public couponService: CouponService, public widgetService: WidgetService) {
    super(couponService, widgetService);
  }

  @Output() widgetSettingsReady = new EventEmitter();

  @Input() brand: Brand;
  _location: Location;
  @Input() set location(value: Location) {
    if (value) this._location = value;
  }

  get location(): Location {
    return this._location;
  }

  @Input() set widgetSettingsJson(value: string) {
    if (value) {
      this.widgetSetting = JSON.parse(value) as AddPromoWidgetSettings;
    } else {
      this.setCouponsAndPreviewMessage({
        widgetType: WidgetType.AddPromo,
        brandName: this.brand?.name,
        locationId: this.location?.id
      });
    }
  }

  private _widget: AddPromoWidgetSettings;
  public preferences: Array<Preferences> = [
    { checked: false, value: PromoPreferences.Sms, name: "SMS" },
    { checked: false, value: PromoPreferences.Email, name: "E-mail" },
    { checked: false, value: PromoPreferences.Phone, name: "Phone Call" }
  ];
  public validators: Array<ValidatorFn> = [Validators.pattern(Patterns.url), Validators.maxLength(2000)];

  settingsControl = SettingsControl;

  public set widgetSetting(value: AddPromoWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls[SettingsControl.PromoListUrl].setValue(value.promoListUrl);
        this.form.controls[SettingsControl.PromoPreferences].setValue(value.promoPreferences);
        this.form.controls[SettingsControl.CampaignId].setValue(value.campaignId);

        this.preferences.forEach((preference) => {
          preference.checked = ((2 ** preference.value) & value.promoPreferences) === 2 ** preference.value;
        });

        this.setCouponsAndPreviewMessage({
          widgetType: WidgetType.AddPromo,
          brandName: this.brand?.name,
          selectedCouponId: value.selectedCouponId,
          locationId: this.location?.id
        });
        this.changeDetectorRef.detectChanges();
      });
    }

    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup(
      {
        [SettingsControl.PromoListUrl]: new UntypedFormControl("", this.validators),
        [SettingsControl.PromoPreferences]: new UntypedFormControl(0, [Validators.min(1)]),
        [SettingsControl.SelectedCouponId]: new UntypedFormControl(""),
        [SettingsControl.CampaignId]: new UntypedFormControl("")
      },
      [this.formValidator()]
    );

    this.widgetSettingsReady.emit();
  }

  onPreferencesSelection(value: number) {
    let preferences = 0;

    this.preferences.forEach((reminder) => {
      if (reminder.checked) {
        preferences = this.form.controls[SettingsControl.PromoPreferences].value ^ Math.pow(2, value);
      }
    });

    this.form.controls[SettingsControl.PromoPreferences].setValue(preferences);
  }

  onCouponListChanged(coupon: CouponInfo): void {
    this.couponListChanged({
      widgetType: WidgetType.AddPromo,
      brandName: this.brand?.name,
      selectedCouponId: coupon?.id
    });
  }

  private formValidator(): ValidatorFn {
    const elseControlNameList: string[] = [SettingsControl.PromoPreferences, SettingsControl.SelectedCouponId];

    return this.getFormValidator(SettingsControl.PromoListUrl, elseControlNameList);
  }

  // destroy:
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
