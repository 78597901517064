<div class="card" *transloco="let t; read: 'account.email'">
  <div class="card-body">
    <div class="p-2">
      <div class="text-center">
        <div class="avatar-md mx-auto">
          <div class="avatar-title rounded-circle bg-light">
            <i class="bx bxs-envelope h1 mb-0 text-primary"></i>
          </div>
        </div>
        <div class="p-2 mt-4">
          <h4>{{ t("verifyE") }}</h4>
          <div *ngIf="!sent">
            <div>{{ t("verifyNice") }}</div>

            <a (click)="send()" class="btn btn-success w-md">{{ t("verify") }}</a>
          </div>
          <p *ngIf="sent">
            {{ t("sent") }}
            <span class="fw-bold">{{ email }}</span
            >,<br />
            {{ t("check") }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!resend" class="mt-3 text-center">
    <p>
      {{ t("notReceive") }} <a (click)="resend()" class="cursor-pointer fw-medium text-primary"> {{ t("resend") }} </a>
    </p>
  </div>
</div>
