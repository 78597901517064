<div class="row row-eq-height icons-view p-2" *transloco="let t; read: 'widgets.common'">
  <span *ngIf="showHeader" class="text-center font-size-18 card-title-desc" hidden>{{ t("adminTitle") }}</span>

  <ng-container *ngFor="let feature of widgets">
    <app-tile
      *ngIf="feature?.locationWidgetSettings && widgetIcon(feature.id)"
      [text]="feature.name"
      [model]="feature"
      (clicked)="widgetSvc.hasWidgetCampaign(feature.id) && openSettings(feature)"
      [icon]="widgetIcon(feature.id)"
      [(disabled)]="feature.locationWidgetSettings.disabled"
      [disabledTooltip]="isInteractiveWidget(feature) ? t('enable') : ''"
      [interactive]="isInteractiveWidget(feature)"
      [commands]="commands"
      [warning]="
        (!feature.locationWidgetSettings?.widgetSettingsJson && widgetSvc.hasWidgetCampaign(feature.id)) ||
        (!feature.locationWidgetSettings.configured && !widgetHasSettings(feature.locationWidgetSettings) && !isQuickSetup)
      "
      (disabledChange)="onWidgetChange(feature?.locationWidgetSettings)"
      [warningMessage]="getWarningMsg(feature)"
      class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 item-icon-view bg-light"
      container="body"
    >
    </app-tile>
  </ng-container>
</div>
