<div class="position-relative">
  <div
  #tooltip="ngbTooltip"
  class=""
  container="body"
  triggers="click:blur"
  tooltipClass="ngb-tooltip-wide-m"
  [ngbTooltip]="iconTooltipTemplate"
  (mouseout)="tooltip.close()"
>
    <i class="fa-exclamation-triangle fas text-warning"></i>

    <ng-template #iconTooltipTemplate>
      <ng-container *ngIf="tooltipRows.length">
        <div class="d-flex flex-column">
          <span *ngIf="tooltipTitle" class="text-start">{{ tooltipTitle }}</span>

          <div class="d-flex flex-column pt-1">
            <span *ngFor="let row of tooltipRows" class="text-start text-secondary border-top">
              {{ row }}
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
