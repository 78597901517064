import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoyaltyCard, LoyaltyExpirationPeriod } from "@app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { BehaviorSubject } from "rxjs";
import { AddRewardTierModalComponent } from "../add-reward-tier-modal/add-reward-tier-modal.component";
import {
  ListType,
  LoyaltyCalculationMode,
  LoyaltyCardType,
  LoyaltyCardTypes,
  LoyaltyOnboardingRequest,
  LoyaltyRewardItem
} from "../enroll-loyalty-widget-settings";
import { getLoyaltyProgramExpirationPeriodLabel, getLoyaltyProgramExpirationPeriodTitle } from "../enroll-loyalty-widget-settings.utils";

@Component({
  selector: "app-reward-settings",
  templateUrl: "./reward-settings.component.html",
  styleUrls: ["./reward-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardSettingsComponent implements OnInit {
  @Input() goalBalance: number;
  @Input() locationId: number;
  @Input() industryId: number;
  @Input() selectedCard: LoyaltyCard;
  @Input() selectedCardTypeSetting: LoyaltyCardType;
  @Input() rewardsList: LoyaltyRewardItem[] = [];
  @Input() loyaltyProgramDetailsResult: LoyaltyOnboardingRequest;

  @Output() rewardsUpdate = new EventEmitter<LoyaltyRewardItem[]>();

  LoyaltyCalculationMode = LoyaltyCalculationMode;
  LoyaltyCardTypes = LoyaltyCardTypes;
  LoyaltyExpirationPeriod = LoyaltyExpirationPeriod;

  expirationPeriod = "";
  expirationPeriodTitle = "";
  isPointCard = false;
  isStampCard = false;
  listTier: ListType[] = [];
  isRewardsOptionsButtonVisible$ = new BehaviorSubject<boolean>(false);

  constructor(private transloco: TranslocoService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.expirationPeriod = getLoyaltyProgramExpirationPeriodLabel(this.loyaltyProgramDetailsResult?.expiration, this.transloco);
    this.expirationPeriodTitle = getLoyaltyProgramExpirationPeriodTitle(this.loyaltyProgramDetailsResult?.loyaltyType, this.transloco);
    this.listTier = this.getTiersListByStep();
    this.isPointCard = this.loyaltyProgramDetailsResult.loyaltyType === LoyaltyCardTypes.Pointcard;
    this.isStampCard = this.loyaltyProgramDetailsResult.loyaltyType === LoyaltyCardTypes.Stampcard;
    this.updatePointsTiers();
    this.updateRewardsButtonVisibility();
  }

  addRewardTierModal() {
    const addRewardTierModal = this.modalService.open(AddRewardTierModalComponent, { backdrop: "static", size: "xxl", centered: true });
    const comp: AddRewardTierModalComponent = addRewardTierModal.componentInstance;
    comp.title = this.transloco.translateObject("widgets.enrollLoyalty.titleModalAdd");
    comp.selectedReward = new LoyaltyRewardItem();
    comp.locationId = this.locationId;
    comp.industryId = this.industryId;
    comp.selectedCardTypeSetting = this.selectedCardTypeSetting;
    comp.allRewards = this.rewardsList;
    comp.listTier = this.listTier;

    addRewardTierModal.result
      .then((res: LoyaltyRewardItem) => {
        this.rewardsList.push(res);
        this.sortRewards();
        this.updateRewardsButtonVisibility();
      })
      .catch((res) => {});
  }

  editItemRewardList(selectedReward: LoyaltyRewardItem) {
    const addRewardTierModal = this.modalService.open(AddRewardTierModalComponent, { backdrop: "static", size: "xxl", centered: true });
    const comp: AddRewardTierModalComponent = addRewardTierModal.componentInstance;
    comp.title = this.transloco.translateObject("widgets.enrollLoyalty.titleModalEdit");
    comp.locationId = this.locationId;
    comp.industryId = this.industryId;
    comp.selectedReward = selectedReward;
    comp.allRewards = this.rewardsList;
    comp.selectedCardTypeSetting = this.selectedCardTypeSetting;
    comp.listTier = this.listTier;

    addRewardTierModal.result
      .then((res: LoyaltyRewardItem) => {
        this.sortRewards();
        this.updateRewardsButtonVisibility();
      })
      .catch((res) => {});
  }

  deleteItemRewardList(selectedItem: LoyaltyRewardItem) {
    const ok = confirm(this.transloco.translateObject("widgets.enrollLoyalty.confirmDeleteRewardItem"));
    if (ok) {
      this.rewardsList = this.rewardsList.filter((item) => item.tier !== selectedItem.tier);
      this.sortRewards();
      this.updateRewardsButtonVisibility();
    }
  }

  private sortRewards() {
    this.rewardsList = this.rewardsList.sort((a, b) => a.tier - b.tier);
    this.rewardsUpdate.emit(this.rewardsList);
  }

  private getTiersListByStep(): ListType[] {
    let step = 0;
    const name = this.selectedCardTypeSetting.suffixList;
    const list = [];

    while (step < this.goalBalance) {
      if (this.selectedCardTypeSetting.id === LoyaltyCardTypes.Stampcard) {
        step++;
      } else if (this.selectedCardTypeSetting.id === LoyaltyCardTypes.Rewardcard) {
        if (this.goalBalance <= 100) {
          step = step + 20;
        } else if (this.goalBalance <= 500 && this.goalBalance > 100) {
          step = step + 50;
        } else {
          step = step + 100;
        }
      } else if (this.selectedCardTypeSetting.id === LoyaltyCardTypes.Pointcard) {
        step = step + 100;
      }

      if (step > 1) {
        list.push({ n: step, name: step.toString() + " " + name + "s" });
      } else {
        list.push({ n: step, name: step.toString() + " " + name });
      }
    }

    return list;
  }

  private updateRewardsButtonVisibility() {
    const availableRewardsCount = this.listTier.length;
    const rewardsListLength = this.rewardsList.length;
    const isVisible = !rewardsListLength || rewardsListLength < availableRewardsCount;

    this.isRewardsOptionsButtonVisible$.next(isVisible);
  }

  private updatePointsTiers() {
    if (this.isPointCard) {
      this.listTier = this.listTier.filter((_, i) => i === this.listTier.length - 1);
    }
  }
}
