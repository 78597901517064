<div *transloco="let t; read: 'common.components'" class="locations-autocomplete {{ selectionType }}">
  <div *transloco="let tl; read: 'location'">
    <h4 *ngIf="selectionType === 'multiple'" class="locations-autocomplete__title">
      {{ tl("selectLocationsTitle") }}
      <span *ngIf="isRequired" class="text-danger">*</span>
    </h4>

    <form class="row filter">
      <div class="input-group d-flex p-0">
        <input
          #searchInput
          type="text"
          class="form-control with-action"
          [disabled]="disabled"
          [placeholder]="'Search'"
          [(ngModel)]="searchString"
          (ngModelChange)="filter()"
          [ngModelOptions]="{ standalone: true }"
        />

        <div class="form-control-action">
          <img (click)="filter()" alt="" src="assets/images/search-icon.svg" width="14" height="14" />
        </div>
      </div>
    </form>
  </div>

  <div
    *ngIf="selectionType === 'multiple'"
    class="locations-autocomplete__actions"
    [ngStyle]="{ 'pointer-events': disabled ? 'none' : 'auto' }"
    [class.hide-opacity]="!filteredLocations.length"
  >
    <div class="location-item" (click)="triggerSelectAll()">
      <button type="button" class="checkbox-button multiple" [ngClass]="{ checked: selectedAll }"></button>

      <span class="locations-autocomplete__text cursor-pointer" [class.inactive]="disabled">
        {{ t("selectAll") }}
      </span>
    </div>

    <div class="selection-info">
      {{ t("selected") }} <span>{{ selectedLocations.length }}</span
      >/{{ originalLocationLength }}
    </div>
  </div>

  <div class="locations-autocomplete__dropdown">
    <ul *ngIf="filteredLocations?.length" class="locations-autocomplete__dropdown-list">
      <ngx-simplebar [options]="scrollbarOptions">
        <li
          *ngFor="let location of filteredLocations"
          class="locations-autocomplete__dropdown-item selected cursor-pointer"
          [ngStyle]="{ 'pointer-events': disabled ? 'none' : 'auto' }"
          (click)="triggerSelectLocation(location)"
        >
          <div class="location-item">
            <button
              type="button"
              class="checkbox-button checked {{ selectionType }}"
              [disabled]="disabled"
              [ngClass]="{ checked: location.selected }"
            ></button>

            <div class="location-info" [class.selected]="location.selected">
              <span
                class="location-info__title"
                [class.inactive]="disabled || (location['status'] && location['status'] === 'NotAvailable')"
              >
                {{ location.companyName }}
              </span>

              <span
                *ngIf="selectionType === 'multiple'"
                class="location-info__text"
                [class.inactive]="disabled || (location['status'] && location['status'] === 'NotAvailable')"
              >
                {{ location | locationInfo : false }}
              </span>
            </div>

            <i
              *ngIf="location['status'] && location['status'] === 'NotAvailable'"
              class="fa-exclamation-triangle fas text-warning me-2"
              container="body"
              [ngbTooltip]="inactiveTemplateTooltip"
            ></i>
          </div>
        </li>
      </ngx-simplebar>
    </ul>

    <div *ngIf="!loading && !filteredLocations?.length" class="text-center pt-2">
      {{ t("noItems") }}
    </div>
  </div>

  <ng-template #inactiveTemplateTooltip>
    <span>{{ "templates.notAvailableLocationTooltip" | transloco }}</span>
  </ng-template>

  <div *ngIf="selectionType === 'single'" class="locations-autocomplete__actions" [ngClass]="hideReset ? 'justify-content-end' : ''">
    <ng-container *transloco="let tc; read: 'common'" class="">
      <button *ngIf="!hideReset" type="button" (click)="onReset()" class="btn btn-outline-light btn-card-action float-start">
        {{ tc("controls.reset") }}
      </button>

      <button type="button" [disabled]="!selectedLocation" (click)="onSave()" class="btn btn-primary btn-card-action float-end">
        {{ tc("controls.save") }}
      </button>
    </ng-container>
  </div>
</div>
