import { SubscriptionPlan, Location, CampaignSettings } from ".";

export class LocationCampaignSettings {
  public locationId: number;
  public defaultWidgetSettingsId: number;
  public disabled: boolean;
  public deliveryMethod: AllowedDeliveryChannel;
  public location: Location;
  public defaultWidgetSettings: Campaign;
}

export class SubscriptionWidget {
  public defaultWidgetSettingsId: number;
  public subscriptionPlanId: number;
  public defaultWidgetSettings: Campaign;
  public subscriptionPlan: SubscriptionPlan;
}
export enum AllowedDeliveryChannel {
  undefined = 0,
  email = 1,
  sms = 2,
  // tslint:disable-next-line:no-bitwise
  emailAndSms = email | sms,
  qr = 64,
  hyperLink = 128,
  all = 16383
}
export class Campaign {
  public id: number;
  public name: string;
  public description: string;
  public deliveryMethod: AllowedDeliveryChannel;
  public isEditing: boolean;
  public videoId: string;
  public useForUnified: boolean;
  public campaignSettings?: CampaignSettings;
}

export class DefaultWidgetCampaign {
  public defaultWidgetSettingsId: number;
  public textMessage: string;
  public campaignName: string;
  public campaignKeyword: string;
}

export interface WidgetLocationsConfigurationStatusDto {
  widgetSettings: LocationWidgetGetDto;
  configured: boolean;
}

export interface LocationWidgetGetDto {
  id: number;
  locationId: number;
  defaultWidgetSettingsId: number;
  disabled: boolean;
  deliveryMethod: AllowedDeliveryChannel;
  configured: boolean;
  widgetSettingsJson: string;
  location: Location;
  defaultWidgetSettings: Campaign;
}
