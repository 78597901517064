import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SenderOption, AllowedDeliveryChannel, EmailSenderOption, GlobalConfiguration, DeliveryProviderType } from "@app/models";
import { ConfigurationService, IndustryService } from "@app/services";
import { emailListUniqueValidator, emailListValidator, isValidEmail, Patterns } from "@app/shared/validators";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-email-sender-form-control",
  templateUrl: "./email-sender-form-control.component.html",
  styleUrls: ["./email-sender-form-control.component.scss"]
})
export class EmailSenderFormControlComponent implements OnInit, OnDestroy {
  private _businessNames: string[];
  domain = "";
  businessName: string;
  @Input() set businessNames(value: string[]) {
    if (value.length && !this._businessNames) {
      this._businessNames = value;
      this.businessName = value[0];
    }
  }
  private _industryId: number;
  public get industryId(): number {
    return this._industryId;
  }
  @Input() public set industryId(value: number) {
    this._industryId = value;
  }

  get businessNames() {
    return this._businessNames;
  }
  public domains = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();
  genericSender: GlobalConfiguration;
  selected = [];
  hasValue = false;
  form = new UntypedFormGroup({
    id: new UntypedFormControl(""),
    channelType: new UntypedFormControl(AllowedDeliveryChannel.email),
    isActive: new UntypedFormControl(false),
    isApproved: new UntypedFormControl(false),
    isGeneric: new UntypedFormControl(true),
    from: new UntypedFormControl(""),
    /// Delete validations and set [] as default when it will be list of emails
    replyTo: new UntypedFormControl("", [Validators.pattern(Patterns.email), Validators.maxLength(128)]),
    cc: new UntypedFormControl([]),
    bcc: new UntypedFormControl([])
  });

  private _emailOptions: EmailSenderOption;

  @Output() optionChanged = new EventEmitter<SenderOption>();
  @Output() optionValid = new EventEmitter<boolean>();
  @Input() set options(value: SenderOption) {
    if (value && !this._emailOptions) {
      this.form.controls.id.setValue(value.id);
      this.form.controls.isActive.setValue(value.isActive);
      this.form.controls.isApproved.setValue(value.isApproved);
      this.emailOptions = value.value as EmailSenderOption;
      this.hasValue = !this.emailOptions.isGeneric;
      this._emailOptions = this.emailOptions;
    }
  }

  @Input() isEdit = false;
  @Input() isAdmin = false;

  get options(): SenderOption {
    return {
      id: this.form.controls.id.value || 0,
      deliveryProviderId: DeliveryProviderType.Courier,
      value: this.emailOptions ? this.emailOptions : null,
      channelType: this.form.controls.channelType.value,
      isActive: this.form.controls.isActive.value ? this.form.controls.isActive.value : false,
      isApproved: this.form.controls.isApproved.value ? this.form.controls.isApproved.value : false
    } as SenderOption;
  }

  private get emailOptions(): EmailSenderOption {
    const from = this.form.controls.from.value.trim();
    return {
      isGeneric: this.form.controls.isGeneric.value,
      from: from,
      /// Delete array when it will be list of emails
      replyToList: this.form.controls.replyTo.value.toString() ? [this.form.controls.replyTo.value.toString().trim()] : [],
      cc: this.form.controls.cc.value,
      bcc: this.form.controls.bcc.value
    };
  }

  private set emailOptions(value: EmailSenderOption) {
    if (value) {
      this.form.controls.isGeneric.setValue(value.isGeneric);
      this.form.controls.from.setValue(value.from);
      /// Remove to string when ths field will be array
      this.form.controls.replyTo.setValue(value.replyToList.toString());
      this.form.controls.cc.setValue(value.cc);
      this.form.controls.bcc.setValue(value.bcc);
    }
  }

  constructor(private cdr: ChangeDetectorRef, private configurationService: ConfigurationService, private industrySvc: IndustryService) {
    setTimeout(() => {
      this.onFromValidate();
      this.optionChanged.emit(this.options);
      this.optionValid.emit(this.form.valid);
    }, 1);
  }

  ngOnInit(): void {
    forkJoin({ subscription: this.configurationService.getSubscription(), industries: this.industrySvc.getPublic() })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ subscription, industries }) => {
        this.genericSender = subscription;
        const ind = industries.find((i) => i.id === this.industryId);
        this.domains = [ind.emailDomain, ind.additionalEmailDomain];
        this.initFrom();
      });
    this.init();
  }

  onChanged(value: boolean) {
    if (!value) {
      this.initFrom();
      this.form.controls.from.setErrors(null);
      this.form.controls.isGeneric.setValue(true);
      if (this.genericSender?.genericEmail) this.form.controls.from.setValue(this.genericSender?.genericEmail);
      /// Change to array
      this.form.controls.replyTo.setValue("");
      this.form.controls.cc.setValue([]);
      this.form.controls.bcc.setValue([]);
      this.form.controls.cc.setErrors(null);
      this.form.controls.bcc.setErrors(null);
    } else {
      if (!this.isEdit) this.form.controls.from.setValue("");
      this.form.controls.from.setValidators([Validators.required, Validators.pattern(Patterns.email), Validators.maxLength(128)]);
      this.form.controls.isGeneric.setValue(false);
      if (!this.form.controls.from.value) this.form.controls.from.setErrors({ required: true });
    }
    this.form.controls.from.markAsPristine();
    this.form.controls.from.markAsUntouched();
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
    this.optionValid.emit(this.form.valid);
  }

  validateValue(value) {
    if (value && !isValidEmail(value)) {
      this.selected = this.form.controls.replyTo.value as string[];
      this.form.controls.replyTo.setValue(this.selected.filter((s) => s !== value));
    }
  }

  onBlur($element) {
    const value = $element.target.value;
    if (isValidEmail(value)) {
      this.selected = this.form.controls.replyTo.value as string[];
      if (this.selected.indexOf(value) === -1) {
        this.selected.push(value);
        this.form.controls.replyTo.setValue(this.selected);
      }
    }
    $element.target.value = "";
  }

  onCCBlur($element) {
    const value = $element.target.value.trim();
    const cc = this.form.controls.cc.value as string[];
    if (value) {
      cc.push(value);
      this.form.controls.cc.setValue(cc);
    }
    this.onFromValidate();
    $element.target.value = [];
  }

  onBCCBlur($element) {
    const value = $element.target.value.trim();
    const bcc = this.form.controls.bcc.value as string[];
    if (value) {
      bcc.push(value);
      this.form.controls.bcc.setValue(bcc);
    }
    this.onFromValidate();
    $element.target.value = [];
  }

  init() {
    if (this.isEdit && !this.isAdmin) {
      this.form.disable();
    }
    if (this.isEdit) {
      this.hasValue = true;
    }
    this.form.controls.isGeneric.value
      ? this.form.controls.from.setValidators(null)
      : this.form.controls.from.setValidators([Validators.required, Validators.pattern(Patterns.email), Validators.maxLength(128)]);
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.optionChanged.emit(this.options);
      }
      this.optionValid.emit(this.form.valid);
    });
  }

  onFromValidate() {
    const cc = this.form.controls.cc.value as string[];
    const bcc = this.form.controls.bcc.value as string[];
    this.form.controls.isGeneric.value
      ? this.form.controls.from.setValidators(null)
      : this.form.controls.from.setValidators([Validators.required, Validators.pattern(Patterns.email), Validators.maxLength(128)]);

    this.form.controls.from.updateValueAndValidity();
    this.validateCCBCC(cc, bcc);
  }

  onCCValidate(value: string) {
    if (value) {
      let cc = this.form.controls.cc.value as string[];
      cc = cc.filter((c) => c !== value);
      cc.push(value.trim());
      this.form.controls.cc.setValue(cc);
      this.onFromValidate();
    }
  }

  onBCCValidate(value: string) {
    if (value) {
      let bcc = this.form.controls.bcc.value as string[];
      bcc = bcc.filter((c) => c !== value);
      bcc.push(value.trim());
      this.form.controls.bcc.setValue(bcc);
      this.onFromValidate();
    }
  }

  onDomainsChanged(domain: string) {
    let email = this.genericSender.genericEmail;
    email = this.businessName + email.substring(email.indexOf("@"));
    email = email.substring(0, email.lastIndexOf("@") + 1) + domain;
    this.genericSender.genericEmail = email;
    this.form.controls.from.setValue(email);
    this.domain = domain;
  }

  onBuisinessNameChange(name: string) {
    let email = this.genericSender.genericEmail;
    email = name + email.substring(email.indexOf("@"));
    this.genericSender.genericEmail = email;
    this.form.controls.from.setValue(email);
    this.businessName = name;
  }

  private initFrom() {
    this.businessName = this.form.controls.from.value.substring(0, this.form.controls.from.value.lastIndexOf("@"));
    if (!this.businessNames.includes(this.businessName)) this.businessName = this._businessNames[0];

    this.domain = this.form.controls.from.value.substring(this.form.controls.from.value.indexOf("@") + 1);
    if (!this.domains.includes(this.domain)) this.domain = this.domains[0];
    this.genericSender.genericEmail = this.businessName + "@" + this.domain;
    if (!this.hasValue) {
      this.form.controls.from.setValue(this.genericSender.genericEmail);
    }
  }

  private validateCCBCC(cc: string[], bcc: string[]) {
    const ccDuplicates = new Set(cc).size !== cc.length;
    const bccDuplicates = new Set(bcc).size !== bcc.length;

    this.form.controls.bcc.setValidators([emailListValidator(), emailListUniqueValidator(ccDuplicates ? [] : [...cc])]);
    this.form.controls.cc.setValidators([emailListValidator(), emailListUniqueValidator(bccDuplicates ? [] : [...bcc])]);

    this.form.controls.bcc.updateValueAndValidity();
    this.form.controls.cc.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
