import { Clipboard } from "@angular/cdk/clipboard";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ToastService } from "@app/shared/ui";
import { TranslocoService } from "@ngneat/transloco";

export interface QrCodeDesignPreviewOptions {
  shortLinkUrl?: string;
  link: SafeResourceUrl;
}

@Component({
  selector: "app-qr-code-design-preview",
  templateUrl: "./qr-code-design-preview.component.html",
  styleUrls: ["./qr-code-design-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrCodeDesignPreviewComponent {
  @Input() set options(value: QrCodeDesignPreviewOptions) {
    if (value) {
      const { link, shortLinkUrl } = value;

      this.link = link;
      this.shortLinkUrl = shortLinkUrl;
    }
  }

  link: SafeResourceUrl = "";
  shortLinkUrl = "";
  form = new FormGroup({
    shortLinkUrl: new FormControl({ value: "", disabled: true })
  });

  constructor(private clipboard: Clipboard, private toast: ToastService, private transloco: TranslocoService) {}

  copyShortLink(): void {
    const copyMsg = this.transloco.translate("common.messages.copiedToClipboard", { name: "Link" });

    this.clipboard.copy(this.shortLinkUrl);
    this.toast.success(copyMsg);
  }
}
