import { ErrorHandler, Injectable, ApplicationRef, Injector } from "@angular/core";

import { UtilityService } from "./utility.service";
import { LogService } from "./logging";
import { LoadingService } from "../components/spinner";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private spinner: LoadingService, private inj: Injector) {}

  handleError(errorResponse: any): void {
    if (errorResponse.status === 401) {
      console.error("Unauthorised", "Pleae login again.");
      this.inj.get(ApplicationRef).tick();
      this.inj.get(UtilityService).navigateToSignIn();
    } else if (errorResponse.status === 400) {
      console.log("***** HANDLE ERROR *****");
      const us = this.inj.get(UtilityService);
      console.error(errorResponse.error.message, us.formatErrors(errorResponse.error.errors));
      this.inj.get(LogService).error(errorResponse.error.message, [errorResponse.error.errors]);
      this.inj.get(ApplicationRef).tick();
    } else {
      console.error(errorResponse.message, JSON.stringify(errorResponse.stack));
      this.inj.get(LogService).error(errorResponse.message + ". StackTrace: " + errorResponse.stack);
    }
    this.spinner.hideSpinner();

    console.error(errorResponse);
    // IMPORTANT: Don't Rethrow the error otherwise it will not emit errors after once
    // https://stackoverflow.com/questions/44356040/angular-global-error-handler-working-only-once
    // throw errorResponse;
  }
}
