<form [formGroup]="form" *transloco="let t; read: 'widgets.sendGiftCard'">
  <h5>{{ t("title") }}</h5>
  <div class="col-md-12">
    <div class="row mb-3">
      <div class="col-md-12">
        <app-url-form-control
          [label]="t('link')"
          formControlName="sendGiftCardUrl"
          [mark]="true"
          [validators]="validators"
        ></app-url-form-control>
      </div>
    </div>
  </div>
</form>
