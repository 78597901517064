import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { PosExportRequestDto } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class TransactionService {
  private serviceUrl = "transaction";
  constructor(private http: DataService) {}

  getExportTransactionsCsv(data: PosExportRequestDto): Observable<any> {
    return this.http.post(`${this.serviceUrl}/export/csv`, data);
  }
}
