<div class="default-widget-modal">
  <div class="modal-header" *transloco="let t; read: 'widgets.issueCoupon'">
    <h4 class="page-title-box m-0 p-0">{{ t("preview") }}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <div class="text-center mb-4">
      <h5>{{ item.name }}</h5>
    </div>
    <div class="card-img mb-4">
      <img src="{{ item.couponImage }}" alt="preview" style="width: 100%" />
    </div>
    <p><strong>Title: </strong><span [outerHTML]="item.title"></span></p>
    <p><strong>Description: </strong><span [outerHTML]="item.description"></span></p>
  </div>
  <div class="modal-footer mb-4" *transloco="let tcc; read: 'common.controls'"></div>
</div>
