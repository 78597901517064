import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DeliveryPlatformAccount, DeliveryProvider } from "@app/models";
import { SenderOptionsDataService } from "@app/services";
import { Unsubscriber } from "@app/shared/components";

@Component({
  selector: "app-delivery-platform-account",
  templateUrl: "./delivery-platform-account.component.html",
  styleUrls: ["./delivery-platform-account.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryPlatformAccountComponent extends Unsubscriber implements OnInit {
  @Input() set markAsTouched(value: boolean) {
    if (value && !this.form.touched && !this.form.disabled) {
      this.form.markAllAsTouched();
      this.cdr.markForCheck();
    }
  }
  @Input() public set deliveryProvider(value: DeliveryProvider) {
    this.labels = value.configuration.authorizationData.labels;
    this.providerTitle = this.senderDataService.getDeliveryProviderLabelByCode(value.code);
    this._deliveryProvider = value;
    this.platformAccount = value.platformAccount;
  }
  @Output() changed = new EventEmitter<DeliveryPlatformAccount>();
  @Output() validated = new EventEmitter<boolean>(false);
  labels: { key: string; value: string }[];
  providerTitle: string;
  form = new FormGroup({
    username: new FormControl(""),
    password: new FormControl(""),
    clientApiId: new FormControl(""),
    clientApiSecret: new FormControl("")
  });

  private _deliveryProvider: DeliveryProvider;
  public get deliveryProvider(): DeliveryProvider {
    return this._deliveryProvider;
  }
  private _platformAccount: DeliveryPlatformAccount;
  private get platformAccount(): DeliveryPlatformAccount {
    return this._platformAccount;
  }
  private set platformAccount(value: DeliveryPlatformAccount) {
    this.form.reset();
    this.form.patchValue(value);
    this._platformAccount = value;
    this.setValidation();
    this.cdr.detectChanges();
  }
  constructor(public senderDataService: SenderOptionsDataService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.sub = this.form.valueChanges.subscribe(() => {
      this.platformAccountChanged();
      this.platformAccountValid();
    });
  }
  getLabel(key: string): string {
    return this.labels.find((l) => l.key == key)?.value;
  }
  private setValidation() {
    Object.keys(this.form.controls).forEach((key) => {
      const control: FormControl = this.form.controls[key];
      if (this.labels.some((l) => l.key == key)) {
        control.addValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
      }
      control.updateValueAndValidity();
    });
  }
  private platformAccountValid() {
    this.validated.emit(this.form.valid);
  }

  private platformAccountChanged() {
    const platformAccount = { deliveryProviderPlatformId: this.deliveryProvider.id, ...this.platformAccount, ...this.form.getRawValue() };
    this.deliveryProvider.platformAccount = platformAccount;
    this.changed.emit(platformAccount);
  }
}
