import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { ToastService } from "./toast.service";

type CrudToastAction = "add" | "update" | "delete" | "inUse";
type CrudToastActionType = "success" | "error";

@Injectable({ providedIn: "root" })
export class CrudToastService {
  constructor(private transloco: TranslocoService, private toast: ToastService) {}

  public showCrudToast(targetKey: string, action: CrudToastAction, actionType: CrudToastActionType): void {
    const target = this.transloco.translate(targetKey);
    const message = this.transloco.translateObject(`common.messages.${actionType}.${action}`, { target });

    if (actionType === "success") {
      this.toast.success(message);
    } else {
      this.toast.danger(message);
    }
  }
}
