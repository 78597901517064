import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent {
  @Input() id: string;
  @Input() name: string;
  @Input() control: FormControl | null = null;
  @Input() value;
  @Input() label: string;
}
