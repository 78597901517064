import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AccountService, AppConfigService } from "@app/core";
import { FileUploaderService } from "@app/services";
import { TranslocoService } from "@ngneat/transloco";
import { DropzoneComponent, DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { FileResult } from "../file-uploader-modal/file-result";
import { FileMimeType, maxFileUploadSize } from "../file-uploader-modal/file-type";

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"]
})
export class FileUploaderComponent implements OnInit {
  @ViewChild(DropzoneComponent, { static: false }) componentRef?: DropzoneComponent;
  @Input() isMultiple = false;
  @Input() storageFolder = "files";
  @Input() extensions: Array<FileMimeType>;
  @Input() maxFileUploadSize: number = maxFileUploadSize;
  @Output() uploadChanged = new EventEmitter<FileResult>();
  public error = "";
  public result: FileResult;
  public config: DropzoneConfigInterface = {
    maxFiles: 1,
    autoReset: null,
    cancelReset: null,
    errorReset: 1,
    maxFilesize: maxFileUploadSize,
    headers: {
      Authorization: `Bearer ${this.accountService.accessToken}`
    }
  };

  constructor(
    private confService: AppConfigService,
    public translateSvc: TranslocoService,
    private accountService: AccountService,
    private fileUploaderService: FileUploaderService
  ) {}

  ngOnInit(): void {
    this.config.acceptedFiles = this.fileUploaderService.getAcceptedFiles(this.extensions);
    this.config.url = `${this.confService.appData.apiUrl}/fileUploader/${this.storageFolder}`;
    this.config.maxFilesize = this.maxFileUploadSize;
  }

  public resetDropzoneUploads(): void {
    if (this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.reset();
      this.result = null;
      this.uploadChanged.next(this.result);
    }
  }

  public onUploadError(error) {
    this.error = "";
    for (let i = 1; i < error.length - 1; i++) {
      if (error[i] instanceof Object) {
        error[i] = error[i].Message;
        this.error += error[i] + "\n";
      } else this.error += error[i] + "\n";
    }
    this.result = null;
    this.uploadChanged.next(this.result);
  }

  public onUploadSuccess(result: any[]): void {
    if (result.length < 2) {
      console.log("result should have minimum 2 items in the array");
      return;
    }

    this.result = { fileDataUrl: result[0].dataURL, fileUrl: result[1].link } as FileResult;
    const image = result[0];
    const reader = new FileReader();
    reader.onloadend = (r) => {
      if (reader.result) {
        this.result = { fileDataUrl: reader.result, fileUrl: result[1].link } as FileResult;
      } else this.result = null;
      this.uploadChanged.next(this.result);
    };
    reader.readAsDataURL(image);
  }

  public onSending() {
    this.error = "";
  }
}
