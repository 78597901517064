import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { GlobalConfiguration } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ConfigurationService {
  private serviceUrl = "configuration";

  constructor(private http: DataService) {}

  public getSubscription(): Observable<GlobalConfiguration> {
    return this.http.get(`${this.serviceUrl}`, {});
  }
}
