import { ApexLegend } from "ng-apexcharts";

import {
  ChartData,
  ChartOptions,
  EngagementOutcome,
  EngagementOutcomeByPeriod,
  EngagementOutcomeByPeriodGetDto,
  EngagementOutcomeCardInfoItem,
  TimeFrameTypes,
  TotalEngagementOutcomeCardInfo
} from "@app/models";
import { OrdinalRawValue } from "echarts/types/src/util/types";
import { DateTimeZoneService } from "../date-timezone.service";

export class DashboardEngagementOutcomes {
  private static readonly topNumber = 5;
  constructor() {}
  private static readonly minNumberForShortLabel = 20;

  private static apexTooltipItem: any = {
    title: {
      formatter: function (value: string) {
        return value;
      }
    }
  };

  private static apexLegend: ApexLegend = {
    tooltipHoverFormatter: function (type: string, opts: any) {
      const value = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
      return `${type} - <strong>${value}</strong>`;
    }
  };

  private static chartOptions: Partial<ChartOptions> = {
    chart: {
      height: 350,
      type: "line",

      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "clearline_engagement_outcomes"
          },
          svg: {
            filename: "clearline_engagement_outcomes"
          },
          png: {
            filename: "clearline_engagement_outcomes"
          }
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "20%"
      }
    },

    dataLabels: {
      enabled: false
    },

    stroke: {
      width: 3,
      curve: "smooth"
    },
    legend: DashboardEngagementOutcomes.apexLegend,
    markers: {
      size: 5,
      hover: {
        sizeOffset: 3
      }
    },
    yaxis: {
      min: 0,
      labels: {
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: function (value: OrdinalRawValue | number) {
          return value ? (value as number).toFixed(0) : "0";
        }
      }
    },
    xaxis: {
      offsetX: 0,
      offsetY: 0,
      tickPlacement: "between",
      labels: {
        trim: false,
        hideOverlappingLabels: true
      }
    },
    tooltip: {
      y: [
        DashboardEngagementOutcomes.apexTooltipItem,
        DashboardEngagementOutcomes.apexTooltipItem,
        DashboardEngagementOutcomes.apexTooltipItem,
        DashboardEngagementOutcomes.apexTooltipItem
      ]
    }
  };

  static getTopEngagementOutcomes(arr: EngagementOutcome[]): EngagementOutcome[] {
    if (arr && arr?.length) {
      const uniqueNames = this.getUniqueByKey(arr) as EngagementOutcome[];
      let group = [];
      uniqueNames.forEach((item, i) => {
        let findList = arr.filter((s) => s.name === item.name);
        const engagementOutcome: EngagementOutcome = this.getTotalEngagementOutcomeFromList(findList);
        group.push(engagementOutcome);
      });
      return group
        .sort((a: EngagementOutcome, b: EngagementOutcome) => b.totalCount - a.totalCount)
        .slice(0, this.topNumber)
        .filter((item: EngagementOutcome) => !!item.totalCount);
    }
  }

  static getUniqueByKey(array) {
    const arrayUniqueByKey = [...new Map(array.map((item) => [item["name"], item])).values()];
    return arrayUniqueByKey;
  }

  static getEngagementOutcomeForCard(list: EngagementOutcome[]): TotalEngagementOutcomeCardInfo {
    if (list?.length) {
      const allList = list;
      const topList = this.getTopEngagementOutcomes(list);
      const totalEngagementOutcome: EngagementOutcome = this.getTotalEngagementOutcomeFromList(allList);
      const data: EngagementOutcomeCardInfoItem[] = topList.map((item: EngagementOutcome) => {
        return {
          label: item.name,
          value: item.totalCount,
          progressPerCent: this.getProgress(item.totalCount, totalEngagementOutcome.totalCount),
          color: item.color
        };
      });

      return {
        totalCount: totalEngagementOutcome?.totalCount ?? 0,
        list: data
      };
    }
  }

  static getChartOptions(
    info: EngagementOutcomeByPeriodGetDto<EngagementOutcomeByPeriod>,
    currentTimeFrame: TimeFrameTypes
  ): Partial<ChartOptions> {
    if (currentTimeFrame) {
      const data: ChartData = this.getApexAxisChartData(info, currentTimeFrame);
      const chartOptions: Partial<ChartOptions> = {
        ...DashboardEngagementOutcomes.chartOptions
      };

      chartOptions.series = data.series;
      chartOptions.xaxis.categories = data.categories;
      chartOptions.xaxis = { ...chartOptions.xaxis };
      return chartOptions;
    }
  }

  private static getPeriodEngagementOutcomeItemCounts(
    info: EngagementOutcomeByPeriodGetDto<EngagementOutcomeByPeriod>,
    key: string,
    periods: string[]
  ): number[] {
    const result: number[] = [];

    if (!info?.data) return result;
    if (periods && info.data) {
      periods.forEach((item: string) => {
        const obj = info.data.find(
          (x) =>
            x.name === key && x.deliveryChannel !== null && x.deliveryChannel !== "Undefined" && x.name !== null && x.startDate === item
        );
        result.push(obj?.totalCount ? obj?.totalCount : 0);
      });
    }
    return result;
  }

  private static getApexAxisChartData(
    info: EngagementOutcomeByPeriodGetDto<EngagementOutcomeByPeriod>,
    currentTimeFrame: TimeFrameTypes
  ): ChartData {
    if (!info) {
      return {
        series: [],
        categories: []
      };
    }

    if (info.data) {
      const periods: string[] = DashboardEngagementOutcomes.getPeriodsForEngagementOutcome(info.data);
      const uniqueNames = this.getUniqueByKey(info.data) as EngagementOutcomeByPeriod[];

      const series = uniqueNames
        .filter((x) => x.deliveryChannel !== "" && x.deliveryChannel !== null && x.deliveryChannel !== "Undefined" && x.name !== null)
        .map((item) => {
          return {
            name: item.name,
            color: item.color,
            data: this.getPeriodEngagementOutcomeItemCounts(info, item.name, periods)
          };
        });

      const timeCategories = [];
      if (periods?.length) {
        const shortDateLabel: boolean = periods?.length > this.minNumberForShortLabel;

        periods.forEach((item: string) => {
          const date: string = DateTimeZoneService.getChartDateItem(item, currentTimeFrame, shortDateLabel);
          timeCategories.push(date);
        });
      }

      return {
        series,
        categories: timeCategories
      };
    }
  }

  private static getPeriodsForEngagementOutcome(list: EngagementOutcomeByPeriod[]): string[] {
    const result = [...new Set(list.map((obj) => obj.startDate))];
    return result;
  }

  private static getProgress(value: number, totalValue: number): number {
    return (value / totalValue) * 100;
  }

  private static getTotalEngagementOutcomeFromList(list: EngagementOutcome[]): EngagementOutcome {
    return list.reduce((previousValue: EngagementOutcome, currentValue: EngagementOutcome) => {
      if (!previousValue) {
        return { ...currentValue };
      } else {
        previousValue.totalCount += currentValue.totalCount;
        return { ...previousValue };
      }
    }, null);
  }
}
