<div class="row text-center m-auto" *ngIf="selectedNotActiveCoupons?.length && brand?.id">
  <div class="app-config-notice" *ngIf="messageSelectedNotActiveCoupons">
    {{ messageSelectedNotActiveCoupons }}
  </div>
</div>

<form [formGroup]="form">
  <div *transloco="let t; read: 'widgets.issuePoints'" hidden>
    <h5>{{ t("title") }}</h5>
    <div class="row mb-3">
      <div class="row">
        <div class="col-12">
          <label class="col-form-label">{{ t("limitation") }}</label>
          <div class="d-flex" class="mb-3">
            <input id="multipleCoupons" class="form-check-input widget me-3" type="checkbox" formControlName="allowMultipleCoupons" /><label
              for="multipleCoupons"
              class="form-check-label"
              >{{ t("enableMulti") }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="false" hidden>
        <label class="col-form-label">{{ t("perUser") }}</label>
        <div class="form-check mb-3" *ngFor="let item of perUserSettings">
          <input
            class="form-check-input"
            type="radio"
            [value]="item"
            id="formRadios+{{ couponPerUserRepresentation[item] }}"
            formControlName="couponPerUser"
            (blur)="onTouched()"
          />
          <label class="form-check-label" for="formRadios+{{ couponPerUserRepresentation[item] }}">
            {{ couponPerUserRepresentation[item] }}
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
<div *transloco="let t; read: 'widgets.issuePoints'">
  <div class="row d-flex justify-content-center">
    <div class="col-md-12 text-center">
      <h5 [innerHtml]="t('descriptionCoupons', { selectedCoupons: activeCoupons?.length, limitCoupons: limitCoupons })"></h5>
    </div>
  </div>
  <div class="coupon-carousel-wrapper centered-content">
    <app-coupon-carousel
      class="coupon-carousel"
      [loading]="loading"
      [selectedCoupon]="selectedCoupon"
      [coupons]="coupons"
      [withPreview]="true"
      [selectionType]="'multiple'"
      [viewType]="'large'"
      [withNothingFoundText]="false"
      [previewMode]="!location?.id"
      (selectCoupon)="select($event)"
    >
    </app-coupon-carousel>
  </div>

  <div class="row d-flex justify-content-center" *ngIf="activeCoupons.length > limitCoupons">
    <div class="col-xl-4 col-lg-6 col-md-12 min-w-500">
      <div class="alert alert-danger mt-3 mb-3" role="alert">
        <img src="assets/images/error-icon.svg" width="16" alt="" class="mx-2" />{{
          t("errorlimitCoupons", { limitCoupons: limitCoupons })
        }}
      </div>
    </div>
  </div>
</div>
