import { SenderOptionBase } from "./sender-option-base";

export enum SmsSenderType {
  default = 1,
  dedicated = 2,
  existing = 3,
  shortCode = 4
}
export class SMSSenderOption extends SenderOptionBase {
  public type: SmsSenderType;
  public isTollFree: boolean;
  public isLocalNumber: boolean;
  public useGeneric: boolean;
  public isShortCode: boolean;
  public value: string;
  public isPool: boolean;
}
