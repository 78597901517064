<div class="multi-filter-wrapper">
  <div class="multi-filter" [ngClass]="{ active: selectedIds.length }" (click)="toggle()" *ngIf="items">
    <div class="multi-filter__content">
      <span *ngIf="!selectedIds.length">All&nbsp;</span>
      <span class="multi-filter__title"> {{ "multiFilter." + titleMultiFilter | transloco }}</span>

      <div *ngIf="items.length" class="multi-filter__indicator">
        <span class="multi-filter__indicator-text">
          {{ selectedIds.length ? selectedIds.length : items.length }}
        </span>
      </div>
    </div>
  </div>
  <app-autocomplete-multiselect
    *ngIf="dropdownOpened"
    class="multi-filter__dropdown"
    [selectedIds]="selectedIds"
    [items]="items"
    [loading]="loading"
    [hideReset]="hideReset"
    [titleMultiFilter]="titleMultiFilter"
    [required]="true"
    (saved)="onSelectedItems($event)"
  >
  </app-autocomplete-multiselect>
</div>
