<form [formGroup]="form" *transloco="let t; read: 'widgets.sendPaymentLink'">
  <h5>{{ t("title") }}</h5>
  <div class="col-md-12" *transloco="let tc; read: 'common.error'">
    <div class="row mb-3">
      <div class="col-md-12">
        <app-url-form-control
          [label]="t('oneTime')"
          formControlName="paymentLink1"
          [mark]="!form.get('paymentLink2').value"
          [validators]="!form.get('paymentLink2').value ? validators : ''"
        ></app-url-form-control>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <app-url-form-control
          [label]="t('signup')"
          formControlName="paymentLink2"
          [mark]="!form.get('paymentLink1').value"
          [validators]="!form.get('paymentLink1').value ? validators : ''"
        ></app-url-form-control>
      </div>
    </div>
  </div>
</form>
