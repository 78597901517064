import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingService } from "@app/core";
import { Subject } from "rxjs";

import { TranslocoService } from "@ngneat/transloco";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  template: ""
})
export class BaseCreateDevicePageComponent {
  constructor(protected loadingService: LoadingService, protected router: Router, protected translateSvc: TranslocoService) {}

  formGroup: UntypedFormGroup;

  loading = false;
  accountId: number;

  protected unsubscribe$ = new Subject<void>();

  get deviceNameControl(): UntypedFormControl {
    return this.formGroup.controls["deviceName"] as UntypedFormControl;
  }

  get deviceTypeControl(): UntypedFormControl {
    return this.formGroup.controls["deviceType"] as UntypedFormControl;
  }

  get serialNumberControl(): UntypedFormControl {
    return this.formGroup.controls["serialNumber"] as UntypedFormControl;
  }

  get deviceStatusControl(): UntypedFormControl {
    return this.formGroup.controls["deviceStatus"] as UntypedFormControl;
  }

  get locationControl(): UntypedFormControl {
    return this.formGroup.controls["location"] as UntypedFormControl;
  }

  // main:

  initForm(): void {
    this.formGroup = new UntypedFormGroup({
      deviceName: new UntypedFormControl("", Validators.required),
      deviceType: new UntypedFormControl("", Validators.required),
      serialNumber: new UntypedFormControl("", Validators.required),
      deviceStatus: new UntypedFormControl("", Validators.required),
      location: new UntypedFormControl(null, Validators.required)
    });
  }
}
