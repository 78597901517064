import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from "@angular/core";

@Directive({
  selector: "[customHoverLink]"
})
export class CustomHoverLinkDirective implements OnInit {
  // It is directive for handling link element hover programmatically.
  // It is needed to avoid hover flickering on this link element after open url in new tab
  private readonly cssClass = "custom-hover-link"; // CSS class added to element on directive initialization
  // NOTE: CSS styles should exclude .custom-hover-link selector on hover
  private readonly cssClassOnHover = "custom-hover"; // CSS class added to element on hover
  // NOTE: CSS styles for hover should be added to .custom-hover-link.custom-hover selector
  private disableHover = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Input() newTab = false;

  @HostListener("click", ["$event"])
  onClick(event: MouseEvent): void {
    // Disable hover styles on click
    if (this.newTab && !event.metaKey) {
      this.disableHover = true;
      this.handleHoverStyles();
    }

    // Prevent the default click behavior (optional)
    event.preventDefault();
  }

  @HostListener("mouseenter")
  onMouseEnter(): void {
    // Enable hover styles when the mouse enters
    if (this.disableHover) {
      this.disableHover = false;
    }

    this.handleHoverStyles();
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    // Disable hover styles when the mouse leaves
    if (!this.disableHover) {
      this.disableHover = true;
      this.handleHoverStyles();
    }
  }

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, this.cssClass);
  }

  private handleHoverStyles(): void {
    const element = this.el.nativeElement;

    if (this.disableHover) {
      // Reset styles when hover is disabled
      this.renderer.removeClass(element, this.cssClassOnHover);
    } else {
      // Apply hover styles
      this.renderer.addClass(element, this.cssClassOnHover);
    }
  }
}
