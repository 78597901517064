import { Component } from "@angular/core";

@Component({
  selector: "app-print-template",
  templateUrl: "./print-template.component.html",
  styleUrls: ["./print-template.component.scss"]
})
export class PrintTemplateComponent {
  constructor() {}

  print(): void {
    const delay = 500;

    setTimeout(() => {
      window.print();
    }, delay);
  }
}
