import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CallbackComponent } from "./account/callback.component";
import { LandingGuard } from "./core/services/landing.guard";
import { HomeComponent } from "./home";

import { LayoutComponent } from "./layouts/layout.component";
import { AuthGuard } from "@app/core/services/auth-guard";
import { RoleTypes } from "@app/models";

const routes: Routes = [
  // { path: '', redirectTo: '/dashboard',  pathMatch: 'full' , canActivate: [LandingGuard]  },

  { path: "home", component: HomeComponent, canActivate: [LandingGuard] },
  { path: "account", loadChildren: () => import("./account/account.module").then((m) => m.AccountModule) },
  { path: "callback", component: CallbackComponent },
  // {
  //     path: 'admin',
  //     loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  //     canActivate: [AuthGuard],
  //     data: { roles: [RoleTypes.Admin] }
  // },
  {
    path: "template",
    loadChildren: () => import("./template/template.module").then((m) => m.TemplateModule),
    canActivate: [AuthGuard],
    data: { roles: [RoleTypes.Admin, RoleTypes.PartnerAdmin, RoleTypes.Manager, RoleTypes.LocationManager] }
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule)
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
