import { Campaign, CampaignSettings, LocationWidgetSettings } from ".";

export class LocationWithDefaultWidget {
  public overrided: boolean;
  public isOutOfThePlan: boolean;
  public defaultWidgetSettings: Campaign;
  public campaignSettings: CampaignSettings;
  public locationWidgetSettings: LocationWidgetSettings;

  public isEditing: boolean;
}
