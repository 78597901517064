import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LoadingService } from "@app/core/components/spinner/spinner.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent implements OnInit, OnDestroy {
  private listener: Subscription;

  private _show = false;
  public get show() {
    return this._show;
  }
  @Input() isBlock = false;
  @Input("loading") public set show(value) {
    this._show = value;
  }
  constructor(public spinnerSvc: LoadingService, public cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const listener = this.spinnerSvc.getChangeEmitter();
    listener.subscribe((show: boolean) => {
      this.show = show;
      this.cdr.detectChanges();
    });
  }
  ngOnDestroy(): void {
    if (this.listener) {
      this.listener.unsubscribe();
    }
  }
}
