import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CouponInfo, CouponInfoQr } from "@app/models";
import { CouponService } from "@app/services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { ListType, LoyaltyCardType, LoyaltyRewardItem } from "../enroll-loyalty-widget-settings";

@Component({
  selector: "app-add-reward-tier-modal",
  templateUrl: "./add-reward-tier-modal.component.html",
  styleUrls: ["./add-reward-tier-modal.component.scss"]
})
export class AddRewardTierModalComponent implements OnInit, OnDestroy {
  title: string;
  selectedReward: LoyaltyRewardItem;
  allRewards: LoyaltyRewardItem[] = [];

  selectedCardTypeSetting: LoyaltyCardType;
  listTier: ListType[] = [];
  public selectedCoupon: CouponInfoQr;
  public activeCoupons: CouponInfoQr[] = [];
  public coupons: Array<CouponInfoQr> = [];

  public locationId: number;
  public industryId: number;
  public loadingCoupons = true;

  private search$ = new Subject();
  private unsubscribe$ = new Subject();

  formTier = new FormGroup({
    rewardTier: new FormControl(null, [Validators.required]),
    selectedCoupon: new FormControl(null, [Validators.required])
  });

  constructor(private activeModal: NgbActiveModal, private changeDetectorRef: ChangeDetectorRef, private couponService: CouponService) {}

  ngOnInit(): void {
    this.deleteItemListTierIfSelected();
    this.loadCoupons();
    this.autoSelectSingleOption();
    this.search$.next();
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  save() {
    if (this.formTier.valid) {
      if (this.formTier.controls.rewardTier.value) {
        this.selectedReward.tier = this.formTier.controls.rewardTier.value;
        this.selectedReward.name = this.listTier.find((o) => o.n === this.formTier.controls.rewardTier.value).name;
      }
      this.selectedReward.couponName = this.selectedCoupon.name;
      this.selectedReward.couponId = this.selectedCoupon.id;
      this.activeModal.close(this.selectedReward);
    }
  }

  select(coupon: CouponInfoQr) {
    this.selectedCoupon = coupon;
    this.coupons.forEach((e, i) => {
      if (e.id === coupon.id) {
        e.isSelected = true;
        this.formTier.controls.selectedCoupon.setValue(coupon.id);
      } else {
        e.isSelected = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadCoupons(): void {
    this.search$
      .pipe(
        tap(() => {
          this.loadingCoupons = true;
          this.coupons = null;
        }),
        switchMap(() =>
          this.locationId > 0
            ? this.couponService.getLocationCoupons(this.locationId)
            : this.couponService.getIndustryCoupons(this.industryId)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (coupons: Array<CouponInfo>) => {
          this.coupons = coupons ? coupons.map((item) => CouponInfoQr.toCouponSettings(item)) : null;
          this.selectedCoupon = null;
          this.loadingCoupons = false;
          this.setEditRewardData();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.loadingCoupons = false;
        }
      );
  }

  private setEditRewardData(): void {
    if (this.selectedReward.couponId) {
      this.formTier.controls.rewardTier.setValue(this.selectedReward.tier);
      this.select(this.coupons.find((o) => o.id === this.selectedReward.couponId));
    }
  }

  private deleteItemListTierIfSelected(): void {
    if (this.allRewards) {
      this.allRewards.forEach((item) => {
        if (item.tier !== this.selectedReward.tier) this.listTier = this.listTier.filter((o) => o.n !== item.tier);
      });
    }
  }

  private autoSelectSingleOption(): void {
    if (this.listTier.length === 1) {
      this.formTier.controls.rewardTier.setValue(this.listTier[0].n, { emitEvent: false });
      this.formTier.controls.rewardTier.disable({ emitEvent: false });
    }
  }
}
