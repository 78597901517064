import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { Location, MultiSelectItem } from "../../../../models";

@Component({
  selector: "app-multi-filter",
  templateUrl: "./multi-filter.component.html",
  styleUrls: ["./multi-filter.component.scss"]
})
export class MultiFilterComponent implements OnInit, OnDestroy {
  @Input() hideReset: boolean = false;
  @Input() selectedIds: number[];
  @Input() titleMultiFilter: string;
  @Input() items: MultiSelectItem[];
  @Output() selected = new EventEmitter<MultiSelectItem[]>();

  public isItemsSet = false;
  private unsubscribe$ = new Subject<void>();

  dropdownOpened = false;
  loading = false;

  constructor() {}

  ngOnInit(): void {
    this.onSelectedItems(this.items);
  }

  toggle(): void {
    if (!this.dropdownOpened) {
      this.dropdownOpened = true;
    }
  }

  onSelectedItems(items: MultiSelectItem[]): void {
    this.selectedIds = items.map((l) => l.id);
    this.dropdownOpened = false;
    this.selected.emit(items);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
