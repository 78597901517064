import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

// App level services
import { AccountService } from "./services/account.service";
import { DataService } from "./services/data.service";
import { UtilityService } from "./services/utility.service";
import { LogPublishersService, LogService } from "./services/logging";
import { AuthInterceptor, TimingInterceptor } from "./services/interceptors";
import { GlobalErrorHandler } from "./services/global-error.service";
import { AuthGuard } from "./services/auth-guard";
import { LoadingService } from "./components/spinner";
import { AppConfigService } from "./services/app-config.service";
import { AuthService } from "./services/auth.service";
import { EmailVerifiedGuard } from "./services/email-confirm-guard";
import { PendingRegistrationGuard } from "./services/pending-registration.guard";
import { EmailVerifyGuard } from "./services/email-verify.guard";
import { OAuthModule } from "angular-oauth2-oidc";
import { LandingGuard } from "./services/landing.guard";
import { TranslocoRootModule } from "./services/transloco/transloco-root.module";
import { FroalaEditorModule } from "angular-froala-wysiwyg";
import { UIModule } from "@app/shared/ui/ui.module";
import { PosIntegrationGuard } from "@app/core/services/pos-integration.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

export function appconfigServiceFactory(appService: AppConfigService): () => Promise<any> {
  return () => appService.getAppData();
}

@NgModule({
  imports: [
    CommonModule,
    OAuthModule.forRoot(),
    FroalaEditorModule.forRoot(),
    HttpClientModule,
    TranslocoRootModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule
  ],
  exports: [
    RouterModule,
    TranslocoRootModule
    // Components
  ],
  providers: []
})
export class CoreModule {
  // forRoot allows to override providers
  // https://angular.io/docs/ts/latest/guide/ngmodule.html#!#core-for-root
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        AccountService,
        AuthGuard,
        PosIntegrationGuard,
        EmailVerifiedGuard,
        DataService,
        LogService,
        LogPublishersService,
        UtilityService,
        LoadingService,
        AppConfigService,
        PendingRegistrationGuard,
        EmailVerifyGuard,
        LandingGuard,
        { provide: APP_INITIALIZER, useFactory: appconfigServiceFactory, deps: [AppConfigService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
      ]
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only");
    }
  }
}
