import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumbersOnlyDirective } from "./numbers-only.directive";
import { UrlOnlyDirective } from "./url-only.directive";
import { AdvancedSortableDirective } from "./advanced-sortable.directive";
import { ActivitiesByAppsChartDirective } from "./activities-by-apps-chart.directive";
import { CustomHoverLinkDirective } from "@app/shared/directives/link-with-hover";

@NgModule({
  declarations: [
    NumbersOnlyDirective,
    UrlOnlyDirective,
    AdvancedSortableDirective,
    ActivitiesByAppsChartDirective,
    CustomHoverLinkDirective
  ],
  imports: [CommonModule],
  exports: [NumbersOnlyDirective, UrlOnlyDirective, AdvancedSortableDirective, ActivitiesByAppsChartDirective, CustomHoverLinkDirective]
})
export class DirectivesModule {}
