import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AllowedDeliveryChannel, DeliveryProviderGetDto, ItemWithLabel, SenderOption, SMSSenderOption, SmsSenderType } from "@app/models";
import { SenderOptionsDataService } from "@app/services";
import { Unsubscriber } from "@app/shared/components";
import { phoneMask, phoneValidator } from "ui-kit";

@Component({
  selector: "app-sms-sender-form-control",
  templateUrl: "./sms-sender-form-control.component.html",
  styleUrls: ["./sms-sender-form-control.component.scss"]
})
export class SmsSenderFormControlComponent extends Unsubscriber implements OnInit {
  defaultDeliveryProviderId = 2;
  readonly SenderTypes = SmsSenderType;
  private initialized: boolean;
  enabledGenericTollfree = false;
  enabledGeneric = false;
  enabledDedicatedTollfree = false;
  enabledDedicatedLocal = false;
  enabledDedicatedShortCode = false;
  enabledExisted = false;

  private _type: SmsSenderType;
  public get type(): SmsSenderType {
    return this._type;
  }
  public set type(value: SmsSenderType) {
    this._type = value;
    this.form.controls.type.setValue(value);
  }
  phoneMask = phoneMask;
  form = new FormGroup({
    id: new UntypedFormControl(""),
    channelType: new UntypedFormControl(AllowedDeliveryChannel.sms),
    isActive: new UntypedFormControl(false),
    isApproved: new UntypedFormControl(false),
    isLocalNumber: new UntypedFormControl(true),
    isTollFree: new UntypedFormControl(false),
    useGeneric: new UntypedFormControl(true),
    isShortCode: new FormControl(false),
    waitConfiguration: new UntypedFormControl(false),
    isPool: new UntypedFormControl(true),
    value: new UntypedFormControl(""),
    type: new FormControl<SmsSenderType>(null, [Validators.required]),
    deliveryProvider: new UntypedFormControl(this.defaultDeliveryProviderId)
  });

  @Input() isEdit = false;
  @Input() isAdmin: boolean;

  @Input() set options(value: SenderOption) {
    if (value && !this.initialized) {
      const deliveryProviderId: number = value.deliveryProviderId ?? this.defaultDeliveryProviderId;

      this.form.controls.id.setValue(value.id);
      this.form.controls.isActive.setValue(value.isActive);
      this.form.controls.isApproved.setValue(value.isApproved);
      this.form.controls.deliveryProvider.setValue(deliveryProviderId);

      this.smsOptions = value.value as SMSSenderOption;
      this.initialized = !!this.smsOptions;
    }
  }

  @Input() set availiableSenderOptions(senderOptions: SenderOption[]) {
    if (!senderOptions?.length) return;
    const activeSenderOptions = senderOptions.filter((o) => o.isActive && o.isApproved);
    this.defaultDeliveryProviderId = activeSenderOptions[0].deliveryProviderId;
    const senderValues = activeSenderOptions.map((o) => JSON.parse(o.value as string) as SMSSenderOption);

    this.enabledGeneric = !!senderValues.find((v) => v.type == SmsSenderType.default && !v.isTollFree);
    if (this.enabledGeneric && !this.type) {
      this.type = SmsSenderType.default;
      this.typeChanged(this.type);
    }
    this.enabledGenericTollfree = !!senderValues.find((v) => v.type == SmsSenderType.default && v.isTollFree);
    this.enabledDedicatedTollfree = !!senderValues.find((v) => v.type == SmsSenderType.dedicated && v.isTollFree);
    this.enabledDedicatedLocal = !!senderValues.find((v) => v.type == SmsSenderType.dedicated && v.isLocalNumber);
    this.enabledDedicatedShortCode = !!senderValues.find((v) => v.type == SmsSenderType.dedicated && v.isShortCode);
    this.enabledExisted = !!senderValues.find((v) => v.type == SmsSenderType.existing);
  }
  @Input() set markAsTouched(value: boolean) {
    if (value && !this.form.touched && !this.form.disabled) {
      this.form.markAllAsTouched();
      this.cdr.markForCheck();
    }
  }

  @Output() optionChanged = new EventEmitter<SenderOption>();
  @Output() optionValid = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef, private senderDataService: SenderOptionsDataService) {
    super();

    setTimeout(() => {
      this.optionChanged.emit(this.options);
      this.emitFormValidity();
    }, 1);
  }

  get options(): SenderOption {
    return {
      id: this.form.controls.id.value || 0,
      value: this.smsOptions || null,
      channelType: this.form.controls.channelType.value,
      isActive: !!this.form.controls.isActive.value,
      isApproved: !!this.form.controls.isApproved.value,
      deliveryProviderId: this.form.controls.deliveryProvider.value
    } as SenderOption;
  }

  get smsOptions(): SMSSenderOption {
    return {
      isLocalNumber: !!this.form.controls.isLocalNumber.value,
      isTollFree: !!this.form.controls.isTollFree.value,
      value: this.type == SmsSenderType.default ? "" : this.form.controls.value.value,
      isPool: !!this.form.controls.isPool.value,
      useGeneric: !!this.form.controls.useGeneric.value,
      isShortCode: !!this.form.controls.isShortCode.value,
      type: !!this.form.controls.isShortCode.value ? SmsSenderType.dedicated : this.type
    };
  }

  set smsOptions(value: SMSSenderOption) {
    if (value) {
      this.form.controls.isLocalNumber.setValue(value.isLocalNumber);
      this.form.controls.isTollFree.setValue(value.isTollFree);
      this.form.controls.isPool.setValue(value.isPool);
      this.form.controls.value.setValue(value.value);
      this.form.controls.useGeneric.setValue(value.useGeneric);
      this.form.controls.isShortCode.setValue(value.isShortCode);
      this.form.controls.waitConfiguration.setValue(!value.useGeneric);
      this.type = value.isShortCode ? SmsSenderType.shortCode : value.type;
      this.typeChanged(this.type);
    }
  }

  ngOnInit(): void {
    this.senderDataService.getDeliveryProviderLabelByCode;
    this.type === SmsSenderType.default || (this.type === SmsSenderType.dedicated && !this.isEdit)
      ? this.form.controls.value.setValidators(null)
      : this.form.controls.value.setValidators([Validators.required, phoneValidator()]);

    if (!this.isAdmin && this.isEdit) {
      this.form.disable();
    }

    this.sub = this.form.valueChanges.subscribe(() => {
      if (this.isOptionValid()) {
        this.optionChanged.emit(this.options);
      }
      this.emitFormValidity();
    });
  }

  localChanged() {
    this.form.controls.isTollFree.setValue(!this.form.controls.isLocalNumber.value);
  }

  tollFreeChanged() {
    this.form.controls.isLocalNumber.setValue(!this.form.controls.isTollFree.value);
  }

  useGenericChanged() {
    this.form.controls.waitConfiguration.setValue(!this.form.controls.useGeneric.value);
  }

  useWaitChanged() {
    this.form.controls.useGeneric.setValue(!this.form.controls.waitConfiguration.value);
  }

  typeChanged(value: number) {
    if (this.type === SmsSenderType.existing) {
      this.form.controls.value.setValidators([Validators.required, phoneValidator()]);
      this.form.controls.isPool.setValue(false);
      this.form.controls.isLocalNumber.setValue(false);
      this.form.controls.isTollFree.setValue(false);
      this.form.controls.isShortCode.setValue(false);
    } else {
      this.form.controls.value.setValidators(null);
      this.form.controls.value.setErrors(null);
    }

    this.form.controls.useGeneric.setValue(true);
    this.form.controls.waitConfiguration.setValue(false);

    if (this.type === SmsSenderType.default) {
      this.form.controls.isLocalNumber.setValue(false);
      this.form.controls.isTollFree.setValue(false);
      this.form.controls.isPool.setValue(true);
      this.form.controls.isShortCode.setValue(false);
    }

    if (this.type === SmsSenderType.dedicated) {
      if (this.isEdit) this.form.controls.value.setValidators([Validators.required, phoneValidator()]);
      this.form.controls.isLocalNumber.setValue(true);
      this.form.controls.isTollFree.setValue(false);
      this.form.controls.isPool.setValue(false);
      this.form.controls.isShortCode.setValue(false);
    }
    if (this.type === SmsSenderType.shortCode) {
      if (this.isEdit) this.form.controls.value.setValidators([Validators.required, phoneValidator()]);
      this.form.controls.isLocalNumber.setValue(false);
      this.form.controls.isTollFree.setValue(false);
      this.form.controls.isPool.setValue(false);
      this.form.controls.isShortCode.setValue(true);
    }

    this.form.controls.value.markAsPristine();
    this.form.controls.value.markAsUntouched();
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
    this.emitFormValidity();
  }

  private isOptionValid(): boolean {
    return this.form.disabled || this.form.valid;
  }

  private emitFormValidity(): void {
    this.optionValid.emit(this.isOptionValid());
  }
}
