import { DeliveryPlatformAccount, DeliveryProviderType, EmailSenderOption, SenderOptionBase, SMSSenderOption } from ".";
import { AllowedDeliveryChannel } from "./campaign";

export class SenderOption {
  id: number;
  deliveryProviderId: number;
  channelType: AllowedDeliveryChannel;
  value: SenderOptionBase | EmailSenderOption | SMSSenderOption | string;
  isActive: boolean;
  isApproved: boolean;
}
export class SenderOptionModel<T extends SMSSenderOption | EmailSenderOption | string> extends SenderOption {
  value: T;
}

export interface DeliveryProviderGetDto {
  id: DeliveryProviderType;
  name: string;
  code: string;
  configuration: DeliveryProviderConfiguration;
  active: boolean;
}
export interface DeliveryProvider extends DeliveryProviderGetDto {
  platformAccount?: DeliveryPlatformAccount;
  senderOptions?: SenderOption[];
}
export interface DeliveryProviderConfiguration {
  authorizationData: DeliveryProviderConfigurationAuthorization;
  smsSenderConfiguration: DeliveryProviderSmsSenderConfiguration;
  code: string;
  configuration: {};
  active: boolean;
}
export interface DeliveryProviderConfigurationAuthorization {
  labels: { key: string; value: string }[];
}
export interface DeliveryProviderSmsSenderConfiguration {
  defaultGenericNumber: string;
  defaultGenericTollfreeNumber: string;
}
