export enum SocialLinkTypes {
  Undefined = 0,
  Facebook = 1,
  Instagram = 2,
  Twitter = 3,
  TikTok = 4,
  LinkedIn = 5,
  Google = 6,
  Snapchat = 7,
  Youtube = 8
}

export interface BrandSocialLink {
  id: number;
  brandId: number;
  linkType: SocialLinkTypes;
  linkUrl: string;
}

export interface BrandSocialLinkSettings {
  id?: number;
  linkType: SocialLinkTypes;
  icon: string;
  color: string;
  defaultPlaceholder: string;
  isShowLink: boolean;
  iconSvg: string;
}
