import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AppConfigService } from "@app/core";
import { FileMimeType, FileResult, FileType, FileUploaderModalComponent, maxImageUploadSize } from "@app/shared/file-uploader";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-upload-button",
  templateUrl: "./upload-button.component.html",
  styleUrls: ["./upload-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadButtonComponent {
  @Input() title = this.transloco.translate("bulkLinks.upload");
  @Input() buttonText = this.transloco.translate("common.file.saveImage");
  @Input() disabled = false;
  @Input() visible = true;
  @Input() hasCropper = false;
  @Input() aspectRatio = 1;
  @Input() storageFolder = "files";
  @Input() extensions: Array<FileMimeType> = [FileMimeType.image];
  @Output() fileResult = new EventEmitter<FileResult>();
  @Output() fileUrl = new EventEmitter<string>();

  constructor(private modal: NgbModal, private appConfigService: AppConfigService, private transloco: TranslocoService) {}

  upload(): void {
    const imageModal = this.modal.open(FileUploaderModalComponent, { backdrop: "static", size: "lg", centered: true });
    const comp: FileUploaderModalComponent = imageModal.componentInstance;
    comp.uploadType = FileType.Image;
    comp.storageFolder = this.storageFolder;
    comp.isCropper = this.hasCropper;
    comp.title = this.title;
    comp.buttonText = this.buttonText;
    comp.aspectRatio = this.aspectRatio;
    comp.extensions = this.extensions;
    comp.maxFileUploadSize = maxImageUploadSize;
    imageModal.result
      .then((file: FileResult) => {
        this.fileResult.next(file);
        this.fileUrl.next(file.fileUrl);
      })
      .catch(() => {});
  }
}
