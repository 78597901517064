import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-pagination-size",
  templateUrl: "./pagination-size.component.html",
  styleUrls: ["./pagination-size.component.scss"]
})
export class PaginationSizeComponent {
  @Output() clicked = new EventEmitter<number>();
  @Input() pageSize: number;
  @Output() pageSizeChange = new EventEmitter<number>();

  sizeList: number[] = [10, 20, 50, 100];

  getPagination(pageSize: number) {
    this.pageSizeChange.emit(pageSize);
    this.clicked.emit(pageSize);
  }
}
