import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FileMimeType, FileResult, FileType, FileUploaderModalComponent, ImageSize, maxImageUploadSize } from "@app/shared/file-uploader";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-custom-image-uploader",
  templateUrl: "./custom-image-uploader.component.html",
  styleUrls: ["./custom-image-uploader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomImageUploaderComponent,
      multi: true
    }
  ]
})
export class CustomImageUploaderComponent {
  @Input() isEditable = false; // todo: refactor to isDisabled
  @Input() isRequired = false;
  @Input() isClearable = false;
  @Input() label = "";
  @Input() tooltip = "";
  @Input() storageFolder = "brands";
  @Input() size: "XL" | "L" | "M" | "S" = "M";
  @Input() extensions: Array<FileMimeType> = [FileMimeType.image];
  @Input() maxImageUploadSize: number = maxImageUploadSize;
  @Input() allowedImageSize: ImageSize;
  @Input() hasCropper = false;
  @Input() labelCssClass = "";

  value = "";
  isDisabled = false;

  private onChange: (value: any) => void = () => {};
  private onTouched: (value: any) => void = () => {};

  constructor(private cdr: ChangeDetectorRef, private modalService: NgbModal, private transloco: TranslocoService) {}

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.onModelChange(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  uploadImage(): void {
    const imageModal = this.modalService.open(FileUploaderModalComponent, { backdrop: "static", size: "lg", centered: true });
    const comp: FileUploaderModalComponent = imageModal.componentInstance;
    comp.uploadType = FileType.Image;
    comp.storageFolder = this.storageFolder;
    comp.title = this.transloco.translateObject("brand.upload");
    comp.buttonText = this.transloco.translateObject("common.file.saveImage");
    comp.extensions = this.extensions;
    comp.maxFileUploadSize = this.maxImageUploadSize;
    comp.allowedImageSize = this.allowedImageSize;
    comp.isCropper = this.hasCropper;

    imageModal.result
      .then((file: FileResult) => {
        const url = file.fileUrl;
        this.updateValue(url);
      })
      .catch((res) => {});
  }

  clearImage(event: Event): void {
    event.preventDefault();
    this.updateValue(null);
  }

  private onModelChange(value: string): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  private updateValue(value: string): void {
    this.onModelChange(value);
    this.onChange(value);
  }
}
