import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import {
  ActivityByPeriod,
  ActivityByPeriodGetDto,
  ActivityByPeriodPostDto,
  TopAccountsActivityReportDto,
  TopAccountActivityReportItemDto,
  AppsActivityByPeriod,
  CustomLinksByPeriod,
  CustomLinksByPeriodPostDto,
  EngagementOutcomeByPeriod,
  EngagementOutcomeByPeriodPostDto,
  KPIsByPeriod,
  KPIsByPeriodPostDto,
  SalesPersonActivityByPeriod,
  TotalTransactionsByPeriodPostDto,
  TotalTransactionsByPeriodReport,
  TotalTransactionsReportByPeriodGetDto,
  TopLocationActivitiesByTemplateReportRequestDto,
  TopLocationActivitiesByTemplateReportItemDto,
  TopPartnersActivityReportItemDto
} from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ReportingService {
  private serviceUrl = "Reporting";

  constructor(private http: DataService) {}

  getTotalActivitiesByPeriod(data: ActivityByPeriodPostDto): Observable<ActivityByPeriodGetDto<ActivityByPeriod>> {
    return this.http.post(`${this.serviceUrl}/totalActivitiesByPeriod`, data);
  }

  getLocationsActivitiesByPeriod(data: ActivityByPeriodPostDto): Observable<ActivityByPeriodGetDto<ActivityByPeriod>> {
    return this.http.post(`${this.serviceUrl}/locationsActivitiesByPeriod`, data);
  }

  getSalePersonsActivitiesByPeriod(data: ActivityByPeriodPostDto): Observable<ActivityByPeriodGetDto<SalesPersonActivityByPeriod>> {
    return this.http.post(`${this.serviceUrl}/salePersonsActivitiesByPeriod`, data);
  }

  getAppsActivitiesByPeriod(data: ActivityByPeriodPostDto): Observable<ActivityByPeriodGetDto<AppsActivityByPeriod>> {
    return this.http.post(`${this.serviceUrl}/appsActivitiesByPeriod`, data);
  }

  getTotalKPIsByPeriod(data: KPIsByPeriodPostDto): Observable<ActivityByPeriodGetDto<KPIsByPeriod>> {
    return this.http.post(`${this.serviceUrl}/kpiActivitiesByPeriod`, data);
  }

  getTotalEngagementOutcomesByPeriod(
    data: EngagementOutcomeByPeriodPostDto
  ): Observable<ActivityByPeriodGetDto<EngagementOutcomeByPeriod>> {
    return this.http.post(`${this.serviceUrl}/totalEngagementsByPeriod`, data);
  }

  getTotalTransactionActivityByPeriod(
    data: TotalTransactionsByPeriodPostDto
  ): Observable<TotalTransactionsReportByPeriodGetDto<TotalTransactionsByPeriodReport>> {
    return this.http.post(`${this.serviceUrl}/totalTransactionActivitiesByPeriod`, data);
  }

  getTotalCustomLinksActivityByPeriod(data: CustomLinksByPeriodPostDto): Observable<ActivityByPeriodGetDto<CustomLinksByPeriod>> {
    return this.http.post(`${this.serviceUrl}/customLinksByPeriod`, data);
  }

  getAccountsActivityReport(data: TopAccountsActivityReportDto): Observable<TopAccountActivityReportItemDto[]> {
    return this.http.post(`${this.serviceUrl}/topAccountsActivityReport`, data);
  }

  getPartnersActivityReport(data: TopAccountsActivityReportDto): Observable<TopPartnersActivityReportItemDto[]> {
    return this.http.post(`${this.serviceUrl}/topPartnersActivityReport`, data);
  }

  getLocationsActivityReport(
    data: TopLocationActivitiesByTemplateReportRequestDto
  ): Observable<TopLocationActivitiesByTemplateReportItemDto[]> {
    return this.http.post(`${this.serviceUrl}/topLocationActivitiesByTemplate`, data);
  }
}
