<div class="default-widget-modal preview-widget-modal">
  <div class="modal-header" *transloco="let t; read: 'widgets.common'">
    <h4 class="page-title-box m-0 p-0">{{ title }}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body text-center">
    <div *transloco="let t; read: 'widgets.common'" class="p-3">
      <input class="form-control" [(ngModel)]="url" disabled="true" />
      <div class="mb-3 mt-3 d-flex justify-content-between">
        <a class="btn btn-outline-light" [href]="url" target="_blank"><i class="mdi mdi-eye text-primary"></i> View </a>
        <button
          [ngbTooltip]="t('copied')"
          [autoClose]="true"
          triggers="manual"
          #tooltip="ngbTooltip"
          class="btn btn-outline-light"
          (click)="copyText()"
        >
          <i class="mdi mdi-content-copy text-primary"></i> {{ t("clipboard") }}
        </button>
      </div>
    </div>
    <iframe *ngIf="qrCodeHtmlUrl" [src]="qrCodeHtmlUrl" class="qr-code-iframe"></iframe>
  </div>
  <div class="modal-footer" *transloco="let tcc; read: 'common.controls'">
    <!-- <button type="button" class="btn btn-primary" [class.disabled]="loading || !isValid"
      (click)="save()">{{tcc('save')}}
    </button>
  <button type="button" class="btn btn-secondary" (click)="dismiss()">
    <i class="fa-lg mdi mdi-cancel"></i>
    {{tcc('close')}}
  </button>-->
  </div>
</div>
