import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-lp-details-card",
  templateUrl: "./lp-details-card.component.html",
  styleUrls: ["./lp-details-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LpDetailsCardComponent {
  @Input() title = "";
}
