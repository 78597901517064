import {
  LoyaltyExpirationPeriod,
  pointsCardList,
  rewardsCardList,
  stampCardList,
  startingPointsCardList,
  startingRewardsCardList,
  startingStampCardList
} from "@app/models";
import { IIsKioskEnabled } from "../widget-settings-base";

export class EnrollLoyaltyWidgetSettings implements IIsKioskEnabled {
  // public loyaltyType: LoyaltyType;
  public maxStampsPoints?: number;
  // public isLimitPerClient: boolean;
  public loyaltyProgram: LoyaltyProgram;
  public enrollLoyaltyLink: string;
  public loyaltyCardId?: string;
  public onboardingRequest: LoyaltyOnboardingRequest;
  IsKioskEnabled: boolean;
}

export enum LoyaltyCalculationMode {
  Points = 0,
  Purchase = 1
}

export enum LoyaltyMinimumRequirementMode {
  Count = "minimumCount",
  Amount = "minimumAmount"
}

export enum LoyaltyType {
  AllLocations = 0,
  SingleLocation = 1,
  MultiLocations = 2
}

export enum LoyaltyProgram {
  DigitalStampCard = 0,
  PointsToPrize = 1,
  RewardsCard = 2,
  MyCard = 3
}

export interface LoyaltyExpirationPeriodData {
  period: LoyaltyExpirationPeriod;
  value?: number;
}

export interface LoyaltyOnboardingRequest {
  loyaltyType: LoyaltyCardTypes;
  loyaltyCardId: string;
  goalBalance: number;
  startingStamps: number;
  maxPerDay: number;
  rewards: LoyaltyReward[];
  description: string;
  isAutomaticCalculationEnabled: boolean;
  conditionConfiguration: LoyaltyConditionConfiguration;
  calculationConfiguration: LoyaltyCalculationConfiguration;
  expiration: LoyaltyExpirationPeriodData;
}

export interface LoyaltyCalculationConfiguration {
  count: number; // integer
  amount: number;
  calculationMode: LoyaltyCalculationMode;
}

export interface LoyaltyConditionConfiguration {
  // only one of the following should be set
  minimumCount?: number; // integer
  minimumAmount?: number;
}

export class LoyaltyReward {
  public tier: number;
  public couponId: string;
}

export class LoyaltyRewardItem {
  public name: string;
  public tier: number;
  public couponId: string;
  public couponName: string;
}

export enum LoyaltyCardTypes {
  Pointcard = 1,
  Stampcard = 2,
  Rewardcard = 3
}

export class LoyaltyCardType {
  public id: number;
  public name: string;
  public suffixList: string;
  public list: ListType[];
  public startingList: ListType[];
  public tooltip: string;
  public sortOrder: number;
}

export class ListType {
  n: number;
  name: string;
}

export const loyaltyCardTypesSettings: LoyaltyCardType[] = [
  {
    id: LoyaltyCardTypes.Pointcard,
    name: "Points card",
    suffixList: "point",
    list: pointsCardList,
    startingList: startingPointsCardList,
    tooltip: "Receive a certain amount of points and get rewarded.\n\r Example: Collect 500 points and get a 10$ off voucher.",
    sortOrder: 2
  },

  {
    id: LoyaltyCardTypes.Stampcard,
    name: "Stamp card",
    suffixList: "stamp",
    list: stampCardList,
    startingList: startingStampCardList,
    tooltip: "Receive a certain amount of stamps and get rewarded. \n\r Example: Buy 10 coffees and get 1 coffee for free.",
    sortOrder: 1
  },

  {
    id: LoyaltyCardTypes.Rewardcard,
    name: "Rewards card",
    suffixList: "point",
    list: rewardsCardList,
    startingList: startingRewardsCardList,
    tooltip:
      "Save points and redeem points from the available rewards.\n\r Example: Consumer collects points and has a list of available rewards and can select which reward to pick.",
    sortOrder: 3
  }
];
