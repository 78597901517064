<!-- <div class="d-flex align-items-center commands">
 <div *ngFor="let item of commands">
    <div [ngbTooltip]="item.tooltip" *ngIf="item.canExecute(model) && !disabled" (click)="item.execute(model)" class="d-flex align-items-center me-3 gap-1 cursor-pointer">
      <a class="text-{{item.iconColor}}"><i
          class="{{item.icon}} font-size-22"></i></a>
    </div>
  </div>
</div>-->
<!-- [ngbTooltip]="text" #t="ngbTooltip" -->
<div class="card-widget position-relative">
  <div [ngClass]="{ 'cursor-pointer': interactive && !disabled, disabled: disabled }" class="text-center">
    <div class="icon-view text-primary pt-3" (click)="click()">
      <i class="ri-3x" [ngClass]="icon"></i>
    </div>
    <div class="text-icon-view pb-4">{{ text }}</div>
  </div>
  <div
    [ngbTooltip]="disabledTooltip"
    placement="bottom"
    class="position-absolute top-0 end-0 form-check form-switch form-switch-md switcher mt-2"
  >
    <input (click)="interactive && onSwitch($event)" [ngModel]="!disabled" class="form-check-input" type="checkbox" id="SwitchCheckSizemd" />
    <label class="form-check-label" for="SwitchCheckSizemd"></label>
  </div>
  <div *ngIf="warning" class="position-absolute top-0 warning font-size-20 mt-2" [ngbTooltip]="warningMessage" (click)="click()">
    <i
      [ngClass]="{ 'mdi dripicons-warning': warningMessage, 'fas fa-exclamation-circle': !warningMessage && !disabled }"
      class="text-warning"
    ></i>
  </div>
  <div
    [ngbTooltip]="commands[1].tooltip"
    *ngIf="commands[1].canExecute(model) && !disabled"
    (click)="commands[1].execute(model)"
    class="position-absolute review top-0 start-0 font-size-20"
  >
    <a class="text-secondary"><i class="{{ commands[1].icon }} font-size-22"></i></a>
  </div>
  <div
    [ngbTooltip]="commands[0].tooltip"
    *ngIf="commands[0].canExecute(model) && !disabled"
    (click)="commands[0].execute(model)"
    class="position-absolute configured font-size-20 bottom-0 end-0 mb-2"
  >
    <a class="text-secondary"><i class="{{ commands[0].icon }} font-size-22"></i></a>
  </div>
</div>
