import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-app-demo-modal",
  templateUrl: "./app-demo-modal.component.html",
  styleUrls: ["./app-demo-modal.component.scss"]
})
export class AppDemoModalComponent implements OnInit {
  title: string;
  videoId: string;
  description: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  dismiss() {
    this.activeModal.dismiss();
  }
}
