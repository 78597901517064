export interface SupportedDomainDto {
  name: string;
  baseDomainName: string;
}

export interface GenerateDomainNameDto {
  domainName: string;
  applicationName: string;
  applicationDescription: string;
  isAvailable: string;
  errorMsg: string;
}

export enum DomainType {
  Subdomain = "SubDomain",
  Custom = "Custom"
}

export interface PartnerCustomDomainConfigurationDto {
  webSite: string;
  infoEmail: string;
}

export interface PartnerDomainConfigurationDto {
  domainType: DomainType;
  baseDomainName: string;
  prefix?: string;
  currentCustomDomainId?: number;
}

export interface PartnerDomainFormData {
  termsEmail: string;
  termsWebsite: string;
  configured?: boolean;
  configuration: PartnerDomainConfigurationDto;
  patched?: boolean;
}
