import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { LoyaltyProgramSettingsGetDto, LoyaltyProgramSettingsPostDto } from "@app/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class LoyaltyProgramService {
  private serviceUrl = "LoyaltyProgram";

  constructor(private http: DataService) {}

  getSettings(): Observable<LoyaltyProgramSettingsGetDto> {
    return this.http.get(`${this.serviceUrl}/settings`);
  }

  updateSettings(params: LoyaltyProgramSettingsPostDto): Observable<LoyaltyProgramSettingsGetDto> {
    return this.http.post(`${this.serviceUrl}/settings`, params);
  }
}
