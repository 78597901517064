<div *transloco="let t; read: 'notFoundItems'" class="align-items-center center">
  <div class="row">
    <div class="d-flex justify-content-center">
      <div class="text-center">
        <img src="assets/images/not-found.svg" alt="" class="mb-3" />

        <p class="font-size-16">
          <ng-container *ngIf="allowAdd; else withoutAddAction">
            {{ t("createNewItem", { item: itemName }) }}
          </ng-container>

          <ng-template #withoutAddAction>
            <ng-container *ngIf="notFoundText; else defaultNotFoundText">
              {{ notFoundText }}
            </ng-container>

            <ng-template #defaultNotFoundText>
              {{ t("defaultText") }}
            </ng-template>
          </ng-template>
        </p>
      </div>
    </div>
  </div>
</div>
