import { Campaign, LocationSubscription, SubscriptionAddons, SubscriptionPlanType } from ".";

export class SubscriptionPlan {
  public id: number;
  public name: string;
  public description: string;
  public price: number;
  public limits: SubscriptionPlanLimits;
  public options: SubscriptionPlanOptions;
  public periodInDays: number;
  public productId: string;
  public locationQuantity: number;
  public widgets: Array<Campaign>;
  public LocationSubscriptions: Array<LocationSubscription>;
  public features: Object;
  public planType: SubscriptionPlanSourceType;
  public addons: Array<SubscriptionAddons>;
}

export class SubscriptionPlanLimits {
  public dailyLimit: number;
  public periodLimit: number;
  public dailyWarningCap: number;
  public periodOverCapacity: number;
  public periodWarningCap: number;
  public couponLimit?: number;
  public reviewRequestLimit?: number;
  public customShortLinkLimit: number;
  public bulkSize: number;
  public socialLinkLimit: number;
  public isUnlimited: boolean;
}

export class SubscriptionPlanOptions {
  public hasDashboard: boolean;
  public hasCmc: boolean;
  public hasCfs: boolean;
  public hasTrackingPixel: boolean;
  public hasOutboundMarketing: boolean;
  public hasReportingAndAnalytics: boolean;
  public hasProducts: boolean;
  public hasExternalContacts: boolean;
  public hasPosIntegration: boolean;
  public hasConnectors: boolean;
  public hasCfsPrint: boolean;
}

export enum SubscriptionPlanSourceType {
  Onboarding = "Onboarding",
  NewRegistration = "NewRegistration",
  ActivationBulk = "ActivationBulk"
}
