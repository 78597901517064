<div class="" *transloco="let t; read: 'senders'">
  <div class="col-md-12">
    <ngb-alert
      *ngIf="!isEdit && !isAdmin && smsOptions.type == SenderTypes.dedicated"
      type="info"
      class="text-center"
      [dismissible]="false"
      >{{ t("smsAlert") }}</ngb-alert
    >
    <ngb-alert
      *ngIf="!isEdit && !isAdmin && smsOptions.type == SenderTypes.existing"
      type="info"
      class="text-center"
      [dismissible]="false"
      >{{ t("smsAlertPort") }}</ngb-alert
    >
  </div>

  <label class="col-form-label font-weight-semibold">
    {{ t("smsOption") }}
  </label>

  <!--  Settings section: -->
  <div>
    <label class="col-form-label">
      {{ t("smsSettings") }}
    </label>

    <div class="form-check mb-3" *ngIf="enabledGeneric">
      <input
        (change)="typeChanged(type)"
        class="form-check-input"
        type="radio"
        name="smsRadios"
        id="default-type"
        [value]="SenderTypes.default"
        [(ngModel)]="type"
      />
      <label class="form-check-label" for="default-type">
        {{ t("smsDefault") }}
      </label>
    </div>
    <div class="form-check mb-3" *ngIf="enabledDedicatedLocal || enabledDedicatedTollfree">
      <input
        (change)="typeChanged(type)"
        class="form-check-input"
        type="radio"
        name="smsRadios"
        id="dedicated-type"
        [value]="SenderTypes.dedicated"
        [(ngModel)]="type"
        #dedicated
      />
      <label class="form-check-label" for="dedicated-type">
        {{ t("smsDedicated") }}
      </label>
    </div>
    <div class="form-check mb-3" *ngIf="enabledDedicatedShortCode">
      <input
        (change)="typeChanged(type)"
        class="form-check-input"
        type="radio"
        name="smsRadios"
        id="dedicatedshort-type"
        [value]="SenderTypes.shortCode"
        [(ngModel)]="type"
        #dedicatedshort
      />
      <label class="form-check-label" for="dedicatedshort-type">
        {{ t("smsDedicatedShortcode") }}
      </label>
    </div>
    <div class="form-check mb-3" *ngIf="enabledExisted">
      <input
        (change)="typeChanged(type)"
        class="form-check-input"
        type="radio"
        name="smsRadios"
        id="existing-type"
        [value]="SenderTypes.existing"
        [(ngModel)]="type"
      />
      <label class="form-check-label" for="existing-type">
        {{ t("smsExisting") }}
      </label>
    </div>

    <form [formGroup]="form">
      <div>
        <div class="mb-3">
          <div *ngIf="type == SenderTypes.dedicated && enabledDedicatedLocal" class="form-check form-switch form-switch-md mb-3">
            <input
              (change)="localChanged()"
              formControlName="isLocalNumber"
              #localNumber
              class="form-check-input"
              type="checkbox"
              id="switch1"
              checked
            />
            <label class="form-check-label" for="switch1">{{ t("smsLocal") }}</label>
          </div>
          <div
            *ngIf="(type == SenderTypes.dedicated && enabledDedicatedTollfree) || (type == SenderTypes.default && enabledGenericTollfree)"
            class="form-check form-switch form-switch-md mb-3"
          >
            <input (change)="tollFreeChanged()" formControlName="isTollFree" class="form-check-input" type="checkbox" id="switch2" />
            <label class="form-check-label" for="switch2">{{ t("smsToll") }}</label>
          </div>
          <div *ngIf="type == SenderTypes.existing" class="form-check form-switch form-switch-md mb-3" hidden>
            <input (change)="useGenericChanged()" formControlName="useGeneric" class="form-check-input" type="checkbox" id="switch3" />
            <label class="form-check-label" for="switch3">{{ t("smsUseExisting") }}</label>
          </div>
          <div *ngIf="type == SenderTypes.existing" class="form-check form-switch form-switch-md mb-3" hidden>
            <input (change)="useWaitChanged()" formControlName="waitConfiguration" class="form-check-input" type="checkbox" id="switch4" />
            <label class="form-check-label" for="switch4">{{ t("smsWait") }}</label>
          </div>
        </div>
        <div
          *ngIf="
            (isEdit && smsOptions.type != SenderTypes.default) || (!isEdit && smsOptions.type && smsOptions.type == SenderTypes.existing)
          "
          class="col-md-6 mt-3 mb-3 mt-sm-0"
        >
          <label class="col-form-label required">{{ t("phone") }}</label>
          <input
            [mask]="phoneMask"
            class="form-control"
            type="text"
            [placeholder]="t('phone')"
            formControlName="value"
            [class.is-invalid]="form.controls['value'].touched && form.controls['value'].errors"
          />
          <ng-container *transloco="let tc; read: 'common.error'">
            <ng-container *ngIf="form.controls['value'].touched && form.controls['value'].errors">
              <ng-container *ngIf="form.controls['value'].errors.required; else second">
                <div class="invalid-feedback position-absolute m-0">
                  {{ tc("required") }}
                </div>
              </ng-container>
              <ng-template #second>
                <div class="invalid-feedback">
                  {{ tc("invalidPhone") }}
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <hr class="m-0" *ngIf="isAdmin" />
      <div class="form-check mt-3 mx-2" *ngIf="isAdmin">
        <input formControlName="isApproved" type="checkbox" id="check" class="form-check-input" />
        <label for="check" class="form-check-label">{{ t("approved") }}</label>
      </div>
    </form>

    <div class="col-md-12">
      <div *ngIf="!isEdit && !isAdmin && smsOptions.type == SenderTypes.existing" class="text-danger mt-3 mb-3">
        {{ t("smsAlertExistedNumber") }}
      </div>
    </div>
  </div>
</div>
