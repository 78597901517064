import { Component } from "@angular/core";
import { CouponInfo } from "@app/models";
import { CouponService, PlatformAccountService } from "@app/services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-coupon-select-modal",
  templateUrl: "./coupon-select-modal.component.html",
  styleUrls: ["./coupon-select-modal.component.scss"]
})
export class CouponSelectModalComponent {
  couponList: CouponInfo[];
  selectedCouponId?: string;

  get externalCouponSetupUrl() {
    const url = this.platformSvc.getCouponExternalSetupUrl();
    return url;
  }
  private _selectedCoupon?: CouponInfo;

  constructor(private _activeModal: NgbActiveModal, private _couponSvc: CouponService, private platformSvc: PlatformAccountService) {}

  ngOnInit(): void {
    this._refreshCouponListSelection();
  }

  onSelectCoupon(coupon: CouponInfo) {
    this.selectedCouponId = coupon.id;
    this._selectedCoupon = coupon;
    this._refreshCouponListSelection();
  }

  private _refreshCouponListSelection(): void {
    this.couponList?.forEach((item: CouponInfo) => {
      item.isSelected = this.selectedCouponId && this.selectedCouponId === item.id;
    });
  }

  apply() {
    this._activeModal.close(this._selectedCoupon);
  }
  cancel() {
    this._activeModal.dismiss();
  }
}
