import { NewRegistrationStatus } from "./new-registration-status";

export class CreateNewRegistrationModel {
  status: NewRegistrationStatus;
  subscriptionPlanId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  state: string;
  address: string;
  city: string;
  zip: string;
  country: number;
  countryCode: string;
  companyName: string;
  website?: string;
  sendToEmail?: string;
}
