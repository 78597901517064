export interface TopLocationActivitiesByTemplateReportRequestDto {
  startDate: string;
  endDate: string;
  period: number;
  timeZoneOffset: number;
  topCount: number;
  isGlobalTemplates: boolean;
  templateIds: number[];
  filterMode: TopLocationActivitiesByTemplateReportFilterModeDto;
  sortDirection: TopLocationActivitiesByTemplateReportSortDirectionDto;
}

export interface TopLocationActivitiesByTemplateReportItemDto {
  locationName: string;
  locationId: number;
  scansCount: number;
  impressionsCount: number;
  clicksCount: number;
  engagementsCount: number;
}

export enum TopLocationActivitiesByTemplateReportSortDirectionDto {
  Highest = "Highest",
  Lowest = "Lowest"
}

export enum TopLocationActivitiesByTemplateReportFilterModeDto {
  Scans = "Scans",
  Impressions = "Impressions",
  Clicks = "Clicks",
  Engagements = "Engagements"
}

export enum TopLocationActivitiesByTemplateReportFilterModeLegendItemIndex {
  Scans = 0,
  Impressions = 1,
  Clicks = 2,
  Engagements = 3
}
