<div *ngIf="visibleFilter" ngbDropdown #dropdownElement="ngbDropdown" container="body" class="location-filter-wrapper">
  <div ngbDropdownToggle class="location-filter">
    <div class="location-filter__content">
      <span class="location-filter__title"> Filter locations </span>

      <img *ngIf="!selectedLocation" src="assets/images/filter-icon.svg" alt="" class="location-filter__icon" />

      <div *ngIf="selectedLocation" [ngbTooltip]="selectedLocation.companyName" class="location-filter__indicator">
        <span class="location-filter__indicator-text">
          {{ selectedLocation.companyName }}
        </span>
      </div>
    </div>
  </div>

  <div ngbDropdownMenu class="location-filter__dropdown">
    <app-location-autocomplete-select
      *ngIf="dropdownElement.isOpen()"
      [selectionType]="'single'"
      [selectedLocationId]="selectedLocation?.id"
      [locations]="locations"
      [loading]="loading"
      [hideReset]="hideReset"
      (selectedLocationChanged)="dropdownElement.toggle(); onSelectedLocation($event)"
    >
    </app-location-autocomplete-select>
  </div>
</div>
