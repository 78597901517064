<div *ngIf="!isHorizontal && enabled">
  <div *transloco="let t">
    <h5 *transloco="let t">
      {{ t("widgets.common.deliveryMethod") }}
    </h5>

    <div *ngFor="let item of deliveryMethods" class="form-check mb-3">
      <input
        (change)="valueChanged(item, $event.target)"
        class="form-check-input"
        type="checkbox"
        [disabled]="item.disabled"
        [checked]="item.selected"
        id="formRadios+{{ item.name }}"
        (blur)="onTouched()"
      />
      <label class="form-check-label" for="formRadios+{{ item.name }}">
        {{ deliveryMethodRepresentation[item.name] }}
      </label>
    </div>
  </div>
</div>
<div *ngIf="isHorizontal && enabled">
  <div *transloco="let t" class="text-{{ position }}">
    <h5 *transloco="let t">
      {{ t("widgets.common.deliveryMethod") }}
    </h5>
    <div class="d-flex justify-content-{{ position }}">
      <div *ngFor="let item of deliveryMethods; let i = index" class="form-check mb-3" [ngClass]="i > 0 ? 'mx-3' : ''">
        <input
          (click)="valueChanged(item, $event.target)"
          class="form-check-input"
          type="checkbox"
          [disabled]="item.disabled"
          [checked]="item.selected"
          id="formRadios+{{ item.name }}"
          (blur)="onTouched()"
        />
        <label class="form-check-label" for="formRadios+{{ item.name }}">
          {{ deliveryMethodRepresentation[item.name] }}
        </label>
      </div>
    </div>
  </div>
</div>
