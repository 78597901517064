import { ImageSize } from "@app/shared/file-uploader/file-uploader-modal/file-type";

export enum BrandType {
  Undefined = 0,
  Private = 1,
  Public = 2
}

export enum BrandLogoTypeEnum {
  MainLogo = "MainLogo",
  QrCodeLogo = "QrCodeLogo",
  HeroLogo = "HeroLogo"
}

export class Brand {
  id: number;
  name: string;
  shortName: string;
  brandHeroImg: string;
  logo: string;
  qrCodeLogo: string;
  appIcon: string;
  website: string;
  facebook: string;
  instagram: string;
  twitter: string;
  google: string;
  snapchat: string;
  slogan: string;
  disclaimer: string;
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  country: number;
  brandType: BrandType;
  ownerId: number;
  notificationId: string;
  industryId: number;
  applicationStylesId: number;
  partnerId: number;
  partnerName?: string;
  public configured: boolean;
}

export const appIconAllowedImageSize: ImageSize = { width: 192, height: 192 };
