import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ViewAsPartnerService {
  private isActivated = false;
  private partnerId = 0;

  getPartnerId(): number {
    return this.partnerId;
  }

  isFeatureEnabled(): boolean {
    return this.isActivated;
  }

  activateFeature(partnerId: number): void {
    this.partnerId = partnerId;
    this.isActivated = this.partnerId > 0;
  }

  deactivateFeature(): void {
    this.partnerId = 0;
  }
}
