import { Component, OnInit, AfterViewInit, Input } from "@angular/core";

@Component({
  selector: "app-horizontal",
  templateUrl: "./horizontal.component.html",
  styleUrls: ["./horizontal.component.scss"]
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {
  @Input() isMenuVisible;

  constructor() {}

  ngOnInit() {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "light");
    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-keep-enlarged");
    document.body.removeAttribute("data-sidebar-small");
  }

  ngAfterViewInit() {}

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle("right-bar-enabled");
  }
}
