import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { CouponInfo, CouponInfoQr, IndustryLoyaltyCard, LoyaltyCard } from "@app/models";
import { CouponService, LoyaltyCardService } from "@app/services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { LoyaltyCardType, loyaltyCardTypesSettings, LoyaltyOnboardingRequest, LoyaltyRewardItem } from "../enroll-loyalty-widget-settings";

@Component({
  selector: "app-loyalty-program-preview-modal",
  templateUrl: "./loyalty-program-preview-modal.component.html",
  styleUrls: ["./loyalty-program-preview-modal.component.scss"]
})
export class LoyaltyProgramPreviewModalComponent implements OnInit, OnDestroy {
  public loyaltyProgramDetailsResult: LoyaltyOnboardingRequest;
  public title: string;
  public locationId: number;
  public accountId?: number;
  public industryId: number;
  public rewardsList: LoyaltyRewardItem[] = [];
  public enrollLoyaltyLink: string;

  selectedCard: LoyaltyCard = new LoyaltyCard();
  selectedCardTypeSetting: LoyaltyCardType;
  loyaltyCards: LoyaltyCard[] = [];
  loading = true;

  public coupons: Array<CouponInfoQr> = [];
  public loadingCoupons = true;
  private search$ = new Subject();
  private unsubscribe$ = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private couponService: CouponService,
    private loyaltyCardService: LoyaltyCardService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.selectedCardTypeSetting) {
      this.setSelectedCardTypeSetting();
    }
    this.loadCoupons();
    this.search$.next();
    this.setLoyaltyCards();
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  setRewardsList(coupons) {
    this.rewardsList = this.loyaltyProgramDetailsResult.rewards as LoyaltyRewardItem[];
    coupons.forEach((e) => {
      this.rewardsList.forEach((r) => {
        if (r.couponId === e.id) {
          r.couponName = e.name;
          r.name = r.tier.toFixed() + " " + this.selectedCardTypeSetting.suffixList;
        }
      });
    });
  }

  private loadCoupons(): void {
    this.search$
      .pipe(
        tap(() => {
          this.loadingCoupons = true;
          this.coupons = null;
        }),
        switchMap(() =>
          this.locationId > 0
            ? this.couponService.getLocationCoupons(this.locationId)
            : this.couponService.getIndustryCoupons(this.industryId)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (coupons: Array<CouponInfo>) => {
          this.coupons = coupons ? coupons.map((item) => CouponInfoQr.toCouponSettings(item)) : null;

          if (this.coupons) {
            this.setRewardsList(this.coupons);
          }
          this.changeDetectorRef.markForCheck();
          this.loadingCoupons = false;
        },
        () => {
          this.loadingCoupons = false;
        }
      );
  }

  private setLoyaltyCards(): void {
    this.loading = true;
    if (this.accountId && this.enrollLoyaltyLink) {
      this.loadCardForAccount(this.accountId);
    } else this.loadCardFromIndustry();
  }

  private loadCardFromIndustry() {
    if (this.industryId) {
      this.loading = true;
      this.loyaltyCardService
        .getIndustryLoyaltyCards(this.industryId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: IndustryLoyaltyCard[]) => {
            this.selectedCard = res.find((o) => o.loyaltyCard.id === this.loyaltyProgramDetailsResult.loyaltyCardId).loyaltyCard;
            this.changeDetectorRef.markForCheck();
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  private loadCardForAccount(accountId: number) {
    this.loading = true;
    this.loyaltyCardService
      .getAccountLoyaltyCard(accountId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (card: LoyaltyCard) => {
          if (card) {
            this.selectedCard = card;
            this.loading = false;
            this.changeDetectorRef.markForCheck();
          } else this.loadCardFromIndustry();
        },
        () => {
          this.loading = false;
        }
      );
  }

  setSelectedCardTypeSetting() {
    this.selectedCardTypeSetting = loyaltyCardTypesSettings.find((o) => o.id === this.loyaltyProgramDetailsResult.loyaltyType);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
