import { DataService } from "@app/core";
import { ConditionTypeGetDto } from "./../../models/pos-conditions";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ConditionTypeService {
  constructor(private http: DataService) {}

  private serviceUrl = "ConditionType";

  getConditionTypes(): Observable<ConditionTypeGetDto[]> {
    return this.http.get(`${this.serviceUrl}`);
  }
}
