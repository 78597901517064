import { KeyValueDto } from "@app/models/key-value";

export class DigitalAsset {
  public id: number;
  public mediaContentId: number;
  public name: string;
  public content: string;
  public title: string;
  public contentUrl: string;
  public digitalAssetType: DigitalAssetType;
  public contentType: string;
  public configuration: {
    defaultParameters: KeyValueDto[];
    screenSize: ScreenSizeType;
  };
}

export enum DigitalAssetType {
  Lottie,
  Html
}

export enum ScreenSizeType {
  Compact = "Compact",
  Full = "Full",
  Vertical = "Vertical"
}
