<form [formGroup]="form" *transloco="let t; read: 'location'">
  <div class="row">
    <div class="col-12">
      <h5>{{ t("details") }}</h5>
      <div class="row">
        <div class="col-md-3">
          <label class="col-form-label">{{ t("locationName") }}</label>
          <div class="">
            <input formControlName="companyName" class="form-control" type="text" />
          </div>
        </div>
        <div class="d-none col-md-3">
          <label class="col-form-label">{{ t("dealer") }}</label>
          <div class="">
            <input formControlName="dealerCode" class="form-control" type="text" />
          </div>
        </div>
        <div class="col-md-3">
          <label class="col-form-label">{{ t("email") }}</label>
          <div class="">
            <input formControlName="email" class="form-control" type="text" />
          </div>
        </div>
        <div class="col-md-3">
          <label class="col-form-label">{{ t("phone") }}</label>
          <div class="">
            <input formControlName="phoneNumber" mask="(000) 000-0000" class="form-control" type="text" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-md-3">
          <label class="col-form-label">{{ t("address") }}</label>
          <div class="">
            <input formControlName="address" class="form-control" type="text" />
          </div>
        </div>
        <div class="col-md-3">
          <label class="col-xs-3 col-form-label">{{ t("state") }}</label>
          <div class="">
            <input formControlName="state" class="form-control" type="text" />
          </div>
        </div>
        <div class="col-md-3">
          <label class="col-xs-3 col-form-label">{{ t("city") }}</label>
          <div class="">
            <input formControlName="city" class="form-control" type="text" />
          </div>
        </div>
        <div class="col-md-3">
          <label class="col-xs-3 col-form-label">{{ t("zip") }}</label>
          <div class="">
            <input numbersOnly formControlName="zip" class="form-control" type="text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
