import { Component, OnInit, ViewChild } from "@angular/core";
import { QRCodeSettings, ShortLinkPreviewDto } from "@app/models";
import { ShortLinkService } from "@app/services";
import { NgbActiveModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardService } from "ngx-clipboard";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { take } from "rxjs/operators";

@Component({
  selector: "app-app-preview-modal",
  templateUrl: "./app-preview-modal.component.html",
  styleUrls: ["./app-preview-modal.component.scss"]
})
export class AppPreviewModalComponent implements OnInit {
  @ViewChild("tooltip") public tooltip: NgbTooltip;
  settings: QRCodeSettings;
  title: string;
  qrCodeHtmlUrl: SafeUrl;
  url: string;
  widgetId: number;
  locationId: number;

  constructor(
    public activeModal: NgbActiveModal,
    private shortLinkSvc: ShortLinkService,
    private clipboardApi: ClipboardService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.shortLinkSvc
      .getCustomWidgetQrCodePreview(this.locationId, this.widgetId)
      .pipe(take(1))
      .subscribe((previewDto: ShortLinkPreviewDto) => {
        const qrCodeHtmlUrl: string = previewDto.qrCodeLinkUrl;

        this.qrCodeHtmlUrl = this.sanitizer.bypassSecurityTrustResourceUrl(qrCodeHtmlUrl);
        this.url = previewDto.shortUrl;
      });
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  copyText() {
    this.clipboardApi.copyFromContent(this.url);
    this.tooltip.open();
    setTimeout(() => {
      this.tooltip.close();
    }, 1000);
  }
}
