import { formatDate } from "@angular/common";
import { ApexLegend } from "ng-apexcharts";
import {
  ChartData,
  ChartOptions,
  KPIs,
  KPIsByPeriod,
  KPIsByPeriodGetDto,
  KPIsCardInfoItem,
  TimeFrameTypes,
  TotalKPIsCardInfo
} from "@app/models";
import { OrdinalRawValue } from "echarts/types/src/util/types";
import { DateTimeZoneService } from "../date-timezone.service";

export class DashboardKPIs extends DateTimeZoneService {
  private static readonly topNumber = 3;

  constructor() {
    super();
  }

  private static readonly minNumberForShortLabel = 20;
  private static apexTooltipItem: any = {
    title: {
      formatter: function (value: string) {
        return value;
      }
    }
  };

  private static apexLegend: ApexLegend = {
    tooltipHoverFormatter: function (type: string, opts: any) {
      const value = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
      return `${type} - <strong>${value}</strong>`;
    }
  };

  private static chartOptions: Partial<ChartOptions> = {
    chart: {
      height: 350,
      type: "bar",

      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "clearline_kpis"
          },
          svg: {
            filename: "clearline_kpis"
          },
          png: {
            filename: "clearline_kpis"
          }
        }
      }
    },

    plotOptions: { bar: { horizontal: false, columnWidth: "55%" } },
    dataLabels: {
      enabled: false
    },
    stroke: { show: true, width: 2, colors: ["transparent"] },
    legend: DashboardKPIs.apexLegend,
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    yaxis: {
      labels: {
        formatter: function (value: OrdinalRawValue | number) {
          return value ? (value as number).toFixed(0) : "0";
        }
      }
    },
    xaxis: {
      tickPlacement: "between",
      labels: {
        trim: false,
        hideOverlappingLabels: true
      }
    },
    tooltip: {
      y: [DashboardKPIs.apexTooltipItem]
    }
  };

  private static getTopKPIs(kpis: KPIs[]): KPIs[] {
    if (kpis?.length) {
      const uniqueNames = [...new Set(kpis.map((obj) => obj.name))];
      const groupKPIs = [];
      uniqueNames.forEach((item, i) => {
        let findKpis = kpis.filter((s) => s.name === item);
        const findKpi: KPIs = this.getTotalKPIsFromList(findKpis);
        groupKPIs.push(findKpi);
      });

      return groupKPIs
        .sort((a: KPIs, b: KPIs) => b.totalCount - a.totalCount)
        .slice(0, this.topNumber)
        .filter((item: KPIs) => !!item.totalCount);
    }
  }

  static getUniqueByKey(array) {
    const arrayUniqueByKey = [...new Map(array.map((item) => [item["name"], item])).values()];
    return arrayUniqueByKey;
  }

  static getKPIsForCard(list: KPIs[]): TotalKPIsCardInfo {
    if (list?.length) {
      const allListKipis = list;
      const topList = this.getTopKPIs(list);
      const totalKPIs: KPIs = this.getTotalKPIsFromList(allListKipis);
      const data: KPIsCardInfoItem[] = topList.map((item: KPIs) => {
        return {
          label: item.name,
          value: item.totalCount,
          progressPerCent: this.getProgress(item.totalCount, totalKPIs.totalCount),
          color: item.color
        };
      });

      return {
        totalCount: totalKPIs?.totalCount ?? 0,
        list: data
      };
    }
  }

  static getChartOptions(info: KPIsByPeriodGetDto<KPIsByPeriod>, currentTimeFrame: TimeFrameTypes): Partial<ChartOptions> {
    if (currentTimeFrame) {
      const data: ChartData = this.getApexAxisChartData(info, currentTimeFrame);
      const chartOptions: Partial<ChartOptions> = {
        ...DashboardKPIs.chartOptions
      };

      chartOptions.series = data.series;
      chartOptions.xaxis.categories = data.categories;
      chartOptions.xaxis = { ...chartOptions.xaxis };
      return chartOptions;
    }
  }

  private static getPeriodKpiItemCounts(info: KPIsByPeriodGetDto<KPIsByPeriod>, name: string, periods: string[]): number[] {
    const result: number[] = [];

    if (!info?.data) return result;
    if (periods && info.data?.length) {
      periods.forEach((item: string) => {
        const kpi = info.data.find((x) => x.name === name && x.name !== null && x.startDate === item);
        result.push(kpi?.totalCount ? kpi?.totalCount : null);
      });
    }
    return result;
  }

  private static getApexAxisChartData(info: KPIsByPeriodGetDto<KPIsByPeriod>, currentTimeFrame: TimeFrameTypes): ChartData {
    if (!info || !info?.data) {
      return {
        series: [],
        categories: []
      };
    }

    if (info.data?.length) {
      const periods: string[] = DashboardKPIs.getPeriodsForKPIs(info.data);
      const uniqueNames: KPIsByPeriod[] = this.getUniqueByKey(info.data) as KPIsByPeriod[];
      const series = uniqueNames
        .filter((x) => x.name !== "" && x.name !== null)
        .map((item) => {
          return {
            name: item.name,
            color: item.color,
            data: this.getPeriodKpiItemCounts(info, item.name, periods)
          };
        });

      const timeCategories = [];
      if (periods?.length) {
        const shortDateLabel: boolean = periods?.length > this.minNumberForShortLabel;

        periods.forEach((item: string) => {
          const date: string = DateTimeZoneService.getChartDateItem(item, currentTimeFrame, shortDateLabel);
          timeCategories.push(date);
        });
      }
      return {
        series,
        categories: timeCategories
      };
    }
  }

  private static getPeriodsForKPIs(kpis: KPIsByPeriod[]): string[] {
    const result = [...new Set(kpis.map((obj) => obj.startDate))];
    return result;
  }

  private static getProgress(value: number, totalValue: number): number {
    return (value / totalValue) * 100;
  }

  private static getTotalKPIsFromList(list: KPIs[]): KPIs {
    return list.reduce((previousValue: KPIs, currentValue: KPIs) => {
      if (!previousValue) {
        return { ...currentValue };
      } else {
        previousValue.totalCount += currentValue.totalCount;
        return { ...previousValue };
      }
    }, null);
  }
}
