import { Injectable } from "@angular/core";
import { ContentSettings, DigitalAsset, ScreenSizeType, Template, TemplatePreviewOptions } from "@app/models";
import { MediaContent } from "@app/models/media-content";
import { DigitalAssetService, MediaContentService } from "@app/services";
import { NULL_OBSERVABLE } from "clearline-api";
import { Observable, Subject } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TemplatePreviewService {
  isScreenSizeResponsive$ = new Subject<boolean>();

  constructor(private mediaContentService: MediaContentService, private digitalAssetService: DigitalAssetService) {}

  getLottieOptions(template: Template): Observable<TemplatePreviewOptions> {
    const { configuration } = template;

    const configurationOptions$ = (_template: Template) => {
      const _configuration = _template.configuration;
      const _templateProperties = _template.templateProperties;
      const { mediaContentId, defaultParameters } = _configuration;

      return mediaContentId
        ? this.mediaContentService.getMediaContent(mediaContentId).pipe(
            tap((mediaContent: MediaContent) => {
              const isScreenSizeResponsive = mediaContent.digitalAssets.some(
                (item: DigitalAsset) => item.configuration?.screenSize && item.configuration.screenSize !== ScreenSizeType.Full
              );

              this.isScreenSizeResponsive$.next(isScreenSizeResponsive);
            }),
            map((mediaContent: MediaContent) => {
              return { contentSettings: _templateProperties, mediaContent, defaultParameters };
            }),
            catchError(() => NULL_OBSERVABLE)
          )
        : NULL_OBSERVABLE;
    };
    const restOptions$ = (_template: Template) => {
      const contentSettings: ContentSettings = _template.templateProperties as ContentSettings;
      const assetId = contentSettings?.assetId;

      return assetId
        ? this.digitalAssetService.getAsset(assetId).pipe(
            tap(() => {
              this.isScreenSizeResponsive$.next(false);
            }),
            map((digitalAsset: DigitalAsset) => {
              return { contentSettings, digitalAsset };
            }),
            catchError(() => NULL_OBSERVABLE)
          )
        : NULL_OBSERVABLE;
    };

    return configuration?.mediaContentId ? configurationOptions$(template) : restOptions$(template);
  }
}
