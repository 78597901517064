import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { HomeComponent } from "./home";
import { CoreModule } from "@app/core";
// import { AdminModule} from './admin/admin.module';
import { NgbAccordionModule, NgbDropdownModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "./shared/shared.module";
import { LayoutsModule } from "./layouts/layouts.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { UIModule } from "./shared/ui/ui.module";
import { CallbackComponent } from "./account/callback.component";
import { SimplebarAngularModule } from "simplebar-angular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { QuickSetupIntegration } from "@app/pages/quick-setup/services/quick-setup-integration";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { CfsCommunicationPackModule } from "cfs-communication-pack";
import { environment } from "../environments/environment";

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    SharedModule,
    LayoutsModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgSelectModule,
    NgbProgressbarModule,
    SimplebarAngularModule,
    UIModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    CfsCommunicationPackModule.forRoot(environment.pubNubConfig)
  ],
  declarations: [AppComponent, HomeComponent, CallbackComponent],
  providers: [QuickSetupIntegration],
  bootstrap: [AppComponent]
})
export class AppModule {}
