import { TranslocoService } from "@ngneat/transloco";

export function onlyAllowedImages(width: number, height: number, transloco: TranslocoService) {
  return (file: Blob, done: Function) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const image = new Image();
      image.onload = () => {
        if (image.width === width && image.height === height) {
          done();
        } else {
          done(transloco.translate("common.error.onlyAllowedImages", { width, height }));
        }
      };
      image.onerror = () => {
        done(transloco.translate("common.error.invalidImage"));
      };
      image.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  };
}
