<div class="row" *transloco="let t; read: 'devices'">
  <div class="col-lg-12" *ngIf="isLoadPage">
    <div class="card" *transloco="let tc; read: 'common'">
      <div class="card-body card-form-content position-relative">
        <div class="row d-flex justify-content-center">
          <div class="page-title-text d-flex">
            <i class="ri-arrow-left-line page-title-icon mr-5" (click)="onGoToDevices()"> </i> {{ t(actionId) }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-6">
            <form *ngIf="formGroup" [formGroup]="formGroup" autocomplete="off">
              <div class="row">
                <!-- device name-->
                <div class="row form-group">
                  <label class="col-form-label"> {{ t("createPage.deviceName") }}<span class="text-danger">*</span> </label>

                  <div class="col-lg-10">
                    <input
                      class="form-control"
                      formControlName="deviceName"
                      required
                      type="text"
                      [class.is-invalid]="deviceNameControl.touched && deviceNameControl.errors"
                    />
                    <ng-container *ngIf="deviceNameControl.touched && deviceNameControl.errors">
                      <ng-container *ngIf="deviceNameControl.errors.required">
                        <div class="invalid-feedback">
                          {{ tc("error.required") }}
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

                <!-- device type-->
                <div class="row form-group">
                  <label class="col-form-label"> {{ t("createPage.deviceType") }}<span class="text-danger">*</span> </label>

                  <div class="col-lg-10">
                    <ng-select
                      formControlName="deviceType"
                      bindLabel="name"
                      bindValue="id"
                      [items]="deviceTypes"
                      [class.is-invalid]="deviceTypeControl.touched && deviceTypeControl.errors"
                      [clearable]="actionId === 'add'"
                    >
                    </ng-select>

                    <ng-container *ngIf="deviceTypeControl.touched && deviceTypeControl.errors">
                      <ng-container *ngIf="deviceTypeControl.errors.required">
                        <div class="invalid-feedback">
                          {{ tc("error.required") }}
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

                <!-- serial number -->
                <div class="row form-group">
                  <label class="col-form-label"> {{ t("createPage.serialNumber") }}<span class="text-danger">*</span> </label>

                  <div class="col-lg-10">
                    <input
                      class="form-control"
                      formControlName="serialNumber"
                      required
                      type="text"
                      [class.is-invalid]="serialNumberControl.touched && serialNumberControl.errors"
                    />
                    <ng-container *ngIf="serialNumberControl.touched && serialNumberControl.errors">
                      <ng-container *ngIf="serialNumberControl.errors.required">
                        <div class="invalid-feedback">
                          {{ tc("error.required") }}
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

                <!-- device status-->
                <div class="row form-group">
                  <label class="col-form-label"> {{ t("createPage.deviceStatus") }}<span class="text-danger">*</span> </label>

                  <div class="col-lg-10">
                    <ng-select
                      formControlName="deviceStatus"
                      bindLabel="name"
                      bindValue="id"
                      [items]="deviceStatuses"
                      [class.is-invalid]="deviceStatusControl.touched && deviceStatusControl.errors"
                      [clearable]="actionId === 'add'"
                    >
                    </ng-select>

                    <ng-container *ngIf="deviceStatusControl.touched && deviceStatusControl.errors">
                      <ng-container *ngIf="deviceStatusControl.errors.required">
                        <div class="invalid-feedback">
                          {{ tc("error.required") }}
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

                <!--                  // devices location:-->
                <div *ngIf="locations$ | async as locations" class="row form-group">
                  <label class="col-form-label"> {{ t("createPage.location") }}<span class="text-danger">*</span> </label>

                  <div class="col-lg-10">
                    <ng-select
                      formControlName="location"
                      bindLabel="companyName"
                      bindValue="id"
                      [items]="locations"
                      [class.is-invalid]="locationControl.touched && locationControl.errors"
                    >
                    </ng-select>

                    <ng-container *ngIf="locationControl.touched && locationControl.errors">
                      <ng-container *ngIf="locationControl.errors.required">
                        <div class="invalid-feedback">
                          {{ tc("error.required") }}
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row card-bottom position-absolute bottom-0 start-0">
          <div class="col-lg-12 mt-1">
            <button (click)="onGoToDevices()" class="btn btn-outline-light btn-card-action float-start">
              {{ tc("controls.cancel") }}
            </button>

            <button type="submit" [disabled]="formGroup?.invalid" (click)="onSubmit()" class="btn btn-primary btn-card-action float-end">
              {{ t(actionId) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
