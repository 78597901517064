<form [formGroup]="form" class="default-widget-modal" *transloco="let t; read: 'widgets.common'">
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <p class="text-muted font-size-14 mb-0">{{ t("marketAppSettings") }}</p>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div *ngIf="!loading; else loader">
    <div class="modal-body card-body pb-0">
      <div class="app-config-notice" *ngIf="!widgetSettings.configured && widgetSettingsJson && !isAdmin && !createMode">
        {{ t("appConfigNotice", { app: title }) }}
      </div>
      <fieldset>
        <!-- <fieldset [disabled]="readonly"></fieldset> -->
        <div class="" [ngSwitch]="widgetId">
          <div class="pb-1" *ngSwitchCase="widgetType.RequestReview">
            <app-delivery-method-form-control
              class="col-md-12"
              formControlName="deliveryMethod"
              [position]="'left'"
              [isHorizontal]="true"
              [value]="deliveryMethod"
            ></app-delivery-method-form-control>
            <app-request-review-widget-settings
              #widget
              [brand]="brand"
              [location]="location"
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-request-review-widget-settings>
          </div>

          <div class="pb-1" *ngSwitchCase="widgetType.EnrollLoyalty">
            <app-delivery-method-form-control
              class="col-md-12"
              formControlName="deliveryMethod"
              [position]="'left'"
              [isHorizontal]="true"
              [value]="deliveryMethod"
            ></app-delivery-method-form-control>
            <app-enroll-loyalty-widget-settings
              #widget
              formControlName="widgetSettings"
              [isConfiguredAdmin]="form.controls.configured.value"
              [location]="location"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
              [brand]="brand"
            ></app-enroll-loyalty-widget-settings>
          </div>

          <div class="pb-1" *ngSwitchCase="widgetType.AnniversaryClub">
            <app-anniversary-club-widget-settings
              #widget
              formControlName="widgetSettings"
              [brand]="brand"
              [location]="location"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-anniversary-club-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.ConnectSocialMedia">
            <app-connect-social-network-widget-settings
              #widget
              formControlName="widgetSettings"
              [brand]="brand"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-connect-social-network-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.SendPaymentLink">
            <app-send-payment-link-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-send-payment-link-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.DownloadMobileApp">
            <app-download-mobile-app-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-download-mobile-app-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.ReferralProgram">
            <app-referral-program-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-referral-program-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.SendSurvey">
            <app-send-survey-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-send-survey-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.ScheduleAppointment">
            <app-schedule-appointment-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-schedule-appointment-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.AddPromo">
            <app-add-promo-widget-settings
              #widget
              formControlName="widgetSettings"
              [brand]="brand"
              [location]="location"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-add-promo-widget-settings>
          </div>

          <div class="mt-3" *ngSwitchCase="widgetType.IssueCoupon">
            <div class="issue-content"></div>
            <app-delivery-method-form-control
              class="col-md-12"
              formControlName="deliveryMethod"
              [isHorizontal]="true"
              [value]="deliveryMethod"
            ></app-delivery-method-form-control>
            <app-issue-coupon-widget-settings
              #widget
              [brand]="brand"
              [location]="location"
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-issue-coupon-widget-settings>
          </div>

          <div class="pb-1" *ngSwitchCase="widgetType.SetReminder">
            <app-set-reminder-widget-settings
              #widget
              formControlName="widgetSettings"
              [brand]="brand"
              [location]="location"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-set-reminder-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.SendGiftCard">
            <app-send-gift-card-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-send-gift-card-widget-settings>
          </div>
          <div class="pb-1" *ngSwitchCase="widgetType.Waitlist">
            <app-add-to-waitlist-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-add-to-waitlist-widget-settings>
          </div>

          <div class="pb-1" *ngSwitchCase="widgetType.SeeMenu">
            <app-see-menu-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            ></app-see-menu-widget-settings>
          </div>

          <div class="pb-1" *ngSwitchCase="widgetType.SmartPage">
            <app-delivery-method-form-control
              class="col-md-12"
              formControlName="deliveryMethod"
              [position]="'left'"
              [isHorizontal]="true"
              [value]="deliveryMethod"
            >
            </app-delivery-method-form-control>

            <app-smart-page-widget-settings
              #widget
              formControlName="widgetSettings"
              [widgetsInThePlan]="widgetsInThePlan"
              [createMode]="createMode"
              [location]="location"
              [brand]="brand"
              [widgetSettingsJson]="widgetSettingsJson"
              (widgetSettingsReady)="setWidgetSettingsValidation()"
            >
            </app-smart-page-widget-settings>
          </div>

          <div *ngSwitchDefault></div>
        </div>

        <div class="row mt-3">
          <app-delivery-method-form-control
            class="col-md-6"
            *ngIf="
              widgetId != widgetType.IssueCoupon &&
              widgetId != widgetType.RequestReview &&
              widgetId != widgetType.EnrollLoyalty &&
              widgetId != widgetType.SmartPage
            "
            formControlName="deliveryMethod"
            [value]="deliveryMethod"
          >
          </app-delivery-method-form-control>

          <div *ngIf="page && widgetId != widgetType.RequestReview && widgetId != widgetType.SmartPage" class="col-md-6">
            <div>
              <h5>{{ title + " " + t("templateLayout") }}</h5>

              <app-thumbnail-preview
                [pageId]="page.id"
                [brandId]="brand && location ? brand.id : null"
                [locationId]="brand && location ? location.id : null"
              ></app-thumbnail-preview>
            </div>
          </div>
        </div>
      </fieldset>

      <div *ngIf="isAdmin">
        <hr class="m-0" />
        <app-checkbox
          class="mt-3 ms-2 d-flex"
          [control]="form.controls.configured"
          [inlineLabel]="t('adminConf')"
          [tooltip]="widgetId === widgetType.EnrollLoyalty ? t('adminConfTooltip') : ''"
          tooltipTriggers="hover"
        ></app-checkbox>
      </div>
    </div>

    <div class="modal-footer" *transloco="let tcc; read: 'common.controls'">
      <div class="card-body row px-2 g-3">
        <div class="col-6" *ngIf="page">
          <button
            class="btn btn-outline w-100 btn-modal-footer"
            [class.disabled]="loading || (!isValid && !createMode)"
            (click)="previewPage()"
          >
            {{ createMode ? tcc("preview") : tcc("applyPreview") }} <sup><i class="fa fa-external-link-alt"></i></sup>
          </button>
        </div>

        <div class="col-6" *ngIf="!page">
          <button type="button" class="btn btn-outline w-100 btn-modal-footer" (click)="dismiss()">
            <i class="fa-lg mdi mdi-cancel"></i>
            {{ tcc("cancel") }}
          </button>
        </div>

        <div class="col-6 text-end">
          <button
            type="button"
            class="btn btn-primary btn-modal-footer w-100 btn-modal-footer"
            [class.disabled]="loading || !isValid"
            (click)="save()"
          >
            {{ tcc("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #loader>
  <app-spinner [isBlock]="true" [loading]="loading"></app-spinner>
</ng-template>
