import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { ColorPickerData } from "@app/shared/form-controls/colors-form-control/color-picker/color-picker.data";

@Component({
  selector: "app-colors-form-control",
  templateUrl: "./colors-form-control.component.html",
  styleUrls: ["./colors-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorsFormControlComponent),
      multi: true
    }
  ]
})
export class ColorsFormControlComponent implements OnInit, ControlValueAccessor {
  readonly whiteColor = ColorPickerData.whiteColor;

  private _color: string;
  formControl = new UntypedFormControl("#FFFFF");
  set color(value: string) {
    this._color = ColorPickerData.isItGradient(value) ? value : value?.toUpperCase();
  }

  get color(): string {
    return this._color;
  }

  @Input() colorPickerType: "color" | "predefined" = "color";

  @Input() set value(value: string) {
    this.formControl.setValue(value);
    this.color = value;
    this.onChange(value);
    this.onTouched();
  }

  get value() {
    return this.formControl.value;
  }

  @Input() placeholder = "";
  @Input() tooltip = "";

  ngOnInit(): void {}

  onTouched: any = () => null;

  onChange: any = () => null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  writeValue(obj: any): void {
    this.value = obj;
  }

  valueChanged() {
    if (this.color !== "#ff0000") this.value = this.color;
  }

  customValueChanged(color: string) {
    this.color = color;

    this.valueChanged();
  }
}
