import { Injectable } from "@angular/core";
import { Category } from "@app/models";
import { Observable } from "rxjs";
import { DataService } from "@app/core";

@Injectable({
  providedIn: "root"
})
export class CategoryService {
  private serviceUrl = "category";

  constructor(private http: DataService) {}

  getCategories(): Observable<Array<Category>> {
    return this.http.get(`${this.serviceUrl}`, {});
  }
}
