import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { ApplicationStyles } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ApplicationStylesService {
  private serviceUrl = "applicationStyles";
  constructor(private http: DataService) {}

  getById(id: number): Observable<ApplicationStyles> {
    return this.http.get(`${this.serviceUrl}/` + id);
  }
  getDefault(): Observable<ApplicationStyles> {
    return this.http.get(`${this.serviceUrl}/default`);
  }
  getByPartnerId(partnerId: number): Observable<ApplicationStyles> {
    return this.http.get(`${this.serviceUrl}/partner/` + partnerId);
  }
  getByBrandId(brandId: number): Observable<ApplicationStyles> {
    return this.http.get(`${this.serviceUrl}/brand/${brandId}`);
  }

  add(applicationStyles: ApplicationStyles): Observable<ApplicationStyles> {
    return this.http.post(`${this.serviceUrl}`, applicationStyles);
  }

  update(id: number, applicationStyles: ApplicationStyles): Observable<ApplicationStyles> {
    return this.http.put(`${this.serviceUrl}/${id}`, applicationStyles);
  }

  updateBrandStyles(brandId: number, styles: ApplicationStyles): Observable<ApplicationStyles> {
    return this.http.put(`${this.serviceUrl}/brand/${brandId}`, styles);
  }

  delete(id: number): Observable<void> {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }
}
