import { ApplicationStyles, BrandColorPalette } from "./brand-color-palette";
import { DigitalAsset } from "./digital-asset";
import { KeyValueAsset } from "./key-value";
import { Location } from "./location";
import { MediaContent } from "./media-content";
import { DisplayQrCodeLogoTypes } from "@app/models/enums";

export enum DefaultTemplateType {
  None = 0,
  Cfs = 1,
  Print = 2,
  CfsAndPrint = 3
}

export enum GlobalTemplateType {
  System = "System",
  Custom = "Custom",
  Global = "Global"
}

export enum TemplateInfoEnum {
  imageForCFSId = 17,
  cfsTemplateName = "Editable CFS Template",
  cfsQrCodeTemplateName = "QR Code Editable CFS Template",
  minUrlLength = 10,
  maxUrlLength = 800
}

export class LocationTemplate {
  locationId: number;
  canEnable: boolean;
  enabled: boolean;
}

export class TemplateDto {
  name?: string;
  templateType?: DefaultTemplateType;
  templateProperties?: TemplateProperties;
  enabled: boolean; // need delete
  useOnCfs?: boolean;
  useOnPos?: boolean;
}

export class TemplatePostDto extends TemplateDto {
  defaultTemplateId: number;
  order?: number;
  configuration?: TemplateConfigurationDto;
}

export class TemplatePutDto extends TemplateDto {
  id?: number;
  order: string;
  configuration?: TemplateConfigurationDto;
}

export interface GeneratedTemplateGetDto extends Template {
  templateContentTypeId: number;
}

export class Template {
  id?: number;
  name: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId?: number;
  templateType?: DefaultTemplateType;
  isDefault?: boolean;
  canEnable?: boolean; // need delete
  enabled: boolean; // need delete
  templateProperties?: TemplateProperties;
  generatedBody?: string;
  industryId?: number;
  industryName?: string;
  fullName?: string;
  templateTypeName?: string;
  marketingChannel?: string;
  useOnCfs?: boolean;
  useOnPos?: boolean;
  activeLocationCount?: number;
  locationTemplates?: LocationTemplate[];
  globalTemplateType?: GlobalTemplateType;
  accountIds?: number[];
  useForAllAccounts?: boolean;
  activeAccountsCount?: number;
  activeAccountsCountGlobalTypeView?: string;
  configuration?: TemplateConfigurationDto;
  errors?: TemplateValidationErrorDto[];
  applicationStyles?: ApplicationStyles;
  displayTypeIds?: number[];
  useAllDisplayTypes?: boolean;
}

export interface ContentSettings {
  assetId: number;
  isPredefined: boolean;
  templateCategory: string;
  displayContent: string;
  backgroundColor: string;
  buttonColor: string;
  campaignSchedule: string;
}

export interface QrCodeContentSettings {
  backgroundColor?: string;
  backgroundImage?: string;
  logoUrl?: string;
  imageUrl?: string;
  qrCodePrimaryColor?: string;
  qrCodeSecondaryColor?: string;
  qrCodeBackground?: string;
  qrCodeHasFrame?: boolean;
  qrCodeLogo?: string;
  qrCodeLogoType?: DisplayQrCodeLogoTypes;
  qrCodeUrl?: string;
  buttonText?: string;
  buttonWidth?: number;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  qrActionText?: string;
  qrActionColor?: string;
  subtitleText?: string;
  subtitleColor?: string;
  titleText?: string;
  titleColor?: string;
}

export interface CouponContentSettings {
  CouponUrl: string;
  CouponName: string;
  CouponId: string;
}
export interface TemplateExtendedProperties {
  LottieJson?: string;
  CouponName?: string;
  ShortLinkUrl?: string;
  LocationId?: string;
}
export interface UrlContentSettings {
  ImageUrl: string;
}

export type TemplateProperties = ContentSettings | UrlContentSettings | CouponContentSettings | TemplateExtendedProperties | null;

export class TemplateLocations {
  locationIds: number[];
}
export class GlobalTemplateAccounts {
  useForAllAccounts: boolean;
  accountIds: number[];
}

export interface TemplatesCarouselData {
  templateList?: Template[];
  brandColorsPalette?: BrandColorPalette;
  withPause?: boolean;
  updateOnNewCircle?: boolean;
}

export interface TemplatePreviewOptions {
  htmlBody?: string;
  previewTitle?: string;
  contentSettings?: ContentSettings;
  digitalAsset?: DigitalAsset;
  defaultParameters?: KeyValueAsset; // from template configuration
  mediaContent?: MediaContent;
}
export interface TemplatePreviewSelectableOptions extends TemplatePreviewOptions {
  id?: number; // id, depending on context - DigitalAsset.Id|Template.Id|...
}

export interface SystemTemplatePutDto {
  id: number;
  useOnCfs: boolean;
  useOnPos: boolean;
  displayTypeIds?: number[];
  useAllDisplayTypes: boolean;
  options: TemplateConfigurationOptionsDto;
}

export interface TemplateConfigurationDto {
  mediaContentId?: number;
  defaultParameters: KeyValueAsset;
  options?: TemplateConfigurationOptionsDto;
  triggers?: TemplateConfigurationTriggerDto[];
}
export interface TemplateConfigurationOptionsDto {
  defaultDestination?: TemplateDestinationDto;
  displayTime?: number;
  image: TemplateImageConfigurationDto;
  logo: TemplateLogoConfigurationDto;
}
export interface TemplateConfigurationTriggerDto {
  triggerType: TemplateConfigurationTriggerType;
  area: TemplateConfigurationTriggerAreaDto;
  action: TemplateConfigurationTriggerActionDto;
}
export interface TemplateConfigurationTriggerAreaDto {
  areaType: TemplateConfigurationTriggerAreaType;
}
export interface TemplateConfigurationTriggerActionDto {
  actionType: TemplateConfigurationTriggerActionType;
  destination: TemplateCustomDestinationDto;
}
export interface TemplateDestinationDto {
  destinationType: TemplateDestinationType;
  separateUrl?: boolean;
  url?: string;
  widgetType?: number;
  externalCouponId?: string;
}
export interface TemplateCustomDestinationDto {
  useDefaultDestination: boolean;
  customDestination?: TemplateDestinationDto;
}

export interface TemplateImageConfigurationDto {
  sourceType: TemplateImageSourceType;
}
export interface TemplateLogoConfigurationDto {
  sourceType: TemplateLogoSourceType;
  brandId?: number;
}

export interface TemplateValidationErrorDto {
  locationId?: number;
  location: Location;
  widgetId?: number;
  externalCouponId?: string;
  messageCode?: string;
}

export interface TemplateValidationResultDto {
  templateId: number;
  isGlobal: boolean;
  errors: TemplateValidationErrorDto[];
}

export interface TemplateAccountLocationsDto {
  accountId: number;
  useForAllLocations: boolean; // All Account Locations. If 'true' - 'LocationIds' property is ignored
  locationIds?: number[];
}

export interface TemplateAssignedAccountsDto {
  useForAllAccounts: boolean; // Is all Accounts assigned. If true - 'Accounts' property is ignored.
  accounts: TemplateAccountLocationsDto[];
}

export interface DisplayTypeDto {
  id: number;
  name?: string;
}

export enum TemplateConfigurationTriggerAreaType {
  Any = "Any"
}
export enum TemplateConfigurationTriggerType {
  None = "None",
  Click = "Click"
}
export enum TemplateConfigurationTriggerActionType {
  IframeNavigation = "IframeNavigation"
}

export enum CfsContentAsset {
  MainBackgroundColor = "%%MAIN_BG_COLOR%%",
  ButtonBackgroundColor = "%%BUTTON_BG_COLOR%%",
  MainBackgroundColorHex = "%%MAIN_BG_COLOR_HEX%%",
  ButtonBackgroundColorHex = "%%BUTTON_BG_COLOR_HEX%%",
  Param1 = "%%PARAM_1%%",
  Param2 = "%%PARAM_2%%",
  Param3 = "%%PARAM_3%%",
  Param4 = "%%PARAM_4%%",
  Param5 = "%%PARAM_5%%",
  Param6 = "%%PARAM_6%%"
}

export enum CfsContentSettingKeys {
  BackgroundColor = "backgroundColor",
  ButtonColor = "buttonColor",
  CampaignSchedule = "campaignSchedule",
  TemplateCategory = "templateCategory"
}

export enum CfsQrCodeContentAsset {
  backgroundColor = "%%BACKGROUND_COLOR%%",
  backgroundImage = "%%BACKGROUND_IMAGE%%",
  logoUrl = "%%LOGO_URL%%",
  imageUrl = "%%IMAGE_URL%%",
  qrCodePrimaryColor = "%%QR_CODE_PRIMARY_COLOR%%",
  qrCodeSecondaryColor = "%%QR_CODE_SECONDARY_COLOR%%",
  qrCodeBackground = "%%QR_CODE_BACKGROUND%%",
  qrCodeHasFrame = "%%QR_CODE_HAS_FRAME%%",
  qrCodeLogo = "%%QR_CODE_LOGO%%",
  qrCodeLogoType = "%%QR_CODE_LOGO_TYPE%%",
  qrCodeUrl = "%%QR_CODE_URL%%",
  buttonText = "%%BUTTON_TEXT%%",
  buttonWidth = '"%%BUTTON_WIDTH%%"',
  buttonTextColor = '"%%BUTTON_TEXT_COLOR%%"',
  buttonBackgroundColor = '"%%BUTTON_BACKGROUND_COLOR%%"',
  qrActionText = "%%QR_ACTION_TEXT%%",
  qrActionColor = '"%%QR_ACTION_COLOR%%"',
  subtitleText = "%%SUBTITLE_TEXT%%",
  subtitleColor = '"%%SUBTITLE_COLOR%%"',
  titleText = "%%TITLE_TEXT%%",
  titleColor = '"%%TITLE_COLOR%%"'
}

export enum TemplateDestinationType {
  Coupon = "Coupon",
  Url = "Url",
  Widget = "Widget"
}

export enum TemplateImageSourceType {
  None = "None",
  Default = "Default",
  Custom = "Custom"
}

export enum TemplateLogoSourceType {
  None = "None",
  Default = "Default",
  CustomBrand = "CustomBrand",
  Custom = "Custom"
}
