<div class="card">
  <div class="card-body">
    <ul *ngIf="navFilterList$ | async as navFilterList" class="nav nav-pills">
      <li *ngFor="let item of navFilterList" class="nav-item">
        <a class="nav-link cursor-pointer" [ngClass]="{ active: item.active }" (click)="filter(item)">
          {{ item.label }}
        </a>
      </li>
    </ul>
  </div>
</div>
