import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

enum QuantityInputAction {
  Increase = "+",
  Decrease = "-"
}

@Component({
  selector: "app-quantity-input",
  templateUrl: "./quantity-input.component.html",
  styleUrls: ["./quantity-input.component.scss"]
})
export class QuantityInputComponent {
  readonly QuantityInputAction = QuantityInputAction;

  @Input() control: FormControl;
  @Input() label: string;
  @Input() maxQuantity = 100_000;
  @Input() errorClassList = "";

  changeQuantityVal(action: QuantityInputAction) {
    if (action === QuantityInputAction.Increase) {
      this.control.setValue(+this.control.value + 1);
    } else {
      this.control.setValue(+this.control.value - 1);
    }
  }
}
