import { AfterViewChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from "@angular/forms";

@Component({
  selector: "app-zip-form-control",
  templateUrl: "./zip-form-control.component.html",
  styleUrls: ["./zip-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZipFormControlComponent),
      multi: true
    }
  ]
})
export class ZipFormControlComponent implements ControlValueAccessor, OnInit, AfterViewChecked {
  formControl = new UntypedFormControl("");
  @Input() disable = false;
  @Input() isCanada = false;
  @Input() label = "";
  public customPatterns = { "0": { pattern: new RegExp("[A-Za-z0-9]") } };

  @Input() set value(value: string) {
    this.formControl.setValue(value);
    this.onChange(value);
    this.onTouched();
    this.changeDetectorRef.markForCheck();
  }

  get value() {
    return this.formControl.value;
  }

  @Input() set validators(value: ValidatorFn[]) {
    this.formControl.setValidators(value);
  }
  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    if (this.isCanada != true) {
      this.formControl = new UntypedFormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(10)]);
    } else {
      this.formControl = new UntypedFormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
    }
  }

  onTouched: any = () => null;
  onChange: any = () => null;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  writeValue(obj: any): void {
    this.value = obj;
  }

  valueChanged() {
    if (this.isCanada) this.value = this.formControl.value.toUpperCase();
    else this.value = this.formControl.value;
  }

  ngAfterViewChecked() {
    this.setDisabledState(this.disable);
    this.changeDetectorRef.detectChanges();
  }

  onPaste() {
    setTimeout(() => {
      this.value = this.formControl.value;
    });
  }

  get validator() {
    const validator = this.formControl.validator({} as AbstractControl);

    if (validator && validator.required) {
      return true;
    }
  }
}
