<div class="default-widget-modal" *transloco="let tr; read: 'widgets.enrollLoyalty'">
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form" autocomplete="off">
      <lib-wizard
        #wizard
        [steps]="steps"
        [templates]="[step1 ,step2, step3, step4]"
        [options]="wizardOptions"
        (stepChange)="onStepChange($event)"
        (cancel)="closeModal()"
        (confirm)="saveResult()"
      ></lib-wizard>


      <ng-template #step1>
        <div class="row">
          <div class="col-md-12 text-start"></div>
        </div>
        <!--BEGIN TYPES CARD -->
        <div class="row" *ngIf="cardTypesSetting">
          <div class="col-md-4" *ngFor="let item of cardTypesSetting">
            <button
              class="btn btn-outline-light btn-card d-flex align-items-center"
              [ngbTooltip]="item.tooltip"
              tooltipClass="tooltip-card-type"
              (click)="setCardProgramTypesList(item.id)"
              [ngClass]="item.id === form.controls.loyaltyType.value ? 'active' : ''"
            >
              <i class="icon-{{ item.id }}"></i> {{ item.name }}
            </button>
          </div>
        </div>
        <!--END TYPES CARD -->
        <div class="row wizard-content-modal mt-4">
          <div class="col-md-12 text-start font-size-16 font-bold">Choose loyalty card style & layout</div>
          <app-loyalty-cards
            class="mt-n5"
            [loyaltyCards]="loyaltyCards"
            [selectedCard]="selectedCard"
            [loading]="loading"
            (selectChanged)="onSelectChanged($event)"
          >
          </app-loyalty-cards>
        </div>
      </ng-template>

      <ng-template #step2>
        <div class="wizard-content-modal p-lg-5" *ngIf="form.controls.loyaltyType.value > 0">
          <div class="col-xl-2 col-lg-12"></div>

          <lib-textarea
            class="col-md-12"
            [control]="form.controls.description"
            [label]="'common.controls.description' | transloco"
            [placeholder]="'common.controls.description' | transloco"
            [rows]="3">
          </lib-textarea>

          <div>
            <div class="row">
              <app-dropdown
                class="col-md-6 col-sm-12 text-start"
                bindLabel="name"
                bindValue="n"
                [control]="form.controls.goalBalance"
                [isRequired]="true"
                [items]="selectedCardTypeSetting.list"
                [label]="tr('labelGoalBalance')"
                [placeholder]="tr('labelGoalBalance')"
                (change)="changeSelectGoalBalance($event)"
              ></app-dropdown>

              <app-input
                class="col-md-6 col-sm-12"
                type="number"
                [control]="form.controls.maxPerDay"
                [label]="tr('labelMaxPerDayGeneric')"
                [isRequired]="true"
              ></app-input>

              <app-dropdown
                class="col-md-6 col-sm-12 text-start"
                bindLabel="name"
                bindValue="n"
                [control]="form.controls.startingStamps"
                [isRequired]="true"
                [items]="selectedCardTypeSetting.startingList"
                [label]="tr('labelStartingStamps')"
                [placeholder]="tr('labelStartingStamps')"
                (change)="changeSelectStartingStamps($event)"
              ></app-dropdown>

              <app-dropdown
                *ngIf="expirationPeriodOptions$ | async as items"
                class="col-md-6 col-sm-12 text-start"
                bindLabel="label"
                bindValue="value"
                [control]="form.controls.expirationPeriod"
                [isRequired]="true"
                [items]="items"
                [label]="expirationPeriodTitle"
                [placeholder]="expirationPeriodTitle"
                (change)="changeSelectExpirationPeriod($event)"
              ></app-dropdown>
            </div>
          </div>

          <div *ngIf="form.controls.loyaltyType.value !== LoyaltyCardTypes.Stampcard">
            <h5>{{ tr('labelCurrencyToDollars') }}</h5>
            <div class="mb-2">{{ tr('labelCurrencyToDollarsDescription') }}</div>

            <div class="col-md-6 col-sm-12">
              <div class="d-flex gap-2">
                <app-input
                  type="number"
                  [control]="form.controls.amount"
                  [label]="tr('labelDollars')"
                  [placeholder]="tr('labelDollars')"
                ></app-input>

                <div class="align-self-center">=</div>

                <app-input
                  type="number"
                  [control]="form.controls.count"
                  [label]="tr('labelPoints')"
                  [placeholder]="tr('labelPoints')"
                ></app-input>
              </div>
            </div>
          </div>

          <h5>{{ tr('labelMinPurchaseRequired') }}</h5>
          <div class="mb-2">{{ tr('labelMinPurchaseDescription') }}</div>

          <app-radio-group
            formControlName="minimumRequirementMode"
            itemClassList="form-check mb-3 me-3 col-md-6 col-sm-12"
            groupName="minimumRequirementMode"
            [items]="minReqRadioButtons"
          ></app-radio-group>

          <div class="row mb-3">
            <app-input
              class="col-md-6 col-sm-12"
              type="number"
              [control]="form.controls.minimumAmount"
              [placeholder]="tr('labelAmount')"
            ></app-input>

            <app-input
              class="col-md-6 col-sm-12"
              type="number"
              [control]="form.controls.minimumCount"
              [placeholder]="tr('labelCount')"
            ></app-input>
          </div>
        </div>
      </ng-template>

      <ng-template #step3>
        <app-reward-settings
          *ngIf="wizard.selectedIndex === 2"
          [goalBalance]="form.controls.goalBalance.value"
          [locationId]="locationId"
          [industryId]="industryId"
          [selectedCardTypeSetting]="selectedCardTypeSetting"
          [selectedCard]="selectedCard"
          [rewardsList]="rewardsList"
          [loyaltyProgramDetailsResult]="loyaltyProgramDetailsResult"
          (rewardsUpdate)="onRewardsUpdate($event)"
        ></app-reward-settings>
      </ng-template>

      <ng-template #step4>
        <div class="row wizard-content-modal mt-4">
          <app-loyalty-program-details
            *ngIf="wizard.selectedIndex === 3 && loyaltyProgramDetailsResult"
            [enrollLoyaltyLink]="enrollLoyaltyLink"
            [rewardsList]="rewardsList"
            [selectedCard]="selectedCard"
            [loyaltyProgramDetailsResult]="loyaltyProgramDetailsResult"
            [selectedCardTypeSetting]="selectedCardTypeSetting"
            [expirationPeriodLabel]="expirationPeriodTitle"
          ></app-loyalty-program-details>
        </div>
      </ng-template>
    </form>
  </div>

  <div class="modal-footer" *transloco="let tcc; read: 'common.controls'"></div>
</div>
