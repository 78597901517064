<div class="">
  <ng-content></ng-content>
  <div class="">
    <app-upload-button
      [disabled]="disabled"
      [visible]="uploadButtonVisible"
      [hasCropper]="hasCropper"
      [aspectRatio]="aspectRatio"
      [storageFolder]="storageFolder"
      (fileUrl)="onImageLoaded($event)">
    </app-upload-button>
  </div>
</div>
