import { ControlValueAccessor, UntypedFormGroup, Validator } from "@angular/forms";
import { Subscription } from "rxjs";

export class WidgetSettingsBaseComponent implements ControlValueAccessor, Validator {
  onChangeSubs: Subscription[] = [];
  form: UntypedFormGroup;
  get fc() {
    return this.form.controls;
  }
  constructor() {}

  addControlErrors(allErrors: any, controlName: string) {
    const errors = { ...allErrors };

    const controlErrors = this.form.controls[controlName].errors;

    if (controlErrors) {
      errors[controlName] = controlErrors;
    }

    return errors;
  }

  onTouched: any = () => null;

  onChange: any = () => null;

  registerOnChange(fn: any): void {
    const sub = this.form.valueChanges.subscribe(fn);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      this.form.setValue(obj, { emitEvent: false });
    }
  }

  validate() {
    if (this.form.valid) {
      return null;
    }

    let errors: any = {};
    Object.keys(this.form.controls).forEach((key) => {
      errors = this.addControlErrors(errors, key);
    });

    return errors;
  }
}
export interface IIsKioskEnabled {
  IsKioskEnabled: boolean;
}
