<div  class="form-group position-relative" *ngIf="control">
  <label *ngIf="label" class="col-form-label">
    {{ label }}<span *ngIf="isRequired" class="text-danger"> *</span>
  </label>
  <ng-select
    [formControl]="control"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [class.is-invalid]="control.touched && !control.valid"
    [clearable]="clearable"
    [closeOnSelect]="closeOnSelect"
    [items]="items"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [multiple]="multiple"
    [searchable]="searchable"
    [dropdownPosition]="dropdownPosition"
    [appendTo]="appendOptionsToBody ? 'body' : null"
    (change)="change.emit($event)"
  >
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <span class="ng-value-label">{{ item[this.bindLabel] }}</span>

      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
        <img alt="" src="assets/images/close.svg" width="16" height="16" />
      </span>
    </ng-template>
  </ng-select>
  <app-form-field-error *ngIf="control.touched && !control.valid" [errors]="control.errors"></app-form-field-error>
</div>
