<div *transloco="let t; read: 'colorPicker'" class="mb-3 row">
  <div class="d-flex align-items-center">
    <label [for]="'example-color-input-' + color" class="col-form-label">{{ placeholder }}</label>
    <i *ngIf="tooltip" [ngbTooltip]="tooltip" class="bx bx-question-mark text-info font-size-18"></i>
  </div>

  <div class="col-md-12">
    <input
      *ngIf="colorPickerType === 'color'"
      [id]="'example-color-input-' + color"
      (colorPickerSelect)="valueChanged()"
      [cpOutputFormat]="'hex'"
      [value]="color"
      [placeholder]="placeholder || t('colorPlaceholder')"
      [formControl]="formControl"
      [(colorPicker)]="color"
      class="form-control cursor-pointer"
      [cpPosition]="'auto'"
    />

    <input
      *ngIf="colorPickerType === 'predefined'"
      appCustomColorPicker
      [id]="'extended-color-input-' + color"
      [value]="color"
      [placeholder]="placeholder || t('colorPlaceholder')"
      [formControl]="formControl"
      class="form-control cursor-pointer"
      (valueSelected)="customValueChanged($event)"
    />

    <div class="selected-color border-2 border-soft border-secondary mx-1">
      <div [style.background]="color" class="color-container" [class.color-with-border]="color === whiteColor"></div>
    </div>
  </div>
</div>
