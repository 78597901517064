import { Injectable } from "@angular/core";
import { Subscription, AccountSubscription } from "@app/models";
import { Location } from "@app/models";
import { BehaviorSubject, Observable } from "rxjs";
import { DataService } from "../core/services/data.service";

@Injectable({
  providedIn: "root"
})
export class CompanyDataService {
  private serviceUrl = "company";

  constructor(private http: DataService) {}

  public getSubscription(): Observable<Subscription> {
    return this.http.get(`${this.serviceUrl}/subscription`, {});
  }
  public getAccountSubscription(): Observable<AccountSubscription> {
    return this.http.get(`${this.serviceUrl}/accountSubscription`, {});
  }
  getLocations(): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/locations`);
  }
}
