<div *ngIf="control" class="form-check">
  <input
    type="radio"
    [id]="id"
    [name]="name"
    class="form-check-input"
    [formControl]="control"
    [value]="value"
  />
  <label class="form-check-label" [for]="id">
    {{ label | transloco }}
  </label>
</div>
