<div class="d-flex flex-row justify-content-between" *transloco="let t; read: 'users'" [formGroup]="form">
  <app-input
    type="{{ pincodeHidden ? 'password' : 'text' }}"
    icon="mdi cursor-pointer {{ pincodeHidden ? 'mdi-eye-off-outline' : 'mdi-eye' }}"
    [control]="form.controls.pincode"
    [isRequired]="false"
    [label]="t('pincode')"
    [placeholder]="t('pincode')"
    [clickableIconWhenDisabled]="true"
    (iconClick)="toggleIcon()"
  ></app-input>

  <div class="d-flex flex-row button-margin ms-2">
    <button
      class="btn btn-card-action btn-outline btn-outline-primary h-40 text-nowrap"
      [disabled]="!(canSendPincode$ | async)"
      (click)="sendPincode()"
    >
      {{ t("pincodeSend") }}
    </button>

    <button
      class="btn btn-card-action btn-outline btn-outline-primary h-40 text-nowrap ms-2"
      [disabled]="!(canGeneratePincode$ | async)"
      (click)="generatePincode()"
    >
      {{ t("pincodeReset") }}
    </button>
  </div>
</div>
