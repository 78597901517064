import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { DisplayTypeDto, SystemTemplatePutDto, Template, TemplateAssignedAccountsDto, TemplateValidationResultDto } from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TemplateService {
  constructor(private http: DataService) {}

  serviceUrl = "template";

  get(templateId: number): Observable<Template> {
    return this.http.get(`${this.serviceUrl}/${templateId}`);
  }

  getAssignedAccounts(templateId: number, isGlobal: boolean): Observable<TemplateAssignedAccountsDto> {
    return this.http.get(`${this.serviceUrl}/${templateId}/assigned/accounts?isGlobal=${isGlobal}`);
  }

  assignAccounts(templateId: number, isGlobal: boolean, data: TemplateAssignedAccountsDto): Observable<void> {
    return this.http.post(`${this.serviceUrl}/${templateId}/assigned/accounts?isGlobal=${isGlobal}`, data);
  }

  getGlobal(templateId: number): Observable<Template> {
    return this.http.get(`${this.serviceUrl}/global/${templateId}`);
  }

  getAll(locationId: number): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/bylocation/${locationId}`);
  }

  getAllByAccount(accountId: number): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/byaccount/${accountId}`);
  }
  getAllGlobal(): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/global`);
  }

  getFilteredAccountTemplates(locationId: number): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/filter/${locationId}`);
  }

  getCfsItems(locationId: number): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/cfs/${locationId}`);
  }

  getGenerated(templateId: number, locationId: number | null): Observable<Template> {
    return this.http.get(`${this.serviceUrl}/generated/${templateId}?locationId=${locationId}`);
  }

  getGeneratedGlobal(templateId: number, locationId: number | null): Observable<Template> {
    return this.http.get(`${this.serviceUrl}/generated-global/${templateId}?locationId=${locationId}`);
  }

  getDisplayTypes(): Observable<DisplayTypeDto[]> {
    return this.http.get(`${this.serviceUrl}/displayTypes`);
  }

  getConfigurable(accountId?: number): Observable<Template[]> {
    const url = accountId != null ? `${this.serviceUrl}/configurable/${accountId}` : `${this.serviceUrl}/configurable`;
    return this.http.get(url).pipe(
      map((x: Template[]) => {
        TemplateService.setTemplatesFullName(x);
        return x;
      })
    );
  }

  getTypes(): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/types`).pipe(
      map((x: Template[]) => {
        TemplateService.setTemplatesFullName(x);
        return x;
      })
    );
  }

  listGlobal(): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/global`).pipe(
      map((x: Template[]) => {
        TemplateService.setTemplatesFullName(x);
        return x;
      })
    );
  }

  getContentByUrl(url: string): Observable<string> {
    return this.http.getTextByFullUrl(url);
  }

  addTemplate(accountId: number, template: Template): Observable<Template> {
    return this.http.post(`${this.serviceUrl}/${accountId}`, template);
  }
  addGlobalTemplate(template: Template): Observable<Template> {
    return this.http.post(`${this.serviceUrl}/global`, template);
  }

  updateTemplate(template: Template): Observable<number> {
    return this.http.put(`${this.serviceUrl}/${template.id}`, template);
  }
  updateGlobalTemplate(template: Template): Observable<number> {
    return this.http.put(`${this.serviceUrl}/global`, template);
  }
  updateSystemTemplate(template: SystemTemplatePutDto): Observable<void> {
    return this.http.put(`${this.serviceUrl}/system`, template);
  }

  deleteTemplate(templateId: number) {
    return this.http.delete(`${this.serviceUrl}/${templateId}`);
  }
  deleteGlobalTemplate(templateId: number) {
    return this.http.delete(`${this.serviceUrl}/global/${templateId}`);
  }

  enableTemplate(templateId: number) {
    return this.http.post(`${this.serviceUrl}/${templateId}/enable`);
  }

  disableTemplate(templateId: number) {
    return this.http.post(`${this.serviceUrl}/${templateId}/disable`);
  }

  validateTemplates(): Observable<TemplateValidationResultDto[]> {
    return this.http.get(`${this.serviceUrl}/validate`);
  }

  private static setTemplatesFullName(templates: Template[]): Template[] {
    templates.forEach((x) => {
      x.fullName = TemplateService.getTemplateFullName(x);
    });
    return templates;
  }
  private static getTemplateFullName(template: Template): string {
    const fullNameIndustryPart: string = template.industryName ? ` (${template.industryName})` : "";
    return `${template.name}${fullNameIndustryPart}`;
  }
}
