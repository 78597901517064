<form [formGroup]="form" *transloco="let t; read: 'widgets.requestReview'">
  <div class="row" *ngIf="isLoad">
    <div class="col-md-12">
      <ngb-alert type="warning" class="text-center" *ngIf="form.errors?.isEmpty" [dismissible]="false">
        {{ t("messageRequestReviewNotConfigured") }}
      </ngb-alert>
      <ngb-alert type="warning" class="text-center" *ngIf="form.errors?.isExceeded" [dismissible]="false">
        {{ t("messageRequestReviewLimitExceeded") }}
      </ngb-alert>
      <div class="font-size-16 font-bold mb-3">{{ t("limitMessage", { limitReviewRequest: limitReviewRequest }) }}</div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <app-url-form-control [label]="t('google')" formControlName="reviewGoogle" [validators]="linksVaidators"></app-url-form-control>
        </div>
        <div class="col-md-6 mb-3">
          <app-url-form-control [label]="t('yelp')" formControlName="reviewYelp" [validators]="linksVaidators"></app-url-form-control>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <!-- BEGIN REVIEW SOURCE -->
      <ng-container formArrayName="reviewSources" class="review-source">
        <ng-container *ngFor="let item of reviewSources.controls; let i = index">
          <div class="row mb-3" [formGroupName]="i">
            <div class="col-6 text-start">
              <label class="col-form-label position-relative">
                {{ t("labelLink") }}{{ i + 3 }}

                <div
                  *transloco="let t; read: 'common.error'"
                  class="font-size-18 widget-icon-tooltip"
                  [ngbTooltip]="t('secureUrl', { field: 'Link#' + (i + 3) })"
                  #tt="ngbTooltip"
                  container="body"
                  (mouseout)="tt.close()"
                  triggers="click:blur"
                >
                  <i class="bx bx-question-mark mb-0 text-info"></i>
                </div>
              </label>
            </div>

            <div class="col-6 text-end">
              <button type="button" (click)="deleteReviewSource(i)" class="btn btn-link text-danger">
                {{ t("btnRemove") }}
              </button>
            </div>
            <div class="d-flex justify-content-start">
              <div class="col-5">
                <input type="text" class="form-control" id="code" formControlName="code" [value]="item.code" hidden />
                <div class="dropdown" ngbDropdown *ngIf="reviewSources">
                  <button
                    class="btn btn-outline btn-light dropdown-toggle btn-link-select"
                    type="button"
                    id="dropdownMenuButton"
                    (click)="showList()"
                    ngbDropdownToggle
                  >
                    <span class="float-start font-bold"
                      >&nbsp; {{ getNameByCode(reviewSources.value[i].code) || t("selectReviewSource") }}
                    </span>
                    <i class="mdi mdi-chevron-down font-size-18 float-end"></i>
                  </button>
                  <div class="dropdown-menu mt-1" ngbDropdownMenu>
                    <div class="has-search p-2">
                      <input
                        type="text"
                        name="search-{{ i }}"
                        [value]="search"
                        class="form-control"
                        placeholder="Search"
                        (focusout)="changeSearch($event.target.value)"
                        (keyup)="changeSearch($event.target.value)"
                      />
                      <span class="fa fa-search font-size-18 mt-3 form-control-feedback"></span>
                    </div>
                    <a
                      class="dropdown-item"
                      href="javascript: void(0);"
                      (click)="selectedReviewSourceItem(el.code, i)"
                      *ngFor="let el of notOtherReviewSourceList; let j = index"
                    >
                      <div [innerHTML]="el.name | highlight : search"></div>
                    </a>
                    <div class="dropdown-divider" *ngIf="notOtherReviewSourceList.length > 0 && otherReviewSourceList.length > 0"></div>

                    <a
                      class="dropdown-item"
                      href="javascript: void(0);"
                      (click)="selectedReviewSourceItem(el.code, i)"
                      *ngFor="let el of otherReviewSourceList; let j = index"
                    >
                      <div [innerHTML]="el.name | highlight : search"></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-7">
                <app-url-form-control
                  placeholder="{{ t('labelLink') }}{{ i + 3 }}"
                  formControlName="url"
                  class="border-radius-left-none"
                  [validators]="linksVaidators"
                ></app-url-form-control>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-12" *ngIf="fc.reviewSources.errors?.isDublicate">
          <div class="alert alert-danger mt-3 mb-3" role="alert">
            <img src="assets/images/error-icon.svg" width="16" alt="" class="mx-2" /> {{ t("errorMessage") }}
          </div>
        </div>

        <button
          type="button"
          class="btn btn-light w-100 h-40 font-bold mt-3"
          (click)="addNewReviewSource()"
          [class.disabled]="isDisableAddBtn"
        >
          <i class="ri-add-line"></i> {{ t("btnAdd") }}
        </button>

        <!--  <button
          type="submit"
          class="btn btn-primary mt-5"
          [disabled]="form.invalid"
        >
          Submit
        </button>-->
        <pre hidden>{{ form?.value | json }}</pre>
      </ng-container>

      <!-- END REVIEW SOURCE -->
    </div>
  </div>
</form>
