<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
<dropzone
  (sending)="onSending()"
  class="dropzone-container"
  [config]="config"
  fxLayout="row"
  fxLayoutAlign="start strech"
  fxFlex="auto"
  [message]="translateSvc.translateObject('common.file.fileUpload')"
  (error)="onUploadError($event)"
  (success)="onUploadSuccess($event)"
>
  <a *ngIf="result" class="dz-remove" (click)="resetDropzoneUploads()" data-dz-remove="">{{
    translateSvc.translateObject("common.file.remove")
  }}</a>
</dropzone>
