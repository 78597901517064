import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from "@angular/forms";
import { Patterns } from "@app/shared/validators";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { SendGiftCardWidgetSettings } from "@app/pages/components/widget-settings/send-gift-card-widget-settings/send-gift-card-widget-settings";

@Component({
  selector: "app-send-gift-card-widget-settings",
  templateUrl: "./send-gift-card-widget-settings.component.html",
  styleUrls: ["./send-gift-card-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SendGiftCardWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SendGiftCardWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class SendGiftCardWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private _widget: SendGiftCardWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.requestReviewWidgetSetting = JSON.parse(value) as SendGiftCardWidgetSettings;
  }

  public set requestReviewWidgetSetting(value: SendGiftCardWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls.sendGiftCardUrl.setValue(value.sendGiftCardUrl);
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }
  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      sendGiftCardUrl: new UntypedFormControl("", [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)])
    });
    this.widgetSettingsReady.emit();
  }
}
