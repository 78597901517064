import { Injectable } from "@angular/core";
import { CfsPutDto } from "@app/models";
import { DataService } from "@app/core/services/data.service";
import { Observable } from "rxjs";
import {
  CashRegister,
  CashRegisterPairingStatusPostDto,
  CashRegisterStatusGetResponseDto,
  CashRegisterStatusPostDto
} from "cfs-communication-pack";

@Injectable({ providedIn: "root" })
export class CashRegisterService {
  serviceUrl = "cashregister";

  constructor(private http: DataService) {}

  getById(id: number): Observable<CashRegister> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  getAll(locationIds: number[]): Observable<CashRegister[]> {
    return this.http.post(`${this.serviceUrl}/bylocation`, { locationIds: locationIds });
  }

  addCashRegister(locationId: number, cashregister: CashRegister): Observable<CashRegister> {
    return this.http.post(`${this.serviceUrl}/${locationId}`, cashregister);
  }

  updateCashRegister(cashregister: CashRegister): Observable<CashRegister> {
    return this.http.put(`${this.serviceUrl}/${cashregister.id}`, cashregister);
  }

  deleteCashRegister(id: number) {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  addCfs(id: number, cfs: CfsPutDto): Observable<CashRegister> {
    return this.http.put(`${this.serviceUrl}/cfs/${id}`, cfs);
  }

  getCashRegisterStatuses(ids: number[]): Observable<CashRegisterStatusGetResponseDto[]> {
    const query = ids?.length ? ids.map((id) => `cashRegisterIds=${id}`).join("&") : "";

    return this.http.get(`${this.serviceUrl}/status?${query}`);
  }

  getCashRegisterStatus(id: number): Observable<CashRegisterStatusGetResponseDto> {
    return this.http.get(`${this.serviceUrl}/${id}/status`);
  }

  setCashRegisterStatus(id: number, data: CashRegisterStatusPostDto): Observable<null> {
    return this.http.post(`${this.serviceUrl}/${id}/status`, data);
  }

  setCashRegisterPairingStatus(id: number, data: CashRegisterPairingStatusPostDto): Observable<null> {
    return this.http.post(`${this.serviceUrl}/${id}/status/pairing`, data);
  }
}
