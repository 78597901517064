<div *ngIf="!iconInside">
  <label *ngIf="label" class="col-form-label position-relative"
    >{{ label }}<span class="text-danger" *ngIf="mark">*</span>
    <div
      *transloco="let t; read: 'common.error'"
      class="font-size-18 widget-icon-tooltip"
      [ngbTooltip]="t('secureUrl', { field: label })"
      #tt="ngbTooltip"
      container="body"
      (mouseout)="tt.close()"
      triggers="click:blur"
    >
      <i class="bx bx-question-mark mb-0 text-info" *ngIf="!isHideTooltip"></i>
    </div>
  </label>
  <div *transloco="let t; read: 'common.error'" class="social-source justify-content-between" [class.d-flex]="icon">
    <a *ngIf="isLinked" href="{{ placeholder }}" target="_blank" class="col-sm-11">
      <div [class.cursor-pointer]="placeholder" class="form-control div-form-control">{{ placeholder }}</div>
    </a>
    <div *ngIf="!isLinked && icon" class="avatar-xs col-sm-2">
      <span class="avatar-title rounded-circle {{ iconColor }} font-size-16">
        <i class="{{ icon }}"></i>
      </span>
    </div>

    <div [class.col-sm-10]="icon" *ngIf="!isLinked">
      <input
        [class.input-group-item]="inputGroup"
        (paste)="onPaste()"
        [urlOnly]="formControl"
        (keyup)="valueChanged()"
        [placeholder]="placeholder"
        [formControl]="formControl"
        class="form-control"
        type="text"
        (blur)="onTouched()"
        [class.is-invalid]="formControl.touched && formControl.errors"
      />
      <ng-container *ngIf="formControl.touched && formControl.errors">
        <ng-container *ngIf="formControl.errors.required">
          <div class="invalid-feedback position-absolute">
            {{ t("required") }}
          </div>
        </ng-container>
        <ng-container *ngIf="formControl.errors.pattern">
          <div class="invalid-feedback position-absolute">
            {{ t("invalidUrl") }}
          </div>
        </ng-container>
        <ng-container *ngIf="formControl.errors.emptyLinks">
          <div class="invalid-feedback position-absolute">
            {{ t("emptyLinks") }}
          </div>
        </ng-container>
        <ng-container *ngIf="(formControl.errors.maxlength || formControl.errors.minlength) && !formControl.errors.pattern">
          <div class="invalid-feedback">
            {{ t("minmaxLenght", { label: label, min: 10, max: formControl.errors.maxlength.requiredLength }) }}
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="iconInside" class="row">
  <label *ngIf="label" class="col-form-label position-relative">{{ label }}<span class="text-danger" *ngIf="mark">*</span> </label>
  <div *transloco="let t; read: 'common.error'">
    <div class="font-size-16">
      <span class="position-absolute top-50 translate-middle start-7 br-none">
        <img class="icon icon-xs icon-rounded" src="{{ iconSvg }}" alt="{{ iconSvg }}" />
      </span>
      <div *ngIf="!isLinked">
        <input
          [class.input-group-item]="inputGroup"
          (paste)="onPaste()"
          [urlOnly]="formControl"
          (keyup)="valueChanged()"
          [placeholder]="placeholder"
          [formControl]="formControl"
          class="form-control input-icon"
          type="text"
          (blur)="onTouched()"
          [class.is-invalid]="formControl.touched && formControl.errors"
        />
        <ng-container *ngIf="formControl.touched && formControl.errors">
          <ng-container *ngIf="formControl.errors.required">
            <div class="invalid-feedback position-absolute">
              {{ t("required") }}
            </div>
          </ng-container>
          <ng-container *ngIf="formControl.errors.pattern">
            <div class="invalid-feedback position-absolute">
              {{ t("invalidUrl") }}
            </div>
          </ng-container>
          <ng-container *ngIf="(formControl.errors.maxlength || formControl.errors.minlength) && !formControl.errors.pattern">
            <div class="invalid-feedback">
              {{ t("minmaxLenght", { label: label, min: 10, max: formControl.errors.maxlength.requiredLength }) }}
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
