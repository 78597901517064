import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslocoRootModule } from "@app/core/services/transloco/transloco-root.module";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDropzoneModule } from "ngx-dropzone";
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from "ngx-dropzone-wrapper";
import { ImageCropperModule } from "ngx-image-cropper";
import { UIModule } from "../ui/ui.module";
import { FileUploaderModalComponent } from "./file-uploader-modal/file-uploader-modal.component";
import { FileUploaderComponent } from "./file-uploader/file-uploader.component";
import { ImageUploaderCropperComponent } from "./image-uploader-cropper/image-uploader-cropper.component";
import { ImageUploaderComponent } from "./image-uploader/image-uploader.component";
import { UploadButtonComponent } from "./upload-button.component/upload-button.component";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  acceptedFiles: "image/*",
  createImageThumbnails: true,
  clickable: true
};

@NgModule({
  declarations: [
    ImageUploaderComponent,
    FileUploaderModalComponent,
    FileUploaderComponent,
    ImageUploaderCropperComponent,
    UploadButtonComponent
  ],
  imports: [CommonModule, TranslocoRootModule, DropzoneModule, NgxDropzoneModule, NgbAlertModule, ImageCropperModule, UIModule],
  exports: [
    ImageUploaderComponent,
    FileUploaderModalComponent,
    FileUploaderComponent,
    ImageUploaderCropperComponent,
    UploadButtonComponent
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ]
})
export class FileUploaderModule {}
