import { AllowedDeliveryChannel } from "./campaign";

export class CampaignSettings {
  public id?: number;
  public campaignId: number;
  public notificationKey: string;
  public clientId?: number;
  public locationId?: number;
  public isDisable: boolean;
  public name: string;
  public deliveryChannel: AllowedDeliveryChannel;
}
