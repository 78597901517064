import { CommonModule } from "@angular/common";
import { Component, forwardRef } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { LoadingService } from "@app/core";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { SimplebarAngularModule } from "simplebar-angular";
import { FilterSelectComponentBase } from "../base/filter-select-component-base";
import { IFilterSelectItem } from "../i-filter-select-item";

/** Filter-multiselect for lists.
 * @example: <app-filter-select-multiple [ngModel]="" [items]="items" label="Filter..." [canClear]="true"> </app-filter-select-multiple>
 * @example: <app-filter-select-multiple [ngModel]="" [items]="items" label="Filter..." [canClear]="true" itemsMaxHeightPx="200" itemsWidthPx="400" xPosition="before" yPosition="above"> </app-filter-select-multiple>
 */
@Component({
  selector: "app-filter-select-multiple",
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatMenuModule, NgbTooltipModule, SimplebarAngularModule, TranslocoModule],
  templateUrl: "./filter-select-multiple.component.html",
  styleUrls: ["./filter-select-multiple.component.scss", "../filter-select-common.scss"],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FilterSelectMultipleComponent), multi: true }]
})
export class FilterSelectMultipleComponent<TValue = number> extends FilterSelectComponentBase<TValue, TValue[]> {
  selectedItems: IFilterSelectItem<TValue>[] = [];
  menuSelectedCount = 0;
  isAllSelected = false;

  constructor(loader: LoadingService) {
    super();

    this.onMenuTriggerInit.subscribe((x) => {
      x.menuClosed.subscribe(() => {
        this.updateSelectedFromControl();
      });
    });
  }

  selectAllChanged(isSelectAll: boolean) {
    this.items.forEach((item) => {
      item.isSelected = isSelectAll;
    });
    this.selectionChanged();
  }

  protected updateSelectedFromControl(): void {
    const value = this.formControl?.value ?? [];
    this.items.forEach((item) => {
      item.isSelected = value.some((x) => item.value === x);
    });
    this.selectionChanged();

    this.selectedItems = this.items.filter((x) => x.isSelected);
  }

  selectionChanged() {
    this.menuSelectedCount = this.items.filter((x) => x.isSelected).length;
    this.isAllSelected = this.menuSelectedCount === this.items.length;
  }
  get tooltipItems() {
    let items = this.selectedItems.map((x) => x.title).sort();
    const total = items.length;

    const maxItemsCount = 15;
    if (items.length > maxItemsCount) {
      items = items.slice(0, maxItemsCount - 1);
      const notVisibleCount = total - items.length;
      items.push(`... +${notVisibleCount} more`);
    }
    return items;
  }

  resetClick() {
    this.items.forEach((x) => (x.isSelected = false));
    this.selectionChanged();
  }
  okClick() {
    const value = this.items.filter((x) => x.isSelected).map((x) => x.value);
    this.formControl.setValue(value);
    this.closeMenu();
  }
}
