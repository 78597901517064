import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
@Pipe({
  name: "ellipsis"
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, limt: number = 3): any {
    const separator = ", ";
    const items: string[] = value.split(separator);
    let result = "";
    if (items?.length > limt) {
      const lastSign = "...";
      result = `${items.slice(0, limt).join(separator)}${lastSign}`;
    } else if (items?.length) {
      result = items.join(separator);
    }
    return result;
  }
}
