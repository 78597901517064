import { PlatformAccount } from "./platform-account";
import { Role } from "./role";

export enum UserStatus {
  Undefined = 0,
  Active = 1,
  Inactive = 2
}

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  managerId?: number;
  password: string;
  role?: Role;
  roleId?: number;
  token?: string;
  platformAccounts?: PlatformAccount[] = [];
  configured?: boolean;
  doneQuickSetup?: boolean;
  emailConfirmed?: boolean;
  emailConfirmedDate?: Date;
  receiveNegativeFeedback?: boolean;
  roleName: string;
  partnerId?: number;
  accountId?: number;
}

export class PartnerAdmin {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  configured?: boolean;
  emailConfirmed?: boolean;
  emailConfirmedDate?: Date;
  partnerId?: number;
}

export class UserList {
  userIds: number[] = [];
}

export class GetPinResponseDto {
  pin: string;
}
export class ResetUserPinResponseDto {
  pin: string;
  warningMessage: string;
}
