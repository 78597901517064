import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

export class YotubeVideoValidator {
  static createValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const subject = new Subject<boolean>();

      const valid = () => {
        let isValid = null;
        const value = control.value as string;

        if (value) {
          const img = new Image();
          img.src = "https://img.youtube.com/vi/" + value + "/mqdefault.jpg";
          img.onload = () => {
            // HACK a mq thumbnail has width of 320.
            // if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.

            isValid = img.width !== 120 ? true : false;
            subject.next(isValid);
            subject.complete();
          };
        } else {
          subject.next(true);
          subject.complete();
        }
      };

      setTimeout(() => {
        valid();
      }, 300);

      return subject.asObservable().pipe(map((result: boolean) => (result ? null : { videoId: { value: control.value } })));
    };
  }
}
