import { Component } from "@angular/core";

import { Router } from "@angular/router";
import { AccountService, AppConfigService, AuthService } from "@app/core";
import {
  AccountsService,
  LoyaltyProgramService,
  PlatformAccountService,
  PosSettingsService,
  PosSystemService,
  SubscriptionPlanService,
  ViewAsPartnerService
} from "@app/services";
import { SidebarComponent } from "../sidebar/sidebar.component";

@Component({
  selector: "app-sidebar-horizontal",
  templateUrl: "./sidebar-horizontal.component.html",
  styleUrls: ["./sidebar-horizontal.component.scss"]
})
export class SidebarHorizontalComponent extends SidebarComponent {
  constructor(
    router: Router,
    configSvc: AppConfigService,
    platformAccountSvc: PlatformAccountService,
    accountSvc: AccountService,
    accountsSvc: AccountsService,
    authService: AuthService,
    planService: SubscriptionPlanService,
    viewAsPartnerService: ViewAsPartnerService,
    posSystemService: PosSystemService,
    posSettingsService: PosSettingsService,
    loyaltyProgramService: LoyaltyProgramService
  ) {
    super(
      router,
      configSvc,
      platformAccountSvc,
      accountSvc,
      accountsSvc,
      authService,
      planService,
      viewAsPartnerService,
      posSystemService,
      posSettingsService,
      loyaltyProgramService
    );
    router.events.subscribe((val) => {
      SidebarComponent.removeAllClasses();
    });
  }
}
