import { Observable, Observer } from "rxjs";

export class ExportFilesService {
  constructor() {}

  public static writeContents(content, fileName, type = "text/csv") {
    const a = document.createElement("a");
    const file = new Blob([content], { type: type });
    a.href = window.URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  public static writeContentsURL(file) {
    window.open(file);
  }
}
