import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { SalesAgentHistory, SalesAgent } from "@app/models";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SalesAgentService {
  private serviceUrl = "SalesAgent";

  constructor(private http: DataService) {}

  getReferralByCode(code: string): Observable<SalesAgent> {
    return this.http.get(`${this.serviceUrl}/getByCode/${code}`);
  }

  getById(id: number): Observable<SalesAgent> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  getAll(partnerIds: number[] = []): Observable<SalesAgent[]> {
    const queryParams = partnerIds.map((number) => `partnerIds=${number}`).join("&");
    const queryString = partnerIds.length ? `?${queryParams}` : "";

    return this.http.get(`${this.serviceUrl}${queryString}`);
  }

  create(salesAgent: SalesAgent): Observable<SalesAgent> {
    return this.http.post(`${this.serviceUrl}`, salesAgent);
  }

  update(id: number, salesAgent: SalesAgent): Observable<SalesAgent> {
    return this.http.post(`${this.serviceUrl}/${id}`, salesAgent);
  }

  delete(id: number) {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  getCountAccountSalesAgent(id: number): Observable<number> {
    return this.http.get(`${this.serviceUrl}/countAccounts/${id}`);
  }

  getHistory(id: number): Observable<SalesAgentHistory[]> {
    return this.http.get(`${this.serviceUrl}/history/${id}`);
  }
}
