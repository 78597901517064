import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountService } from "./account.service";

import { AuthService } from "./auth.service";
import { UtilityService } from "./utility.service";
import { RoleTypes } from "@app/models";

@Injectable()
export class EmailVerifiedGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router, private utilitySvc: UtilityService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.accountService.user;

    const result = user && (user.email_verified || this.authService.requredMinimumRole(RoleTypes.PartnerAdmin));
    if (!result) this.router.navigate(["/verify"]);
    return result;
  }
}
