import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { Brand, Location, LocationLink, LocationUser, SearchLocationVm } from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class MarketService {
  private serviceUrl = "Market";

  constructor(private http: DataService) {}

  getBrands(partnerIds: number[] = [], includeUnassigned = false): Observable<Brand[]> {
    let params = new HttpParams();

    partnerIds.forEach((id) => {
      params = params.append("partnerIds", id.toString());
    });

    if (includeUnassigned) {
      params = params.append("includeUnassigned", "true");
    }

    return this.http.get(`${this.serviceUrl}/brands?${params.toString()}`);
  }

  getUserBrands(userId: number): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/brand/user/${userId}`);
  }

  getLocationUsersIds(locationId: number): Observable<number[]> {
    return this.getLocationsUsers().pipe(map((uls) => uls.filter((ul) => ul.locationId === locationId).map((ul) => ul.userId)));
  }

  getLocationsUsers(): Observable<LocationUser[]> {
    return this.http.get(`${this.serviceUrl}/userlocation/all`);
  }

  getLocations(): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/locations`);
  }

  searchLocations(searchLocationVm: SearchLocationVm): Observable<Location[]> {
    return this.http.post(`${this.serviceUrl}/locations/search`, searchLocationVm);
  }

  getLocationLinks(): Observable<LocationLink[]> {
    return this.http.get(`${this.serviceUrl}/locationLinks`);
  }

  importBrands(): Observable<void> {
    return this.http.post(`${this.serviceUrl}/import`);
  }

  deleteLocation(id: number): Observable<Location[]> {
    return this.http.post(`${this.serviceUrl}/deleteLocation/${id}`);
  }

  importUserLocations(email: string): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/locations/email/${email}`);
  }

  addUserLocations(userId: number, locations: Location[]): Observable<void> {
    const userLocs = locations.map((l) => ({ userId: userId, locationId: l.id }));
    return this.http.post(`${this.serviceUrl}/linkLocationUser/`, userLocs);
  }

  addUserBrands(userId: number, brands: Brand[]): Observable<void> {
    const userLocs = brands.map((l) => ({ userId: userId, brandId: l.id }));
    return this.http.post(`${this.serviceUrl}/linkBrandUser/`, userLocs);
  }

  getLocation(locationId: number): Observable<Location> {
    return this.http.get(`${this.serviceUrl}/location/${locationId}`);
  }

  getBrandsByLocation(locationId: number): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/brandsByLocation/${locationId}`);
  }

  getLocationLinkByLocationId(locationId: number): Observable<LocationLink> {
    return this.http.get(`${this.serviceUrl}/locationLink/${locationId}`);
  }

  addLocationLink(link: LocationLink): Observable<LocationLink> {
    return this.http.post(`${this.serviceUrl}/locationLink`, link);
  }

  updateLocationLink(id: number, link: LocationLink): Observable<LocationLink> {
    return this.http.put(`${this.serviceUrl}/locationLink/${id}`, link);
  }
}
