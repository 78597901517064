import { QrCodeConfiguration } from "./qr-code-configuration";

export interface BrandColorPalette {
  id: number;
  brandId: number;
  primary: string;
  secondary: string;
  tertiary: string;
}

export interface BrandInfo {
  brandLogoUrl?: string;
  brandQrCodeLogoUrl?: string;
}

export enum ApplicationAccessLevel {
  Private = "private",
  Public = "public"
}

export class ApplicationStyles {
  id: number;
  name: string;
  applicationAccessLevel: ApplicationAccessLevel;
  qrConfiguration: QrCodeConfiguration;
  static toBrandColorPalette(brandId: number, styles: ApplicationStyles): BrandColorPalette {
    return {
      id: styles.id,
      brandId: brandId,
      primary: styles.qrConfiguration.primaryColor,
      secondary: styles.qrConfiguration.secondaryColor,
      tertiary: styles.qrConfiguration.backgroundColor
    };
  }
}
