import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AppConfigService } from "@app/core";
import {
  Brand,
  DisplayQrCodeLogoTypes,
  ItemWithLabel,
  QrCodeFormat,
  QrCodeTemplateName,
  QrCodeWithTemplatePostDto,
  SerialNumberTypes,
  ShortLinkTargetType
} from "@app/models";
import { QrCodeGeneratorService } from "@app/services";
import { Observable, of } from "rxjs";
import { auditTime, catchError, distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { Unsubscriber } from "../base/unsubscriber.component";

interface LogoUrlOptions {
  ignoreFormValue?: boolean;
  qrLogoType?: DisplayQrCodeLogoTypes;
  brandId?: number;
}

export interface QrCodeDesignSettings {
  sizeType: QrCodeDesignSettingsSizeType;
  isAdmin: boolean;
  configuration: QrCodeWithTemplatePostDto;
  targetType?: ShortLinkTargetType;
  serialNumberType?: SerialNumberTypes;
  customBrandList?: Brand[];
  isCustomBrandVisible?: boolean;
}

export enum QrCodeDesignSettingsSizeType {
  Full = "full",
  Compact = "compact"
}

@Component({
  selector: "app-qr-code-design-settings",
  templateUrl: "./qr-code-design-settings.component.html",
  styleUrls: ["./qr-code-design-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrCodeDesignSettingsComponent extends Unsubscriber implements OnInit {
  @Input() title = "";
  @Input() brandLogo = "";

  @Input() set configuration(value: QrCodeDesignSettings) {
    if (value && JSON.stringify(value) !== JSON.stringify(this._settings)) {
      this.sizeType = value.sizeType;
      const primaryColorDefault = this.configSvc.appData.qrCodeConfiguration?.primaryColor;
      const secondaryColorDefault = this.configSvc.appData.qrCodeConfiguration?.secondaryColor;
      const { configuration, targetType, serialNumberType, isAdmin, customBrandList, isCustomBrandVisible } = value;
      const {
        data,
        name,
        primaryColor,
        secondaryColor,
        backgroundColor,
        primaryText,
        primaryTextColor,
        secondaryText,
        secondaryTextColor,
        qrLogoType,
        logoBrandId,
        hasFrame,
        hasPrintId,
        printId,
        hasSerialNumber,
        serialNumber,
        couponCode,
        hasCouponCode,
        templateName,
        width,
        height
      } = configuration;

      this._settings = value;
      this.isAdmin = isAdmin;
      this.isCustomBrandVisible = isCustomBrandVisible;
      this.customBrandList = customBrandList || [];
      this.logoTypeRadioButtons = this.getLogoTypeRadioButtons();
      this.targetType = targetType;
      this.originalSerialNumber = serialNumber;
      this.originalCouponCode = couponCode;
      this.serialNumberType = serialNumberType;
      this.form.patchValue({
        data,
        name,
        hasFrame,
        hasLogo: qrLogoType !== DisplayQrCodeLogoTypes.None,
        logoBrandId,
        logo: this.getLogoUrl({ qrLogoType }),
        qrLogoType,
        primary: primaryColor,
        secondary: secondaryColor,
        backgroundColor,
        primaryText,
        primaryTextColor: primaryTextColor ?? primaryColorDefault,
        secondaryText,
        secondaryTextColor: secondaryTextColor ?? secondaryColorDefault,
        hasPrintId,
        printId,
        hasSerialNumber,
        serialNumber,
        hasCouponCode,
        templateName: templateName ?? QrCodeTemplateName.Bulk,
        width: width ?? this.defaultSize,
        height: height ?? this.defaultSize
      });
    }
  }

  @Output() configurationChange = new EventEmitter<QrCodeWithTemplatePostDto>();

  @Output() linkChange = new EventEmitter<SafeResourceUrl>();

  SerialNumberTypes = SerialNumberTypes;
  ShortLinkTargetType = ShortLinkTargetType;
  SizeType = QrCodeDesignSettingsSizeType;

  sizeType: QrCodeDesignSettingsSizeType;
  serialNumberType: SerialNumberTypes = SerialNumberTypes.None;
  targetType: ShortLinkTargetType = ShortLinkTargetType.DestinationUrl;
  isAdmin = false;
  isCustomBrandVisible = false;

  form = new FormGroup({
    primary: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.primaryColor),
    secondary: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.secondaryColor),
    backgroundColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.backgroundColor),
    data: new FormControl<string>("https://clearline.me"),
    name: new FormControl<string>(null),
    hasCouponCode: new FormControl<boolean>(false),
    hasFrame: new FormControl<boolean>(false),
    hasLogo: new FormControl<boolean>(false),
    hasPrintId: new FormControl<boolean>(false),
    printId: new FormControl<number>(null),
    hasSerialNumber: new FormControl<boolean>(false),
    serialNumber: new FormControl<string>(null),
    logo: new FormControl<string>(null),
    logoBrandId: new FormControl<number>(0),
    qrLogoType: new FormControl<DisplayQrCodeLogoTypes>(DisplayQrCodeLogoTypes.BrandLogo),
    primaryText: new FormControl<string>(null),
    secondaryText: new FormControl<string>(null),
    primaryTextColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.primaryTextColor),
    secondaryTextColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.secondaryTextColor),
    templateName: new FormControl<QrCodeTemplateName>(null),
    width: new FormControl<number>(null),
    height: new FormControl<number>(null)
  });

  readonly DisplayQrCodeLogoTypes = DisplayQrCodeLogoTypes;
  readonly logoImageAspectRatio = 5 / 4;
  readonly defaultSize = 280;
  logoTypeRadioButtons: ItemWithLabel<DisplayQrCodeLogoTypes>[] = this.getLogoTypeRadioButtons();
  customBrandList: Brand[] = [];

  private _settings: QrCodeDesignSettings;
  private originalSerialNumber = "";
  private originalCouponCode = "";

  constructor(
    private configSvc: AppConfigService,
    private sanitizer: DomSanitizer,
    private qrCodeGenerator: QrCodeGeneratorService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.form.controls.hasSerialNumber.valueChanges.pipe(distinctUntilChanged()).subscribe((hasSerialNumber: boolean) => {
      if (hasSerialNumber) {
        this.form.patchValue({
          hasCouponCode: false,
          serialNumber: this.originalSerialNumber
        });
      }
    });

    this.sub = this.form.controls.hasCouponCode.valueChanges.pipe(distinctUntilChanged()).subscribe((hasCouponCode: boolean) => {
      if (hasCouponCode) {
        this.form.patchValue({
          hasSerialNumber: false,
          serialNumber: this.originalCouponCode
        });
      }
    });

    this.sub = this.form.controls.qrLogoType.valueChanges.pipe(auditTime(300)).subscribe((qrLogoType: DisplayQrCodeLogoTypes) => {
      const logoUrl = this.getLogoUrl({ ignoreFormValue: true });

      if (qrLogoType === DisplayQrCodeLogoTypes.CustomLogo) {
        this._settings.configuration.logo = logoUrl;
      }

      this.form.controls.logo.setValue(logoUrl, { emitEvent: false });
      this.form.controls.hasLogo.setValue(qrLogoType !== DisplayQrCodeLogoTypes.None, { emitEvent: false });
      this.cdr.markForCheck();
    });

    this.sub = this.form.controls.logoBrandId.valueChanges.subscribe((brandId: number) => {
      this.form.controls.logo.setValue(this.getLogoUrl({ brandId }));
    });

    this.sub = this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        auditTime(300),
        distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
        switchMap(() => this.getQrCodeTemplateUrl())
      )
      .subscribe((link) => {
        this.linkChange.emit(link);
      });
  }

  private getQrCodeTemplateUrl(): Observable<SafeResourceUrl> {
    const {
      data,
      name,
      primary,
      secondary,
      backgroundColor,
      qrLogoType,
      logoBrandId,
      hasFrame,
      hasLogo,
      hasCouponCode,
      hasPrintId,
      hasSerialNumber,
      printId,
      serialNumber,
      primaryText,
      primaryTextColor,
      secondaryText,
      secondaryTextColor,
      templateName,
      width,
      height
    } = this.form.getRawValue();
    const payload: QrCodeWithTemplatePostDto = {
      logo: hasLogo || logoBrandId ? this.getLogoUrl({ brandId: logoBrandId }) : null,
      name,
      qrLogoType,
      primaryColor: primary,
      secondaryColor: secondary,
      backgroundColor,
      primaryText,
      secondaryText,
      primaryTextColor,
      secondaryTextColor,
      width,
      height,
      hasCouponCode,
      hasFrame,
      hasPrintId,
      hasSerialNumber,
      printId: hasPrintId ? printId : 0,
      templateName,
      serialNumber: hasSerialNumber || hasCouponCode ? serialNumber : null,
      couponCode: null,
      data
    };

    return this.qrCodeGenerator.generateQrCodeUrlWithHtmlContent(QrCodeFormat.Png, payload).pipe(
      catchError(() => of({ link: "" })),
      map(({ link }) => this.sanitizer.bypassSecurityTrustResourceUrl(link)),
      tap(() => {
        this.configurationChange.emit({
          ...payload,
          logoBrandId: logoBrandId,
          logo: qrLogoType === DisplayQrCodeLogoTypes.CustomLogo ? payload.logo : this._settings.configuration.logo
        });
      })
    );
  }

  private getLogoUrl(options: LogoUrlOptions = {}): string {
    const { qrLogoType, ignoreFormValue, brandId } = options;

    switch (qrLogoType || this.form.controls.qrLogoType.value) {
      case DisplayQrCodeLogoTypes.BrandLogo: {
        return this.brandLogo;
      }
      case DisplayQrCodeLogoTypes.CustomLogo: {
        const storedLogo = this._settings.configuration.logo;
        const formLogo = this.form.controls.logo.value;

        return ignoreFormValue ? storedLogo : formLogo || storedLogo;
      }
      case DisplayQrCodeLogoTypes.DisplayQrCodeLogo: {
        const targetBrand = this.customBrandList.find((brand) => brand.id === brandId);

        return targetBrand?.qrCodeLogo || targetBrand?.logo || "";
      }
      default: {
        return "";
      }
    }
  }

  private getLogoTypeRadioButtons(): ItemWithLabel<DisplayQrCodeLogoTypes>[] {
    return this.isAdmin && this.isCustomBrandVisible
      ? [
          { label: "bulkLinks.fields.radioBrand", value: DisplayQrCodeLogoTypes.BrandLogo, disabled: false },
          { label: "bulkLinks.fields.radioCustomBrand", value: DisplayQrCodeLogoTypes.DisplayQrCodeLogo, disabled: false },
          { label: "bulkLinks.fields.radioCustom", value: DisplayQrCodeLogoTypes.CustomLogo, disabled: false },
          { label: "bulkLinks.fields.radioNone", value: DisplayQrCodeLogoTypes.None, disabled: false }
        ]
      : [
          { label: "bulkLinks.fields.radioBrand", value: DisplayQrCodeLogoTypes.BrandLogo, disabled: false },
          { label: "bulkLinks.fields.radioCustom", value: DisplayQrCodeLogoTypes.CustomLogo, disabled: false },
          { label: "bulkLinks.fields.radioNone", value: DisplayQrCodeLogoTypes.None, disabled: false }
        ];
  }
}
