export enum PromoPreferences {
  Undefined = 0,
  Sms = 1,
  Email = 2 | Sms,
  Phone = 4 | Email
}

export class AddPromoWidgetSettings {
  public promoListUrl: string;
  public promoPreferences: PromoPreferences;
  public selectedCouponId: string;
  public campaignId: string;
}
