import { Account } from "./account";
import {
  ActivationBulkOption,
  BulkType,
  BulkActivationStatus,
  RelationshipBulkType,
  SerialNumberTypes,
  ShortLinkTargetType,
  ShortLinkTargetTypes,
  DeliverySources
} from "./enums";
import { ShortLinkQrConfiguration, ShortLinkQrConfigurationDto } from "./qr-code-configuration";
import { ShortLinkCustomDataDto } from "./shorl-link/short-link";
import { WidgetType } from "./widget-type";

export interface BulkShortLinkDeliverySourcesGetDto {
  allowedBulkDeliveredSources?: keyof DeliverySources[];
}

export class BulkLinkPutDto {
  public name: string;
  public locationId?: number;
  public ownerId?: number;
  public description: string;
  public configuration: ShortLinkQrConfigurationDto;
  public activationStatus?: BulkActivationStatus;
  public targetData: BulkShortLinkTargetDataDto;
  public linkDeliverySource: DeliverySources;
}

export class BulkLinkGetDto {
  public id: number;
  public name: string;
  public locationId?: number;
  public quantity: number;

  public serialNumberType: SerialNumberTypes;
  public prefix: string;
  public serialNumberLength: number;
  public serialNumberPrefix: string;
  public description: string;
  public showBrandShortName: boolean;
  public configuration: ShortLinkQrConfiguration;
  public customData: ShortLinkCustomDataDto;
  public targetType: ShortLinkTargetType;
  public targetData: BulkShortLinkTargetDataDto;

  public createdBy: number;
  public createdOn: Date;
  public createdDate: string;
  public modifiedBy?: number;
  public modifiedOn: Date;
  public modifiedDate: string;
  public code: string;

  public subscriptionPlanId?: number;
  public ownerId?: number;
  public activatedOn?: Date;
  public activatedDate?: string;
  public activationStatus?: BulkActivationStatus;

  public parentBulkId?: number;
  public parentBulkName: string;
  public bulkType?: BulkType;
  public linkDeliverySource: DeliverySources;
}
export class BulkLinksDetails extends BulkLinkGetDto {
  public numberOfClicks?: number;
  public owner?: string;
  public userCreatedBy?: string;
  public status?: string;
  public price?: number;
  public subscriptionPlan?: string;
  public location?: string;
  public couponStatus?: string;
  public bulkContentType?: string;
  public widget?: string;
  public relationship?: RelationshipBulkType;
  public activationBulkOption?: ActivationBulkOption;
}
export class BulkTotalViewsGetDto {
  public bulkId: number;
  public viewCount: number;
}

export class BulkShortLinkPostDto {
  public name: string;
  public locationId?: number;
  public targetType: ShortLinkTargetTypes;
  public targetData: BulkShortLinkTargetDataDto;
  public quantity: number;
  public showBrandShortName: boolean;
  public serialNumberType: SerialNumberTypes;
  public prefix: string;
  public serialNumberPrefix?: string;
  public serialNumberLength: number;
  public parentBulkId?: number;
  public subscriptionPlanId?: number;
  public ownerId?: number;
  public configuration?: ShortLinkQrConfiguration;
  public activationStatus?: BulkActivationStatus;
  public linkDeliverySource: keyof DeliverySources;
}

export class LinkedBulkShortLinkPostDto {
  public parentBulkId?: number;
  public name: string;
  public quantity: number;
  public subscriptionPlanId?: number;
  public shortLinkIds: number[];
}

export class AssignBulkToLocationPostDto {
  public bulkId?: number;
  public brandId?: number;
  public locationId?: number;
  public resetStat: boolean;
}

export class AccountValidationResultDto {
  public account?: Account;
  public isValid?: boolean;
}

export class SearchBulkAssignAccountsPostDto {
  public bulkId: number;
  public companyName: string;
}

export class BulkShortLinkTargetDataDto {
  public couponId: string;
  public targetUrl: string;
  public widgetType: WidgetType;
}

export class BulkLinkFilterPostDto {
  accountId?: number;
  locationIds: number[];
  bulkIds?: number[];
}

export class BulkQrCodeByShortLinkIdDto {
  shortLinkIds: number[];
}
export class BulkActivationStatusSetting {
  public id: BulkActivationStatus;
  public name: string;
  public title: string;
}

export const bulkActivationStatusSettings: BulkActivationStatusSetting[] = [
  { id: BulkActivationStatus.Pending, name: "pending", title: "Pending" },
  { id: BulkActivationStatus.Downloaded, name: "downloaded", title: "Downloaded" },
  { id: BulkActivationStatus.Printed, name: "printed", title: "Printed" },
  { id: BulkActivationStatus.NewAccount, name: "newAccount", title: "New Account" },
  { id: BulkActivationStatus.LinkedToAccount, name: "linkedToAccount", title: "Linked To Account" }
];

export class ShortLinkTargetTypeSetting {
  public id: ShortLinkTargetType;
  public name: string;
}

export const shortLinkTargetTypeSettings: ShortLinkTargetTypeSetting[] = [
  { id: ShortLinkTargetType.DestinationUrl, name: "Destination Url" },
  { id: ShortLinkTargetType.Coupon, name: "Coupon" },
  { id: ShortLinkTargetType.Widget, name: "Widget" }
];
