import { IDropDownItem } from "../models";

export class EnumHelpers {
  static getKeyNameByValue<T extends EnumType>(enumType: T, value: string | number): string {
    const keyName = Object.keys(enumType)[Object.values(enumType).indexOf(value)];
    return keyName;
  }

  static getKeys<T extends EnumType>(enumType: T): ReadonlyArray<keyof T> {
    return Object.keys(enumType).filter((x: string) => isNaN(Number(x)));
  }
  static getValues<T extends EnumType>(enumType: T): ReadonlyArray<T[keyof T]> {
    const enumKeys = this.getKeys(enumType);
    return enumKeys.map((x) => enumType[x]);
  }
  static getList<T extends EnumType>(enumType: T) {
    const enumKeys = this.getKeys(enumType);
    return enumKeys.map((x) => ({ key: x, value: enumType[x] }));
  }
  static getDropDownList<T extends EnumType>(enumType: T, labelFn: (x: keyof T) => string = (x) => x.toString()): IDropDownItem<keyof T>[] {
    const enumKeys = this.getKeys(enumType);
    return enumKeys.map((x) => ({ value: x, label: labelFn(x) }));
  }
}

export type EnumType = Record<string, string | number>;
