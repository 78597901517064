<div *transloco="let t; read: 'colorPicker'" class="color-picker p-3">
   <div *ngIf="solidColors?.length" class="flex-column color-picker__column">
      <span class="section-title">{{ t('solidColors') }}</span>

      <div class="colors-wrapper mt-2">
         <div
           *ngFor="let color of solidColors"
           class="color cursor-pointer"
           [style.background-color]="color"
           [class.selected]="color.toLowerCase() === selectedValue.toLowerCase()"
           [class.with-wrapper]="color === whiteColor"
           (click)="selectColor(color)">
         </div>
      </div>
   </div>

   <div *ngIf="gradients?.length" class="flex-column color-picker__column mt-3">
      <span class="section-title">{{ t('gradients') }}</span>

      <div class="colors-wrapper mt-2">
         <div
           *ngFor="let gradient of gradients"
           class="color cursor-pointer"
           [style.background-image]="gradient"
           [class.selected]="gradient.toLowerCase() === selectedValue.toLowerCase()"
           (click)="selectGradient(gradient)">
         </div>
      </div>
   </div>
</div>
