import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { BrandSocialLink } from "@app/models";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BrandSocialLinkService {
  private serviceUrl = "brandSocialLink";

  private linksChanged$: BehaviorSubject<BrandSocialLink[]> = new BehaviorSubject([]);
  public onLinksChanged = this.linksChanged$.asObservable();

  private linksValidationChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public onLinksValidationChanged = this.linksValidationChanged$.asObservable();
  constructor(private http: DataService) {}

  getBrandSocialLinks(brandId: number): Observable<BrandSocialLink[]> {
    return this.http.get(`${this.serviceUrl}/list/` + brandId);
  }

  addBrandSocialLinks(links: BrandSocialLink[]): Observable<BrandSocialLink[]> {
    return this.http.post(`${this.serviceUrl}/links`, links);
  }

  updateBrandSocialLinks(brandId: number, links: BrandSocialLink[]): Observable<BrandSocialLink[]> {
    return this.http.put(`${this.serviceUrl}/links/${brandId}`, links);
  }

  public onUpdateLinks(links: BrandSocialLink[]) {
    this.linksChanged$.next(links);
  }

  public onUpdateLinksValidation(isValid: boolean) {
    this.linksValidationChanged$.next(isValid);
  }
}
