import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Location, MultiSelectItem } from "@app/models";

@Component({
  selector: "app-autocomplete-multiselect",
  templateUrl: "./autocomplete-multiselect.component.html",
  styleUrls: ["./autocomplete-multiselect.component.scss"]
})
export class AutocompleteMultiSelectComponent implements OnInit {
  constructor() {}

  @Input() hideReset: boolean;
  @Input() titleMultiFilter: string;
  @Input() required: boolean;
  @Input() selectedIds: number[];
  @Input() loading = false;

  @Input() set items(items: MultiSelectItem[]) {
    if (items) {
      this._items = items;
      this.filter(items);
      this.handleSelectedItems(this.selectedIds);
    }
  }

  @Output() selectChanged = new EventEmitter<MultiSelectItem[]>();
  @Output() saved = new EventEmitter<MultiSelectItem[]>();

  private _items: MultiSelectItem[] = [];
  get items(): MultiSelectItem[] {
    return this._items;
  }

  filteredItems: MultiSelectItem[] = [];
  selectedItems: MultiSelectItem[] = [];
  selectedAll = false;

  scrollbarOptions: any = {
    forceVisible: false,
    autoHide: false
  };

  get disabled(): boolean {
    return this.required && !this.selectedItems.length;
  }

  ngOnInit(): void {
    if (this.selectedItems.length === 0) {
      this.triggerSelectAll();
      this.selectChanged.emit(this.selectedItems);
    }
  }

  filter(items: MultiSelectItem[]): void {
    this.filteredItems = items;
    this.resetSelections();
  }

  reset() {
    this.filteredItems = this.items;
    this.resetSelections();
  }

  triggerSelectItem(item: MultiSelectItem): void {
    this.multipleSelectItem(item);
  }

  private multipleSelectItem(item: MultiSelectItem): void {
    this.filteredItems.some((el: MultiSelectItem, index: number) => {
      const isCurrent: boolean = el.id === item.id;
      if (isCurrent) {
        this.filteredItems[index].selected = !this.filteredItems[index].selected;
      }

      return isCurrent;
    });

    this.selectedItems = this.getSelectedItems();
    this.selectedAll = this.getSelectAll();

    this.selectChanged.emit(this.selectedItems);
  }

  private multipleSelectByItemIds(itemIds: number[]): void {
    this.filteredItems.forEach((item: MultiSelectItem, index: number) => {
      this.filteredItems[index].selected = itemIds.some((id: number) => id === item.id);
    });

    this.selectedItems = this.getSelectedItems();
    this.selectedAll = this.getSelectAll();
  }

  onSave(): void {
    if (!this.disabled) {
      this.selectChanged.emit(this.selectedItems);
      this.saved.emit(this.selectedItems);
    }
  }

  private getSelectedItems(): MultiSelectItem[] {
    return this.filteredItems.filter((item: MultiSelectItem) => item.selected);
  }

  private getSelectAll(): boolean {
    return this.selectedItems?.length === this.filteredItems?.length;
  }

  private handleSelectedItems(selectedIds: number[]): void {
    if (selectedIds?.length) {
      this.multipleSelectByItemIds(selectedIds);
    }
  }

  private resetSelections(): void {
    this.selectedAll = true;
    this.triggerSelectAll();
  }

  triggerSelectAll(): void {
    this.selectedAll = !this.selectedAll;
    this.selectedItems = [];

    this.filteredItems.forEach((item: Location, index: number) => {
      this.filteredItems[index].selected = this.selectedAll;

      if (this.filteredItems[index].selected) {
        this.selectedItems.push(item);
      }
    });

    this.selectChanged.emit(this.selectedItems);
  }
}
