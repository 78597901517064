import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "@app/core/services/account.service";
@Component({
  template: ""
})
export class CallbackComponent implements OnInit {
  constructor(private router: Router, private accSvc: AccountService) {}
  ngOnInit() {
    const message = window.location.hash;
    if (window.parent && window.parent !== window) {
      // if loaded as an iframe during silent refresh
      window.parent.postMessage(message, location.origin);
    } else if (window.opener && window.opener !== window) {
      // if loaded as a popup during initial login
      window.opener.postMessage(message, location.origin);
    }

    this.accSvc.userChanged$.subscribe((u) => {
      if (this.accSvc.isLoggedIn) {
        const user = this.accSvc.user;
        const result = user.doneQuickSetup && !user.configured;
        if (result) {
          this.router.navigate(["/pending-registration"]);
        } else {
          this.router.navigate([""]);
        }
      }
    });
  }
}
