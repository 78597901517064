import { Injectable } from "@angular/core";
import {
  ConditionOptionsBaseForm,
  Location,
  LocationAvailability,
  LocationAvailabilityDto,
  LocationAvailabilityRequestDto,
  TemplateLocation,
  WidgetType
} from "@app/models";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LocationService } from "../location.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

type MarketingActionItem = "Template" | "Coupon" | "MAPP";

@Injectable()
export class PosConditionUpdatePageService {
  constructor(private locationService: LocationService) {}

  private selectedLocationsSubject = new BehaviorSubject<Location[]>([]);
  private conditionIdSubject = new BehaviorSubject<number>(null);
  private targetMarketingActionItem$ = new BehaviorSubject<MarketingActionItem>(null);
  private targetLocationAvailabilityItem: LocationAvailabilityRequestDto = {};

  locations$: Observable<Location[]> = this.targetMarketingActionItem$.pipe(
    map((marketingActionItem) => {
      switch (marketingActionItem) {
        case "Template":
          return this.templateLocations;
        case "Coupon":
          return this.locationsForCoupons;
        case "MAPP":
          return this.widgetLocations;
        default:
          return this.accountLocations;
      }
    })
  );
  selectedLocations$: Observable<Location[]> = this.selectedLocationsSubject.asObservable();
  selectedLocationIds$: Observable<number[]> = this.selectedLocationsSubject.pipe(map((locations) => locations.map((x) => x.id)));
  locationIdsControl = new FormControl<number[]>(null, Validators.required);
  conditionId$: Observable<number> = this.conditionIdSubject.asObservable();

  private accountLocations: Location[] = [];
  private widgetLocations: Location[] = [];
  private templateLocations: Location[] = [];
  private locationsForCoupons: Location[] = [];
  private selectedLocations: Location[];
  private locationIdsFromAPI: number[] = [];

  setTargetMarketingActionItem(item: MarketingActionItem): void {
    this.targetMarketingActionItem$.next(item);
  }

  setAccountLocations(locations: Location[]): void {
    this.accountLocations = locations;
  }

  setLocationIdsFromAPI(locationIds: number[]): void {
    const selectedLocations = this.accountLocations.filter((item: Location) => locationIds.includes(item.id));

    this.locationIdsFromAPI = locationIds;
    this.selectedLocationsSubject.next(selectedLocations);
  }

  setSelectedLocations(selectedLocations: Location[] = []): void {
    this.selectedLocations = selectedLocations;
    this.locationIdsControl.setValue(selectedLocations.map((x) => x.id));
    this.selectedLocationsSubject.next(selectedLocations);
  }

  getAccountLocations(): Location[] {
    return this.accountLocations.slice();
  }

  getSelectedLocationIds(): number[] {
    return this.selectedLocations?.map((x) => x.id) ?? [];
  }

  getLocationIdsFromAPI(): number[] {
    return this.locationIdsFromAPI;
  }

  parseMarketingActionItemFromCondition(condition: ConditionOptionsBaseForm): MarketingActionItem {
    if (condition.accountTemplateId) {
      return "Template";
    }
    if (condition.widget?.widgetType === WidgetType.IssueCoupon) {
      return "Coupon";
    }
    return "MAPP";
  }

  setConditionId(conditionId: number): void {
    this.conditionIdSubject.next(conditionId);
  }

  updateLocationAvailabilityItem(item: LocationAvailabilityRequestDto): void {
    this.targetLocationAvailabilityItem = item;
  }

  getSelectableLocations(): Observable<TemplateLocation[]> {
    return combineLatest([this.selectedLocations$, this.getLocationsAvailability()]).pipe(
      map(([selectedLocations, locationAvailability]) => {
        return locationAvailability.map((la) => ({
          ...la,
          selected: selectedLocations.some(({ id }) => id === la.id)
        }));
      })
    );
  }

  private getLocationsAvailability(): Observable<LocationAvailability[]> {
    const nonFalsyPayload = Object.entries(this.targetLocationAvailabilityItem).reduce((acc, [k, v]) => {
      if (!!v) {
        acc[k] = v;
      }
      return acc;
    }, {});

    return this.locationService
      .getLocationsAvailability({
        ...nonFalsyPayload,
        useOnPos: true
      })
      .pipe(map((locations: LocationAvailabilityDto[]) => locations.map(({ location, status }) => ({ ...location, status }))));
  }
}
