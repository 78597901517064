import { Component, Input } from "@angular/core";

/**
 * WarningComponent is a component to display warning messages.
 * It can either display a simple text message or custom content.
 */
@Component({
  selector: "app-warning",
  templateUrl: "./warning.component.html",
  styleUrls: ["./warning.component.scss"]
})
export class WarningComponent {
  /**
   * The text to display in the warning message.
   */
  @Input() text = "";

  /**
   * Indicates whether the warning component has child content.
   * If true, the component will project the custom content inside it.
   */
  @Input() hasChildContent = false;
}
