import { Injectable } from "@angular/core";
import { FileUploaderService } from "@app/services";
import { BehaviorSubject, Observable, Subscriber, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FileResult } from "./file-result";

@Injectable()
export class FileUploaderModalService {
  isUploading$ = new BehaviorSubject<boolean>(false);

  constructor(private fileUploaderService: FileUploaderService) {}

  uploadImage(blob: Blob, storageFolder: string): Observable<FileResult> {
    this.isUploading$.next(true);

    return combineLatest([this.readAsBlob(blob), this.uploadImageAsBlob(blob, storageFolder)]).pipe(
      map(
        ([fileDataUrl, fileUrl]) => ({ fileDataUrl, fileUrl }),
        tap(() => this.isUploading$.next(false))
      )
    );
  }

  private readAsBlob(blob: Blob): Observable<any> {
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(blob);
    return new Observable((sub: Subscriber<string>) => {
      reader.onloadend = () => {
        if (reader.result && typeof reader.result === "string") {
          sub.next(reader.result);
        }
      };

      reader.onerror = (e) => {
        sub.error(e);
      };
    });
  }

  private uploadImageAsBlob(blob: Blob, storageFolder: string): Observable<string> {
    const mimeType = blob.type;
    const formData = new FormData();

    formData.append("file", blob, `${Date.now()}.${mimeType.split("/")[1]}`);

    return this.fileUploaderService.uploadFormData(formData, storageFolder);
  }
}
