<div class="col-md-6 mt-3 wrapper" *transloco="let t; read: 'bulkLinks'">
  <div *ngIf="shortLinkUrl">
    <label class="card-title">{{ t("shortLinkPreview") }}</label>

    <div class="input-group" (click)="copyShortLink()">
      <input [ngModel]="shortLinkUrl" readonly class="form-control input-link cursor-pointer" />
      <div class="input-group-text"><i class="ri-file-copy-line font-size-20 cursor-pointer"></i></div>
    </div>
  </div>

  <div class="mb-2 mt-3">
    <label class="card-title">{{ t("qrCodePreview") }}</label>
  </div>
  <div class="mt-3 mb-3" *ngIf="!!link">
    <img class="styles-preview" [src]="link" alt="Application Styles Image" />
  </div>
</div>
