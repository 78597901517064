import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AccountService, AuthService } from "@app/core";
import { RoleTypes, Template } from "@app/models";
import { TemplateService } from "@app/services";
import { Subject, throwError } from "rxjs";
import { mergeMap, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-template-page",
  templateUrl: "./template-page.component.html",
  styleUrls: ["./template-page.component.scss"]
})
export class TemplatePageComponent implements OnInit, OnDestroy {
  private routeData: ITemplatePageComponentData | undefined = this.route.snapshot.data;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private templateService: TemplateService,
    private accountSvc: AccountService,
    private authService: AuthService,
    private titleService: Title
  ) {}

  @Input() forPrint = false;

  @Output() print = new EventEmitter();

  template: Template;

  private unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.handleDataOnRouteParams();
  }

  onPrint(): void {
    this.print.emit();
  }

  private handleDataOnRouteParams(): void {
    this.route.params
      .pipe(
        mergeMap((params: Params) => {
          const templateId: number = params["templateId"];

          let locationId = this.route.snapshot.queryParams.location;

          if (!templateId) {
            return throwError(null);
          }
          if (this.routeData?.isGlobalTemplate) return this.templateService.getGeneratedGlobal(templateId, locationId);
          else return this.templateService.getGenerated(templateId, locationId);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (generatedTemplate: Template) => {
          this.handleGeneratedTemplateOnSuccess(generatedTemplate);
        },
        () => {
          this.goToTemplates();
        }
      );
  }

  private handleGeneratedTemplateOnSuccess(generatedTemplate: Template): void {
    const { generatedBody } = generatedTemplate;
    this.template = {
      ...generatedTemplate,
      generatedBody
    };

    this.setPageTitle();
  }

  private setPageTitle(): void {
    if (this.template.name) {
      this.titleService.setTitle(this.template.name);
    }
  }

  private goToTemplates(): void {
    const path = this.authService.requredMinimumRole(RoleTypes.PartnerAdmin) ? "/admin/templates" : "/manager/templates";
    this.router.navigate([path]).then(() => {});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export interface ITemplatePageComponentData {
  isGlobalTemplate?: boolean;
}
