import { ECharts } from "echarts";

export interface EchartsToolboxFeatureData {
  params: any;
  lineChart: ECharts;
  menu: HTMLElement;
  exportSVG: HTMLElement;
  exportPNG: HTMLElement;
  exportCSV: HTMLElement;
  fileNameFirstPart: string;
  withArraySeriesData?: boolean;
}

export class DashboardExportUtilities {
  constructor() {}

  // private handlers:
  private static readonly background = "#FFF";

  private exportSVGEventListenerHandler;
  private exportCSVEventListenerHandler;
  private exportPNGEventListenerHandler;

  private static exportSVGHandler({ lineChart, menu, fileNameFirstPart }: EchartsToolboxFeatureData): void {
    const data: string = lineChart.getDataURL({ type: "svg", excludeComponents: ["toolbox"] });
    const fileName = `${fileNameFirstPart}_SVG`;

    lineChart.resize();
    DashboardExportUtilities.triggerDownload(data, fileName, "svg");
    menu.classList.remove("dom-menu-open");

    menu = null;
  }

  private static exportCSVHandler({ params, menu, withArraySeriesData = false, fileNameFirstPart }: EchartsToolboxFeatureData): void {
    const data: string = DashboardExportUtilities.downloadCSV(params, withArraySeriesData);
    const fileName = `${fileNameFirstPart}_CSV`;

    DashboardExportUtilities.triggerDownload(data, fileName, "csv");
    menu.classList.remove("dom-menu-open");

    menu = null;
  }

  private static exportPNGHandler({ lineChart, menu, fileNameFirstPart }: EchartsToolboxFeatureData): void {
    const fileName = `${fileNameFirstPart}_PNG`;

    DashboardExportUtilities.getPngImgURI(lineChart).then((imgURI: string) => {
      DashboardExportUtilities.triggerDownload(imgURI, fileName, "png");
      menu.classList.remove("dom-menu-open");

      menu = null;
    });
  }

  private static triggerDownload(href: string, filename: string, ext: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = filename + "." + ext;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private static getPngImgURI(lineChart) {
    return new Promise((resolve, reject) => {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const img = new Image();

      img.src = lineChart.getDataURL();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;

        DashboardExportUtilities.fillCanvasBackgroundWithColor(canvas, DashboardExportUtilities.background);

        const ctx = canvas.getContext("2d");

        ctx?.drawImage(img, 0, 0);

        const imgURI = canvas.toDataURL("image/png", 1);

        resolve(imgURI);
      };

      img.onerror = (error: Event | string) => {
        reject(error);
      };
    });
  }

  private static fillCanvasBackgroundWithColor(canvas: HTMLCanvasElement, color: string) {
    const context = canvas.getContext("2d");

    context.save();

    context.globalCompositeOperation = "destination-over";

    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.restore();
  }

  public static downloadCSV(chart, withArraySeriesData: boolean = false) {
    const csvData = DashboardExportUtilities.getCsvData(chart, withArraySeriesData);

    const lineArray = [];

    csvData.forEach((infoArray: string[], index: number) => {
      const line = infoArray.join(",");

      lineArray.push(index === 0 ? "\uFEFF" + line : line);
    });

    const csvContent = lineArray.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    return window.URL.createObjectURL(blob);
  }

  private static getCsvData(chart, withArraySeriesData: boolean = false): string[][] {
    const csvData: string[][] = [];

    if (chart.option.xAxis) {
      for (let i = 0; i < chart.option.xAxis.length; i++) {
        csvData.push(["", ...chart.option.xAxis[i].data]);
      }
    }

    if (chart.option.series) {
      if (withArraySeriesData) {
        const seriesItem = chart.option.series[0];
        const data = seriesItem.data;

        csvData.push([seriesItem.name]);

        for (let i = 0; i < data.length; i++) {
          csvData.push([data[i].name, data[i].value]);
        }
      } else {
        for (let i = 0; i < chart.option.series.length; i++) {
          csvData.push([chart.option.series[i].name, chart.option.series[i].data]);
        }
      }
    }

    return csvData;
  }

  private static closeOtherChartsMenu(menu: HTMLElement): boolean {
    const menuList: NodeList = document.querySelectorAll(".chart-dom-menu.dom-menu-open");

    if (Array.from(menuList)?.length) {
      return Array.from(menuList).some((menuItem: HTMLElement) => {
        const isSameNode: boolean = menu.isSameNode(menuItem);

        return !isSameNode;
      });
    }

    return false;
  }

  // main handler:
  toolboxFeatureClickHandler(data: EchartsToolboxFeatureData, info): boolean {
    if (data?.menu) {
      const { menu } = data;

      if (menu.classList.contains("dom-menu-open")) {
        menu.classList.remove("dom-menu-open");

        this.removeEventListenerOnExport(data);
      } else {
        this.removeEventListenerOnExport(data);
        menu.classList.add("dom-menu-open");

        this.setEventListenerHandlersOnExport(data);
        this.setEventListenersOnExport(data);

        return DashboardExportUtilities.closeOtherChartsMenu(menu);
      }
    }

    return false;
  }

  private setEventListenerHandlersOnExport(data: EchartsToolboxFeatureData): void {
    this.exportSVGEventListenerHandler = DashboardExportUtilities.exportSVGHandler.bind(null, data);
    this.exportCSVEventListenerHandler = DashboardExportUtilities.exportCSVHandler.bind(null, data);
    this.exportPNGEventListenerHandler = DashboardExportUtilities.exportPNGHandler.bind(null, data);
  }

  private removeEventListenerOnExport({ exportSVG, exportCSV, exportPNG }: EchartsToolboxFeatureData): void {
    exportSVG.removeEventListener("click", this.exportSVGEventListenerHandler);

    exportCSV.removeEventListener("click", this.exportCSVEventListenerHandler);

    exportPNG.removeEventListener("click", this.exportPNGEventListenerHandler);
  }

  private setEventListenersOnExport({ exportSVG, exportCSV, exportPNG }: EchartsToolboxFeatureData): void {
    exportSVG.addEventListener("click", this.exportSVGEventListenerHandler);

    exportCSV.addEventListener("click", this.exportCSVEventListenerHandler);

    exportPNG.addEventListener("click", this.exportPNGEventListenerHandler);
  }
}
