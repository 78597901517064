export const usaStates = [
  { name: "Alabama", shortName: "AL" },
  { name: "Alaska", shortName: "AK" },
  { name: "Arizona", shortName: "AZ" },
  { name: "Arkansas", shortName: "AR" },
  { name: "California", shortName: "CA" },
  { name: "Colorado", shortName: "CO" },
  { name: "Connecticut", shortName: "CT" },
  { name: "Delaware", shortName: "DE" },
  { name: "Florida", shortName: "FL" },
  { name: "Georgia", shortName: "GA" },
  { name: "Hawaii", shortName: "HI" },
  { name: "Idaho", shortName: "ID" },
  { name: "Illinois", shortName: "IL" },
  { name: "Indiana", shortName: "IN" },
  { name: "Iowa", shortName: "IA" },
  { name: "Kansas", shortName: "KS" },
  { name: "Kentucky", shortName: "KY" },
  { name: "Louisiana", shortName: "LA" },
  { name: "Maine", shortName: "ME" },
  { name: "Maryland", shortName: "MD" },
  { name: "Massachusetts", shortName: "MA" },
  { name: "Michigan", shortName: "MI" },
  { name: "Minnesota", shortName: "MN" },
  { name: "Mississippi", shortName: "MS" },
  { name: "Missouri", shortName: "MO" },
  { name: "Montana", shortName: "MT" },
  { name: "Nebraska", shortName: "NE" },
  { name: "Nevada", shortName: "NV" },
  { name: "New Hampshire", shortName: "NH" },
  { name: "New Jersey", shortName: "NJ" },
  { name: "New Mexico", shortName: "NM" },
  { name: "New York", shortName: "NY" },
  { name: "North Carolina", shortName: "NC" },
  { name: "North Dakota", shortName: "ND" },
  { name: "Ohio", shortName: "OH" },
  { name: "Oklahoma", shortName: "OK" },
  { name: "Oregon", shortName: "OR" },
  { name: "Pennsylvania", shortName: "PA" },
  { name: "Rhode Island", shortName: "RI" },
  { name: "South Carolina", shortName: "SC" },
  { name: "South Dakota", shortName: "SD" },
  { name: "Tennessee", shortName: "TN" },
  { name: "Texas", shortName: "TX" },
  { name: "Utah", shortName: "UT" },
  { name: "Vermont", shortName: "VT" },
  { name: "Virginia", shortName: "VA" },
  { name: "Washington", shortName: "WA" },
  { name: "West Virginia", shortName: "WV" },
  { name: "Wisconsin", shortName: "WI" },
  { name: "Wyoming", shortName: "WY" }
];

export const canadaProvinces = [
  {
    name: "Alberta",
    shortName: "AB",
    rank: 4,
    population: 4067175,
    capital: "Edmonton",
    latitude: 53.9332706,
    longitude: -116.5765035
  },
  {
    name: "British Columbia",
    shortName: "BC",
    rank: 3,
    population: 4648055,
    capital: "Victoria",
    latitude: 53.7266683,
    longitude: -127.6476206
  },
  {
    name: "Manitoba",
    shortName: "MB",
    rank: 5,
    population: 1278365,
    capital: "Winnipeg",
    latitude: 53.7608608,
    longitude: -98.8138763
  },
  {
    name: "New Brunswick",
    shortName: "NB",
    rank: 8,
    population: 747101,
    capital: "Fredericton",
    latitude: 46.5653163,
    longitude: -66.4619164
  },
  {
    name: "Newfoundland And Labrador",
    shortName: "NL",
    rank: 9,
    population: 519716,
    capital: "St John's",
    latitude: 48.6064556,
    longitude: -56.3330408
  },
  {
    name: "Nova Scotia",
    shortName: "NS",
    rank: 7,
    population: 923598,
    capital: "Halifax",
    latitude: 44.6819866,
    longitude: -63.744311
  },
  {
    name: "Nunavut",
    shortName: "NU",
    rank: 12,
    population: 35944,
    capital: "Iqaluit",
    latitude: 70.2997711,
    longitude: -83.1075769
  },
  {
    name: "Northwest Territories",
    shortName: "NT",
    rank: 11,
    population: 41786,
    capital: "Yellowknife",
    latitude: 64.8255441,
    longitude: -124.8457334
  },
  {
    name: "Ontario",
    shortName: "ON",
    rank: 1,
    population: 13448494,
    capital: "Toronto",
    latitude: 51.253775,
    longitude: -85.3232139
  },
  {
    name: "Prince Edward Island",
    shortName: "PE",
    rank: 10,
    population: 142907,
    capital: "Charlottetown",
    latitude: 46.510712,
    longitude: -63.4168136
  },
  {
    name: "Quebec",
    shortName: "QC",
    rank: 2,
    population: 8164361,
    capital: "Quebec City",
    latitude: 52.9399159,
    longitude: -73.5491361
  },
  {
    name: "Saskatchewan",
    shortName: "SK",
    rank: 6,
    population: 1098352,
    capital: "Regina",
    latitude: 52.9399159,
    longitude: -106.4508639
  },
  {
    name: "Yukon",
    shortName: "YT",
    rank: 13,
    population: 35874,
    capital: "Whitehorse",
    latitude: 64.2823274,
    longitude: -135
  }
];
