<div class="card" *transloco="let t; read: 'account.denied'">
  <div class="card-body">
    <div class="p-2">
      <div class="text-center">
        <div class="avatar-md mx-auto">
          <div class="avatar-title rounded-circle bg-light">
            <i class="mdi mdi-lock h1 mb-0 text-primary"></i>
          </div>
        </div>
        <div class="p-2 mt-4">
          <h4>{{ t("denied") }}</h4>
          <p>
            <span *ngIf="isManager">{{ t("notPermited") }}</span
            ><br />
            <span *ngIf="isManager">{{ t("contactAdmin") }}</span>
            <span *ngIf="!isManager">{{ t("mangerRoleToGrant") }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isManager" (click)="goToDashboard()" class="mt-3 text-center">
    <p>
      <a class="cursor-pointer fw-medium text-primary">{{ t("returnHome") }}</a>
    </p>
  </div>
  <div *ngIf="!isManager" (click)="goToCmc()" class="mt-3 text-center">
    <p>
      <a class="cursor-pointer fw-medium text-primary">{{ t("returnToCmc") }}</a>
    </p>
  </div>
</div>
