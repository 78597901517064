import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Location } from "@app/models";
import { catchError, map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { LocationService, MarketService } from "@app/services";
import { LoadingService } from "@app/core";

@Component({
  selector: "app-update-device-page",
  templateUrl: "./update-device-page.component.html"
})
export class UpdateDevicePageComponent implements OnInit {
  constructor(private locationService: LocationService, private marketService: MarketService, private loadingSvc: LoadingService) {}

  @Input() actionId: "add" | "edit" = "add";
  @Input() forAdmin = false;
  @Input() loading = false;

  @Input() formGroup: UntypedFormGroup;
  @Input() accountId: number;
  @Input() locationId: number;

  @Output() submit = new EventEmitter<null>();
  @Output() goToDevices = new EventEmitter<null>();

  isLoadPage = true;
  actionTitle = "";
  locations$: Observable<Location[]>;

  deviceTypes = [
    { id: 1, name: "Merchant Tablet" },
    { id: 2, name: "CFS" }
  ];

  deviceStatuses = [
    { id: 1, name: "Ordered" },
    { id: 2, name: "Delivered" },
    { id: 3, name: "Active" }
  ];

  get deviceNameControl(): UntypedFormControl {
    return this.formGroup.controls["deviceName"] as UntypedFormControl;
  }

  get deviceTypeControl(): UntypedFormControl {
    return this.formGroup.controls["deviceType"] as UntypedFormControl;
  }

  get serialNumberControl(): UntypedFormControl {
    return this.formGroup.controls["serialNumber"] as UntypedFormControl;
  }

  get deviceStatusControl(): UntypedFormControl {
    return this.formGroup.controls["deviceStatus"] as UntypedFormControl;
  }

  get locationControl(): UntypedFormControl {
    return this.formGroup.controls["location"] as UntypedFormControl;
  }

  ngOnInit() {
    this.setLocations();
  }

  onSubmit(): void {
    this.submit.emit();
  }

  onGoToDevices(): void {
    this.goToDevices.emit(null);
  }

  private setLocations(): void {
    this.startLoading();

    if (this.forAdmin) {
      this.setLocationsForAdmin();
    } else {
      this.setLocationsForManager();
    }
  }

  private setLocationsForAdmin(): void {
    this.locations$ = this.marketService.getLocations().pipe(
      startWith([]),
      map((locations: Location[] = null) => {
        if (!locations || locations?.length) {
          this.finishLoading();
        }

        return locations.filter((location: Location) => location.accountId === this.accountId);
      }),
      catchError(() => {
        this.finishLoading();

        return [];
      })
    );
  }

  private setLocationsForManager(): void {
    this.locations$ = this.locationService.getAll().pipe(
      startWith([]),
      map((locations: Location[] = []) => {
        if (!locations || locations?.length) {
          this.finishLoading();
        }

        return locations;
      }),
      catchError(() => {
        this.finishLoading();

        return [];
      })
    );
  }

  private startLoading(): void {
    this.loadingSvc.showSpinner();
    this.loading = true;
  }

  private finishLoading(): void {
    this.loadingSvc.hideSpinner();
    this.loading = false;
  }
}
