<div class="default-widget-modal">
  <div class="modal-header" *transloco="let t; read: 'widgets.common'">
    <h4 class="page-title-box m-0 p-0">{{ title }}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    <p>{{ description }}</p>
    <app-embedded-video-form-control [value]="videoId" [preview]="true" *ngIf="videoId"> </app-embedded-video-form-control>
  </div>
  <div class="modal-footer" *transloco="let tcc; read: 'common.controls'">
    <!--<button type="button" class="btn btn-secondary" (click)="dismiss()">
    <i class="fa-lg mdi mdi-cancel"></i>
    {{tcc('close')}}
  </button>-->
  </div>
</div>
