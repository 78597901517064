import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { CreateNewRegistrationModel, NewRegistrationModel } from "@app/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class NewRegistrationService {
  serviceUrl = "newRegistration";
  constructor(private http: DataService) {}

  getAll(partnerIds: number[] = []): Observable<NewRegistrationModel[]> {
    const queryParams = partnerIds.map((number) => `partnerIds=${number}`).join("&");
    const queryString = partnerIds.length ? `?${queryParams}` : "";

    return this.http.get(`${this.serviceUrl}${queryString}`);
  }

  getById(id: number): Observable<NewRegistrationModel> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  delete(id: number): Observable<void> {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  create(createModel: CreateNewRegistrationModel): Observable<number> {
    return this.http.post(`${this.serviceUrl}`, createModel);
  }
}
