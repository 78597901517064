import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Template } from "@app/models";

@Component({
  selector: "app-printable-template",
  templateUrl: "./printable-template.component.html",
  styleUrls: ["./printable-template.component.scss"]
})
export class PrintableTemplateComponent {
  @Input() template: Template;

  @Output() print = new EventEmitter();

  private readyToPrintTemplateCount = 0;

  onPrint(): void {
    this.readyToPrintTemplateCount += 1;

    if (this.readyToPrintTemplateCount === 2) {
      setTimeout(() => {
        this.print.emit();
      }, 0);
    }
  }
}
