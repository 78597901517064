import { Injectable } from "@angular/core";
import { CustomerDevice } from "@app/models";
import { DataService } from "@app/core/services/data.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CustomerDevicesService {
  serviceUrl = "customerdevice";

  constructor(private http: DataService) {}

  get(deviceId: number): Observable<CustomerDevice> {
    return this.http.get(`${this.serviceUrl}/${deviceId}`);
  }
  getAll(accountId: number): Observable<CustomerDevice[]> {
    return this.http.get(`${this.serviceUrl}/byaccount/${accountId}`);
  }
  getAllCurrent(): Observable<CustomerDevice[]> {
    return this.http.get(`${this.serviceUrl}/allcurrent`);
  }
  addDevice(accountId: number, device: CustomerDevice): Observable<CustomerDevice> {
    return this.http.post(`${this.serviceUrl}/${accountId}`, device);
  }
  updateDevice(device: CustomerDevice): Observable<number> {
    return this.http.put(`${this.serviceUrl}/${device.id}`, device);
  }
  updateDeviceLocation(id: number, locationId: number): Observable<number> {
    return this.http.post(`${this.serviceUrl}/${id}/location/${locationId}`);
  }
  deleteDevice(deviceId: number) {
    return this.http.delete(`${this.serviceUrl}/${deviceId}`);
  }
}
