import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { QrCodeFormat, QrCodeWithTemplatePostDto } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class QrCodeTemplateService {
  private serviceUrl = "qrcode";

  constructor(private http: DataService) {}

  getQrCodeHtmlContent(format: QrCodeFormat, qrCodeBulkDto: QrCodeWithTemplatePostDto): Observable<any> {
    return this.http.get(`${this.serviceUrl}/${format}`, qrCodeBulkDto);
  }

  getBulkWithHtmlTemplate(format: QrCodeFormat, qrCodeBulkDto: QrCodeWithTemplatePostDto[]): Observable<any[]> {
    return this.http.get(`${this.serviceUrl}/bulk/${format}`, qrCodeBulkDto);
  }
}
