import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Observable } from "rxjs";
import { Partner, PartnerGetDto, PartnerPostDto } from "../models/partner";

@Injectable({
  providedIn: "root"
})
export class PartnerService {
  private serviceUrl = "Partner";

  constructor(private http: DataService) {}

  getPartnerList(): Observable<PartnerGetDto[]> {
    return this.http.get<PartnerGetDto[]>(`${this.serviceUrl}`);
  }

  getPartner(id: number): Observable<Partner> {
    return this.http.get<Partner>(`${this.serviceUrl}/${id}`);
  }

  getOwnPartner(): Observable<Partner> {
    return this.http.get(`${this.serviceUrl}/own`);
  }

  createPartner(data: Partner): Observable<PartnerPostDto> {
    return this.http.post<PartnerPostDto>(`${this.serviceUrl}`, data);
  }

  updatePartner(id: number, data: Partner): Observable<PartnerPostDto> {
    return this.http.put<PartnerPostDto>(`${this.serviceUrl}/${id}`, data);
  }

  deletePartner(id: number): Observable<unknown> {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }
}
