import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { SmsUsaAccount } from "@app/models";

import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class SmsUsaService {
  private serviceUrl = "SmsUsaPlatform";
  constructor(private http: DataService) {}
  getAccount(accountId: string): Observable<SmsUsaAccount> {
    return this.http.get(`${this.serviceUrl}/getAccount/${accountId}`);
  }

  getAllAccountIds(): Observable<string[]> {
    return this.http.get(`${this.serviceUrl}/getAccounts`);
  }
  createAccount(account: SmsUsaAccount): Observable<SmsUsaAccount> {
    return this.http.post(`${this.serviceUrl}/createAccount`, account);
  }
}
