import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from "@angular/forms";
import { AccountService, AuthService, LoadingService } from "@app/core";
import { Brand, Location, LoyaltyCard, RoleTypes } from "@app/models";
import {
  EnrollLoyaltyWidgetSettings,
  LoyaltyOnboardingRequest,
  LoyaltyProgram,
  LoyaltyType
} from "@app/pages/components/widget-settings/enroll-loyalty-widget-settings/enroll-loyalty-widget-settings";
import { WidgetSettingsBaseComponent } from "@app/pages/components/widget-settings/widget-settings-base";
import { EnumRepresentationService, LoyaltyCardService } from "@app/services";
import { Patterns } from "@app/shared/validators";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CreateLoyaltyProgramModalComponent } from "./create-loyalty-program-modal/create-loyalty-program-modal.component";
import { LoyaltyProgramPreviewModalComponent } from "./loyalty-program-preview-modal/loyalty-program-preview-modal.component";

@Component({
  selector: "app-enroll-loyalty-widget-settings",
  templateUrl: "./enroll-loyalty-widget-settings.component.html",
  styleUrls: ["./enroll-loyalty-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: EnrollLoyaltyWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnrollLoyaltyWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class EnrollLoyaltyWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private _widget: EnrollLoyaltyWidgetSettings;
  public loyaltyTypesRepresentation: { [status: number]: string };
  public loyaltyProgramTypesRepresentation: { [status: number]: string };
  public loyaltyTypes = [LoyaltyType.SingleLocation, LoyaltyType.MultiLocations, LoyaltyType.AllLocations];
  public loyaltyProgramTypes = [LoyaltyProgram.DigitalStampCard, LoyaltyProgram.PointsToPrize, LoyaltyProgram.RewardsCard];
  public LOYALTY_PROGRAM = LoyaltyProgram;
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  public isCreatedProgram = false;
  private _brand: Brand;
  public _isConfiguredAdmin: boolean;
  @Output() widgetSettingsReady = new EventEmitter();

  @Input() set widgetSettingsJson(value: string) {
    if (value) this.widgetSettings = JSON.parse(value) as EnrollLoyaltyWidgetSettings;
  }

  get isConfiguredAdmin(): boolean {
    return this._isConfiguredAdmin;
  }

  @Input() set isConfiguredAdmin(isConfiguredAdmin: boolean) {
    this._isConfiguredAdmin = isConfiguredAdmin;
    if (this.form) {
      this.changeValidate();
    }
  }

  @Input() location: Location;

  @Input() set brand(value: Brand) {
    if (value) this._brand = value;
  }

  get brand(): Brand {
    return this._brand;
  }
  get isAdmin() {
    return this.authService.requredMinimumRole(RoleTypes.PartnerAdmin);
  }

  public set widgetSettings(value: EnrollLoyaltyWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        if (value.loyaltyProgram) this.form.controls.loyaltyProgram.setValue(value.loyaltyProgram);
        if (value.maxStampsPoints) this.form.controls.maxStampsPoints.setValue(value.maxStampsPoints);
        if (value.loyaltyCardId) this.form.controls.loyaltyCardId.setValue(value.loyaltyCardId);
        if (value.enrollLoyaltyLink) this.form.controls.enrollLoyaltyLink.setValue(value.enrollLoyaltyLink);
        if (value.onboardingRequest?.loyaltyType) {
          this.updateControls(value.onboardingRequest);
          if (this.location?.id && !this.form.controls.enrollLoyaltyLink.value) {
            this.getCurrentLoyaltyCard().subscribe((c) => {
              if (c) this.form.controls.enrollLoyaltyLink.setValue(c.url);
            });
          }
        }
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private loaderSvc: LoadingService,
    private loyaltySvc: LoyaltyCardService,
    private accountSvc: AccountService,
    private authService: AuthService,
    private enumRepresentationService: EnumRepresentationService,
    private activeModal: NgbActiveModal,
    private modalSvc: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.enumRepresentationService.init();
    this.form = new FormGroup({
      maxStampsPoints: new FormControl(1),
      loyaltyCardId: new FormControl(null),
      loyaltyProgram: new FormControl(LoyaltyProgram.PointsToPrize),
      enrollLoyaltyLink: new FormControl(null),
      onboardingRequest: new FormControl(null)
    });

    this.loyaltyTypesRepresentation = this.enumRepresentationService.eventLoyaltyTypes;
    this.loyaltyProgramTypesRepresentation = this.enumRepresentationService.eventLoyaltyProgramTypes;
    this.form.controls.loyaltyProgram.valueChanges.subscribe(() => this.cardTypeChanged());
    this.changeValidate();
    this.form.controls.onboardingRequest.valueChanges.subscribe(() => this.changeValidate());
    this.form.controls.enrollLoyaltyLink.valueChanges.subscribe(() => this.changeValidate());
    this.widgetSettingsReady.emit();
  }

  changeValidate() {
    this.form.controls.onboardingRequest.clearValidators();
    this.form.controls.enrollLoyaltyLink.clearValidators();

    if (this.form.controls.enrollLoyaltyLink.value && !this.form.controls.onboardingRequest.value) {
      this.form.controls.enrollLoyaltyLink.setValidators([
        Validators.required,
        Validators.pattern(Patterns.url),
        Validators.maxLength(100)
      ]);
    } else if (this.isAdmin && this.isConfiguredAdmin) {
      this.form.controls.enrollLoyaltyLink.setValidators([
        Validators.required,
        Validators.pattern(Patterns.url),
        Validators.maxLength(100)
      ]);
    } else if (this.isAdmin && !this.isConfiguredAdmin) {
      this.form.controls.enrollLoyaltyLink.setValidators([Validators.pattern(Patterns.url), Validators.maxLength(100)]);
    } else {
      this.form.controls.onboardingRequest.setValidators([Validators.required]);
    }
    this.form.controls.enrollLoyaltyLink.updateValueAndValidity({ emitEvent: false });
    this.form.controls.onboardingRequest.updateValueAndValidity({ emitEvent: false });
    this.onChange(null);
    this.changeDetectorRef.markForCheck();
  }

  clearMaxStampsPoints() {
    this.form.controls.maxStampsPoints.setValue(null);
    this.onChange(null);
    this.changeDetectorRef.markForCheck();
  }

  cardTypeChanged() {
    const loyaltyControl = this.form.controls.loyaltyProgram;
    this.form.controls.maxStampsPoints.clearValidators();
    if (loyaltyControl.value === LoyaltyProgram.MyCard) {
    } else {
      this.form.controls.maxStampsPoints.setValidators([Validators.required]);
    }

    this.form.controls.maxStampsPoints.updateValueAndValidity();
  }

  syncLocation() {
    this.loaderSvc.showSpinner();
    this.loyaltySvc.syncLocationLoyaltyCard(this.location.id).subscribe(
      (_) => {},
      () => {},
      () => {
        this.loaderSvc.hideSpinner();
      }
    );
  }

  createNewProgram() {
    const createNewProgramModal = this.modalSvc.open(CreateLoyaltyProgramModalComponent, {
      backdrop: "static",
      size: "xxl",
      centered: true
    });
    const comp: CreateLoyaltyProgramModalComponent = createNewProgramModal.componentInstance;
    comp.title = "Create New Program";
    comp.locationId = this.location?.id;
    comp.industryId = this.brand?.industryId;
    if (this.form.controls.enrollLoyaltyLink.value) comp.enrollLoyaltyLink = this.form.controls.enrollLoyaltyLink.value;

    createNewProgramModal.result
      .then((res: LoyaltyOnboardingRequest) => {
        if (res) {
          this.updateControls(res);
          this.changeDetectorRef.markForCheck();
        }
      })
      .catch((res) => {});
  }
  private getCurrentLoyaltyCard(): Observable<LoyaltyCard> {
    return this.loyaltySvc.getAccountAvailableLoyaltyCards(this.location.accountId).pipe(
      map((cards) => {
        const request: LoyaltyOnboardingRequest = this.form.controls.onboardingRequest.value;
        if (cards.length == 1) return cards[0];
        const card = cards.find((c) => c.loyaltyCardType == request.loyaltyType);
        return card;
      })
    );
  }

  previewProgram() {
    const previewProgramModal = this.modalSvc.open(LoyaltyProgramPreviewModalComponent, {
      backdrop: "static",
      size: "xxl",
      centered: true
    });
    const comp: LoyaltyProgramPreviewModalComponent = previewProgramModal.componentInstance;
    comp.title = "Loyalty Program Details";
    comp.locationId = this.location?.id;
    comp.accountId = this.location?.accountId;
    comp.industryId = this.brand?.industryId;
    comp.loyaltyProgramDetailsResult = this.form.controls.onboardingRequest.value as LoyaltyOnboardingRequest;

    if (this.form.controls.enrollLoyaltyLink.value) comp.enrollLoyaltyLink = this.form.controls.enrollLoyaltyLink.value;
  }

  updateControls(onboardingRequest: LoyaltyOnboardingRequest): void {
    this.isCreatedProgram = true;
    this.form.controls.onboardingRequest.setValue(onboardingRequest);
  }
}
