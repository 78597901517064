import { Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from "@angular/core";
import { ColorPickerComponent } from "./color-picker";
import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  RepositionScrollStrategy,
  ScrollStrategyOptions
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { fromEvent, merge, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Directive({
  selector: "[appCustomColorPicker]"
})
export class CustomColorPickerDirective implements OnDestroy {
  private readonly colorPickerWidth = 400;
  private readonly colorPickerHeight = 424;
  private readonly overlayGap = 4;

  private overlayRef: OverlayRef;
  private destroy$ = new Subject();

  @Output() valueSelected = new EventEmitter<string>();

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private scrollStrategy: ScrollStrategyOptions
  ) {
    merge(
      fromEvent<MouseEvent>(document, "click").pipe(
        filter((event: MouseEvent) => {
          const clickTarget = event.target as HTMLElement;
          return this.overlayRef && this.overlayRef.hasAttached() && clickTarget !== this.el.nativeElement;
        })
      ),
      fromEvent(window, "resize").pipe(filter(() => this.overlayRef && this.overlayRef.hasAttached()))
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.overlayRef.detach();
      });
  }

  @HostListener("click") onClick() {
    if (!this.overlayRef?.hasAttached()) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.el)
        .withPositions(this.getPositions())
        .withFlexibleDimensions(false)
        .withPush(true)
        .withLockedPosition(true);
      const scrollStrategy: RepositionScrollStrategy = this.scrollStrategy.reposition();

      this.overlayRef = this.overlay.create({
        width: this.colorPickerWidth,
        height: this.colorPickerHeight,
        positionStrategy,
        scrollStrategy
      });

      const colorPickerPortal = new ComponentPortal(ColorPickerComponent);
      const component = this.overlayRef.attach(colorPickerPortal);
      component.instance.selectedValue = this.el.nativeElement.value;

      component.instance.colorSelected.pipe(takeUntil(this.destroy$)).subscribe((color: string) => {
        this.el.nativeElement.value = color;
        this.valueSelected.emit(color);
      });

      component.instance.gradientSelected.pipe(takeUntil(this.destroy$)).subscribe((gradient: string) => {
        this.el.nativeElement.value = gradient;
        this.valueSelected.emit(gradient);
      });

      this.overlayRef
        .backdropClick()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.overlayRef.detach();
        });
    }
  }

  ngOnDestroy() {
    this.overlayRef?.dispose();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getPositions(): ConnectedPosition[] {
    return [
      {
        originX: "start",
        originY: "bottom",
        overlayX: "start",
        overlayY: "top",
        offsetY: this.overlayGap
      },
      {
        originX: "end",
        originY: "bottom",
        overlayX: "end",
        overlayY: "top",
        offsetY: this.overlayGap
      },
      {
        originX: "start",
        originY: "top",
        overlayX: "start",
        overlayY: "bottom",
        offsetY: -this.overlayGap
      },
      {
        originX: "end",
        originY: "top",
        overlayX: "end",
        overlayY: "bottom",
        offsetY: -this.overlayGap
      }
    ];
  }
}
