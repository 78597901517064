import { Injectable } from "@angular/core";
import { BrandType, AllowedDeliveryChannel, LandingPurposes, UserStatus } from "@app/models";
import {
  LoyaltyProgram,
  LoyaltyType
} from "@app/pages/components/widget-settings/enroll-loyalty-widget-settings/enroll-loyalty-widget-settings";
import { CouponPerUsers } from "@app/pages/components/widget-settings/issue-coupon-widget-settings/issue-coupon-widget-settings";
import {
  SetReminderFrequency,
  SetReminderExpiration,
  SetReminderType
} from "@app/pages/components/widget-settings/set-reminder-widget-settings/set-reminder-widget-settings";
import { TranslocoService } from "@ngneat/transloco";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

export interface WidgetEventTypes {
  [status: number]: string;
}

@Injectable({
  providedIn: "root"
})
export class EnumRepresentationService {
  eventStatuses: WidgetEventTypes = {};
  eventStatusColors: WidgetEventTypes = {};
  eventDeliveryMethods: WidgetEventTypes = {};
  eventLoyaltyTypes: WidgetEventTypes = {};
  eventLoyaltyProgramTypes: WidgetEventTypes = {};
  eventCouponPerUserTypes: WidgetEventTypes = {};
  eventSetReminderType: WidgetEventTypes = {};
  eventSetReminderFrequencyName: WidgetEventTypes = {};
  eventSetReminderFrequencyLabel: WidgetEventTypes = {};
  eventSetReminderExpiration: WidgetEventTypes = {};
  eventBrandTypes: WidgetEventTypes = {};

  landingPurposes = new Map<number, string>();
  load$ = new BehaviorSubject(false);
  loaded = this.load$.asObservable();

  constructor(private translateSvc: TranslocoService) {
    translateSvc.events$.pipe(filter((e) => e.type === "translationLoadSuccess")).subscribe((_) => this.init());
  }

  init() {
    console.log("init");
    this.eventStatuses[UserStatus.Undefined] = this.translateSvc.translateObject("enums.users");
    this.eventStatuses[UserStatus.Active] = this.translateSvc.translateObject("enums.cusers");
    this.eventStatuses[UserStatus.Inactive] = this.translateSvc.translateObject("enums.ncusers");

    this.eventStatusColors[UserStatus.Active] = "success";
    this.eventStatusColors[UserStatus.Inactive] = "warning";

    this.eventDeliveryMethods[AllowedDeliveryChannel.qr] = this.translateSvc.translateObject("enums.qr");
    this.eventDeliveryMethods[AllowedDeliveryChannel.sms] = this.translateSvc.translateObject("enums.sms");
    this.eventDeliveryMethods[AllowedDeliveryChannel.email] = this.translateSvc.translateObject("enums.email");
    this.eventDeliveryMethods[AllowedDeliveryChannel.emailAndSms] = this.translateSvc.translateObject("enums.smsemail");
    this.eventDeliveryMethods[AllowedDeliveryChannel.hyperLink] = this.translateSvc.translateObject("enums.hyperLink");

    this.eventLoyaltyTypes[LoyaltyType.AllLocations] = this.translateSvc.translateObject("enums.allLoc");
    this.eventLoyaltyTypes[LoyaltyType.SingleLocation] = this.translateSvc.translateObject("enums.1loc");
    this.eventLoyaltyTypes[LoyaltyType.MultiLocations] = this.translateSvc.translateObject("enums.mloc");

    this.eventLoyaltyProgramTypes[LoyaltyProgram.DigitalStampCard] = this.translateSvc.translateObject("enums.dcard");
    this.eventLoyaltyProgramTypes[LoyaltyProgram.PointsToPrize] = this.translateSvc.translateObject("enums.prize");
    this.eventLoyaltyProgramTypes[LoyaltyProgram.RewardsCard] = this.translateSvc.translateObject("enums.rewcard");

    this.eventCouponPerUserTypes[CouponPerUsers.Forever] = this.translateSvc.translateObject("enums.uforever");
    this.eventCouponPerUserTypes[CouponPerUsers.PerMonth] = this.translateSvc.translateObject("enums.umont");
    this.eventCouponPerUserTypes[CouponPerUsers.PerYear] = this.translateSvc.translateObject("enums.uyear");

    this.eventSetReminderType[SetReminderType.SMSDefaultSettings] = this.translateSvc.translateObject("enums.smsdef");
    this.eventSetReminderType[SetReminderType.SMSFromLocalNumber] = this.translateSvc.translateObject("enums.local");
    this.eventSetReminderType[SetReminderType.SMSFromTollFreeNumber] = this.translateSvc.translateObject("enums.toll");
    this.eventSetReminderType[SetReminderType.SMSFromMyStore] = this.translateSvc.translateObject("enums.store");

    this.eventSetReminderFrequencyName[SetReminderFrequency.Weekly] = this.translateSvc.translateObject("enums.frequencyWeekly");
    this.eventSetReminderFrequencyName[SetReminderFrequency.OncePerTwoWeeks] = this.translateSvc.translateObject("enums.frequencyBiWeekly");
    this.eventSetReminderFrequencyName[SetReminderFrequency.Monthly] = this.translateSvc.translateObject("enums.frequencyMonthly");
    this.eventSetReminderFrequencyName[SetReminderFrequency.Quarterly] = this.translateSvc.translateObject("enums.frequencyQuarterly");

    this.eventSetReminderFrequencyLabel[SetReminderFrequency.Weekly] = this.translateSvc.translateObject("enums.weekly");
    this.eventSetReminderFrequencyLabel[SetReminderFrequency.OncePerTwoWeeks] = this.translateSvc.translateObject("enums.biWeekly");
    this.eventSetReminderFrequencyLabel[SetReminderFrequency.Monthly] = this.translateSvc.translateObject("enums.monthly");
    this.eventSetReminderFrequencyLabel[SetReminderFrequency.Quarterly] = this.translateSvc.translateObject("enums.quarterly");

    this.eventSetReminderExpiration[SetReminderExpiration.ThreeMonth] = this.translateSvc.translateObject("enums.send3m");
    this.eventSetReminderExpiration[SetReminderExpiration.SixMonth] = this.translateSvc.translateObject("enums.send6m");
    this.eventSetReminderExpiration[SetReminderExpiration.TwelveMonth] = this.translateSvc.translateObject("enums.send12m");
    this.eventSetReminderExpiration[SetReminderExpiration.TwentyFour] = this.translateSvc.translateObject("enums.send24m");
    this.eventSetReminderExpiration[SetReminderExpiration.ThirtySix] = this.translateSvc.translateObject("enums.send36m");
    this.eventSetReminderExpiration[SetReminderExpiration.FortyEight] = this.translateSvc.translateObject("enums.send48m");

    this.eventBrandTypes[BrandType.Public] = this.translateSvc.translateObject("enums.public");
    this.eventBrandTypes[BrandType.Private] = this.translateSvc.translateObject("enums.private");

    this.landingPurposes.set(LandingPurposes.None, this.translateSvc.translateObject("enums.purposeNone"));
    this.landingPurposes.set(LandingPurposes.RequestReview, this.translateSvc.translateObject("enums.purposeReview"));
    this.landingPurposes.set(LandingPurposes.EnrollToLoyalty, this.translateSvc.translateObject("enums.purposeEnrollToLoyalty"));
    this.landingPurposes.set(LandingPurposes.DownloadMobileApp, this.translateSvc.translateObject("enums.purposeDownloadMobileApp"));
    this.landingPurposes.set(LandingPurposes.ConnectSocialNetwork, this.translateSvc.translateObject("enums.purposeConnectSocialNetwork"));
    this.landingPurposes.set(LandingPurposes.OneTimePayment, this.translateSvc.translateObject("enums.purposeOneTimePayment"));
    this.landingPurposes.set(LandingPurposes.AnniversaryClub, this.translateSvc.translateObject("enums.purposeAnniversaryClub"));
    this.landingPurposes.set(LandingPurposes.AddPromo, this.translateSvc.translateObject("enums.purposeAddPromo"));
    this.landingPurposes.set(LandingPurposes.AddToReferralProgram, this.translateSvc.translateObject("enums.purposeAddToReferralProgram"));
    this.landingPurposes.set(LandingPurposes.AddToWaitlist, this.translateSvc.translateObject("enums.purposeAddToWaitlist"));
    this.landingPurposes.set(LandingPurposes.ScheduleAppointment, this.translateSvc.translateObject("enums.purposeScheduleAppointment"));
    this.landingPurposes.set(LandingPurposes.SendGiftCard, this.translateSvc.translateObject("enums.purposeSendGiftCard"));
    this.landingPurposes.set(LandingPurposes.SendSurvey, this.translateSvc.translateObject("enums.purposeSendSurvey"));
    this.landingPurposes.set(LandingPurposes.SetReminder, this.translateSvc.translateObject("enums.purposeSetReminder"));

    this.load$.next(true);
  }
}
