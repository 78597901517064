import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Observable } from "rxjs";
import { CashRegister, ContentProviderConnectCashRegisterRequestDto, ContentProviderType } from "cfs-communication-pack";
import { ContentProviderConnectCashRegisterResponseDto } from "@app/models/content-providers";

@Injectable({
  providedIn: "root"
})
export class ContentProviderService {
  serviceUrl = "ContentProvider";

  constructor(private http: DataService) {}

  connectCashRegisterToProvider(
    providerType: ContentProviderType,
    cashRegisterId: number,
    data: ContentProviderConnectCashRegisterRequestDto
  ): Observable<ContentProviderConnectCashRegisterResponseDto> {
    return this.http.post<ContentProviderConnectCashRegisterResponseDto>(`${this.serviceUrl}/${providerType}/cashRegister/${cashRegisterId}/connect`, data);
  }
}
