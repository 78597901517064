import { Injectable } from "@angular/core";
import { AuthService, DataService } from "@app/core";
import { QuickSetupChecklist, SubscriptionPlan, Location, SendEmailWithRegistrationUrlRequestDto, User } from "@app/models";
import { QuickSetupConfiguration } from "@app/models";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CompletequickSetupModel } from "../pages/quick-setup/models";

@Injectable({ providedIn: "root" })
export class QuickSetupService {
  private serviceUrl = "QuickSetup";
  constructor(private http: DataService, private authSvc: AuthService) {}
  createAccount(quickSetupModel: CompletequickSetupModel): Observable<any> {
    return this.http.post(`${this.serviceUrl}`, quickSetupModel);
  }

  getChecklist(locationId: number): Observable<QuickSetupChecklist> {
    return this.http.get(`${this.serviceUrl}/getchecklist/${locationId}`);
  }
  updateChecklist(locationId: number, checklist: QuickSetupChecklist): Observable<QuickSetupChecklist> {
    return this.http.post(`${this.serviceUrl}/updatechecklist/${locationId}`, checklist);
  }
  markAsConfigured(locationId: number, widgetId: number): Observable<boolean> {
    return this.http.post(`${this.serviceUrl}/markAsConfigured/${locationId}/${widgetId}`);
  }
  doneQuickSetup(): Observable<boolean> {
    return this.http.post(`${this.serviceUrl}/doneQuickSetup`);
  }
  getConfiguration(): Observable<QuickSetupConfiguration> {
    return this.http.get(`${this.serviceUrl}/configuration`);
  }

  resetAccount(): Observable<boolean> {
    return this.http.post(`${this.serviceUrl}/resetAccount`);
  }

  getAccountSubscriptionPlan(): Observable<SubscriptionPlan> {
    return this.http.get(`${this.serviceUrl}/account/subscriptionPlan`);
  }

  getAccountSubscriptionLocation(): Observable<Location> {
    return this.http.get(`${this.serviceUrl}/account/subscriptionPlan/location`);
  }

  sendRegistrationUrl(user: User, registrationUrl: string): Observable<null> {
    const { email, firstName = "User", lastName } = user;
    const data: SendEmailWithRegistrationUrlRequestDto = { email, firstName, lastName, registrationUrl };

    return this.http.post(`${this.serviceUrl}/send/email/registration-url`, data);
  }
}
