import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../core";
import { PagesWidgetCompletePostDto } from "../../dto";

@Injectable({
  providedIn: "root"
})
export class PagesWidgetService {
  private serviceUrl = "pages/widget";

  constructor(private api: ApiService) {}

  send(payload: PagesWidgetCompletePostDto): Observable<void> {
    return this.api.post(`${this.serviceUrl}/send`, payload);
  }
}
