<div class="modal-header">
  <h5 class="modal-title">Custom filter</h5>

  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    [title]="'common.controls.close' | transloco"
    (click)="dismiss()"
  ></button>
</div>

<div class="modal-body">
  <app-custom-range-datepicker
    [fromDate]="fromDate"
    [toDate]="toDate"
    [maxDaysBetween]="maxDaysBetween"
    (selected)="updateRangeDates($event)"
  >
  </app-custom-range-datepicker>
</div>

<div class="modal-footer one-action">
  <button type="button" class="btn btn-main" [disabled]="!toDate || !fromDate" (click)="submitFilter()">Filter</button>
</div>
