import { Injectable, OnDestroy } from "@angular/core";
import { MultiSelectItem, ShortLinkGetDto } from "@app/models";
import { Subject } from "rxjs";
import { ShortLinkService } from "./short-link.service";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class MultiFilterService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public items: MultiSelectItem[];

  constructor(private shortLinksServise: ShortLinkService) {}

  public getMultiFilterItems(nameMultiFilter: string, locationIds: number[] = null): MultiSelectItem[] {
    this.items = [];
    if (nameMultiFilter === "customLinks") {
      this.getCustomLinks(locationIds);
      return this.items;
    }
  }

  public getCustomLinks(locationIds: number[]) {
    this.shortLinksServise
      .getAllCustom(locationIds)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customShortLinks: ShortLinkGetDto[] = []) => {
        customShortLinks.forEach((item: ShortLinkGetDto) => {
          let obj: MultiSelectItem = {
            id: item.id,
            name: item.targetUrl,
            value: item.targetUrl,
            selected: true,
            description: null,
            data: item
          };
          this.items.push(obj);
        });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
