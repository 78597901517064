import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-image-upload-form-control",
  templateUrl: "./image-upload-form-control.component.html",
  styleUrls: ["./image-upload-form-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImageUploadFormControlComponent), multi: true }]
})
export class ImageUploadFormControlComponent implements ControlValueAccessor {
  @Input() uploadButtonVisible = true;
  @Input() hasCropper = false;
  @Input() aspectRatio = 1;
  @Input() storageFolder = "files";

  value = "";

  protected onTouched: any = () => null;
  protected onChange: any = () => null;
  protected disabled = false;

  constructor(private cdr: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  onModelChanged() {
    this.onChange(this.value);
  }

  protected onImageLoaded(fileUrl: string) {
    this.value = fileUrl;
    this.onModelChanged();
  }
}
