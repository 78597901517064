import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BreadcrumbItem } from "./breadcrumbItem";

@Component({
  selector: "app-page-title",
  templateUrl: "./pagetitle.component.html",
  styleUrls: ["./pagetitle.component.scss"]
})
export class PagetitleComponent implements OnInit {
  @Input() breadcrumbItems: BreadcrumbItem[];
  @Input() brandName = "";
  @Input() icon: string;
  @Input() vertical = false;
  @Input() hideIcon = false;
  @Input() titleBoxClassList = "";

  @Input() set title(title: string) {
    this.pageTitle = title;
    this.titleService.setTitle(title);
  }

  @Output() clicked = new EventEmitter();

  pageTitle = "";

  constructor(private titleService: Title) {}

  ngOnInit() {}

  back() {
    this.clicked.emit();
  }
}
