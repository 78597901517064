<!--<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.className"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.content"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.content }}</ng-template>
</ngb-toast>
-->

<div *ngFor="let toast of toastService.toasts">
  <ngb-toast
    *ngIf="toast.className == 'success'"
    [class]="toast.className"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.content"></ng-template>
    </ng-template>

    <ng-template #text>
      <button
        type="button"
        class="btn-close font-size-12 float-end"
        data-bs-dismiss="toast"
        (click)="toastService.remove(toast)"
        aria-label="Close"
      ></button>
      <div class="row">
        <div class="col-1">
          <img src="assets/images/success-icon.svg" width="20" alt="" />
        </div>
        <div class="col-10 px-3">
          <h5 class="font-size-16 text-success">Success</h5>
          {{ toast.content }}
        </div>
      </div>
    </ng-template>
  </ngb-toast>

  <ngb-toast
    *ngIf="toast.className == 'danger'"
    [class]="toast.className"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.content"></ng-template>
    </ng-template>

    <ng-template #text>
      <button
        type="button"
        class="btn-close font-size-12 float-end"
        data-bs-dismiss="toast"
        (click)="toastService.remove(toast)"
        aria-label="Close"
      ></button>
      <div class="row">
        <div class="col-1">
          <img src="assets/images/error-icon.svg" width="20" alt="" />
        </div>
        <div class="col-10 px-3">
          <h5 class="font-size-16 text-danger">Error</h5>
          {{ toast.content }}
        </div>
      </div>
    </ng-template>
  </ngb-toast>

  <ngb-toast
    *ngIf="toast.className == 'primary'"
    [class]="toast.className"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.content"></ng-template>
    </ng-template>

    <ng-template #text>
      <button
        type="button"
        class="btn-close font-size-12 float-end"
        data-bs-dismiss="toast"
        (click)="toastService.remove(toast)"
        aria-label="Close"
      ></button>
      <div class="row">
        <div class="col-1 mt-n2">
          <i class="ri-information-line font-size-24 text-primary"></i>
        </div>
        <div class="col-10 px-3">
          <h5 class="font-size-16 text-primary">Info</h5>
          {{ toast.content }}
        </div>
      </div>
    </ng-template>
  </ngb-toast>
</div>
