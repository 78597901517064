import { TableColumnDataSortType, TableCompareFn } from "@app/models";
import { SortEvent } from "@app/shared/directives/advanced-sortable.directive";

export const tableDateFormat = {
  full: "MM.dd.yyyy hh:mm:ss",
  short: "MM.dd.yyyy"
};

export function sortTableByType<T>(tables: T[], sort: SortEvent, sortType: TableColumnDataSortType): T[] {
  const compareFn: TableCompareFn = getTableCompareFn(sortType);

  return [...tables].sort(tableComparator(sort, compareFn));
}

export function tableComparator<T>({ column, direction }: SortEvent, compareFn: TableCompareFn): (a: T, b: T) => number {
  return (a: T, b: T) => {
    const result = compareFn(a[column], b[column]);

    return direction === "desc" ? -result : result;
  };
}

export const getTableCompareFn = (sortType: TableColumnDataSortType): TableCompareFn => {
  switch (sortType) {
    case "number":
      return compareAsNumbers;
    case "string":
      return compareAsStrings;
    case "date":
      return compareAsDates;
    default:
      return compareAsStrings;
  }
};

export const compareAsNumbers = (a: number, b: number): number => a - b;

export const compareAsStrings = (a: string, b: string): number => `${a}`.toLowerCase().localeCompare(`${b}`.toLowerCase());

export const compareAsDates = (a: string, b: string): number => {
  const aDate = new Date(a).getTime();
  const bDate = new Date(b).getTime();

  return aDate - bDate;
};

export const valueAsDigits = (value: string): string => {
  if (!value) {
    return "";
  }

  const onlyDigitsRegExp = new RegExp(/\d+/g);
  const valueAsDigits = value.match(onlyDigitsRegExp)?.join("");

  return valueAsDigits;
};
