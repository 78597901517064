import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function isValidUrl(value: string) {
  const urlRegex: RegExp = /^https:\/\/?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; // todo check url format

  const isValid = urlRegex.test(value);
  return isValid;
}
export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toString() as string;

    const isValid = isValidUrl(value);
    return isValid ? null : { url: !isValid, type: "url" };
  };
}
export class Patterns {
  // tslint:disable-next-line:max-line-length
  public static url = RegExp(/^https:\/\/?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?(\?.*)?$/);

  // public static email = RegExp(/^([_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z0-9-]{2,5}))$/);
  public static email = RegExp(/^(\s*[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z0-9-]{2,5})*\s*)$/);

  public static price = RegExp(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/);

  public static trim = RegExp(/^(?! ).*[^ ]$/);
}
