<div class="coupon-carousel-wrapper">
  <div class="coupon-carousel">
    <div *ngIf="loyaltyCards?.length" class="coupon-container">
      <ngx-simplebar [options]="scrollbarOptions">
        <app-loyalty-card
          *ngFor="let item of loyaltyCards; index as i"
          class="coupon"
          [ngClass]="{ 'last-child': i === loyaltyCards.length - 1 }"
          [item]="item"
          [type]="'full'"
          [selectedCard]="selectedCard"
          (selectCard)="select($event)"
        >
        </app-loyalty-card>
      </ngx-simplebar>
    </div>

    <div *ngIf="!loyaltyCards?.length && !loading">
      <h5 class="text-truncate pb-1">Not Found</h5>
    </div>

    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"> </span>
  </div>
</div>
