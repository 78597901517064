import { AfterContentChecked, ChangeDetectorRef, Component, Input } from "@angular/core";
import { NgbActiveModal, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { DatePickerRangeInfo } from "../../../models";

@Component({
  selector: "app-custom-filter-modal",
  templateUrl: "./custom-filter-modal.component.html",
  styleUrls: ["./custom-filter-modal.component.scss"]
})
export class CustomFilterModalComponent implements AfterContentChecked {
  constructor(public activeModal: NgbActiveModal, private cdr: ChangeDetectorRef) {}

  @Input() fromDate: NgbDate | null = null;
  @Input() toDate: NgbDate | null = null;
  @Input() maxDaysBetween?: number = null;

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  dismiss(rangeInfo: DatePickerRangeInfo = null): void {
    this.activeModal.dismiss(rangeInfo);
  }

  updateRangeDates(rangeInfo: DatePickerRangeInfo = null): void {
    if (rangeInfo) {
      this.fromDate = rangeInfo.fromDate;
      this.toDate = rangeInfo.toDate;
    }
  }

  submitFilter(): void {
    if (this.fromDate && this.toDate) {
      this.dismiss({
        fromDate: this.fromDate,
        toDate: this.toDate
      });
    }
  }
}
