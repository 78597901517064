import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AccountService, AppConfigService } from "@app/core";
import { FileUploaderService } from "@app/services";
import { TranslocoService } from "@ngneat/transloco";
import { DropzoneComponent, DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { FileResult } from "../file-uploader-modal/file-result";
import { FileMimeType, ImageSize, maxImageUploadSize } from "../file-uploader-modal/file-type";
import { onlyAllowedImages } from "./image-uploader.component.utils";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"]
})
export class ImageUploaderComponent implements OnInit {
  @ViewChild(DropzoneComponent, { static: false }) componentRef?: DropzoneComponent;
  @Input() isMultiple = false;
  @Input() storageFolder = "images";
  @Input() extensions: Array<FileMimeType> = [];
  @Input() maxFileUploadSize: number = maxImageUploadSize;
  @Input() allowedImageSize: ImageSize;
  @Output() uploadChanged = new EventEmitter<FileResult>();
  @Output() bigpreview = new EventEmitter<boolean>();
  public error = "";
  public result: FileResult;
  public config: DropzoneConfigInterface = {
    acceptedFiles: "image/*",
    maxFiles: 1,
    autoReset: null,
    cancelReset: null,
    thumbnailWidth: 600,
    thumbnailHeight: 600,
    thumbnailMethod: "contain",
    errorReset: 1,
    maxFilesize: maxImageUploadSize,
    headers: {
      Authorization: `Bearer ${this.accountService.accessToken}`
    }
  };

  constructor(
    private confService: AppConfigService,
    public translateSvc: TranslocoService,
    private accountService: AccountService,
    private fileUploaderService: FileUploaderService
  ) {}

  ngOnInit(): void {
    this.config.url = `${this.confService.appData.apiUrl}/fileUploader/${this.storageFolder}`;
    this.config.acceptedFiles = this.fileUploaderService.getAcceptedFiles(this.extensions);
    this.config.maxFilesize = this.maxFileUploadSize;

    if (this.allowedImageSize) {
      const { width, height } = this.allowedImageSize;

      this.config.accept = onlyAllowedImages(width, height, this.translateSvc);
    }
  }

  public resetDropzoneUploads(): void {
    if (this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.reset();
      this.result = null;
      this.uploadChanged.next(this.result);
    }
  }

  public onUploadError(error) {
    this.error = "";
    for (let i = 1; i < error.length - 1; i++) {
      if (error[i] instanceof Object) {
        error[i] = error[i].Message;
        this.error += error[i] + "\n";
      } else this.error += error[i] + "\n";
    }
    this.result = null;
    this.uploadChanged.next(this.result);
  }

  public onUploadSuccess(imageResult: any[]): void {
    if (imageResult.length < 2) {
      console.log("imageResult should have minimum 2 items in the array");
      return;
    }

    this.result = { fileDataUrl: imageResult[0].dataURL, fileUrl: imageResult[1].link } as FileResult;
    const image = imageResult[0];
    const reader = new FileReader();
    reader.onloadend = (r) => {
      if (!image.dataURL) {
        this.error = this.translateSvc.translateObject("brand.bigpreview");
        this.bigpreview.next(true);
      }
      if (reader.result) {
        this.result = { fileDataUrl: reader.result, fileUrl: imageResult[1].link } as FileResult;
      } else this.result = null;
      this.uploadChanged.next(this.result);
    };
    reader.readAsDataURL(image);
  }

  public onSending() {
    this.error = "";
  }
}
