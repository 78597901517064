<div *transloco="let t; read: 'common'" class="locations-autocomplete">
  <div>
    <h4 *ngIf="showTitle" class="locations-autocomplete__title">
      {{ t("components.selectItems") }}
    </h4>

    <form class="row filter">
      <div class="input-group d-flex p-0">
        <input
          [formControl]="searchControl"
          [placeholder]="t('controls.search')"
          type="text"
          class="form-control with-action"
          (keyup.enter)="filter()"
        />

        <div class="form-control-action">
          <img (click)="filter()" alt="" src="assets/images/search-icon.svg" width="14" height="14" />
        </div>
      </div>
    </form>
  </div>
</div>
