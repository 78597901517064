<div class="box-empty-image">
  <div *ngIf="!imageChangeEvent && !base64" class="text-empty-image">{{ title }}</div>
  <image-cropper
    [imageBase64]="base64"
    [imageChangedEvent]="imageChangeEvent"
    [aspectRatio]="aspectRatio"
    [maintainAspectRatio]="maintainAspectRatio"
    [resizeToWidth]="1080"
    [onlyScaleDown]="true"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>

<div *ngIf="!!croppedImage" class="cropped-image mt-2">
  <img [src]="croppedImage" alt="cropped image">

  <app-checkbox
    [control]="useRecommendedDimensionsControl"
    [inlineLabel]="'common.controls.useRecommendedDimensions' | transloco"
    (selected)="onUseRecommendedDimensions($event)"
  ></app-checkbox>
</div>
