import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core";
import {
  AccountCouponsGetDto,
  CouponInfo,
  CouponLocationsGetDto,
  CouponSearchModel,
  CouponSession,
  CouponSessionUpdateResult,
  LocationConfigurationStatusResultGetDto,
  UpdateCouponSession
} from "@app/models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CouponService {
  private serviceUrl = "Coupon";

  constructor(private http: DataService) {}

  searchCoupon(filter: CouponSearchModel = null): Observable<Array<CouponInfo>> {
    return this.http.get(`${this.serviceUrl}/search`, filter);
  }

  getCouponById(couponId: string): Observable<CouponInfo> {
    return this.http.get(`${this.serviceUrl}/coupon/${couponId}`);
  }

  getLocationCoupons(locationId: number): Observable<Array<CouponInfo>> {
    return this.http.get(`${this.serviceUrl}/${locationId}`);
  }

  getLocationWidgetCoupons(locationId: number, widgetId: number): Observable<Array<CouponInfo>> {
    return this.http.get(`${this.serviceUrl}/locationWidget/${locationId}/${widgetId}`);
  }

  getWidgetDefaultCoupons(widgetId: number): Observable<Array<CouponInfo>> {
    return this.http.get(`${this.serviceUrl}/widgetDefault/${widgetId}`);
  }

  getIndustryCoupons(industryId: number): Observable<Array<CouponInfo>> {
    return this.http.get(`${this.serviceUrl}/industry/${industryId}`);
  }

  getAccountCouponsInfo(accountId: number = null): Observable<Array<AccountCouponsGetDto>> {
    const params = accountId ? { AccountId: accountId } : null;

    return this.http.get(`${this.serviceUrl}/accountCoupons`, params);
  }

  getCouponSessionByCode(code: string): Observable<CouponSession> {
    return this.http.post(`${this.serviceUrl}/searchSessionByCode/${code}`);
  }

  getLocationIdsByActiveCoupon(couponId: string): Observable<number[]> {
    return this.http.get(`${this.serviceUrl}/${couponId}/locations`).pipe(map((data: CouponLocationsGetDto) => data?.locationIds || []));
  }

  validateCoupon(updateCouponSession: UpdateCouponSession): Observable<CouponSessionUpdateResult> {
    return this.http.post(`${this.serviceUrl}/validateSession/`, updateCouponSession);
  }

  getCouponConfigurationStatus(couponId: string): Observable<LocationConfigurationStatusResultGetDto[]> {
    return this.http.get(`${this.serviceUrl}/${couponId}/locations/status`);
  }
}
