<form [formGroup]="form" *transloco="let t; read: 'widgets.anniversaryClub'">
  <h5>
    {{ t("title") }}
  </h5>

  <div class="widget-explanation mt-3">
    <span class="widget-explanation__text">
      {{ t("explanation") }}
    </span>
  </div>

  <div class="row mt-3 mb-3">
    <div class="col-md-4 col-6 mb-3">
      <label for="example-time-input" class="col-form-label">
        {{ t("sendingTime") }}
      </label>

      <input
        [placeholder]="t('sendingTime')"
        class="form-control"
        [formControlName]="settingsControl.SendingTime"
        type="time"
        value="13:45:00"
        id="example-time-input"
      />
    </div>

    <div class="col-12">
      <app-url-form-control
        label="Birthday / Anniversary Club Link"
        [mark]="false"
        [formControlName]="settingsControl.AnniversaryClubUrl"
        [validators]="validators"
      >
      </app-url-form-control>
    </div>

    <div class="col-12 mt-3">
      <span class="col-form-label">
        {{ t("couponCarouselTitle") }}
      </span>

      <div class="coupon-carousel-wrapper mt-2">
        <app-coupon-carousel
          class="coupon-carousel"
          [loading]="loading"
          [centerSpinner]="true"
          [coupons]="couponList$ | async"
          [selectedCoupon]="selectedCoupon"
          [withPreview]="false"
          [selectionType]="'single'"
          [viewType]="'small'"
          [couponSizeType]="'small'"
          [withNothingFoundText]="true"
          [removeSelected]="true"
          (selectCoupon)="onCouponListChanged($event)"
        >
        </app-coupon-carousel>
      </div>
    </div>

    <div *ngIf="textMessage$ | async as textMessage" class="col-12 mt-3">
      <span class="col-form-label">
        {{ "widgets.common.previewMessageTitle" | transloco }}
      </span>

      <div class="mt-2">
        <span class="preview-message">
          {{ textMessage }}
        </span>
      </div>
    </div>
  </div>
</form>
