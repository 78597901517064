<div *transloco="let t; read: 'widgets.connectSocialMedia'">
  <h5>{{ t("title") }}</h5>
  <div class="row">
    <div class="col-12 mb-3">
      <label class="col-form-label">{{ t("selectfrom") }}</label>
      <div class="col-md-auto">
        <div class="btn-group btn-group-example" role="group">
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio3"
            [value]="true"
            [(ngModel)]="isFromBrand"
            [checked]="isFromBrand"
            autocomplete="off"
            (click)="setFromBrand()"
          />
          <label class="first-label btn btn-outline-primary w-sm px-3" [class.active]="isFromBrand" for="btnradio3">{{ t("fromB") }}</label>
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio1"
            [value]="false"
            [(ngModel)]="isFromBrand"
            [checked]="!isFromBrand"
            autocomplete="off"
            (click)="setFromLocation()"
          />
          <label class="btn btn-outline-primary w-sm px-3" [class.active]="!isFromBrand" for="btnradio1">{{ t("fromL") }}</label>
        </div>
      </div>
    </div>

    <div class="col-12 mb-3" *ngIf="!isFromBrand">
      <form [formGroup]="form">
        <label class="col-form-label position-relative"
          >{{ t("links") }}
          <div
            *transloco="let tc; read: 'common.error'"
            class="font-size-18 widget-icon-tooltip"
            [ngbTooltip]="tc('secureUrl', { field: t('links') })"
            #tt="ngbTooltip"
            container="body"
            (mouseout)="tt.close()"
            triggers="click:blur"
          >
            <i class="bx bx-question-mark mb-0 text-info"></i>
          </div>
        </label>

        <app-social-links-form-control
          [isFull]="true"
          [formGroup]="form"
          [isEditable]="true"
          (linksChanged)="onLinksChnaged($event)"
          [links]="widgetLinks"
        ></app-social-links-form-control>
      </form>
    </div>
    <div class="col-12 mb-3" *ngIf="isFromBrand">
      <label for="example-time-input" class="col-form-label position-relative">{{ t("links") }} </label>
      <app-social-links-form-control [isFull]="true" [links]="links"></app-social-links-form-control>
    </div>
  </div>
</div>
