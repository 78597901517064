<div class="thumbnail" [style.height.px]="height">
  <div class="background">
    <div *ngIf="loading" class="spinner-chase mt-3">
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
    </div>
  </div>
  <div class="content" [class.transparent]="loading" [style.transform]="'scale(' + zoom + ')'">
    <iframe #cont class="frame" scrolling="no" [src]="pageUrl"></iframe>
  </div>
</div>
