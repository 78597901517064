import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Location } from "@app/models";
@Component({
  selector: "app-multilocation-selection",
  templateUrl: "./multilocation-selection.component.html",
  styleUrls: ["./multilocation-selection.component.scss"]
})
export class MultilocationSelectionComponent implements OnInit {
  @Input() locations: Location[] = [];
  @Input() loading = true;
  @Input() selectedLocations: number[] = [];
  @Output() changed = new EventEmitter<number[]>();
  isSelectedAll = false;
  constructor() {}

  ngOnInit(): void {}
  changeCheckbox(element: Location, event) {
    if (event.checked) {
      this.selectedLocations.push(element.id);
    } else {
      this.selectedLocations = this.selectedLocations.filter((item) => item !== element.id);
    }
    this.changed.emit(this.selectedLocations);
  }
}
