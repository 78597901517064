import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { valueAsDigits } from "../components/table/table.utils";

type MaskedDigitItem = {
  originalIndex: number;
  digitIndex: number;
  highlighted: boolean;
};

@Injectable({
  providedIn: "root"
})
@Pipe({
  name: "phoneHighlight"
})
export class PhoneHighlightPipe implements PipeTransform {
  transform(value: string, searchString: string): string {
    if (!value || !searchString) {
      return value;
    }

    const getIndexMap = (str: string): Map<number, MaskedDigitItem> => {
      const indexMap = new Map<number, MaskedDigitItem>();
      let digitIndex = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str[i];

        if (Number.isInteger(+char) && char !== " ") {
          indexMap.set(digitIndex, { originalIndex: i, digitIndex, highlighted: false });
          digitIndex += 1;
        }
      }

      return indexMap;
    };

    const markChars = (indexMap: Map<number, MaskedDigitItem>, markCount: number, digitStringIndex = -1) => {
      let count = markCount;
      let tIndex = digitStringIndex;

      while (count > 0 && tIndex >= 0) {
        if (indexMap.has(tIndex)) {
          const target = indexMap.get(tIndex);
          target.highlighted = true;
        }
        tIndex += 1;
        count -= 1;
      }
    };

    const getHighlightedIndexes = (digitString: string, matchesLength: number) => {
      const indexMap: Map<number, MaskedDigitItem> = getIndexMap(value);
      let matchCount = matchesLength;
      let startIndex = 0;

      while (matchCount > 0) {
        const digitStringIndex = digitString.indexOf(searchString, startIndex);

        if (digitStringIndex >= 0) {
          markChars(indexMap, searchString.length, digitStringIndex);
        }
        startIndex = digitStringIndex + 1;
        matchCount -= 1;
      }

      const replacedIndexes = [...indexMap.values()].filter((v) => v.highlighted);

      return replacedIndexes;
    };

    const digits = valueAsDigits(value);
    const regex = new RegExp(`${searchString}`, "g");
    const matches = digits.match(regex) || [];
    const highlightedIdexes = getHighlightedIndexes(digits, matches.length);

    const result = value
      .split("")
      .map((v, i) => {
        const target = highlightedIdexes.find(({ originalIndex }) => originalIndex === i);
        if (target) {
          return `<mark>${v}</mark>`;
        }
        return v;
      })
      .join("");

    return result;
  }
}
