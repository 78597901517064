<!-- breadcrumb item -->
<div class="row page-title-wrapper" *ngIf="!vertical">
  <div class="col-12">
    <div class="page-title-box d-flex align-items-center justify-content-between {{ titleBoxClassList }}">
      <div class="d-flex align-items-center title-wrapper">
        <i *ngIf="icon" [class]="icon" class="text-primary" (click)="back()"></i>
        <span class="page-title-text">{{ pageTitle }}</span>
      </div>
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li class="breadcrumb-item" *ngIf="!item.active">
              <a *ngIf="!item.url" href="javascript: void(0);">{{ item.label }}</a>
              <a *ngIf="item.url" [routerLink]="item.url">{{ item.label }}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="item.active">{{ item.label }}</li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="row mb-3 page-title-wrapper vertical" *ngIf="vertical">
  <div class="col-12">
    <div class="page-title-left" *ngIf="breadcrumbItems">
      <ol class="breadcrumb m-0 p-0">
        <ng-container *ngFor="let item of breadcrumbItems">
          <li class="breadcrumb-item" *ngIf="!item.active">
            <a *ngIf="!item.url" href="javascript: void(0);">{{ item.label }}</a>
            <a *ngIf="item.url" [routerLink]="item.url">{{ item.label }}</a>
          </li>
          <li class="breadcrumb-item active" *ngIf="item.active">{{ item.label }}</li>
        </ng-container>
      </ol>
    </div>
    <div class="row title-wrapper">
      <div class="page-title-text d-flex align-items-center">
        <span class="ri-arrow-left-line page-title-icon mr-5" (click)="back()" *ngIf="!hideIcon"></span>
        {{ pageTitle }}
      </div>
    </div>
  </div>
</div>
