import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({ template: "" })
export class Unsubscriber implements OnDestroy {
  set sub(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  private subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
