export interface SendEmailWithRegistrationUrlRequestDto {
  registrationUrl: string;
  email: string;
  firstName?: string;
  lastName?: string;
}

export enum QuickSetupWizardSteps {
  BrandDetails = 0,
  BrandColors = 1,
  MessageDeliveryOptions = 2,
  Location = 3,
  PricingPlan = 4,
  Apps = 5,
  Done = 6
}

export enum QuickSetupIntegrationWizardSteps {
  BrandDetails = 0,
  BrandColors = 1,
  MessageDeliveryOptions = 2,
  Location = 3,
  Apps = 4,
  Done = 5
}
