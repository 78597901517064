<div class="row align-items-center mb-3" *ngIf="!preview">
  <label class="col-form-label">{{ placeholder }}</label>
  <div class="col-md-12">
    <input
      class="form-control"
      type="text"
      [placeholder]="placeholder"
      (input)="valueChanged()"
      (paste)="valueChanged()"
      [formControl]="formControl"
    />
  </div>
</div>
<div *ngIf="value" class="ratio ratio-16x9 mb-3">
  <iframe [src]="link" class="embed-responsive-item"></iframe>
</div>
