export const maxImageUploadSize = 100; // 100MB
export const maxFileUploadSize = 20; // 20MB

export enum FileType {
  Image = 1,
  File = 2
}

export enum FileMimeType {
  image = "image/*",
  png = "image/png",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls = "application/vnd.ms-excel"
}

export interface ImageSize {
  width: number;
  height: number;
}
