import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Directive({
  selector: "[urlOnly]"
})
export class UrlOnlyDirective {
  @Input() urlOnly = new UntypedFormControl();

  constructor(private _el: ElementRef) {}

  @HostListener("keydown", ["$event"]) onKeydownHandler(event) {
    const initalValue = this._el.nativeElement.value;
    const urlPattern = new RegExp(/^https?:\/\/[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/i);
    if (!urlPattern.test(initalValue) && event.keyCode == 8) {
      this.urlOnly.setValue("");
      event.preventDefault();
    }
  }

  @HostListener("input", ["$event"]) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    if (initalValue) {
      const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);
      this.urlOnly.setValue(withHttp(initalValue));
    }

    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
