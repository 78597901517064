import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColorPickerData } from "@app/shared/form-controls/colors-form-control/color-picker/color-picker.data";

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"]
})
export class ColorPickerComponent {
  @Input() selectedValue: string;

  @Output() colorSelected = new EventEmitter<string>();
  @Output() gradientSelected = new EventEmitter<string>();

  readonly whiteColor = ColorPickerData.whiteColor;
  readonly solidColors = ColorPickerData.solidColors;
  readonly gradients = ColorPickerData.gradients;

  selectColor(color: string) {
    this.colorSelected.emit(color);
  }

  selectGradient(gradient: string) {
    this.gradientSelected.emit(gradient);
  }
}
