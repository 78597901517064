import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Unsubscriber } from "@app/shared/components";
import { ImageCroppedEvent, LoadedImage } from "ngx-image-cropper";
import { Subject } from "rxjs";

@Component({
  selector: "app-image-uploader-cropper",
  templateUrl: "./image-uploader-cropper.component.html",
  styleUrls: ["./image-uploader-cropper.component.scss"]
})
export class ImageUploaderCropperComponent extends Unsubscriber implements OnInit {
  @Input() title = "Custom Logo";
  @Input() aspectRatio = 1;
  @Input() imageChangeEvent: Event;
  @Input() base64 = "";
  @Input() maintainAspectRatio = false;

  @Output() croppedChanged = new EventEmitter<Blob>();

  imageChangeEvent$ = new Subject<Event>();
  croppedImage: SafeUrl = "";
  useRecommendedDimensionsControl = new FormControl<boolean>(true);

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    const useRecommendedDimensions = this.aspectRatio !== 1;

    this.useRecommendedDimensionsControl.setValue(useRecommendedDimensions);
    this.maintainAspectRatio = useRecommendedDimensions;
  }

  imageCropped(event: ImageCroppedEvent) {
    const blob = event.blob;

    if (blob) {
      this.croppedChanged.next(blob);
      this.croppedImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.objectUrl);
    }
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    this.croppedChanged.next(null);
  }

  onUseRecommendedDimensions(value: boolean): void {
    this.maintainAspectRatio = value;
  }
}
