export class QuickSetupChecklist {
  locationId: number;
  reviewedAccountDetails: boolean;
  firstPaymentReceived: boolean;
  emailSettingsAdopted: boolean;
  smsSettingsAdopted: boolean;
  configuredInboundMarketing: boolean;
  couponAdded: boolean;
  loyaltyCardAdded: boolean;
  smsCampaignCreated: boolean;
  campaignsCreatedOnSmsGateway: boolean;
  retentionCenterFeedback: boolean;
  retentionCenterUserSetup: boolean;
  retentionCenterAccountSetup: boolean;
  completeConfirmed: boolean;
  agreeToPay: boolean;
}
