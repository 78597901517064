import { Component, Input, OnInit } from "@angular/core";
import { LoaderService } from "../../../core/services/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent implements OnInit {
  @Input() loading: boolean;
  constructor(private loaderService: LoaderService) {
    const v = this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit(): void {}
}
