import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { ActivationTransactionsPosResponsetDto, ActivationTransactionsPostRequestDto } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ReportingTransactionsService {
  private serviceUrl = "Reporting";

  constructor(private http: DataService) {}

  getActivationTransactions(data: ActivationTransactionsPostRequestDto): Observable<ActivationTransactionsPosResponsetDto> {
    return this.http.post(`${this.serviceUrl}/transactions/activation`, data);
  }
}
