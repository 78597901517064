<form [formGroup]="form" *transloco="let t; read: 'widgets.downloadMobileApp'">
  <h5>{{ t("title") }}</h5>
  <div class="row mb-3" *transloco="let tc; read: 'common.error'">
    <div class="col-12 mb-3">
      <app-url-form-control
        iconColor="bg-success"
        icon="mdi mdi-android-debug-bridge"
        [label]="t('android')"
        formControlName="androidAppUrl"
        [mark]="!form.get('iosAppUrl').value"
        [validators]="!form.get('iosAppUrl').value ? validators : ''"
      ></app-url-form-control>
    </div>
    <div class="col-12">
      <app-url-form-control
        iconColor="bg-dark"
        icon="mdi mdi-apple text-white"
        [label]="t('ios')"
        formControlName="iosAppUrl"
        [mark]="!form.get('androidAppUrl').value"
        [validators]="!form.get('androidAppUrl').value ? validators : ''"
      ></app-url-form-control>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group mb-3">
        <label for="description" class="col-form-label">{{ t("description") }}</label>
        <textarea id="description" class="form-control" [placeholder]="t('description')" rows="5" formControlName="description"></textarea>
      </div>
    </div>
  </div>
</form>
