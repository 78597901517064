import { Clipboard } from "@angular/cdk/clipboard";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-copy-string",
  templateUrl: "./copy-string.component.html",
  styleUrls: ["./copy-string.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyStringComponent {
  @Input() value = "";

  readonly actionMessage = this.transloco.translate("common.components.clickToCopy");
  readonly successMessage = this.transloco.translate("common.components.copied");

  tooltipMode: "action" | "success" = "action";
  disabled = false;

  constructor(private clipboard: Clipboard, private transloco: TranslocoService, private cd: ChangeDetectorRef) {}

  @HostListener("mouseout") onMouseOut() {
    setTimeout(() => {
      this.tooltipMode = "action";
      this.disabled = false;
      this.cd.markForCheck();
    }, 300);
  }

  get tooltipMessage() {
    return this.tooltipMode === "success" ? this.successMessage : this.actionMessage;
  }

  copy(): void {
    this.clipboard.copy(this.value);
    this.tooltipMode = "success";
    this.disabled = true;
  }
}
