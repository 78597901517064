import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DropdownPosition } from "@ng-select/ng-select";

/**
 * @deprecated This component is deprecated. Please use `lib-dropdown` from `ui-kit` instead.
 */
@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DropdownComponent {
  @Input() control: FormControl | null = null;
  @Input() isRequired = false;
  @Input() clearable = false;
  @Input() closeOnSelect = true;
  @Input() searchable = false;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() appendOptionsToBody = false;
  @Input() dropdownPosition: DropdownPosition = "auto";
  @Input() label = "";
  @Input() bindLabel = "";
  @Input() bindValue = "";
  @Input() placeholder = "";
  @Input() items = [];

  @Output() change = new EventEmitter();
}
