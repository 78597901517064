<div *transloco="let t; read: 'common.components'" class="multiselect multiple">
  <app-multi-autocomplete [items]="items" [titleMultiFilter]="titleMultiFilter" (filtered)="filter($event)"></app-multi-autocomplete>

  <div class="multiselect__dropdown">
    <ul *ngIf="filteredItems?.length" class="multiselect__dropdown-list">
      <ngx-simplebar [options]="scrollbarOptions">
        <li *ngFor="let item of filteredItems" class="multiselect__dropdown-item selected">
          <div class="multi-item" (click)="triggerSelectItem(item)">
            <button type="button" class="checkbox-button checked multiple" [ngClass]="{ checked: item.selected }"></button>

            <div class="multi-info" [ngClass]="{ selected: item.selected }">
              <span class="multi-info__title">
                {{ item?.name }}
              </span>

              <span class="multi-info__text">
                {{ item?.info }}
              </span>
            </div>
          </div>
        </li>
      </ngx-simplebar>
    </ul>

    <div class="mx-4" *ngIf="!loading && !filteredItems?.length">
      {{ t("noItems") }}
    </div>
  </div>

  <div *ngIf="filteredItems?.length" class="multiselect__actions">
    <div class="multi-item">
      <button type="button" class="checkbox-button multiple" [ngClass]="{ checked: selectedAll }" (click)="triggerSelectAll()"></button>

      <span class="multiselect__text"> {{ t("selectAll") }} {{ "multiFilter." + titleMultiFilter | transloco }} </span>
    </div>

    <div class="selection-info">
      {{ t("selected") }} <span>{{ selectedItems?.length }}</span
      >/{{ filteredItems.length }}
    </div>
  </div>

  <div class="multiselect__actions" [ngClass]="hideReset ? 'justify-content-end' : ''">
    <ng-container *transloco="let tc; read: 'common'" class="">
      <button *ngIf="!hideReset" type="button" (click)="reset()" class="btn btn-outline-light btn-card-action float-start">
        {{ tc("controls.reset") }}
      </button>

      <button type="button" [disabled]="disabled" (click)="onSave()" class="btn btn-primary btn-card-action float-end">
        {{ tc("controls.save") }}
      </button>
    </ng-container>
  </div>
</div>
