import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService, AppConfigService } from "@app/core";
import { RoleTypes } from "@app/models";

@Component({
  selector: "app-access-denied",
  templateUrl: "./access-denied.component.html",
  styleUrls: ["./access-denied.component.scss"]
})
export class AccessDeniedComponent implements OnInit {
  get isManager() {
    return this.accountSvc.isUserInRole(RoleTypes.Manager);
  }

  get isLocationManager() {
    return this.accountSvc.isUserInRole(RoleTypes.LocationManager);
  }

  constructor(private router: Router, private accountSvc: AccountService, private configSvc: AppConfigService) {}

  ngOnInit(): void {}

  goToDashboard() {
    this.router.navigate(["/"]);
  }
  goToCmc() {
    const cmcUrl = this.configSvc.appData.marketingCenterUrl;
    location.href = cmcUrl;
  }
}
