import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

export type FooterViewType = "default" | "simple";

@Injectable({ providedIn: "root" })
export class FooterService {
  private viewTypeSrc$: BehaviorSubject<FooterViewType> = new BehaviorSubject<FooterViewType>("default");

  viewType$ = this.viewTypeSrc$.pipe(distinctUntilChanged());

  setViewType(value: FooterViewType): void {
    this.viewTypeSrc$.next(value);
  }
}
