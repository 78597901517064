import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Industry, ReviewSource } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class IndustryService {
  private serviceUrl = "industry";
  constructor(private http: DataService) {}

  getAll(): Observable<Industry[]> {
    return this.http.get(`${this.serviceUrl}`);
  }
  getPublic(): Observable<Industry[]> {
    return this.http.get(`${this.serviceUrl}/public`);
  }
  getForPartner(): Observable<Industry[]> {
    return this.http.get(`${this.serviceUrl}/partner`);
  }

  getReviewSourcesByCountry(industryId: number, countryId: number): Observable<ReviewSource[]> {
    return this.http.get(`${this.serviceUrl}/${industryId}/reviewSources/${countryId}`);
  }
}
