import { TimeFrameTypes } from "./enums";

interface DashboardTotalEngagementOutcome {
  totalCount: number;
}

export interface EngagementOutcomeByPeriodPostDto {
  locationId?: number;
  startDate?: string;
  endDate?: string;
  period: TimeFrameTypes;
}

export class EngagementOutcome {
  startDate: string;
  totalCount: number;
  name: string;
  color: string;
  deliveryChannel?: string;
  deliverySource?: string;
}

export interface EngagementOutcomeByPeriodGetDto<EngagementOutcome> {
  data: EngagementOutcome[];
  endDate: string;
  startDate: string;
  total: number;
}

export interface EngagementOutcomeByPeriod extends DashboardTotalEngagementOutcome {
  totalCount: number;
  name: string;
  startDate: string;
  data: EngagementOutcome[];
  color: string;
  deliveryChannel?: string;
  deliverySource?: string;
}

export interface TotalEngagementOutcomeCardInfo {
  totalCount: number;
  list: EngagementOutcomeCardInfoItem[];
}
export interface EngagementOutcomeCardInfoItem {
  label: string;
  value: number;
  progressPerCent: number;
  color?: string;
}

export interface EngagementOutcomeCardInfo {
  maxCount: number;
  list: EngagementOutcomeCardInfoItem[];
}

export class EngagementOutcomeTypeSetting {
  name?: string;
  color?: string;
  deliveryChannel?: string;
  deliverySource?: string;
}

export const engagementOutcomeTypesSettings: EngagementOutcomeTypeSetting[] = [
  { name: "Email", color: "#ffdd00", deliveryChannel: "Email" },
  { name: "SMS Message", color: "#8000ff", deliveryChannel: "Sms" },
  { name: "QR Code scans from CMC", color: "#FF4500", deliveryChannel: "QrCode", deliverySource: "Cmc" },
  { name: "Short Link", color: "#DAFFD6", deliveryChannel: "HyperLink" },
  { name: "QR Code scans from CFS", color: "#DA70D6", deliveryChannel: "QrCode", deliverySource: "Cfs" },
  { name: "QR Code scans from printed flyers", color: "#ADFF2F", deliveryChannel: "QrCode", deliverySource: "Printed" },
  { name: "QR Code scans from POS", color: "#5988FF", deliveryChannel: "QrCode", deliverySource: "Pos" },
  { name: "Scans from Custom Bulks", color: "#000", deliveryChannel: "QrCode", deliverySource: "Custom" },
  { name: "Scans from Stand Bulks", color: "#4BA3C3", deliveryChannel: "QrCode", deliverySource: "Stand" },
  { name: "Scans from Sticker Bulks (square)", color: "#6DA34D", deliveryChannel: "QrCode", deliverySource: "SquareSticker" },
  { name: "Scans from Sticker Bulks (circle)", color: "#7C90DB", deliveryChannel: "QrCode", deliverySource: "CircleSticker" },
  { name: "Short Link from Landing Page", color: "#40ff00", deliveryChannel: "HyperLink", deliverySource: "LandingPage" },
  { name: "Short Link from Kiosk", color: "#0080ff", deliveryChannel: "HyperLink", deliverySource: "Kiosk" }
];
