export class PixelTag {
  public id: number;
  public name: string;
  public tag: string;
  public locationId: number;
  public widgetId: number;
  public isActive: boolean;
}

export interface IPixelTagData {}
