<div class="modal-header">
  <h4 class="page-title-box m-0 p-0">{{ title }}</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="close()"></button>
</div>
<div class="card-body">
  <div *ngIf="url">
    <iframe class="full" [src]="trustedUrl" frameborder="0"></iframe>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()">{{ closeBtnText }}</button>
</div>
