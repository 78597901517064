import { Injectable } from "@angular/core";
import { AppConfigService } from "../core";

@Injectable({
  providedIn: "root"
})
export class ConsoleLogHandlerService {
  constructor(private appConfigService: AppConfigService) {}

  log(message: string, value: any = null): void {
    if (!this.appConfigService.isProduction) {
      if (value) {
        console.log(message, value);
      } else {
        console.log(message);
      }
    }
  }
}
