import { ValidatorFn, Validators } from "@angular/forms";
import { canadaProvinces, usaStates } from "./state-list";

export const countries = [
  { name: "USA", value: 0 },
  { name: "Canada", value: 1 }
];

export class Country {
  id: number;
  name: string;
  states: { name: string; shortName: string }[];
  maskZip: string;
  customPatternsZip: any;
  minLengthZip: number;
  maxLengthZip: number;
  zipValidators: ValidatorFn[];
  code: string;
}

const caLetter = "[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]"; // Letter in Canada ZIP pattern.
export const countriesSettings: Country[] = [
  {
    id: 0,
    name: "USA",
    states: usaStates,
    maskZip: "NNNNN",
    customPatternsZip: { N: { pattern: /[0-9]/ } },
    minLengthZip: 5,
    maxLengthZip: 5,
    zipValidators: [Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^[0-9]{5}$/)],
    code: "US"
  },
  {
    id: 1,
    name: "Canada",
    states: canadaProvinces,
    maskZip: "XNX NXN",
    customPatternsZip: { X: { pattern: new RegExp(caLetter) }, N: { pattern: /[0-9]/ } },
    minLengthZip: 6,
    maxLengthZip: 6,
    zipValidators: [
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern(new RegExp(`^${caLetter}[0-9]${caLetter}[0-9]${caLetter}[0-9]`))
    ],
    code: "CA"
  }
];
