<div *ngIf="fullScreenWrapper" #lottieContainer class="template-preview" [class.fullScreenWrapper]="!isPrintView">
  <ng-lottie
    *ngIf="lottieOptions$ | async as lottieOptions"
    [options]="lottieOptions"
    (animationCreated)="animationCreated($event)"
    (domLoaded)="domLoaded()"
    class="lottie-template"
  ></ng-lottie>
</div>

<div *ngIf="!fullScreenWrapper" class="non-fullscreen template-preview flex-column">
  <div class="title-container content-title item-title">
    <ng-container *ngIf="templateOptions?.previewTitle as title; else defaultTitle">
      <span class="template-title">{{ title }}</span>
    </ng-container>
  </div>
  <div #lottieContainer class="h-100">
    <div
      *ngIf="htmlBody; else lottie"
      class="template__wrapper"
      [innerHTML]="htmlBody"
    ></div>

    <ng-template #lottie>
      <ng-lottie *ngIf="lottieOptions$ | async as lottieOptions" [options]="lottieOptions" class="lottie-template"></ng-lottie>
    </ng-template>
  </div>
</div>


<ng-template #defaultTitle>
  <ng-container *ngIf="templateOptions?.digitalAsset as asset">
    <span class="template-title">{{ templateOptions?.digitalAsset.title }}</span>
  </ng-container>

  <ng-container *ngIf="templateOptions?.mediaContent as mediaContent">
    <span class="template-title">{{ mediaContent.title }}</span>
  </ng-container>
</ng-template>
