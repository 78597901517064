export class ImportResult {
  status: string;
  errors: Array<ImportError>;
  warnings: Array<ImportWarning>;
}

export class ImportError {
  public entityId: string;
  public rowNumber: number;
  public errorMessage: string;
  public errorDetails: string;
}

export class ImportWarning {
  public entityId: string;
  public rowNumber: number;
  public message: string;
  public details: string;
}
