import { AfterViewChecked, ChangeDetectorRef, Component, forwardRef, Inject, Injector, Input, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl
} from "@angular/forms";

@Component({
  selector: "app-url-control",
  templateUrl: "./url-control.component.html",
  styleUrls: ["./url-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UrlControlComponent),
      multi: true
    }
  ]
})
export class UrlControlComponent implements ControlValueAccessor, OnInit, AfterViewChecked {
  formControl: UntypedFormControl;

  @Input() label = "";
  @Input() placeholder = "";
  @Input() icon = "";
  @Input() iconColor = "";

  @Input() iconInside: string;
  @Input() iconInsideColor: string;
  @Input() iconSvg: string;
  @Input() mark = false;
  @Input() isHideTooltip = false;
  @Input() disable = false;
  @Input() isLinked = false;
  @Input() inputGroup = false;

  constructor(@Inject(Injector) private injector: Injector, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.placeholder = !this.placeholder ? this.label : this.placeholder;

    this.setComponentControl();
  }

  ngAfterViewChecked() {
    this.setDisabledState(this.disable);
    this.changeDetectorRef.detectChanges();
  }

  onTouched: any = () => null;
  onChange: any = () => null;

  onPaste: any = () => {};

  valueChanged: any = () => {};

  writeValue: any = () => {
    this.onTouched();
    this.changeDetectorRef.markForCheck();
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else if (this.formControl.disabled) {
      this.formControl.enable({ emitEvent: false });
    }
  }

  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl);
    this.formControl = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
  }
}
