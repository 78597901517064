import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfigService, AuthService } from "@app/core";
import { AccountService } from "@app/core/services/account.service";
import { User } from "@app/models";
import { UserService } from "@app/services";
import { QuickSetupIntegration } from "@app/pages/quick-setup/services/quick-setup-integration";

@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.scss"]
})
export class VerificationComponent implements OnInit {
  sent = false;
  resent = false;
  @Input() email: string;
  emailConfirmUrl: string;
  user: User;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private authSvc: AuthService,
    private userSvc: UserService,
    private configSvc: AppConfigService,
    private integration: QuickSetupIntegration
  ) {}
  ngOnInit(): void {
    this.userSvc.getById(this.accountService.userId).subscribe((u) => {
      this.user = u;
      if (u.emailConfirmedDate && u.emailConfirmed) {
        this.authSvc.logout(true);
        window.location.href = "/account/auth/quick-welcome";
      }
    });

    const identityUrl = this.configSvc.appData.identityUrl;
    const returnUrl = "/";

    this.emailConfirmUrl = `${identityUrl}/Account/SendVerificationEmail?returnUrl=${returnUrl}`;
    if (!this.email) this.email = this.accountService.user?.email;
    this.sent = !!this.accountService.user?.email_verified_date;
    if (this.accountService.user.email_verified) {
      this.integration.ensureNavigated(this.accountService.user);
    }
  }
  send() {
    // if(!this.resent) localStorage.clear();
    window.location.href = this.emailConfirmUrl;
  }
  resend() {
    this.resent = true;
    this.send();
  }
}
