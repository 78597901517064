export enum PlatformTypes {
  SmsGlobal = 1,
  SmsUSA = 2,
  TwoReward = 3,
  Vendasta = 4,
  Yodeck = 5
}

export enum DeliveryProviderType {
  SmsGlobal = 1,
  SmsUSA = 2,
  Twilio = 3,
  Courier = 4
}
