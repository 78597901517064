import { Component } from "@angular/core";

@Component({
  selector: "app-template-preview-page",
  templateUrl: "./template-preview-page.component.html",
  styleUrls: ["./template-preview-page.component.scss"]
})
export class TemplatePreviewPageComponent {
  constructor() {}
}
