<div *ngIf="visible">
  <div
    class="coupon position-relative {{ viewType }} {{ selectionType }} {{ sizeType }}"
    [class.disabled]="disabledNotActive && !isActive"
    [class.selected]="item.isSelected"
    [class.blured]="!item.isSelected"
    [class.singleSelection]="selectionType === 'single'"
    [class.not-active]="disabledNotActive && !isActive"
    (click)="singleSelectCoupon(item)"
  >
    <div
      *ngIf="(selectionType === 'multiple' && !isActive && !previewMode) || (disabledNotActive && !isActive)"
      class="position-absolute top-0 end-0 warning font-size-20 mt-2 mx-3"
      placement="bottom"
      [ngbTooltip]="'This coupon is not active. Please go to the Coupons in the Advanced Tools on the Admin panel to activate this coupon'"
    >
      <i class="mdi dripicons-warning text-warning"></i>
    </div>

    <div class="coupon__content" [class.not-active]="disabledNotActive && !isActive">
      <div class="coupon-row coupon-image" [ngStyle]="{ 'background-image': 'url(' + item.couponImage + ')' }"></div>

      <div *ngIf="item.name && viewType === 'full'" class="coupon-row coupon-text coupon-name" [innerHTML]="item.name"></div>

      <div class="coupon-row coupon-text coupon-title" [innerHTML]="item.title"></div>

      <button *ngIf="selectionType === 'multiple'" type="button" (click)="multipleSelectCoupon(item)" class="btn btn-coupon-ch"></button>
    </div>
  </div>

  <div *ngIf="withPreview" class="px-3">
    <button type="button" class="btn btn-light w-100" (click)="preview(item)">Preview</button>
  </div>
</div>
