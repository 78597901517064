import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { IndustryLoyaltyCard, LoyaltyCard, LoyaltyProgramExpirationDto } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LoyaltyCardService {
  private serviceUrl = "LoyaltyCard";

  constructor(private http: DataService) {}

  getAccountLoyaltyCard(accountId?: number): Observable<LoyaltyCard> {
    let url = `${this.serviceUrl}/account`;
    if (accountId) url += `/${accountId}`;
    return this.http.get(url);
  }

  getAccountAvailableLoyaltyCards(accountId: number): Observable<Array<LoyaltyCard>> {
    return this.http.get(`${this.serviceUrl}/account/${accountId}/available`);
  }

  syncLocationLoyaltyCard(locationId: number): Observable<string[]> {
    return this.http.post(`${this.serviceUrl}/syncLocation/${locationId}`);
  }

  getIndustryLoyaltyCards(industryId: number): Observable<Array<IndustryLoyaltyCard>> {
    return this.http.get(`${this.serviceUrl}/industry/${industryId}`);
  }

  getExpirationList(): Observable<Array<LoyaltyProgramExpirationDto>> {
    return this.http.get(`${this.serviceUrl}/expiration/list`);
  }
}
