import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ConditionValidationErrorDto, TemplateValidationErrorDto } from "@app/models";
import { CouponService, TemplateService, WidgetService } from "@app/services";
import { TranslocoService } from "@ngneat/transloco";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export type GridValidationError = ConditionValidationErrorDto | TemplateValidationErrorDto;

@Component({
  selector: "app-grid-validation-errors-tooltip",
  templateUrl: "./grid-validation-errors-tooltip.html",
  styleUrls: ["./grid-validation-errors-tooltip.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridValidationErrorsTooltipComponent {
  @Input() set errors(errors: GridValidationError[]) {
    if (errors.length) {
      const firstItem = errors[0];

      this.getTargetItemName(firstItem)
        .pipe()
        .subscribe((name: string) => {
          const message = this.transloco.translateObject(`templates.templateValidateError.${firstItem.messageCode}`, {
            name
          });

          this.tooltipTitle = message;
          this.tooltipRows = this.getTooltipRows(errors);
        });
    }
  }

  readonly visibleRowsCount = 15;

  tooltipRows: string[] = [];
  tooltipTitle = "";

  constructor(
    private couponService: CouponService,
    private widgetService: WidgetService,
    private templateService: TemplateService,
    private transloco: TranslocoService
  ) {}

  private getTooltipRows(errors: TemplateValidationErrorDto[]): string[] {
    let notVisibleCount = 0;
    const totalCount = errors.length;
    const result = [];

    for (let i = 0; i < totalCount; i++) {
      const error = errors[i];
      const { companyName, address, city, state, zip } = error.location;

      if (i > this.visibleRowsCount - 1) {
        notVisibleCount = totalCount - this.visibleRowsCount;
        const more = this.transloco.translate("common.menu.More").toLowerCase();

        result.push(`...+${notVisibleCount} ${more}`);
        break;
      }

      result.push(`${companyName}, ${address}, ${city}, ${state}, ${zip}`);
    }

    return result;
  }

  private getTargetItemName(firstItem: GridValidationError): Observable<string> {
    if (firstItem.widgetId) {
      return of(this.widgetService.getWidgetNameById(firstItem.widgetId));
    }

    if (firstItem.externalCouponId) {
      return this.couponService.getCouponById(firstItem.externalCouponId).pipe(map(({ name }) => name));
    }

    if ((<ConditionValidationErrorDto>firstItem).templateId) {
      return this.templateService.get((<ConditionValidationErrorDto>firstItem).templateId).pipe(map(({ name }) => name));
    }

    return of("");
  }
}
