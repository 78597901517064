export class RegisterUserModel {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  confirmEmail: string;
  confirmPassword: string;
}
export class CreateUserModel {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
}
export class CreatePartnerAdminModel {
  firstName: string;
  lastName: string;
  email: string;
  partnerId?: number;
}
