export class SalesAgent {
  public id?: number;
  public code?: string;
  public nickname: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public address: string;
  public isEligible: boolean;
  public isActive: boolean;
  public createdBy?: number;
  public createdOn?: Date;
  public comment?: string;
  public firstAccountCreated?: Date;
  public lastAccountCreated?: Date;
  public accountsCurrent?: number;
  public accountsPrevious?: number;
  public mrrCurrent?: number;
  public mrrPrevious?: number;
  public partnerId: number;
}

export class SalesAgentHistory {
  salesAgentId: number;
  period: Date;
  accountsNumber: number;
  mrr: number;
}
