import { Injectable } from "@angular/core";
import { ProfileModel } from "@app/core";
import { Router } from "@angular/router";
import { RouterStateSnapshot } from "@angular/router";

@Injectable()
export class QuickSetupIntegration {
  constructor(private router: Router) {}

  ensureNavigated(user: ProfileModel) {
    var route = "/quick-setup";
    if (user && user.subscriptionId && user.subscriptionId !== "") {
      route = "/quick-setup-integration";
    }

    if (!this.router.routerState.snapshot.url.startsWith(route)) {
      this.router.navigate([route]);
    }
  }
}
