import { TimeFrameTypes } from "./enums";

interface DashboardTotalKPIs {
  totalCount: number;
}

export interface KPIsByPeriodPostDto {
  locationId?: number;
  startDate?: string;
  endDate?: string;
  period: TimeFrameTypes;
}

export class KPIs {
  startDate: string;
  totalCount: number;
  name: string;
  kpiActivityType?: string;
  parameter1?: string;
  parameter2?: string;
  isRandom?: boolean;
  color?: string;
}

export interface KPIsByPeriodGetDto<KPIs> {
  data: KPIs[];
  endDate: string;
  startDate: string;
  total: number;
}

export interface KPIsByPeriod extends DashboardTotalKPIs {
  totalCount: number;
  name: string;
  startDate: string;
  data: KPIs[];
  color: string;
}

export interface TotalKPIsCardInfo {
  totalCount: number;
  list: KPIsCardInfoItem[];
}
export interface KPIsCardInfoItem {
  label: string;
  value: number;
  progressPerCent: number;
  color?: string;
}

export interface KPIsCardInfo {
  maxCount: number;
  list: KPIsCardInfoItem[];
  totalCount: number;
}

export class KpiTypeSetting {
  kpiActivityType: string;
  color?: string;
  parameter1: string;
  parameter2: string;
  isRandom?: boolean;
}

export const kpiTypeSettings: KpiTypeSetting[] = [
  { kpiActivityType: "RequestReview", color: "#B0C4DE", parameter1: null, parameter2: null, isRandom: true },

  { kpiActivityType: "SatisfactionLevel", color: "#40ff00", parameter2: null, parameter1: "like" },
  { kpiActivityType: "SatisfactionLevel", color: "#708090", parameter2: null, parameter1: "dislike" },

  { kpiActivityType: "ConnectSocialNetwork", color: "#0080ff", parameter1: null, parameter2: null, isRandom: true },

  { kpiActivityType: "EnrollToLoyalty", color: "#00ffff", parameter1: null, parameter2: null },
  { kpiActivityType: "IssueCoupon", color: "#8000ff", parameter1: null, parameter2: null },
  { kpiActivityType: "ManagerFeedback", color: "#FF00FF", parameter1: null, parameter2: null },
  { kpiActivityType: "AddToReferralProgram", color: "#7B68EE", parameter1: null, parameter2: null },
  { kpiActivityType: "NotSatisfactionLevel", color: "#708090", parameter1: null, parameter2: null },
  { kpiActivityType: "DownloadMobileApp", color: "#800080", parameter1: null, parameter2: null },
  { kpiActivityType: "BirthdayClub", color: "#FF4500", parameter1: null, parameter2: null },
  { kpiActivityType: "SmsReminder", color: "#DA70D6", parameter1: null, parameter2: null },
  { kpiActivityType: "ScheduleAppointment", color: "#FFA07A", parameter1: null, parameter2: null },
  { kpiActivityType: "SendSurvey", color: "#ADFF2F", parameter1: null, parameter2: null }
];
