<form [formGroup]="form" *transloco="let t; read: 'widgets.scheduleAppointment'">
  <h5>{{ t("title") }}</h5>
  <div class="col-md-12">
    <div class="row mb-3">
      <div class="col-md-12 mb-3">
        <app-url-form-control
          [label]="t('link')"
          formControlName="appointmentUrl"
          [mark]="true"
          [validators]="validators"
        ></app-url-form-control>
      </div>
      <div class="col-md-12">
        <label class="col-form-label">{{ t("remind") }}</label>
        <div class="" *ngFor="let item of reminders; let i = index" class="mb-3">
          <input
            id="reminder+{{ i }}"
            class="form-check-input widget me-3"
            type="checkbox"
            [checked]="item.checked"
            (change)="item.checked = !item.checked; onChangeSelection(item.value)"
          /><label for="reminder+{{ i }}" class="form-check-label">{{ item.name }}</label>
        </div>
      </div>
    </div>
  </div>
</form>
