import { TimeFrameTypes } from "./enums";

interface DashboardTotalCustomLinks {
  totalCount: number;
}

export interface CustomLinksByPeriodPostDto {
  locationId?: number;
  startDate: string;
  endDate: string;
  period: TimeFrameTypes;
  timeZoneOffset?: number;
  targetUrls: string[];
}

export class CustomLinks {
  startDate: string;
  totalCount: number;
  name: string;
  color: string;

  targetUrl: string;
  shortLinkId: number;
  shortLinkCode: string;
}

export interface CustomLinksByPeriodGetDto<CustomLinks> {
  data: CustomLinks[];
  endDate: string;
  startDate: string;
  total: number;
}

export interface CustomLinksByPeriod extends DashboardTotalCustomLinks {
  totalCount: number;
  name: string;
  startDate: string;
  data: CustomLinks[];
  color: string;

  targetUrl: string;
  shortLinkId: number;
  shortLinkCode: string;
}

export interface TotalCustomLinksCardInfo {
  totalCount: number;
  list: CustomLinksCardInfoItem[];
}
export interface CustomLinksCardInfoItem {
  label: string;
  value: number;
  progressPerCent: number;
  color: string;
}

export interface CustomLinksCardInfo {
  maxCount: number;
  list: CustomLinksCardInfoItem[];
}

export class CustomLinksTypeSetting {
  name: string;
  color: string;
}
