import { Location } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { AccountService, AuthService } from "@app/core";
import { AllowedDeliveryChannel, CustomizedCampaign, LocationWidgetSettings, RoleTypes, WidgetType } from "@app/models";
import { WidgetSettingsModalBaseComponent } from "@app/pages/components/widget-settings/widget-settings-modal-base";
import { LandingService, WidgetService } from "@app/services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export class WidgetSettings {
  public widgetSettingsJson: string;
  public deliveryMethod: AllowedDeliveryChannel;
  public configured: boolean;
}

@Component({
  selector: "app-widget-settings-modal",
  templateUrl: "./widget-settings-modal.component.html",
  styleUrls: ["./widget-settings-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetSettingsModalComponent extends WidgetSettingsModalBaseComponent implements OnInit, AfterViewInit {
  constructor(
    public activeModal: NgbActiveModal,
    public landingSvc: LandingService,
    public widgetSvc: WidgetService,
    private accountService: AccountService,
    private authService: AuthService,
    private cdf: ChangeDetectorRef,
    private locationUrl: Location
  ) {
    super(activeModal);

    this.staticUrl = window.location.pathname;
    this.locationUrl.onUrlChange((x) => this.urlChange(x));
  }

  createMode: boolean;
  isLoadPage = true;
  widgetType = WidgetType;
  loading = true;
  staticUrl: string;
  widgetsInThePlan: Array<CustomizedCampaign>;

  @ViewChild("widget") widget;

  private _locationWidget: LocationWidgetSettings;

  get widgetSettings() {
    return {
      deliveryMethod: this.form.controls.deliveryMethod.value,
      widgetSettingsJson: JSON.stringify(this.widget?.form.value),
      configured: this.form.controls.configured.value
    } as WidgetSettings;
  }

  get isAdmin() {
    return this.authService.requredMinimumRole(RoleTypes.PartnerAdmin);
  }

  get isValid() {
    return this.form.valid && (!this.widget || this.widget?.form.valid);
  }

  get locationWidget(): LocationWidgetSettings {
    this._locationWidget.deliveryMethod = this.form.controls.deliveryMethod.value;
    this._locationWidget.configured = this.form.controls.configured.value;
    this._locationWidget.widgetSettingsJson = JSON.stringify(this.form.controls.widgetSettings.value);
    return this._locationWidget;
  }

  set locationWidget(value: LocationWidgetSettings) {
    this._locationWidget = value;
    this.form.controls.deliveryMethod.setValue(value.deliveryMethod);
    this.form.controls.configured.setValue(value.configured);
  }

  form = new FormGroup({
    widgetSettings: new UntypedFormControl(""),
    deliveryMethod: new UntypedFormControl("", [Validators.required]),
    configured: new FormControl()
  });

  ngOnInit(): void {
    this.form.controls.deliveryMethod.setValue(this.deliveryMethod ? this.deliveryMethod : AllowedDeliveryChannel.qr);
    this.form.controls.configured.setValue(this.configured);
    this.landingSvc.getPublishedPageByPurpose(this.widgetId).subscribe(
      (p) => {
        this.page = p;
        this.loading = false;
        this.cdf.detectChanges();
      },
      () => {
        this.loading = false;
        this.cdf.detectChanges();
      }
    );
  }

  ngAfterViewInit() {
    this.cdf.detectChanges();
  }

  previewPage() {
    if (this.createMode) {
      this.openTestPage();
      return;
    }

    if (!this.isValid) {
      return;
    }

    if (this.brand && this.brand.id > 0) {
      this.widgetSvc.updateLocationCampaign(this.locationWidget).subscribe(() => {
        const url = this.landingSvc.getProdLandingPageUrl(this.page.id, this.brand.shortName, this.location.id);
        window.open(url, "_blank");
      });
    }
  }

  openTestPage() {
    const url = this.landingSvc.getLandingPageUrl(this.page.id, true);
    window.open(url, "_blank");
  }

  save() {
    if (!this.isValid) {
      return;
    }
    this.activeModal.close(this.widgetSettings);
  }

  setWidgetSettingsValidation() {
    // this.form.controls.widgetSettings.setValidators([Validators.required]);
    // this.form.controls.widgetSettings.reset();
    this.form.controls.widgetSettings.markAsTouched();
    setTimeout(() => this.cdf.detectChanges(), 100);
  }

  urlChange(x) {
    if (x && !x.includes(this.staticUrl)) {
      this.activeModal.close();
    }
  }
}
