export enum CustomerDeviceType {
  MerchantTablet = 1,
  Cfs = 2
}

export enum CustomerDeviceStatus {
  Ordered = 1,
  Delivered = 2,
  Active = 3
}

export class CustomerDevice {
  public id?: number;
  public name: string;
  public deviceType: CustomerDeviceType;
  public serialNumber: string;
  public deviceStatus: CustomerDeviceStatus;
  public accountId?: number;
  public locationId?: number;
  public locationName?: string;
  public deviceTypeName?: string;
  public deviceStatusName?: string;
}
