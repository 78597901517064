import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { TableCellAction, TableCellActionDataIn, TableCellActionDataOut, TableColumnData } from "@app/models";
import { TableService } from "@app/services";
import { AdvancedSortableDirective, SortEvent } from "@app/shared/directives/advanced-sortable.directive";
import { phoneMask } from "ui-kit";
import { sortTableByType, tableDateFormat } from "./../table.utils";

@Component({
  selector: "app-base-grid",
  templateUrl: "./base-grid.component.html",
  styleUrls: ["./base-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseGridComponent<T extends { id: number | string }> {
  readonly dateFormat = tableDateFormat;
  readonly phoneMask = phoneMask;

  @Input() wrappedByCard = false;
  @Input() set data(value: T[]) {
    if (value) {
      this.tableDataSrc = value;
      this.normalizeTableData();
      this.updateTableData();
    }
  }

  @Input() set searchString(search: string) {
    this.search = search;
    this.pageIndex = 1;
    this.updateTableData();
  }

  @Input() columnData: TableColumnData[] = [];

  @Input() columnDateFormat = this.dateFormat.full;

  @Input() set defaultSort(sorts: SortEvent) {
    if (sorts) {
      this.sorts = sorts;
      this.normalizeTableData();
      this.updateTableData();
    }
  }

  @Input() actionList: TableCellActionDataIn[];

  @Input() stickyActionListColumn = false;

  @Input() loading: boolean;

  @Output() tableAction = new EventEmitter<TableCellActionDataOut>();

  @ViewChildren(AdvancedSortableDirective) headers: QueryList<AdvancedSortableDirective>;

  sorts: SortEvent;
  search = "";
  pageIndex = 1;
  pageSize = 10;
  maxSize = 4;
  tableData: T[] = [];
  total = this.tableData.length;

  protected tableDataSrc: T[] = [];

  constructor(protected tableService: TableService, protected datePipe: DatePipe) {}

  onSort(e: SortEvent): void {
    const column = e.column;
    const direction = e.direction === "desc" ? "desc" : "asc";

    this.sorts = { column, direction };
    this.headers = this.tableService.header(this.headers, column);
    this.updateTableData();
  }

  onPagination(pageSize: number = this.pageSize): void {
    this.pageSize = pageSize;
    this.updateTableData();
  }

  onTableAction(action: TableCellAction, itemId: number | string): void {
    this.tableAction.emit({ action, itemId });
  }

  protected filterTable(list: T[]): T[] {
    if (this.search) {
      return list.filter((item: T) =>
        this.columnData.some((column) => {
          if (item.hasOwnProperty(column.key) && !column.hidden) {
            return `${item[column.key]}`.toLowerCase().includes(this.search.toLowerCase());
          }

          return false;
        })
      );
    }

    return list;
  }

  protected updateTableData(): void {
    let table = this.tableDataSrc.slice();

    table = this.filterTable(table);

    if (this.sorts?.column) {
      const targetColumn = this.columnData.find((c) => c.key === this.sorts.column);

      if (targetColumn?.dataType) {
        table = sortTableByType(table, this.sorts, targetColumn?.dataType);
      }
    }

    this.total = table.length;
    this.tableData = this.tableService.pagination(table, this.pageIndex, this.pageSize);
  }

  protected normalizeTableData(): void {
    if (this.columnData.length && this.tableDataSrc.length) {
      const specificColumns = this.columnData.filter((c) => c.dataType !== "number" && c.dataType !== "string");
      specificColumns.forEach((column) => {
        this.tableDataSrc = this.tableDataSrc.map((item) => {
          if (item[column.key] && column.dataType === "date") {
            item[column.key] = this.datePipe.transform(item[column.key], this.columnDateFormat);
          }

          return item;
        });
      });
    }
  }
}
