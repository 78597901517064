import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core";
import { WidgetLocationsConfigurationStatusDto } from "@app/models";

@Injectable({
  providedIn: "root"
})
export class LocationWidgetService {
  private serviceUrl = "locationWidget";

  constructor(private http: DataService) {}

  getConfiguredWidgetStatus(widgetId: number): Observable<WidgetLocationsConfigurationStatusDto[]> {
    return this.http.get(`${this.serviceUrl}/configured/${widgetId}/status`);
  }
}
