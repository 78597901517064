import { Location, AllowedDeliveryChannel, Campaign } from ".";

export class LocationWidgetSettings {
  public locationId: number;
  public defaultWidgetSettingsId: number;
  public disabled = true;
  public deliveryMethod: AllowedDeliveryChannel;
  public location: Location;
  public defaultWidgetSettings: Campaign;
  public isEditing: boolean;
  public configured: boolean;
  public widgetSettingsJson: string;
  public landingUrl: string;
}
