import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountService } from "./account.service";

import { AuthService } from "./auth.service";
import { UtilityService } from "./utility.service";

@Injectable()
export class PendingRegistrationGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private accountSvc: AccountService,
    private utilitySvc: UtilityService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let result = true;

    result = this.isAccountPendingConfiguration(state);
    if (!result) {
      this.router.navigate(["/"]);
      return result;
    }

    return result;
  }

  isAccountPendingConfiguration(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = user?.doneQuickSetup && !user?.configured;
    if (!result) {
      return false;
    }
    return true;
  }
}
