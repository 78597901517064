export class PosExportRequestDto {
  startDate: string;
  endDate: string;
  isDetailed: boolean;
}

export class PosTransaction {
  date: Date;
  transactionType: string;
  locationId: string;
  amount: string;
}
