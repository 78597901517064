<div class="form-group d-flex justify-content-between align-items-center">
  <app-url-form-control
    class="w-100"
    [(ngModel)]="innerValue"
    [disable]="isDisabled || isReadOnly"
    [label]="label"
    [isHideTooltip]="true"
    [validators]="validators"
    (ngModelChange)="valueChanged()"
  ></app-url-form-control>

  <div
    *ngIf="isReadOnly"
    class="d-flex justify-content-center align-items-center align-self-end bg-body square-40 ms-2 cursor-pointer"
    (click)="isReadOnly = false">
    <i class="ri-edit-line font-size-20"></i>
  </div>
</div>
