<div class="card bg-light mb-3">
  <div class="row card-body">
    <div *ngIf="title" class="col-12 d-flex justify-content-between w-100">
      <h5 class="m-0">{{ title }}</h5>
    </div>
    <div *ngIf="title" class="col-12">
      <hr class="mb-3" />
    </div>

    <ng-content></ng-content>
  </div>
</div>
