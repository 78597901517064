import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import {
  AccountInfoGetDto,
  ActivationCodeGetDto,
  ActivationCodePostDto,
  ActivationCodePutDto,
  ActivationCodeStatus,
  AssignActivationCodeDto,
  SendActivationCodeRequestDto
} from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ActivationCodeService {
  private serviceUrl = "activationCode";

  constructor(private http: DataService) {}

  exportCsv(partnerIds: number[], statusIds: ActivationCodeStatus[]): Observable<string> {
    const params = this.getHttpParams(partnerIds, statusIds);

    return this.http.get(`${this.serviceUrl}/export/csv?${params.toString()}`);
  }

  getActivationCodes(partnerIds: number[], statusIds: ActivationCodeStatus[]): Observable<ActivationCodeGetDto[]> {
    const params = this.getHttpParams(partnerIds, statusIds);

    return this.http.get(`${this.serviceUrl}?${params.toString()}`);
  }

  createActivationCodes(data: ActivationCodePostDto): Observable<ActivationCodeGetDto> {
    return this.http.post(`${this.serviceUrl}`, data);
  }

  getActivationCode(id: number): Observable<ActivationCodeGetDto> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  updateActivationCode(id: number, data: ActivationCodePutDto): Observable<ActivationCodeGetDto> {
    return this.http.put(`${this.serviceUrl}/${id}`, data);
  }

  deleteActivationCode(id: number): Observable<void> {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  sendActivationCode(id: number, data: SendActivationCodeRequestDto): Observable<void> {
    return this.http.post(`${this.serviceUrl}/${id}/send`, data);
  }

  useActivationCode(activationCode: string): Observable<string> {
    return this.http.get(`${this.serviceUrl}/use/${activationCode}`);
  }

  assignActivationCode(activationCodeId: number, payload: AssignActivationCodeDto): Observable<{ data?: string }> {
    return this.http.post(`${this.serviceUrl}/${activationCodeId}/assign`, payload);
  }

  getUnassignedAccountsActivationCode(partnerId: number): Observable<AccountInfoGetDto[]> {
    return this.http.get(`${this.serviceUrl}/unassignedAccounts?partnerId=${partnerId}`);
  }

  publishActivationCode(id: number): Observable<void> {
    return this.http.post(`${this.serviceUrl}/${id}/publish`);
  }

  private getHttpParams(partnerIds: number[], statusIds: ActivationCodeStatus[]): HttpParams {
    let params = new HttpParams();

    partnerIds.forEach((id) => {
      params = params.append("partnerIds", id.toString());
    });

    statusIds.forEach((id) => {
      params = params.append("statuses", id.toString());
    });

    return params;
  }
}
