<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!-- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <li [class.d-none]="item.hidden">
          <a *ngIf="item.subItems" class="is-parent has-arrow">
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>

            <span><ng-container *ngTemplateOutlet="itemLabel; context: { label: item.label, untranslatedLabel: item.untranslatedLabel }"></ng-container></span>
          </a>
          <a
            #sideNavLink
            *ngIf="item.routePath"
            [routerLink]="item.routePath"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: !item.pathWithParam }"
            (click)="onRouterLink()"
          >
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>
            <span><ng-container *ngTemplateOutlet="itemLabel; context: { label: item.label, untranslatedLabel: item.untranslatedLabel }"></ng-container></span>
          </a>
          <a
            #sideNavLink
            *ngIf="item.function"
            customHoverLink
            [newTab]="item.newTab"
            (click)="onClick(item.function, item.param)">
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>
            <span><ng-container *ngTemplateOutlet="itemLabel; context: { label: item.label, untranslatedLabel: item.untranslatedLabel }"></ng-container></span>
          </a>

          <ul *ngIf="item.subItems" [class.d-none]="item.hidden" class="sub-menu" aria-expanded="false">
            <ng-container *ngFor="let subitem of item.subItems">
              <li [class.d-none]="subitem.hidden">
                <a
                  #sideNavLink
                  *ngIf="subitem.routePath"
                  [routerLink]="subitem.routePath"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: !subitem.pathWithParam }"
                  (click)="onRouterLink()"
                >
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label, untranslatedLabel: subitem.untranslatedLabel }"></ng-container>
                </a>
                <a
                  #sideNavLink
                  *ngIf="subitem.function"
                  customHoverLink
                  [newTab]="subitem.newTab"
                  (click)="onClick(subitem.function, subitem.param)"
                  [class.no-wrap]="(labelTranslationKey + subitem.label | transloco).length < 25"
                >
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label, untranslatedLabel: subitem.untranslatedLabel }"></ng-container>
                  <sup *ngIf="subitem.icon"><i class="{{ subitem.icon }}"></i></sup>
                </a>
                <a
                  #sideNavLink
                  *ngIf="subitem.hyperLink"
                  [href]="subitem.hyperLink"
                  target="_blank"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label, untranslatedLabel: subitem.untranslatedLabel }"></ng-container>
                  <sup><i class="{{ subitem.icon }}"></i></sup>
                </a>

                <a *ngIf="subitem.subItems" class="side-nav-link-a-ref has-arrow">
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label, untranslatedLabel: subitem.untranslatedLabel }"></ng-container>
                </a>

                <ul *ngIf="subitem.subItems" class="sub-menu mm-collapse" aria-expanded="false">
                  <ng-container *ngFor="let subSubitem of subitem.subItems">
                    <li [class.d-none]="subSubitem.hidden">
                      <a
                        #sideNavLink
                        *ngIf="subSubitem.routePath"
                        [routerLink]="subSubitem.routePath"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        (click)="onRouterLink()"
                      >
                        <ng-container *ngTemplateOutlet="itemLabel; context: { label: subSubitem.label, untranslatedLabel: subitem.untranslatedLabel }"></ng-container>
                      </a>
                      <a #sideNavLink *ngIf="subSubitem.function" (click)="onClick(subSubitem.function, subSubitem.param)">
                        <ng-container *ngTemplateOutlet="itemLabel; context: { label: subSubitem.label, untranslatedLabel: subitem.untranslatedLabel }"></ng-container>
                        <sup><i class="{{ subSubitem.icon }}"></i></sup>
                      </a>
                      <a
                        #sideNavLink
                        *ngIf="subSubitem.hyperLink"
                        [href]="subSubitem.hyperLink"
                        target="_blank"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        <ng-container *ngTemplateOutlet="itemLabel; context: { label: subSubitem.label, untranslatedLabel: subitem.untranslatedLabel }"></ng-container>
                        <sup><i class="{{ subSubitem.icon }}"></i></sup>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>

<!-- Sidebar -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100" #ngxSimplebar>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->


<ng-template #itemLabel let-label="label" let-untranslatedLabel="untranslatedLabel">
  <ng-container *ngIf="label">{{ untranslatedLabel ? label : (labelTranslationKey + label | transloco) }}</ng-container>
</ng-template>
