import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { YotubeVideoValidator } from "@app/shared/validators";

@Component({
  selector: "app-embedded-video-form-control",
  templateUrl: "./embedded-video-form-control.component.html",
  styleUrls: ["./embedded-video-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmbeddedVideoFormControlComponent),
      multi: true
    }
  ]
})
export class EmbeddedVideoFormControlComponent implements ControlValueAccessor, OnInit {
  private _videoLink = "https://www.youtube.com/embed/";
  public formControl = new UntypedFormControl("");
  public link: SafeResourceUrl;
  @Input() placeholder;
  @Input() preview = false;
  @Input() set value(value: string) {
    this.formControl.setValue(value);
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this._videoLink + this.value);
    this.onChange(value);
    this.onTouched();
    this.changeDetectorRef.markForCheck();
  }

  get value() {
    return this.formControl.value;
  }

  constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.value) this.formControl.setValidators([YotubeVideoValidator.createValidator()]);
  }

  onTouched: any = () => null;
  onChange: any = () => null;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  writeValue(obj: any): void {
    this.value = obj;
  }

  valueChanged() {
    setTimeout(() => {
      this.value = this.formControl.value;
      if (this.value) {
        this.formControl.setValidators([YotubeVideoValidator.createValidator()]);
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this._videoLink + this.value);
      }
    }, 300);
  }
}
