import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn } from "@angular/forms";
import { BaseUrlFormControlComponent } from "../base-url-form-control.component";

@Component({
  selector: "app-safe-url-form-control",
  templateUrl: "./safe-url-form-control.component.html",
  styleUrls: ["./safe-url-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SafeUrlFormControlComponent),
      multi: true
    }
  ]
})
export class SafeUrlFormControlComponent extends BaseUrlFormControlComponent implements ControlValueAccessor {
  @Input() validators: ValidatorFn[] = [];

  get value(): string {
    return this.innerValue;
  }

  set value(value: string) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  isDisabled = false;
  isReadOnly = false;
  innerValue: string;

  writeValue(value: string): void {
    this.value = value;
    this.isReadOnly = !!value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  valueChanged(): void {
    this.onChange(this.value);
  }
}
