import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { BrandSocialLink, BrandSocialLinkSettings, SocialLinkTypes } from "@app/models";
import { BrandSocialLinkService } from "@app/services";
import { Patterns } from "@app/shared/validators";
import { skip } from "rxjs/operators";

@Component({
  selector: "app-social-links-form-control",
  templateUrl: "./social-links-form-control.component.html",
  styleUrls: ["./social-links-form-control.component.scss"]
})
export class SocialLinksFormControlComponent implements OnInit, AfterViewInit {
  private _links: BrandSocialLink[];
  @Input() isRuleBtn = false;
  @Input() isFull = false;
  @Input() isListOnlyRead = false;
  ruleBtn = true;
  titleRuleBtn = "Show more";

  public deafaultLinks: BrandSocialLinkSettings[] = [
    {
      linkType: SocialLinkTypes.Facebook,
      icon: "mdi mdi-facebook",
      color: "primary",
      defaultPlaceholder: "https://www.facebook.com/",
      isShowLink: true,
      iconSvg: "assets/images/social-icons/facebook.svg"
    },
    {
      linkType: SocialLinkTypes.Instagram,
      icon: "mdi mdi-instagram",
      color: "pink",
      defaultPlaceholder: "https://www.instagram.com/",
      isShowLink: true,
      iconSvg: "assets/images/social-icons/instagram.svg"
    },
    {
      linkType: SocialLinkTypes.LinkedIn,
      icon: "mdi mdi-linkedin",
      color: "primary",
      defaultPlaceholder: "https://www.linkedin.com/",
      isShowLink: true,
      iconSvg: "assets/images/social-icons/linkedin.svg"
    },
    {
      linkType: SocialLinkTypes.Google,
      icon: "mdi mdi-google",
      color: "danger",
      defaultPlaceholder: "https://www.google.com/",
      isShowLink: false,
      iconSvg: "assets/images/social-icons/google.svg"
    },
    {
      linkType: SocialLinkTypes.Snapchat,
      icon: "mdi mdi-snapchat",
      color: "warning",
      defaultPlaceholder: "https://www.snapchat.com/",
      isShowLink: false,
      iconSvg: "assets/images/social-icons/snapchat.svg"
    },
    {
      linkType: SocialLinkTypes.Twitter,
      icon: "mdi mdi-twitter",
      color: "info",
      defaultPlaceholder: "https://twitter.com/",
      isShowLink: false,
      iconSvg: "assets/images/social-icons/twitter.svg"
    },
    {
      linkType: SocialLinkTypes.Youtube,
      icon: "mdi mdi-youtube",
      color: "dark-danger",
      defaultPlaceholder: "https://www.youtube.com/",
      isShowLink: false,
      iconSvg: "assets/images/social-icons/youtube.svg"
    },
    {
      linkType: SocialLinkTypes.TikTok,
      icon: "bx bxl-tiktok-old",
      color: "dark",
      defaultPlaceholder: "https://tiktok.com/",
      isShowLink: false,
      iconSvg: "assets/images/social-icons/tiktok.svg"
    }
  ];
  form: UntypedFormGroup;
  @Output() linksChanged = new EventEmitter<BrandSocialLink[]>();
  @Output() linksValid = new EventEmitter<boolean>();
  @Input() brandId: number;
  @Input() isEditable = false;
  @Input() set links(value: BrandSocialLink[]) {
    setTimeout(() => {
      if (value && value.length && !this._links) {
        const formValues = [];
        this.deafaultLinks.forEach((link) => {
          link.id = value?.find((l) => l.linkType === link.linkType)?.id;
          const formLink = value?.find((l) => l.linkType === link.linkType)?.linkUrl;
          formValues.push({ [link.linkType]: formLink ? formLink : "" });
        });
        this.form.patchValue(Object.assign({}, ...formValues.map((fl) => fl)), { emitEvent: false });
        this.linksValid.emit(this.form.valid);
        this.brandSocialLinkService.onUpdateLinks(value);
      }
      this._links = value;
      this.cdr.detectChanges();
    }, 300);
  }
  get links(): BrandSocialLink[] {
    return this._links;
  }
  public brandTypes = SocialLinkTypes;
  public validators: Array<ValidatorFn> = [Validators.pattern(Patterns.url), Validators.maxLength(2000)];

  constructor(private brandSocialLinkService: BrandSocialLinkService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const form = {};
    this.deafaultLinks.forEach((links) => {
      form[links.linkType] = new UntypedFormControl("", this.validators);
    });
    this.form = new UntypedFormGroup(form);
    this.brandSocialLinkService.onUpdateLinks(
      this.deafaultLinks.map((l) => {
        return {
          id: l.id,
          linkType: l.linkType,
          linkUrl: ""
        } as BrandSocialLink;
      })
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subcribeChanges();
    }, 300);
  }

  getLinkUrl(type: SocialLinkTypes): string {
    return this.links?.find((l) => l.linkType === type)?.linkUrl;
  }

  subcribeChanges() {
    this.form.valueChanges.pipe().subscribe(() => {
      const links: BrandSocialLink[] = this.generateLinks();

      if (this.form.valid) {
        this.brandSocialLinkService.onUpdateLinks(links);
        this.brandSocialLinkService.onUpdateLinksValidation(this.form.valid);
        this.linksChanged.emit(links);
      }
      this.linksValid.emit(this.form.valid);
      this.brandSocialLinkService.onUpdateLinksValidation(this.form.valid);
    });
  }

  private generateLinks(): BrandSocialLink[] {
    const links: BrandSocialLink[] = [];
    Object.keys(this.form.controls).forEach((key) => {
      const id = this.deafaultLinks?.filter((l) => l.linkType === +key)[0].id;
      const link = {
        linkUrl: this.form.controls[key].value,
        linkType: +key,
        id: id
      } as BrandSocialLink;
      links.push(link);
    });
    return links;
  }

  showMoreLink(valueBtn) {
    this.ruleBtn = !valueBtn;

    if (!this.ruleBtn) {
      this.titleRuleBtn = "Show less";
      this.deafaultLinks.forEach((e) => {
        if (!e.isShowLink) {
          e.isShowLink = true;
        }
      });
    } else {
      this.titleRuleBtn = "Show more";
      let i = 0;
      this.deafaultLinks.forEach((e) => {
        if (i < 3) {
          e.isShowLink = true;
          i = i + 1;
        } else {
          e.isShowLink = false;
        }
      });
    }
  }
}
