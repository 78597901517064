<div class="form-group" *transloco="let tc; read: 'common.error'">
  <label class="col-form-label">{{ label }}</label>
  <input
    *ngIf="!isCanada"
    [placeholder]="label"
    (change)="valueChanged()"
    mask="0000000000"
    [class.is-invalid]="formControl.touched && formControl.errors"
    [formControl]="formControl"
    class="form-control"
    type="text"
  />
  <input
    *ngIf="isCanada"
    [placeholder]="label"
    (change)="valueChanged()"
    [patterns]="customPatterns"
    mask="000 000"
    [class.is-invalid]="formControl.touched && formControl.errors"
    [formControl]="formControl"
    class="form-control text-uppercase"
    type="text"
  />

  <ng-container *ngIf="formControl.touched && formControl.errors">
    <ng-container *ngIf="formControl.errors.required; else second">
      <div class="invalid-feedback">
        {{ tc("required") }}
      </div>
    </ng-container>
  </ng-container>
  <ng-template #second>
    <div class="invalid-feedback">
      {{ tc("required") }}
    </div>
  </ng-template>
</div>
