import { NavFilterItem, TimeFrameTypes } from "@app/models";

export class DashboardFiltersUtilities {
  constructor() {}

  static getNavFilterList(): NavFilterItem[] {
    return [
      {
        type: TimeFrameTypes.Year,
        label: "Year",
        active: false
      },
      {
        type: TimeFrameTypes.Month,
        label: "Month",
        active: false
      },
      {
        type: TimeFrameTypes.Week,
        label: "Week",
        active: false
      },
      {
        type: TimeFrameTypes.Day,
        label: "Day",
        active: false
      },
      {
        type: TimeFrameTypes.Custom,
        label: "Custom",
        active: false
      }
    ];
  }
}
