<form [formGroup]="form" *ngIf="!isRuleBtn && !isFull && !isListOnlyRead">
  <div class="" *ngFor="let item of deafaultLinks">
    <div class="social-source d-flex justify-content-between mt-3">
      <div class="rounded-circle avatar-xs mb-3 min-icon-width">
        <span class="avatar-title rounded-circle bg-{{ item.color }} font-size-16">
          <i class="{{ item.icon }} text-white"></i>
        </span>
      </div>
      <app-url-form-control
        [formControlName]="item.linkType"
        [isLinked]="getLinkUrl(item.linkType) && !isEditable"
        [disable]="!isEditable"
        [placeholder]="!getLinkUrl(item.linkType) ? item.defaultPlaceholder : getLinkUrl(item.linkType)"
        class="col-sm-11"
        [validators]="validators"
      >
      </app-url-form-control>
    </div>
  </div>
</form>

<form [formGroup]="form" *ngIf="isRuleBtn">
  <div class="row d-flex justify-content-end">
    <ng-container *ngFor="let item of deafaultLinks">
      <div *ngIf="item.isShowLink" class="form-group col-sm-6">
        <app-url-form-control
          [iconInside]="item.icon"
          [iconSvg]="item.iconSvg"
          [iconInsideColor]="item.color"
          [formControlName]="item.linkType"
          [isLinked]="getLinkUrl(item.linkType) && !isEditable"
          [disable]="!isEditable"
          [placeholder]="!getLinkUrl(item.linkType) ? item.defaultPlaceholder : getLinkUrl(item.linkType)"
          [validators]="validators"
        >
        </app-url-form-control>
      </div>
    </ng-container>
    <div class="form-group col-sm-6">
      <button class="form-control btn btn-outline-light" (click)="showMoreLink(ruleBtn)">{{ titleRuleBtn }}</button>
    </div>
  </div>
</form>

<form [formGroup]="form" *ngIf="isFull">
  <div class="row d-flex justify-content-end">
    <ng-container *ngFor="let item of deafaultLinks">
      <div class="form-group col-sm-6">
        <app-url-form-control
          [iconInside]="item.icon"
          [iconSvg]="item.iconSvg"
          [iconInsideColor]="item.color"
          [formControlName]="item.linkType"
          [isLinked]="getLinkUrl(item.linkType) && !isEditable"
          [disable]="!isEditable"
          [placeholder]="!getLinkUrl(item.linkType) ? item.defaultPlaceholder : getLinkUrl(item.linkType)"
          [validators]="validators"
        >
        </app-url-form-control>
      </div>
    </ng-container>
  </div>
</form>

<div *ngIf="isListOnlyRead">
  <div *ngFor="let item of deafaultLinks">
    <div class="row form-group" *ngIf="getLinkUrl(item.linkType)">
      <div class="col-sm-2 col-3 text-muted text-start">
        <div class="font-size-16">
          <span class="position-absolute top-25 start-4 br-none">
            <img class="icon icon-xs icon-rounded" [src]="item.iconSvg" alt="{{ item.iconSvg }}" />
          </span>
        </div>
      </div>
      <div class="col-sm-10 col-9 text-end">{{ getLinkUrl(item.linkType) }}</div>
    </div>
  </div>
</div>
