<div *ngIf="dataType === 'check'">
  <span class="table-icon-wrapper">
    <i [ngClass]="value ? checkIconInfo.checked : checkIconInfo.unchecked"></i>
  </span>
</div>

<div *ngIf="dataType === 'active'">
  <span>{{ (value ? 'grid.active' : 'grid.notActive' ) | transloco }}</span>
</div>

<div *ngIf="dataType === 'status'">
  <!--              placeholder for status component-->
</div>

<div *ngIf="dataType === 'link'">
  <!--              placeholder for link-->
</div>
