<div
  [ngClass]="
    {
      'row': isEditable && !(size === 'L' || size === 'XL'),
      'col-xxl-8 col-xl-8 col-lg-10 col-md-10 col-sm-7 col-7': size !== 'L',
      'col-md-12': size === 'L'
    }"
    class="w-100"
  >
  <div class="form-group">
    <div [class.col-md-6]="isEditable && size === 'M'">
      <div class="image-logo-container">
        <label class="col-form-label {{ labelCssClass }}" >
          <span>{{ label }}</span>
          <span *ngIf="isRequired" class="text-danger">*</span>
          <i *ngIf="tooltip" [ngbTooltip]="tooltip" class="bx bx-question-mark text-info font-size-18"></i>
        </label>
        <div class="position-relative image-logo-content-{{size}}">
          <i
            *ngIf="isEditable && isClearable && value"
            class="mdi mdi-close-circle clear-button text-danger"
            [ngbTooltip]="'common.controls.delete' | transloco"
            (click)="clearImage($event)"
          ></i>
          <div
            class="image-logo-content image-logo-content-{{size}}"
            [class.uploader]="isEditable && !isDisabled"
            (click)="uploadImage()"
            >
            <img
              *ngIf="value"
              class="image-logo image-logo-{{size}} rounded me-2 image-contain"
              alt="200x200"
              [src]="value"
              data-holder-rendered="true"
            />
            <div *ngIf="!value" class="upload-message-container upload-message-placeholder upload-message-container-{{ size }}">
              <span class="fas fa-plus upload-plus"></span>
              <span class="upload-text">{{ 'common.controls.uploadPlaceholder' | transloco }}</span>
            </div>
            <div *ngIf="isEditable" class="upload-message-container upload-message upload-message-{{ size }}">{{ 'common.controls.upload' | transloco }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
