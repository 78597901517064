import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { PixelTag } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PixelTagService {
  private serviceUrl = "PixelTag";

  constructor(private http: DataService) {}

  getById(id: number): Observable<PixelTag> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  getAllByLocation(locationId: number): Observable<PixelTag[]> {
    return this.http.get(`${this.serviceUrl}/bylocation/${locationId}`);
  }

  create(pixelTag: PixelTag): Observable<PixelTag> {
    return this.http.post(`${this.serviceUrl}`, pixelTag);
  }

  update(pixelTag: PixelTag): Observable<PixelTag> {
    return this.http.put(`${this.serviceUrl}`, pixelTag);
  }

  delete(id: number) {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }
}
