import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"]
})
export class ConfirmationModalComponent implements OnInit {
  public title = "";
  public content = "";
  public confirmBtnText = "";
  public cancelBtnText = "";
  constructor(public activeModal: NgbActiveModal, private translateSvc: TranslocoService) {}

  ngOnInit(): void {
    this.confirmBtnText = this.translateSvc.translateObject("common.controls.confirm");
    this.cancelBtnText = this.translateSvc.translateObject("common.controls.cancel");
  }
  confirm() {
    this.activeModal.close(true);
  }
  cancel() {
    this.activeModal.dismiss();
  }
}
