import { ActivityByPeriod, ActivityByPeriodGetDto } from "@app/models";
import { DashboardService } from "@app/services";
import { DashboardExportUtilities, EchartsToolboxFeatureData } from "@app/services/dashboard/dashboard-export-activities.utilities";
import * as echarts from "echarts";
import { ECharts, EChartsOption } from "echarts";
import { OrdinalRawValue } from "echarts/types/src/util/types";

type SeriesOption$1 = echarts.BarSeriesOption | echarts.LineSeriesOption;

interface SeriesData {
  [type: string]: SeriesOption$1;
}

export class DashboardLocationActivitiesUtilities {
  constructor(public dashboardService: DashboardService) {}

  private static toolboxFeatureClickParams: any;
  private static dashboardExportUtilities: DashboardExportUtilities = new DashboardExportUtilities();

  private static getSeriesData(): SeriesData {
    return {
      qrCount: {
        name: "QR code",
        type: "bar",
        color: "#F79824"
      },
      smsCount: {
        name: "SMS",
        type: "bar",
        color: "#4DAA57"
      },
      emailCount: {
        name: "Email",
        type: "bar",
        color: "#2520B6"
      },
      cfsTemplateCount: {
        name: "CFS Template",
        type: "bar",
        color: "#FF4500"
      },
      totalCount: {
        name: "Total count",
        type: "line",
        yAxisIndex: 0,
        color: "#8664D3"
      }
    };
  }

  toolboxFeatureClickHandler(params = null): void {
    if (params) {
      DashboardLocationActivitiesUtilities.toolboxFeatureClickParams = params;
    } else {
      params = DashboardLocationActivitiesUtilities.toolboxFeatureClickParams;
    }

    const lineChartLoc: HTMLElement = document.getElementById("lineChart-loc");
    const lineChart: ECharts = echarts.init(lineChartLoc as HTMLElement, null, { renderer: "canvas" });

    const menu: HTMLElement = document.getElementById("id-chart-dom");
    const exportSVG: HTMLElement = document.getElementById("id-exportSVG-loc");
    const exportPNG: HTMLElement = document.getElementById("id-exportPNG-loc");
    const exportCSV: HTMLElement = document.getElementById("id-exportCSV-loc");

    const data: EchartsToolboxFeatureData = {
      params,
      lineChart,
      menu,
      exportSVG,
      exportCSV,
      exportPNG,
      fileNameFirstPart: "activities_by_locations",
      withArraySeriesData: false
    };

    const closeOtherChartsMenu: boolean = DashboardLocationActivitiesUtilities.dashboardExportUtilities.toolboxFeatureClickHandler(
      data,
      "locations"
    );

    if (closeOtherChartsMenu) {
      this.dashboardService.closeOtherChartsMenu("locations");
    }
  }

  private getChartOptionsDefault(): EChartsOption {
    return {
      title: {
        show: false,
        text: "clearline_activities_by_location"
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999"
          }
        }
      },
      toolbox: {
        feature: {
          dataView: { show: false, readOnly: false },
          magicType: { show: false, type: ["line", "bar"] },
          myTool: {
            show: true,
            title: "",
            icon: "image://./assets/images/menu-icon.svg",
            onclick: this.toolboxFeatureClickHandler.bind(this)
          },
          restore: { show: false },
          saveAsImage: {
            show: false,
            type: "png",
            title: "Save",
            emphasis: { iconStyle: { color: "#495057", textPadding: 0 } }
          }
        }
      },
      legend: {
        data: ["QR code", "SMS", "Email", "CFS Template", "Total monthly limit"]
      },
      xAxis: [
        {
          type: "category",
          data: [],
          axisPointer: {
            type: "shadow"
          }
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Activities",
          minInterval: 1,
          axisLabel: {
            formatter: function (value: OrdinalRawValue | number) {
              return (value as number).toFixed(0);
            }
          }
        }
      ],
      series: []
    };
  }

  getChartOptions(info: ActivityByPeriodGetDto<ActivityByPeriod>): EChartsOption {
    if (info?.data) {
      return this.getDataToOptions(info.data);
    }

    return this.getChartOptionsDefault();
  }

  private getDataToOptions(list: ActivityByPeriod[]): EChartsOption {
    const chartOptions: EChartsOption = this.getChartOptionsDefault();
    const seriesData: SeriesData = DashboardLocationActivitiesUtilities.getSeriesData();
    chartOptions.xAxis[0].data = [];
    chartOptions.series = [
      seriesData.qrCount,
      seriesData.smsCount,
      seriesData.emailCount,
      seriesData.cfsTemplateCount,
      seriesData.totalCount
    ];

    list.forEach((item: ActivityByPeriod) => {
      chartOptions.xAxis[0].data.push(item.locationName);

      Object.keys(seriesData).forEach((key: string) => {
        if (!seriesData[key].data) {
          seriesData[key].data = [];
        }
        seriesData[key].data.push(item[key]);
      });
    });

    return chartOptions;
  }
}
