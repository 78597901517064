import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function showCustomErrorMessageValidator(existingValidators: ValidatorFn[], customErrorMessage: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const validatorErrors = existingValidators.reduce((acc, validator) => ({ ...acc, ...validator(control) }), {} as ValidationErrors);

    if (Object.keys(validatorErrors).length > 0) {
      return { [customErrorMessage]: true };
    }

    return null;
  };
}
