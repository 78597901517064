import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePickerRangeInfo } from "@app/models";
import { DateStringRangeDto, DateTimeZoneService } from "@app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";
import { CustomFilterModalComponent } from "../custom-filter-modal";
import { tableDateFormat } from "../table/table.utils";

@Component({
  selector: "app-from-to-date-picker",
  templateUrl: "./from-to-date-picker.component.html",
  styleUrls: ["./from-to-date-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FromToDatePickerComponent implements OnInit {
  @Input() classList = "";
  @Input() label = "fromToDatepicker.labelDatePeriod";

  @Output() change = new EventEmitter<DateStringRangeDto>();
  @Output() inited = new EventEmitter<DateStringRangeDto>();

  form = new FormGroup({
    startDate: new FormControl<string | null>(null, Validators.required),
    endDate: new FormControl<string | null>(null, Validators.required)
  });

  currentRangeInfo: DatePickerRangeInfo = DateTimeZoneService.generateDefaultNgbDateRange();
  readonly dateFormat = tableDateFormat.short;

  constructor(private modalSvc: NgbModal, private datePipe: DatePipe) {}

  ngOnInit() {
    this.updateFormValues();
    this.form.markAllAsTouched();

    const dateRange = DateTimeZoneService.getNgbDateStringRangeDto(this.currentRangeInfo);
    this.inited.emit(dateRange);
  }

  showDateRangeModal() {
    const modal = this.modalSvc.open(CustomFilterModalComponent, {
      backdrop: "static",
      size: "l",
      centered: true,
      windowClass: "custom"
    });
    const modalComponent: CustomFilterModalComponent = modal.componentInstance;
    modalComponent.fromDate = this.currentRangeInfo.fromDate;
    modalComponent.toDate = this.currentRangeInfo.toDate;

    modal.dismissed.pipe(take(1)).subscribe((rangeInfo: DatePickerRangeInfo) => {
      if (rangeInfo) {
        this.currentRangeInfo = rangeInfo;
        this.updateFormValues();

        const dateRange = DateTimeZoneService.getNgbDateStringRangeDto(this.currentRangeInfo);
        this.change.emit(dateRange);
      }
    });
  }

  private updateFormValues(): void {
    const { startDate, endDate } = DateTimeZoneService.getNgbDateRangeDto(this.currentRangeInfo);

    this.form.patchValue({
      startDate: this.datePipe.transform(startDate.toISOString(), this.dateFormat),
      endDate: this.datePipe.transform(endDate.toISOString(), this.dateFormat)
    });
  }
}
