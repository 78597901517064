<div class="default-widget-modal" *transloco="let tr; read: 'widgets.enrollLoyalty'">
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body text-center content-modal">
    <form [formGroup]="formTier" autocomplete="off">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-8 form-group text-start">
          <app-dropdown
            bindValue="n"
            bindLabel="name"
            [control]="formTier.controls.rewardTier"
            [items]="listTier"
            [label]="tr('labelRewardTier')"
            [placeholder]="tr('labelRewardTier')"
          ></app-dropdown>
        </div>
      </div>
      <input formControlName="selectedCoupon" hidden />
    </form>

    <div class="coupon-carousel-wrapper centered-content">
      <app-coupon-carousel
        class="coupon-carousel"
        [loading]="loadingCoupons"
        [selectedCoupon]="selectedCoupon"
        [coupons]="coupons"
        [selectionType]="'single'"
        [withNothingFoundText]="true"
        [viewType]="'large'"
        (selectCoupon)="select($event)"
      >
      </app-coupon-carousel>
    </div>
  </div>

  <div class="modal-footer" *transloco="let t; read: 'common.controls'">
    <div class="d-flex justify-content-between w-100">
      <button class="btn btn-outline" (click)="dismiss()">
        {{ t("back") }}
      </button>
      <button class="btn btn-primary" [class.disabled]="!formTier.valid" (click)="save()">{{ t("save") }}</button>
    </div>
  </div>
</div>
