import { formatDate } from "@angular/common";
import { ApexAxisChartSeries, ApexLegend } from "ng-apexcharts";

import { ActivityByPeriod, ActivityByPeriodGetDto, ChartData, ChartOptions, TimeFrameTypes, TotalActivitiesCardInfo } from "@app/models";
import { DateTimeZoneService } from "../date-timezone.service";

export class DashboardTotalActivities {
  constructor() {}

  private static readonly minNumberForShortLabel = 20;

  private static apexTooltipItem: any = {
    title: {
      formatter: function (value: string) {
        return value;
      }
    }
  };

  private static apexLegend: ApexLegend = {
    tooltipHoverFormatter: function (type: string, opts: any) {
      const value = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];

      return `${type} - <strong>${value}</strong>`;
    }
  };

  private static chartOptions: Partial<ChartOptions> = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "clearline_total_engagement_attempts"
          },
          svg: {
            filename: "clearline_total_engagement_attempts"
          },
          png: {
            filename: "clearline_total_engagement_attempts"
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 3,
      curve: "straight",
      dashArray: [0, 8, 5, 6]
    },
    legend: DashboardTotalActivities.apexLegend,
    markers: {
      size: 5,
      hover: {
        sizeOffset: 3
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val?.toFixed(0) || "0";
        }
      }
    },
    xaxis: {
      tickPlacement: "between",
      labels: {
        trim: false,
        hideOverlappingLabels: true
        // formatter: DashboardTotalActivities.xaxisFormatter
      }
    },
    tooltip: {
      y: [
        DashboardTotalActivities.apexTooltipItem,
        DashboardTotalActivities.apexTooltipItem,
        DashboardTotalActivities.apexTooltipItem,
        DashboardTotalActivities.apexTooltipItem
      ]
    }
  };

  private static get apexAxisChartData(): any {
    return {
      totalCount: {
        name: "Total",
        data: [],
        color: "#4E2AA2"
      },
      qrCount: {
        name: "QR code",
        data: [],
        color: "#F79824"
      },
      smsCount: {
        name: "SMS",
        data: [],
        color: "#4DAA57"
      },
      emailCount: {
        name: "Email",
        data: [],
        color: "#2520B6"
      }
    };
  }

  static getActivitiesForCard(list: ActivityByPeriod[]): TotalActivitiesCardInfo {
    const totalActivities: ActivityByPeriod = this.getTotalActivitiesFromList(list);
    const { qrCount, smsCount, emailCount, totalCount } = totalActivities;

    return {
      totalCount,
      list: [
        {
          label: "QR code",
          value: qrCount,
          progressPerCent: this.getProgressForActivity(qrCount, totalCount),
          colorClass: "yellow"
        },
        {
          label: "SMS",
          value: smsCount,
          progressPerCent: this.getProgressForActivity(smsCount, totalCount),
          colorClass: "green"
        },
        {
          label: "Email",
          value: emailCount,
          progressPerCent: this.getProgressForActivity(emailCount, totalCount),
          colorClass: "blue"
        }
      ]
    };
  }

  static getChartOptions(info: ActivityByPeriodGetDto<ActivityByPeriod>, currentTimeFrame: TimeFrameTypes): Partial<ChartOptions> {
    const data: ChartData = this.getApexAxisChartData(info, currentTimeFrame);
    const chartOptions: Partial<ChartOptions> = { ...DashboardTotalActivities.chartOptions };

    chartOptions.series = data.series;
    chartOptions.xaxis.categories = data.categories;
    chartOptions.xaxis = { ...chartOptions.xaxis };

    return chartOptions;
  }

  private static getApexAxisChartData(info: ActivityByPeriodGetDto<ActivityByPeriod>, currentTimeFrame: TimeFrameTypes): ChartData {
    const totalActivities: ActivityByPeriod[] = info?.data;
    const data: any = DashboardTotalActivities.apexAxisChartData;
    const series: ApexAxisChartSeries[] = Object.values(data).map((item: ApexAxisChartSeries) => item);
    const timeCategories = [];

    if (totalActivities?.length) {
      const shortDateLabel: boolean = totalActivities?.length > this.minNumberForShortLabel;

      totalActivities.forEach((item: ActivityByPeriod, index: number) => {
        const date: string = DateTimeZoneService.getChartDateItem(item.startDate, currentTimeFrame, shortDateLabel);

        timeCategories.push(date);

        Object.keys(data).forEach((key: string) => {
          data[key].data.push(item[key]);
        });
      });
    }

    return {
      series,
      categories: timeCategories
    };
  }

  private static getProgressForActivity(activityValue: number, totalValue: number): number {
    return (activityValue / totalValue) * 100;
  }

  private static getTotalActivitiesFromList(list: ActivityByPeriod[]): ActivityByPeriod {
    return list.reduce((previousValue: ActivityByPeriod, currentValue: ActivityByPeriod) => {
      if (!previousValue) {
        return { ...currentValue };
      } else {
        previousValue.emailCount += currentValue.emailCount;
        previousValue.smsCount += currentValue.smsCount;
        previousValue.qrCount += currentValue.qrCount;
        previousValue.totalCount += currentValue.totalCount;

        return { ...previousValue };
      }
    }, null);
  }
}
