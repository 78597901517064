export interface TopAccountsActivityReportDto {
  startDate: string;
  endDate: string;
  period: number;
  timeZoneOffset: number;
  topCount: number;
  filterMode: TopAccountsActivityReportFilterModeDto;
  sortDirection: TopAccountsActivityReportSortDirectionDto;
}

export interface TopActivityReportBaseItemDto {
  clicksCount: number;
  attemptsCount: number;
}

export interface TopAccountActivityReportItemDto extends TopActivityReportBaseItemDto {
  accountId: number;
  brandId: number;
  companyName: string;
}

export interface TopPartnersActivityReportItemDto extends TopActivityReportBaseItemDto {
  partnerId: number;
  partnerName: string;
}

export enum TopAccountsActivityReportFilterModeDto {
  Clicks = "Clicks",
  Attempts = "Attempts"
}

export enum TopAccountsActivityReportSortDirectionDto {
  Highest = "Highest",
  Lowest = "Lowest",
  NoActivity = "NoActivity"
}
