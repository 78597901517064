import { Injectable } from "@angular/core";
import { AppConfigService } from "@app/core";
import { VariableMetaData, VariableData, LandingPage } from "@app/models";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";

@Injectable({
  providedIn: "root"
})
export class LandingService {
  private serviceUrl = "Landing";

  constructor(private http: DataService, private configSvc: AppConfigService) {}

  public getLandingPageById(id: number): Observable<LandingPage> {
    return this.http.get(`${this.serviceUrl}/${id}`, {});
  }
  public getPublishedPageByPurpose(purposeId: number): Observable<LandingPage> {
    return this.http.get(`${this.serviceUrl}/purpose/${purposeId}`, {});
  }
  public getPagePreview(pageId: number, brandId: number, locationId: number): Observable<LandingPage> {
    return this.http.get(`${this.serviceUrl}/preview/${pageId}/${brandId}/${locationId}`, {});
  }
  public getTestPagePreview(pageId: number): Observable<LandingPage> {
    return this.http.get(`${this.serviceUrl}/previewTest/${pageId}`, {});
  }
  public getLandingPageUrl(pageId: number, useTest = false, brandId: number = null, locationId: number = null): string {
    let pageUrl = this.configSvc.appData.shortLinkUrl + "/landing/";
    if (brandId && locationId && !useTest) {
      pageUrl += `${pageId}/${brandId}/${locationId}`;
    } else {
      pageUrl += "test/" + pageId;
    }
    return pageUrl;
  }
  public getProdLandingPageUrl(pageId: number, brandName: string = null, locationId: number): string {
    const pageUrl = `${this.configSvc.appData.shortLinkUrl}/${brandName}/${pageId}/${locationId}`;
    return pageUrl;
  }
  public getLandingPages(): Observable<LandingPage[]> {
    return this.http.get(`${this.serviceUrl}`, {});
  }
  public getPublishedLandingPages(): Observable<LandingPage[]> {
    return this.http.get(`${this.serviceUrl}/published`, {});
  }

  public createLandingPage(page: LandingPage): Observable<LandingPage> {
    return this.http.post(`${this.serviceUrl}`, page);
  }
  public updateLandingPage(page: LandingPage): Observable<LandingPage> {
    return this.http.put(`${this.serviceUrl}/${page.id}`, page);
  }
  publish(pageId: number): Observable<LandingPage> {
    return this.http.post(`${this.serviceUrl}/publish/${pageId}`);
  }
  public deleteLandingPage(page: LandingPage): Observable<LandingPage> {
    return this.http.delete(`${this.serviceUrl}/${page.id}`);
  }

  public getVariableMetadata(): Observable<VariableMetaData[]> {
    return this.http.get(`${this.serviceUrl}/variableMetadata`, {});
  }
  public getVariableValues(brandId: number, locationId: number): Observable<VariableData[]> {
    return this.http.get(`${this.serviceUrl}/variables/${brandId}/${locationId}`, {});
  }
  public getCurrentUserVariableValues(): Observable<VariableData[]> {
    return this.http.get(`${this.serviceUrl}/variables`, {});
  }
}
