<div class="d-flex justify-content-between mb-3" *ngIf="!isImport">
  <div class="">
    <div class="page-title-text">{{ title }}</div>
  </div>

  <div class="">
    <button
      *ngIf="withAddAction"
      class="btn btn-card-action btn-primary w-100"
      [disabled]="disabled"
      (click)="add()">
      <i class="mdi mdi-plus me-1"></i>
      {{ "common.controls.add" | transloco }} {{ item }}
    </button>

    <button
      *ngIf="!withAddAction && otherActionText"
      class="btn btn-card-action btn-primary w-100"
      [disabled]="disabled"
      (click)="otherAction()">
      {{ otherActionText }}
    </button>
  </div>
</div>

<div *ngIf="isImport" class="row d-flex justify-content-center mb-3">
  <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12">
    <div class="page-title-text">{{ title }}</div>
  </div>

  <div *transloco="let t; read: 'dealerImport'" class="col-xl-9 col-lg-9 col-md-12 col-sm-12 flex-fill text-sm-end">
    <div class="row">
      <div class="col-xl-4 col-lg-1 col-md-4 col-sm-5 col-6 pb-2"></div>

      <div class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-6 pb-2">
        <button class="btn btn-outline-primary w-100" [disabled]="disabled" (click)="import()">
          {{ t("import-btn") }}
        </button>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-6 pb-2">
        <button [disabled]="disabled" (click)="download()" class="btn btn-outline-primary w-100">
          {{ t("download") }}
        </button>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-3 col-sm-2 col-12 pb-2">
        <button class="btn btn-primary w-100" [disabled]="disabled" (click)="add()">
          <i class="mdi mdi-plus me-1"></i>
          {{ "locationProducts.add-product-btn" | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-grid-not-found [itemName]="item" [allowAdd]="!disabled && withAddAction" [notFoundText]="notFoundText"></app-grid-not-found>
