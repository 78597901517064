import { Injectable } from "@angular/core";
import { ActivityByPeriodPostDto, NavFilterItem, TimeFrameTypes } from "@app/models";
import { DashboardFiltersUtilities } from "@app/services/dashboard/dashboard-filters.utilities";
import { BehaviorSubject, Observable } from "rxjs";
import { DatePickerRangeInfo, PeriodType } from "./../models/date-picker";
import { DateTimeZoneService } from "./date-timezone.service";

@Injectable({
  providedIn: "root"
})
export class DateFilterService {
  private currentTimeFrame: TimeFrameTypes = TimeFrameTypes.Month;
  private currentRangeInfo: DatePickerRangeInfo = null;
  private navFilterListSubject = new BehaviorSubject<NavFilterItem[]>([]);
  private currentPeriodSubject = new BehaviorSubject<ActivityByPeriodPostDto>({
    period: this.currentTimeFrame,
    timeZoneOffset: new Date().getTimezoneOffset()
  });

  public navFilterList$: Observable<NavFilterItem[]> = this.navFilterListSubject.asObservable();
  public currentPeriod$: Observable<ActivityByPeriodPostDto> = this.currentPeriodSubject.asObservable();

  constructor() {}

  public filter(periodType: PeriodType): void {
    this.setCurrentTimeFrameType(periodType);
    const list: NavFilterItem[] = this.getNavFilterList();
    this.updateNavFilterList(list);
  }

  public getCurrentTimeFrame() {
    return this.currentTimeFrame;
  }

  private getNavFilterList(): NavFilterItem[] {
    const list: NavFilterItem[] = DashboardFiltersUtilities.getNavFilterList();

    list.forEach((item: NavFilterItem, index: number) => {
      list[index].active = item.type === this.currentTimeFrame;
    });

    return list;
  }

  private setCurrentTimeFrameType({ timeFrame, rangeInfo }: PeriodType) {
    this.currentTimeFrame = timeFrame ?? this.currentTimeFrame;
    this.currentRangeInfo = rangeInfo ?? this.currentRangeInfo;

    if (this.currentTimeFrame === TimeFrameTypes.Custom && !this.currentRangeInfo) {
      this.currentTimeFrame = TimeFrameTypes.Month;
      this.currentRangeInfo = null;
    }

    this.updateCurrentPeriodType({ timeFrame: this.currentTimeFrame, rangeInfo: this.currentRangeInfo });
  }

  private updateNavFilterList(list: NavFilterItem[]): void {
    this.navFilterListSubject.next(list);
  }

  private updateCurrentPeriodType(periodType: PeriodType): void {
    const data: ActivityByPeriodPostDto = DateTimeZoneService.getPostDto(periodType);

    this.currentPeriodSubject.next(data);
  }
}
