/** Returns flat array of `NavMenuItem`s with its `subItems` recursively. */
export function getNavMenuItemsDeep(menuItems: NavMenuItem[]): NavMenuItem[] {
  const res = [];
  if (menuItems?.length) {
    menuItems.forEach((menuItem) => {
      res.push(menuItem);
      if (menuItem.subItems?.length) res.push(...getNavMenuItemsDeep(menuItem.subItems));
    });
  }
  return res;
}

export type NavMenuItem = {
  /** Label, that will be translated (Default: src/assets/i18n/en.json). */
  label?: string;

  /** Icon class. `Remix Icon` mostly. */
  icon?: string;

  /** Identifier for custom item handling. */
  identifier?: NavMenuIdentifier;

  /** Custom display handling. Will not be displayed on `showAll`. */
  customDisplayHandling?: boolean;

  /** This label should remain untranslated */
  untranslatedLabel?: boolean;

  hidden?: boolean;
} & (
  | { routePath: string; hyperLink?: never; function?: never; newTab?: never; param?: never; subItems?: never; pathWithParam?: boolean }
  | { routePath?: never; hyperLink: string; function?: never; newTab?: never; param?: never; subItems?: never; pathWithParam?: never }
  | { routePath?: never; hyperLink?: never; function: string; newTab?: boolean; param?: any; subItems?: never; pathWithParam?: never }
  | {
      routePath?: never;
      hyperLink?: never;
      function?: never;
      newTab?: never;
      param?: never;
      subItems: NavMenuItem[];
      pathWithParam?: never;
    }
);
//| { link?: never; hyperLink?: never; function?: never; param?: never; subItems?: never }

export enum NavMenuIdentifier {
  Dashboard,
  MarketingCenter,
  MarketingTools,
  MarketingAppsSettings,
  Templates,
  CFSTerminals,
  AdvancedTools, //manager
  URLsAndQRCodes,
  AppsShortURLs,
  CustomShortURLs,
  PixelTags,
  BulkLinks,
  OutboundMarketing, //manager
  POSRules, //manager & location-manager
  POSActivityReport, //manager
  POSSettings, //manager
  POSReceiptSettings, //manager
  ReportingAnalytics, //manager
  ThirdPartyAnalytics, //manager
  CustomTransactionReport, //manager
  Contacts,
  Products,
  CompanySettings,
  Locations,
  Devices,
  Users,
  MyBrand,
  CompanyConnectors, //manager
  DigitalScreens,
  DigitalScreensDashboard,
  LoyaltyProgram,
  LoyaltyReporting
}
