import { Component, OnInit } from "@angular/core";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-iframe-browser-modal",
  templateUrl: "./iframe-browser-modal.component.html",
  styleUrls: ["./iframe-browser-modal.component.scss"]
})
export class IframeBrowserModalComponent implements OnInit {
  public title = "";
  private trustedUrl: SafeResourceUrl;
  private _url: string;
  public get url() {
    return this._url;
  }
  public set url(value: string) {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    this._url = value;
  }
  public closeBtnText = "";
  constructor(public activeModal: NgbActiveModal, private translateSvc: TranslocoService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.closeBtnText = this.translateSvc.translateObject("common.controls.close");
  }
  close() {
    this.activeModal.close(true);
  }
}
