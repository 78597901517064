import { Injectable } from "@angular/core";
import { Account, RoleTypes } from "@app/models";
import { OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, Observable } from "rxjs";
import { ProfileModel } from "../models/profile-model";
import { JwtHelperService } from "./jwtHelperService";

@Injectable()
export class AccountService {
  private currentAccountSrc$ = new BehaviorSubject<Account>(null);
  private userSrc$: BehaviorSubject<ProfileModel> = new BehaviorSubject<ProfileModel>(this.user);

  isValidEmail = false;
  userChanged$: Observable<ProfileModel> = this.userSrc$.asObservable();
  currentAccount$ = this.currentAccountSrc$.asObservable();

  constructor(private oAuthService: OAuthService, private jwtHelper: JwtHelperService) {}

  get isLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  get user(): ProfileModel | undefined {
    if (this.accessToken) {
      return this.jwtHelper.decodeToken(this.accessToken);
    }
    return undefined;
  }

  get accessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  get idToken(): string {
    // Used to access user information
    return this.oAuthService.getIdToken();
  }

  get userId() {
    let userId = null;
    if (this.isLoggedIn && this.user) userId = +this.user.sub;
    return userId;
  }

  setCurrentAccount(account: Account) {
    this.currentAccountSrc$.next(account);
  }

  isUserInRole(role: RoleTypes): boolean {
    return this.isLoggedIn && this.user.role === role;
  }

  refreshUser() {
    this.userSrc$.next(this.user);
  }
}
