<div class="range-datepicker">
  <ngb-datepicker
    #dp
    (dateSelect)="onDateSelection($event)"
    [markDisabled]="onMarkDisabled.bind(this)"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [displayMonths]="2"
    [dayTemplate]="t"
    outsideDays="hidden"
  >
  </ngb-datepicker>

  <ng-template #t let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>
</div>
