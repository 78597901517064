import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { DeliveryPlatformAccount, DeliveryProviderGetDto, SenderOption } from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class DeliverySettingsService {
  private serviceUrl = "DeliverySettings";
  constructor(private http: DataService) {}

  getBrandSenderOptions(brandId: number): Observable<SenderOption[]> {
    return this.http.get(`${this.serviceUrl}/brand/${brandId}/senderOptions`).pipe(
      map((opts: SenderOption[]) => {
        return opts.map((o) => {
          o.value = JSON.parse(o.value as string);
          return o;
        });
      })
    );
  }

  createBrandSenderOption(brandId: number, senderOption: SenderOption): Observable<SenderOption> {
    const option = Object.assign({}, senderOption);
    option.value = JSON.stringify(senderOption.value);
    return this.http.post(`${this.serviceUrl}/brand/${brandId}/senderOptions`, option);
  }

  updateBrandSenderOption(brandId: number, senderOption: SenderOption): Observable<SenderOption> {
    if (!senderOption.deliveryProviderId) {
      // todo: remove courier temp fix after email delivery provider will be implemented
      senderOption.deliveryProviderId = 2;
    }

    const option = Object.assign({}, senderOption);
    option.value = JSON.stringify(senderOption.value);
    return this.http.put(`${this.serviceUrl}/brand/${brandId}/senderOptions/${senderOption.id}`, option).pipe(
      map((result: SenderOption) => {
        result.value = JSON.parse(result.value as string);
        return result;
      })
    );
  }
  deleteSenderOption(brandId: number, id: number): Observable<void> {
    return this.http.delete(`${this.serviceUrl}brand/${brandId}/senderOptions/${id}`);
  }

  getAllDeliveryProviders(): Observable<DeliveryProviderGetDto[]> {
    return this.http.get(`${this.serviceUrl}/providers/all`);
  }
  getAvaliableParnerDeliveryProviders(partnerId: number): Observable<DeliveryProviderGetDto[]> {
    return this.http.get(`${this.serviceUrl}/providers/partner/${partnerId}/available`);
  }

  saveAvaliableParnerDeliveryProviders(partnerId: number, providers: DeliveryProviderGetDto[]): Observable<DeliveryProviderGetDto[]> {
    return this.http.post(`${this.serviceUrl}/providers/partner/${partnerId}/available`, providers);
  }
  saveAvaliableBrandDeliveryProviders(brandId: number, providers: DeliveryProviderGetDto[]): Observable<DeliveryProviderGetDto[]> {
    return this.http.post(`${this.serviceUrl}/providers/brand/${brandId}/available`, providers);
  }

  getAvaliableBrandDeliveryProviders(brandId: number): Observable<DeliveryProviderGetDto[]> {
    return this.http.get(`${this.serviceUrl}/providers/brand/${brandId}/available`);
  }
  getPartnerSenderOptions(partnerId: number): Observable<SenderOption[]> {
    return this.http.get(`${this.serviceUrl}/partner/${partnerId}/senderOptions`);
  }
  getPartnerDeliveryAccounts(partnerId: number): Observable<DeliveryPlatformAccount[]> {
    return this.http.get(`${this.serviceUrl}/providers/partner/${partnerId}/account`);
  }
  getBrandDeliveryAccounts(brandId: number): Observable<DeliveryPlatformAccount[]> {
    return this.http.get(`${this.serviceUrl}/providers/brand/${brandId}/account`);
  }
  createPartnerDeliveryAccount(partnerId: number, platformAccount: DeliveryPlatformAccount): Observable<DeliveryPlatformAccount> {
    return this.http.post(`${this.serviceUrl}/providers/partner/${partnerId}/account`, platformAccount);
  }
  createBrandDeliveryAccount(brandId: number, platformAccount: DeliveryPlatformAccount): Observable<DeliveryPlatformAccount> {
    return this.http.post(`${this.serviceUrl}/providers/brand/${brandId}/account`, platformAccount);
  }
  updatePartnerDeliveryAccount(partnerId: number, platformAccount: DeliveryPlatformAccount): Observable<DeliveryPlatformAccount> {
    return this.http.put(`${this.serviceUrl}/providers/partner/${partnerId}/account`, platformAccount);
  }
  updateBrandDeliveryAccount(brandId: number, platformAccount: DeliveryPlatformAccount): Observable<DeliveryPlatformAccount> {
    return this.http.put(`${this.serviceUrl}/providers/brand/${brandId}/account`, platformAccount);
  }

  savePartnerSenderOptions(partnerId: number, options: SenderOption[]): Observable<SenderOption> {
    options = options.map((option) => {
      const optionCopy = Object.assign({}, option);
      optionCopy.value = JSON.stringify(option.value);
      return optionCopy;
    });
    return this.http.post(`${this.serviceUrl}/partner/${partnerId}/senderOptions`, options);
  }
  savePublicBrandSenderOption(brandId: number, option: SenderOption): Observable<SenderOption> {
    return this.http.post(`${this.serviceUrl}/brand/${brandId}/senderOption`, option);
  }
}
