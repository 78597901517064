import { AccountTemplateConfiguration } from "@app/models";
import { AccountConfigurationContentProviderDto } from "@app/models/content-providers";

export interface AccountUrlsConfigurationTagDto {
  title?: string;
  urls?: string;
  useIn?: string;
}

export interface UrlsConfigurationDto {
  secretGestureDestinationUrl?: string;
  tags?: AccountUrlsConfigurationTagDto;
}

export interface ActivationTransactions {
  reportName: string;
  isReportEnabled: boolean;
}

export interface AccountConfigurationReport {
  activationTransactions?: ActivationTransactions;
}
export interface AccountConfigurationSecurity {
  isPinCodeEnabled: boolean;
}

export interface AccountConfigurationDigitalScreensDto {
  isEnabled: boolean;
}

export interface AccountConfigurationExternalConnectionDto {
  contentProviders?: AccountConfigurationContentProviderDto[];
}

export interface AccountConfigurationSmsApiDto {
  isEnabled: boolean;
}

export class AccountConfigurationDto {
  templatesConfiguration: AccountTemplateConfiguration;
  urlsConfiguration: UrlsConfigurationDto;
  reporting?: AccountConfigurationReport;
  security: AccountConfigurationSecurity;
  digitalScreens?: AccountConfigurationDigitalScreensDto;
  externalConnection: AccountConfigurationExternalConnectionDto;
  smsApi?: AccountConfigurationSmsApiDto;
}
