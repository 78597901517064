import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { SubscriptionAddons, SubscriptionPlan, SubscriptionPlanSourceType, SubscriptionPlanType } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SubscriptionPlanService {
  private serviceUrl = "SubscriptionPlan";
  constructor(private http: DataService) {}

  getList(sourceType: SubscriptionPlanSourceType): Observable<SubscriptionPlan[]> {
    return this.http.get(`${this.serviceUrl}/${sourceType}`);
  }

  getListBySourceType(sourceType: SubscriptionPlanType): Observable<SubscriptionPlan[]> {
    return this.http.get(`${this.serviceUrl}/${sourceType}`);
  }

  getByProduct(productId: string): Observable<SubscriptionPlan> {
    return this.http.get(`${this.serviceUrl}/byProduct/${productId}`);
  }

  getLocationPlan(locationId: number): Observable<SubscriptionPlan> {
    return this.http.get(`${this.serviceUrl}/locationPlan/${locationId}`);
  }

  getAddons(): Observable<SubscriptionAddons[]> {
    return this.http.get(`${this.serviceUrl}/addons`);
  }
}
