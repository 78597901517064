export class SubscriptionAddons {
  public id: number;
  public name: string;
  public description: string;
  public defaultPriceConfiguration: PriceConfiguration;
  public addonType: SubscriptionAddonType;
  public quantity?: number;
  public imageUrl: string;
  public info: string;
  public isActive: boolean;
}

export class PriceConfiguration {
  public price: number;
  public productId: string;
  public priceTable: object;
}

export enum SubscriptionAddonType {
  Regular = "Regular",
  Device = "Device",
  StickerPrice = "StickerPrice",
  MarketingApp = "MarketingApp"
}