import { TimeFrameTypes } from "@app/models/enums";

export interface ActivityByPeriodPostDto {
  locationId?: number;
  startDate?: string;
  endDate?: string;
  period: TimeFrameTypes;
  timeZoneOffset?: number;
}

interface DashboardActivity {
  totalCount: number;
}

export interface ActivityByPeriod extends DashboardActivity {
  emailCount: number;
  smsCount: number;
  qrCount: number;
  cfsTemplateCount: number;
  totalCount: number;
  limit?: number;
  startDate?: string;
  locationId?: string;
  locationName?: string;
}

export interface SalesPersonActivityByPeriod extends DashboardActivity {
  salesPersonId: number;
  salesPersonName: string;
}

export interface AppsActivityByPeriod extends DashboardActivity {
  widgetId: number;
  widgetName?: string;
}

export interface ActivityByPeriodGetDto<Activity> {
  data: Activity[];
  endDate: string;
  startDate: string;
  total: number;
}

export interface TotalActivitiesCardInfo {
  totalCount: number;
  list: ActivitiesCardInfoItem[];
}

export interface ActivitiesCardInfoItem {
  label: string;
  value: number;
  progressPerCent: number;
  colorClass: string;
}

export enum DashboardChartOptions {
  smallDesktopScreen = 1100,
  smallMobileScreen = 499,
  chartDiameter = 300,
  legendGap = 100,
  maxDataLength = 32
}
