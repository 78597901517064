import { Injectable } from "@angular/core";

import { Location, LocationAvailabilityDto, LocationAvailabilityRequestDto, LocationShort } from "@app/models";
import { DataService } from "@app/core/services/data.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class LocationService {
  constructor(private http: DataService) {}
  serviceUrl = "locations";

  // Data handlers:

  static getLocationFullName(loc: Location): string {
    return `${loc.companyName}, ${loc.address}, ${loc.city}, ${loc.state}, ${loc.zip}`;
  }

  static getLocationName(locations: Location[], locationId: number): string {
    return locations?.find((location: Location) => location.id === locationId)?.companyName;
  }

  // API calls:

  getAll(): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}`);
  }
  getAllList(): Observable<Location[]> {
    return this.http.post(`${this.serviceUrl}/list`);
  }
  getLocationsAvailability(payload: LocationAvailabilityRequestDto): Observable<LocationAvailabilityDto[]> {
    return this.http.get(`${this.serviceUrl}/list/availability`, payload);
  }
  create(location: Location): Observable<Location> {
    return this.http.post(`${this.serviceUrl}`, location);
  }
  update(location: Location): Observable<Location> {
    return this.http.put(`${this.serviceUrl}`, location);
  }

  getUserLocations(userId: number): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/user/${userId}`);
  }

  getLocationsShort(includeLocationIds: number[] = [], top: number = 20, searchValue: string = ""): Observable<LocationShort[]> {
    const params = {
      includeLocationIds,
      top,
      searchValue
    };
    return this.http.get(`${this.serviceUrl}/short`, params);
  }

  updateUserLocations(userId: number, selectedLocations: number[]): Observable<Location[]> {
    return this.http.post(`${this.serviceUrl}/user/${userId}`, selectedLocations);
  }

  getLocationsForWidget(widgetId: number): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/widget/${widgetId}`);
  }

  getAccountLocations(accountId: number): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/account/${accountId}`);
  }
}
