import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AccountService, ProfileModel } from "@app/core";
import { RoleTypes } from "@app/models";
import { EventService } from "../core/services/event.service";

export const LAYOUT_VERTICAL = "vertical";
export const LAYOUT_HORIZONTAL = "horizontal";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"]
})
export class LayoutComponent implements OnInit, AfterViewInit {
  user: ProfileModel;
  layoutType: string;
  isLoad = false;
  isMenuVisible = false;

  get isIframe() {
    return window.self !== window.top;
  }

  constructor(private eventService: EventService, private accountService: AccountService, private cdr: ChangeDetectorRef) {
    this.accountService.userChanged$.subscribe((user) => {
      this.user = user;
      this.isMenuVisible = this.visibleMenu(user);
      if (this.isIframe && this.isMenuVisible) {
        this.layoutType = LAYOUT_HORIZONTAL;
      } else {
        this.layoutType = this.isMenuVisible ? LAYOUT_VERTICAL : LAYOUT_HORIZONTAL;
      }
    });
  }

  visibleMenu(user: ProfileModel) {
    return !(user && !user.doneQuickSetup && user.role === RoleTypes.Manager);
  }

  ngOnInit() {
    this.eventService.subscribe("changeLayout", (layout) => {
      this.layoutType = layout;
    });
  }

  ngAfterViewInit() {
    this.isLoad = true;

    this.cdr.detectChanges();
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
}
