import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { CampaignSettings, CampaignType, WidgetType } from "@app/models";
import { TranslocoService } from "@ngneat/transloco";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class CampaignService {
  getCampaignNameById(campaignId: CampaignType): string {
    return this.translateCampaignMap.get(campaignId);
  }
  constructor(private http: DataService, private translateService: TranslocoService) {
    this.initCampaignTranslations();
  }

  private serviceUrl = "campaign";
  private translateCampaignMap = new Map<CampaignType, string>();
  getAllCampaigns(): Observable<CampaignSettings[]> {
    return this.http.get(`${this.serviceUrl}`);
  }

  getLocationCampaigns(locationId: number): Observable<CampaignSettings[]> {
    return this.http.get(`${this.serviceUrl}/location/${locationId}`);
  }
  addCustomLocationCampaign(campaign: CampaignSettings): Observable<CampaignSettings> {
    return this.http.post(`${this.serviceUrl}`, campaign);
  }
  updateCustomCampaign(campaign: CampaignSettings): Observable<CampaignSettings> {
    return this.http.put(`${this.serviceUrl}/${campaign.id}`, campaign);
  }
  deleteCustomLocationCampaign(campaignId: number) {
    return this.http.delete(`${this.serviceUrl}/${campaignId}`);
  }
  initCampaignTranslations() {
    return this.translateService.selectTranslateObject("widgets").subscribe((t) => {
      for (const key in CampaignType) {
        if (isNaN(Number(key))) {
          this.translateCampaignMap.set(CampaignType[key] as unknown as CampaignType, t[key]);
        }
      }
    });
  }
}
