import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../core";
import {
  LoyaltyCardUserGetDto,
  PagesLoyaltyProgramApplyRewardPostDto,
  PagesLoyaltyProgramCheckInPostDto,
  ShortLinkQrCodeDto
} from "../dto";

@Injectable({
  providedIn: "root"
})
export class PagesLoyaltyProgramService {
  private serviceUrl = "pages/LoyaltyProgram";

  constructor(private api: ApiService) {}

  applyReward(payload: PagesLoyaltyProgramApplyRewardPostDto): Observable<void> {
    return this.api.post(`${this.serviceUrl}/apply-reward`, payload);
  }

  checkIn(data: PagesLoyaltyProgramCheckInPostDto): Observable<LoyaltyCardUserGetDto> {
    return this.api.post(`${this.serviceUrl}/check-in`, data);
  }

  qrCode(locationId: number): Observable<ShortLinkQrCodeDto> {
    return this.api.get(`${this.serviceUrl}/qr-code?locationId=${locationId}`);
  }
}
