import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Command } from "@app/core/commands/command";

@Component({
  selector: "app-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"]
})
export class TileComponent<T> {
  @Input() model: T;
  @Input() commands: Command<T>[];
  @Input() text: string;
  @Input() icon: string;
  @Input() interactive = true;
  @Input() disabled = false;
  @Input() warning = false;
  @Input() disabledTooltip = "";
  @Input() warningMessage = "";

  @Output() clicked = new EventEmitter();
  @Output() disabledChange = new EventEmitter<boolean>();

  click() {
    if (!this.disabled) this.clicked.emit();
  }

  onSwitch(event) {
    this.disabled = !this.disabled;
    this.disabledChange.emit(this.disabled);
    event.stopPropagation();
  }
}
