import {
  LoyaltyCardTypes,
  LoyaltyReward
} from "@app/pages/components/widget-settings/enroll-loyalty-widget-settings/enroll-loyalty-widget-settings";
import { ExternalLocation } from "../external-location";

export class LoyaltyCard {
  id: string;
  code: string;
  url: string;
  status: string;
  name: string;
  title: string;
  description: string;
  type: string;
  loyaltyCardType: LoyaltyCardTypes;
  amountOfStamps: number;
  amountOfPoints: number;
  frontImage: string;
  logo: string;
  locations: ExternalLocation[];
}

export class IndustryLoyaltyCard {
  id: number;
  industryId: number;
  loyaltyType: LoyaltyCardTypes;
  loyaltyCard: LoyaltyCard;
}

export enum LoyaltyExpirationPeriod {
  Never = "Never",
  Month = "Month"
}

export interface LoyaltyProgramExpirationDto {
  period: LoyaltyExpirationPeriod;
  value: number;
}
