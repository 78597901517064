import { Component, Input } from "@angular/core";

@Component({
  selector: "app-grid-not-found",
  templateUrl: "./grid-not-found.component.html",
  styleUrls: ["./grid-not-found.component.css"]
})
export class GridNotFoundComponent {
  @Input() allowAdd = true;
  @Input() otherActionText = "";
  @Input() notFoundText = "";
  @Input() itemName = "";
}
