import { LoyaltyExpirationPeriod } from "@app/models";
import { TranslocoService } from "@ngneat/transloco";
import { LoyaltyCardTypes, LoyaltyExpirationPeriodData } from "./enroll-loyalty-widget-settings";

export function getLoyaltyProgramExpirationPeriodLabel(expiration: LoyaltyExpirationPeriodData, transloco: TranslocoService): string {
  if (expiration) {
    const { period, value } = expiration;
    const i18nKey = `enums.LoyaltyExpirationPeriod.${period}`;

    return period === LoyaltyExpirationPeriod.Never ? `${transloco.translate(i18nKey)}` : `${value} ${transloco.translate(i18nKey)}`;
  }

  return "";
}

export function getLoyaltyProgramExpirationPeriodTitle(loyaltyType: LoyaltyCardTypes, transloco: TranslocoService): string {
  const i18Suffix = loyaltyType === LoyaltyCardTypes.Stampcard ? "Stamps" : "Points";
  const i18nKey = `widgets.enrollLoyalty.labelExpirationPeriod${i18Suffix}`;

  return transloco.translate(i18nKey);
}
