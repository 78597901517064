import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { DatePickerRangeInfo } from "../../../../models";

@Component({
  selector: "app-custom-range-datepicker",
  templateUrl: "./range-datepicker.component.html",
  styleUrls: ["./range-datepicker.component.scss"]
})
export class CustomRangeDatepickerComponent implements OnInit, AfterViewInit {
  constructor(public activeModal: NgbActiveModal, private calendar: NgbCalendar) {
    const currentDate = new Date();
    this.minDate = NgbDate.from({ year: 2010, month: 1, day: 1 });
    this.maxDate = NgbDate.from({ year: 2048, month: 12, day: 31 });
  }

  @Input() fromDate: NgbDate | null = null;
  @Input() toDate: NgbDate | null = null;
  @Input() maxDaysBetween?: number | null = null;

  @Output() selected = new EventEmitter<DatePickerRangeInfo>();

  @ViewChild("dp") dp: NgbDatepicker;

  hoveredDate: NgbDate | null = null;

  minDate: NgbDate;
  maxDate: NgbDate;

  private static getDateByNgb(date: NgbDate): Date {
    const { day, month, year } = date;

    return new Date(year, month - 1, day);
  }

  ngOnInit(): void {
    this.preHandleDataRangeOnInit();
    this.emitOnSelected();
  }

  ngAfterViewInit(): void {
    this.dp.navigateTo(this.fromDate);
  }

  onMarkDisabled(date: NgbDate, current?: { year: number; month: number }): boolean {
    return (
      (date.year >= this.maxDate.year && date.month >= this.maxDate.month && date.day >= this.maxDate.day) ||
      (date.year <= this.minDate.year && date.month <= this.minDate.month && date.day <= this.minDate.day)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = this.fromDate;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.handleLimitRange();
    this.emitOnSelected();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  private preHandleDataRangeOnInit(): void {
    if (!this.fromDate) {
      this.fromDate = this.calendar.getToday();
    }

    if (!this.toDate) {
      this.toDate = this.calendar.getNext(this.calendar.getToday(), "d", 10);
    }
  }

  private handleLimitRange(): void {
    if (this.maxDaysBetween != null) {
      if (this.fromDate && !this.toDate) {
        const currentDate: Date = CustomRangeDatepickerComponent.getDateByNgb(this.fromDate);
        const timeBetween: number = this.maxDaysBetween * (1000 * 3600 * 24);
        const maxDateTime: Date = new Date(new Date(currentDate).getTime() + timeBetween);

        this.maxDate = NgbDate.from({
          year: maxDateTime.getFullYear(),
          month: maxDateTime.getMonth() + 1,
          day: maxDateTime.getDate()
        });
      }
    }
  }

  private emitOnSelected(): void {
    this.selected.emit({
      fromDate: this.fromDate,
      toDate: this.toDate
    });
  }
}
