import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoadingService } from "@app/core";
import { expirationPeriodsList, IndustryLoyaltyCard, LoyaltyCard, LoyaltyExpirationPeriod, LoyaltyProgramExpirationDto } from "@app/models";
import { LoyaltyCardService } from "@app/services";
import { Unsubscriber } from "@app/shared/components";
import { positiveIntegerValidator } from "@app/shared/validators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { map, startWith, take } from "rxjs/operators";
import { maxTextAreaInputLength, WizardOptions, WizardStepItem } from "ui-kit";
import {
  LoyaltyCalculationConfiguration,
  LoyaltyCalculationMode,
  LoyaltyCardType,
  LoyaltyCardTypes,
  loyaltyCardTypesSettings,
  LoyaltyConditionConfiguration,
  LoyaltyMinimumRequirementMode,
  LoyaltyOnboardingRequest,
  LoyaltyReward,
  LoyaltyRewardItem
} from "../enroll-loyalty-widget-settings";
import { getLoyaltyProgramExpirationPeriodTitle } from "../enroll-loyalty-widget-settings.utils";

@Component({
  selector: "app-create-loyalty-program-modal",
  templateUrl: "./create-loyalty-program-modal.component.html",
  styleUrls: ["./create-loyalty-program-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateLoyaltyProgramModalComponent extends Unsubscriber implements OnInit {
  readonly baseInputValidators = [Validators.required, Validators.min(1)];
  readonly expirationPeriodOptions$ = this.loyaltyCardService.getExpirationList().pipe(
    map((options: LoyaltyProgramExpirationDto[]) =>
      options.map(({ period, value }) => {
        const i18nKey = `enums.LoyaltyExpirationPeriod.${period}`;

        return period === LoyaltyExpirationPeriod.Never
          ? { label: this.transloco.translate(i18nKey), value: 0 }
          : { label: `${value} ${this.transloco.translate(i18nKey)}`, value };
      })
    )
  );

  LoyaltyCardTypes = LoyaltyCardTypes;
  title: string;
  locationId: number;
  industryId: number;
  expirationPeriodTitle = "";
  cardTypesSetting: LoyaltyCardType[];
  enrollLoyaltyLink: string;
  selectedCard: LoyaltyCard;
  rewardsList: LoyaltyRewardItem[] = [];
  rewards: LoyaltyReward[] = [];
  loyaltyProgramDetailsResult: LoyaltyOnboardingRequest;
  loading = true;
  nothingFound = false;
  expirationPeriodsList = expirationPeriodsList;
  selectedCardTypeSetting: LoyaltyCardType;
  loyaltyCards: LoyaltyCard[] = [];
  industryLoyaltyCards: IndustryLoyaltyCard[] = [];
  wizardOptions: WizardOptions = {
    isModalWindow: true,
    button: {
      complete: { label: this.transloco.translate("common.controls.save") },
      previous: { label: this.transloco.translate("common.controls.back") }
    }
  };
  steps: WizardStepItem[] = [];

  form = new FormGroup({
    description: new FormControl(null, [Validators.maxLength(maxTextAreaInputLength)]),
    loyaltyType: new FormControl(2, [Validators.required]),
    loyaltyCardId: new FormControl("", [Validators.required]),
    goalBalance: new FormControl(null, [Validators.required]),
    expirationPeriod: new FormControl<number>(null, [Validators.required]),
    startingStamps: new FormControl(null, [Validators.required]),
    maxPerDay: new FormControl(null, [...this.baseInputValidators, positiveIntegerValidator]),
    rewards: new FormControl([]),
    calculationMode: new FormControl<LoyaltyCalculationMode>({ value: null, disabled: false }, [Validators.required]),
    minimumRequirementMode: new FormControl<LoyaltyMinimumRequirementMode>(LoyaltyMinimumRequirementMode.Amount, [Validators.required]),
    minimumAmount: new FormControl<number>({ value: null, disabled: false }, [...this.baseInputValidators]),
    minimumCount: new FormControl<number>({ value: null, disabled: true }, [...this.baseInputValidators]),
    count: new FormControl<number>({ value: null, disabled: true }, [...this.baseInputValidators]),
    amount: new FormControl<number>({ value: null, disabled: true }, [...this.baseInputValidators])
  });

  minReqRadioButtons = [
    { label: "widgets.enrollLoyalty.minAmount", value: LoyaltyMinimumRequirementMode.Amount, disabled: false },
    { label: "widgets.enrollLoyalty.minCount", value: LoyaltyMinimumRequirementMode.Count, disabled: false }
  ];

  constructor(
    public activeModal: NgbActiveModal,
    public loadingSvc: LoadingService,
    private loyaltyCardService: LoyaltyCardService,
    private transloco: TranslocoService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.cardTypesSetting = loyaltyCardTypesSettings.sort(this.compareNumbers);
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    this.steps = [
      {
        label: this.transloco.translateObject("widgets.enrollLoyalty.step1"),
        stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
      },
      {
        label: this.transloco.translateObject("widgets.enrollLoyalty.step2"),
        stepControl: this.form
      },
      {
        label: this.transloco.translateObject("widgets.enrollLoyalty.step3"),
        stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
      },
      {
        label: this.transloco.translateObject("widgets.enrollLoyalty.step4"),
        stepControl: this.form
      }
    ];

    if (this.form.controls.loyaltyType.value) {
      this.setCardProgramTypesList(this.form.controls.loyaltyType.value);
    }

    this.setLoyaltyCards();

    this.sub = this.form.controls.goalBalance.valueChanges.subscribe((value: number) => {
      this.form.controls.maxPerDay.setValidators([...this.baseInputValidators, positiveIntegerValidator, Validators.max(value)]);
      this.form.controls.maxPerDay.updateValueAndValidity({ emitEvent: false });
      this.resetRewards();
    });

    this.sub = this.form.controls.calculationMode.valueChanges
      .pipe(startWith(this.form.controls.calculationMode.value))
      .subscribe((value: LoyaltyCalculationMode) => {
        switch (value) {
          case LoyaltyCalculationMode.Points: {
            this.form.controls.count.setValidators([...this.baseInputValidators, positiveIntegerValidator]);
            this.form.controls.amount.setValidators([...this.baseInputValidators]);
            this.form.controls.count.enable({ emitEvent: false });
            this.form.controls.amount.enable({ emitEvent: false });
            break;
          }
          case LoyaltyCalculationMode.Purchase: {
            this.disableCalculationInputs();
            break;
          }
        }
      });

    this.sub = this.form.controls.minimumRequirementMode.valueChanges.subscribe((value: LoyaltyMinimumRequirementMode) => {
      switch (value) {
        case LoyaltyMinimumRequirementMode.Count: {
          this.form.controls.minimumCount.setValidators([...this.baseInputValidators, positiveIntegerValidator]);
          this.form.controls.minimumCount.enable({ emitEvent: false });
          this.form.controls.minimumAmount.clearValidators();
          this.form.controls.minimumAmount.disable();
          break;
        }
        case LoyaltyMinimumRequirementMode.Amount: {
          this.form.controls.minimumAmount.setValidators([...this.baseInputValidators]);
          this.form.controls.minimumAmount.enable({ emitEvent: false });
          this.form.controls.minimumCount.clearValidators();
          this.form.controls.minimumCount.disable();
          break;
        }
      }
    });
  }

  setCardProgramTypesList(id: LoyaltyCardTypes) {
    this.form.controls.loyaltyType.setValue(id);

    if (this.form.controls.loyaltyType.value) {
      this.selectedCardTypeSetting = loyaltyCardTypesSettings.find((o) => o.id === this.form.controls.loyaltyType.value);
      this.expirationPeriodTitle = getLoyaltyProgramExpirationPeriodTitle(this.form.controls.loyaltyType.value, this.transloco);
      this.loadCardsByType();
      this.resetStepsAfterChangeTypeProgram();
      this.updateAutomaticCalculationControls(id);
      this.updateStep1Validity();
      this.changeDetectorRef.markForCheck();
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  onSelectChanged(loyaltyCard: LoyaltyCard) {
    this.selectedCard = loyaltyCard;
    this.form.controls.loyaltyCardId.setValue(loyaltyCard.id);
    this.updateStep1Validity();
  }

  onRewardsUpdate(rewards: LoyaltyRewardItem[]): void {
    this.form.controls.rewards.patchValue(rewards);
    this.rewardsList = rewards;

    this.saveData();
  }

  onStepChange(stepIndex: number): void {
    if (stepIndex > 1) {
      this.saveData();
    }
  }

  saveResult() {
    if (this.loyaltyProgramDetailsResult && this.form.valid) {
      this.activeModal.close(this.loyaltyProgramDetailsResult);
    }
  }

  closeModal(): void {
    this.dismiss();
  }

  changeSelectGoalBalance(value) {
    if (!value) {
      this.form.controls.goalBalance.reset(null, { emitEvent: false });
    } else {
      this.changeMaxPerDay();
    }
  }

  changeSelectExpirationPeriod(value: number) {
    if (!value) {
      this.form.controls.expirationPeriod.reset(null, { emitEvent: false });
    }
  }

  changeSelectStartingStamps(value) {
    if (!value) {
      this.form.controls.startingStamps.reset(null, { emitEvent: false });
    }
  }

  private updateStep1Validity(): void {
    this.steps[0].stepControl.setValue(this.form.controls.loyaltyType.value > 0 && this.form.controls.loyaltyCardId.value.length > 1);
  }

  private setLoyaltyCards(): void {
    this.loading = true;
    if (this.industryId > 0) {
      this.loyaltyCardService
        .getIndustryLoyaltyCards(this.industryId)
        .pipe(take(1))
        .subscribe(
          (res: IndustryLoyaltyCard[]) => {
            this.industryLoyaltyCards = res;
            this.changeDetectorRef.markForCheck();
            this.loadCardsByType();
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  private loadCardsByType() {
    this.loyaltyCards = [];
    const cards = this.industryLoyaltyCards.filter((o) => o.loyaltyType === this.form.controls.loyaltyType.value);
    for (let i = 0; i < cards.length; i++) {
      if (cards[i] && cards[i].loyaltyCard) {
        this.loyaltyCards.push(cards[i].loyaltyCard as LoyaltyCard);
      }
    }
  }

  private compareNumbers(a: LoyaltyCardType, b: LoyaltyCardType) {
    return a.sortOrder - b.sortOrder;
  }

  private saveData() {
    this.rewardsList.forEach((e) => {
      this.rewards.push({ tier: e.tier, couponId: e.couponId });
    });

    if (this.form.valid) {
      this.loyaltyProgramDetailsResult = this.getLoyaltyProgramDetailsResult();
    }

    this.steps[2].stepControl.setValue(this.rewardsList.length > 0);
  }

  private resetStepsAfterChangeTypeProgram() {
    this.selectedCard = null;
    this.form.controls.description.setValue(null);
    this.form.controls.loyaltyCardId.setValue("");
    this.form.controls.goalBalance.reset(null, { emitEvent: false });
    this.form.controls.expirationPeriod.reset(null, { emitEvent: false });
    this.form.controls.startingStamps.reset(null, { emitEvent: false });
    this.form.controls.maxPerDay.reset(0, { emitEvent: false });
    this.form.controls.calculationMode.reset({ value: null, disabled: false }, { emitEvent: false });
    this.form.controls.amount.reset({ value: null, disabled: false }, { emitEvent: false });
    this.form.controls.count.reset({ value: null, disabled: false }, { emitEvent: false });
    this.form.controls.minimumAmount.reset(null, { emitEvent: false });
    this.form.controls.minimumCount.reset(null, { emitEvent: false });
    this.form.controls.rewards.reset([], { emitEvent: false });
    this.resetRewards();
  }

  private resetRewards(): void {
    this.rewardsList = [];
    this.steps[2].stepControl.setValue(false);
  }

  private changeMaxPerDay() {
    if (this.form.controls.maxPerDay.value > this.form.controls.goalBalance.value) {
      this.form.controls.maxPerDay.setValue(this.form.controls.goalBalance.value);
    }
  }

  private getLoyaltyProgramDetailsResult(): LoyaltyOnboardingRequest {
    const formData = this.form.getRawValue();
    const { count, amount, calculationMode, minimumAmount, minimumCount, minimumRequirementMode, expirationPeriod, loyaltyType, ...rest } =
      formData;
    const calculationConfiguration: LoyaltyCalculationConfiguration = {
      count,
      amount,
      calculationMode
    };
    const conditionConfiguration: LoyaltyConditionConfiguration = {
      [minimumRequirementMode]: formData[minimumRequirementMode]
    };
    const expiration = {
      period: expirationPeriod === 0 ? LoyaltyExpirationPeriod.Never : LoyaltyExpirationPeriod.Month
    };

    if (expirationPeriod > 0) {
      expiration["value"] = expirationPeriod;
    }

    return {
      ...rest,
      calculationConfiguration,
      conditionConfiguration,
      expiration,
      loyaltyType,
      isAutomaticCalculationEnabled: false
    };
  }

  private disableCalculationInputs() {
    this.form.controls.count.disable({ emitEvent: false });
    this.form.controls.amount.disable({ emitEvent: false });
    this.form.controls.amount.clearValidators();
    this.form.controls.count.clearValidators();
  }

  private updateAutomaticCalculationControls(id: LoyaltyCardTypes) {
    const isStampCard = id === LoyaltyCardTypes.Stampcard;
    const calculationMode = isStampCard ? LoyaltyCalculationMode.Purchase : LoyaltyCalculationMode.Points;

    this.disableCalculationInputs();

    if (isStampCard) {
      this.form.controls.count.setValue(calculationMode, { emitEvent: true });
    }
    this.form.controls.calculationMode.setValue(calculationMode, { emitEvent: true });
  }
}
